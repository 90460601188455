/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;300;400;500;600;700;800;900&display=swap');

body {
  font-size: 22px;
  font-family: 'Outfit', sans-serif;
  color: #4b4b4b;
  line-height: 1.8em;
  font-weight: 400;
  padding: 100px 0;
  background-color: #f7f3fc;
}

a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #222222;
  font-size: 20px;
  line-height: 1.5;
}

.container {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

/* p {
  font-size: 16px;
  margin: 0px 0 10px;
  line-height: 1.8;
} */

a {
  color: #971ca6;
  text-decoration: unset;
  font-weight: 600;
}

.pink-color {
  color: #971ca6;
}

.common-spacing {
  padding: 0 0 20px 0;
  border-bottom: 1px solid #f7f3fb;
  margin: 0 0 20px 0;
}

.top30 {
  padding-top: 30px;
}

/* main css start */
.pxlnx-privacy-section1 > h3 {
  font-weight: 400;
}
p.pxlnx-positon-p1 {
  position: relative;
  margin: 0 0 20px 0;
}

p.pxlnx-positon-p1 span {
  font-weight: 600;
  color: #000000;
}

.pxlnx-privacy-section1 p {
  margin: 0;
  font-weight: 500;
}

.pxlnx-privacy-section2 {
  padding: 10px 0px 0px;
}

.pxlnx-loginBtn>a {
  background: linear-gradient(90.81deg, #6F10B9 8.52%, #C02A93 110.1%);
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 25px;
  border-radius: 50px;
  transition: all 0.5s;
}

.pxlnx-privacy-policy-banner {
  margin: 0px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 30px 40px;
  border-radius: 10px;
}

.pxlnx-privacy-policy-inner-wrap {
  background-color: #ffffff;
  padding: 40px 40px;
  border-radius: 10px;
}

.pxlnx-privacy-section11 p span b {
  display: block;
}

p.pxlnx-positon-p {
  position: relative;
  margin: 0px 0 10px 20px;
}

p.pxlnx-positon-p span {
  position: absolute;
  left: -20px;
  top: 0px;
}

@media(max-width:580px) {
  body {
    padding: 30px 0;
  }

  .pxlnx-privacy-policy-banner {
    padding: 20px 20px;
  }

  .pxlnx-privacy-policy-inner-wrap {
    padding: 20px 20px;
  }

  .top30 {
    padding-top: 15px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 18px;
  }

}
@media(max-width:480px){
  .pxlnx-privacy-section12 p a {
    font-size: 14px;
  }
  p.pxlnx-positon-p1 {
    margin: 0 0 15px 0;
  }
  .pxlnx-privacy-policy-logo svg {
    width: 130px;
  }
}
@media(max-width:380px){
  .pxlnx-privacy-section12 p a {
    font-size: 12px;
  }
}

.pxlnx-privacy-section4 a{
  color: #1a73e8; 
  font-weight: 500;
  
}