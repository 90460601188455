/* Project Page Start*/
.xs_projects_wr {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap:15px;
    
}

.sch_topSpace_project{
    margin-top: 30px
}

.xs_projects_box {
    position: relative;
    padding: 10px;
    /* margin-bottom: 50px; */
    box-shadow: 0px 0px 60px 0px #5825710F;
    border: 1px solid var(--borderColor);
    background: #fff;
    border-radius: 8px;
    /* transition: all 0.5s; */
}
.active_post {
    box-shadow: 0 0 0 2px var(--sch-primary-color), inset 0 0 0 2px #fff;
}
.xs_projects_box.pxl-builderTrash {
    padding: 30px 10px 10px 10px;
  }
.xs_projects_box:hover .sch_hide_checkbox_projectTrash{
    opacity: 1;
    left: 5px !important;
    top: 5px !important;
}
.xs_projectModule .xs_project_name{
    /* padding: 25px 0px 0px; */
}
.xs_project_name {
    display: grid;
    position: relative;
    gap: 12px;
    /* padding: 15px 0px 0px; */
    align-items: center;
}
.xs_projectModule{

}
.xs_projects_box:hover .sch_hide_checkbox{
    opacity: 1;
}
.sch_hide_checkbox{
    opacity: 0;
    left: 5px !important;
    top: 5px !important;
}

.xs_project_menu {
    color: #D9D9D9;
    font-size: 30px;
    font-weight: 500;
    cursor: pointer;
}

/* .xs_project_menu_btns {
    position: absolute;
    right: 15px;
    bottom: -25px;
    z-index: 1;
} */
.xs_project_icons_sec > ul{
    display: flex;
    align-items: center;
    gap: 3px;
    position: absolute;
    bottom: -20px;
    right: 10px;
}
.xs_project_menu_btns>a {
    width: 36px;
    height: 36px;
    border-radius: 50px;
    background-color: var(--primaryColor);
    /* margin-bottom: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
}

.xs_project_menu_btns>a svg path {
    width: 30px;
    fill: #ffffff !important;
}

.xs_project_menu_btns.open>a {
    opacity: 1;
}

.xs_project_menu_btns.open>a:first-child {
    opacity: 1;
    transform: scale(1) translateY(0px);
}
                
.xs_project_menu_btns.open>a:last-child {
    transition-delay: 0.1s;
    transform: scale(1) translateY(0px);
}

.xs_project_menu_btns>a>svg>path {
    fill: var(--primaryColor);
}


.xs_project_name span {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    margin-top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(var(--primaryColor-rgb), 0.15);
}



.xs_project_name span svg {
    width: 30px;
    height: auto;
}

.xs_project_name span svg path {
    fill: var(--primaryColor);
}

.xs_project_name h3 {
    font-size: 18px;
    color: #161930;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 5px !important;
    /* max-width:150px; */
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */
    /* overflow: hidden; */
}

.xs_project_name h3:hover a{
    color: #8417af;
}
.xs_project_name > p {
    font-size: 14px;
    color: var(--primaryColor);
    margin: 0;
    font-weight: 400;
    /* padding-top: 10px; */
}
.pxl-pixasupport-project-head-tooltip.cun-iconTooltip .cun-show-tooltip:before {
    left: 0;
}
.pxl-pixasupport-project-head-tooltip.cun-iconTooltip .cun-show-tooltip > p {
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.xs_project_line {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #ECF0F1;
}

.xs_project_details_main {
    display: grid;
    gap: 15px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 15px;
    background: #FEFAFF;
}

.xs_project_details {
    display: grid;
    grid-template-columns: 90px 1fr;
    gap: 30px;
    align-items: center;
    padding: 0;
}

.xs_project_details_left p {
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    color: #4F536F;
}

.xs_project_avatar_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.xs_project_avatar {
    position: relative;
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.xs_project_avatar_initials {
    width: 22px;
    height: 22px;
    border-radius: 5px;
    background-color: var(--primaryColor);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
}

.xs_project_avatar .xs_project_avatar_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    border-radius: 50px;
    border: 1px solid var(--primaryColor);
}
.pxl-trash-builder-data .pxl-builder-projectWrapper .sch-custom-check-box {
    top: 10px;
    left: 10px;
}
.xs_project_details_right_link p{
    font-size: 16px;
    margin: 0;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
}
.xs_project_details_right p {
    font-size: 16px;
    margin: 0;
    font-weight: 400;
    color: var(--heading-color);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
}

.xs_project_details_right a {
    color: var(--primaryColor);
    font-weight: 400;
    font-size: 14px;
    overflow-wrap: anywhere;
}

.xs_project_details_right_link a {
    color: #4F536F;
    font-weight: 400;
    font-size: 14px;
    overflow-wrap: anywhere;
}

/* PRoject Skelaton loader Css Start */
.skeleton_uperbox {
    display: flex;
    background-color: #fff;
    padding: 10px;
    gap: 10px;
}

.skeleton_uperbox .skeleton_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.xs_justify_btween{
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 30px
}
.search_btn_right_wrap{
    display: flex;
    align-items: center;
    gap: 20px;
}
.xs_support_projectName > a{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.xs_support_projectName > a > h4{
    font-size: 18px;
    font-weight: 600;
    color: var(--sch-primary-color);
}
 /*  */
 @media(max-width:1800px){
    .xs_support_projectName > a{
        display: block;
    }
 }
 @media(max-width:1600px){
    .xs_project_details_right p{
        max-width: 160px;
    }
    .xs_project_details_right_link p{
        max-width: 160px;
    }
    .xs_support_projectName > a > h4{
        font-size: 15px;
    }
   
 }
 @media(max-width:1500px){
   
    .xs_project_name p{
        padding: 0px 0px 15px 0px;
    }
    .xs_project_name h3{
        max-width: 100%;
    }
    .xs_support_projectName > a > h4{
        font-size: 15px;
    }
 }
@media(max-width:1200px){
  
    .xs_project_name{
        gap: unset;
    }
}
@media(max-width:1156px){
    .xs_project_details_right p{
        max-width:160px;
    }
    .xs_project_details_right_link p{
        max-width:260px;
    }
}
@media(max-width:991px){
    .xs_project_details_right p{
        max-width: 100px;
    }
    .xs_project_details_right_link p{
        max-width: 100px;
    }
    .xs_projects_wr {
        grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
        
    }
}

@media(max-width:767px){
    .xs_support_projectName > a >{
        display: block;
    }
    .xs_support_projectName > a > h4{
        font-size: 18px;
        padding: 2px 0 5px 0;
    }
    .xs_projects_wr {
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        gap: 15px;
    }
    .xs_project_name h3{
        max-width: 230px;
    }
    .xs_support_projectName > a{
        display: block;
    }
}

@media(max-width:594px){
    .xs_project_name h3{
        max-width: 400px;
    }
    .xs_project_details_right p{
        max-width: 240px;
    }
    .xs_project_details_right_link p{
        max-width: 240px;
    }
}
@media(max-width:480px){
    .xs_support_projectName > a > h4{
        font-size: 16px;
    }
    .xs_project_menu_btns>a{
        width: 30px;
        height: 30px;
    }
    .xs_project_menu_btns>a>svg{
        width: 12px;
        height: 12px;
    }
    .xs_project_details_right p{
        max-width: 150px;
    }
    .xs_project_details_right_link p{
        max-width: 150px;
    }
    .xs_project_name h3{
        max-width: 300px;
        font-size: 18px;
    }
}
@media(max-width:380px){
    .xs_project_details_right p{
        max-width: 100px;
    }
    .xs_project_details_right_link p{
        max-width: 100px;
    }
    .xs_project_name h3{
        max-width: 220px;
    }
}









































/* Project Page End  */