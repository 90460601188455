.emoj_mainwrapper {
    padding: 10px;
    position: absolute;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 0px 5px rgba(0 0 0 / 15%);
    max-width: 250px;
    border-radius: 10px;
    width: 250px;
    bottom: 0;
    left: 100%;
    z-index: 999999;
}
.emoj_input {
    width: 100%;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    height: 40px;
    margin-bottom: 10px;
    outline: none !important;
    box-shadow: none !important;
    padding: 0px 15px;
}
.emoj_sectionsScroll ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.emoj_tabIcons li a {
    font-size: 14px;
}
.emoj_scrollableBox {
    /* max-height: 240px; */
    max-height: 159px;
    overflow: auto;
    margin-bottom: 20px;
}
.emoj_title {
    margin-bottom: 10px;
    line-height: normal;
}
.emoj_icons {
    margin: 3px;
}
.emoj_tabIcons {
    display: flex;
    flex-wrap: nowrap;
    /* padding: 5px; */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: space-around;
    background-color: #e5e5e5;
    border-top: 1px solid #ccc;
}
.emoj_tabIcons li{
    padding: 2px;
}
li.isEmojiActive {
    background-color: #fff;
}
.emoj_mainwrapper[placement="left"] {
    left: auto;
    /* bottom: 70px; */
    bottom: auto;
}
.emoj_mainwrapper[placement="right"] {
    left: 100%;
    right: auto;
}
.emoj_mainwrapper[placement="top"] {
    left: 0;
    right: auto;
    bottom: 100%;
    top: auto;
}
.emoj_mainwrapper[placement="bottom"] {
    left: 0;
    right: auto;
    top: 100%;
    bottom: auto;
}
.emoj_sectionsScroll ul li a {
    font-size: 16px;
}
@media(max-width:580px){
    .emoj_scrollableBox {
        max-height: 106px;
    }
    .cun-show-tooltip {
        display: none;
    }
}
@media(max-width:480px){
    .emoj_mainwrapper {
        max-width: 239px;
    }
}