/* Calendar css start  */
.pxl-body-white{
  background-color: #ffffff;
}
.pl_cal_img {
  padding: 10px;
  display: flex;
  gap: 3px;
  width: 100%;
  flex-wrap: wrap;
}

/* .fc .fc-daygrid-day-frame {
  min-height: 100%;
  height: 250px !important;
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
} */
.sch-container-fluid.schCalender-Wrapper {
  padding-bottom: 20px;
  position: relative;
  z-index: 0;
}
.fc .fc-daygrid-day-frame {
  border: 1px solid #E0DCF3;
  border-radius: 5px;
}
.fc .fc-daygrid-day-frame {
  /* min-height: 100%;
  max-height: 50px !important; */
  overflow: hidden;
  height:240px;
  position: relative;
  /* display: flex; */
  /* flex-direction: row-reverse; */
}

.fc .fc-daygrid-day-top {
  margin-right: 6px;
  background: none !important;
  position: absolute;
  right: 0;
}
.fc .fc-col-header-cell-cushion {
  cursor: initial;
}


.fc .fc-daygrid-day-number span {
  cursor: initial;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #E0DCF3;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  flex: 1;
}

.fc-daygrid-dot-event {
  flex-direction: column;
  padding: 5px 7px;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
  background: none;
}

.fc-theme-standard .fc-scrollgrid-sync-table td {
  padding: 3px;
  position: relative;
  width: 239px;
  height: 239px;
}
.fc-scrollgrid-sync-table tr:last-child {
  display: none;
}
.fc-scroller-harness .fc-theme-standard td,
.fc-theme-standard th {
  border: 0px;
}

.fc .fc-day-other .fc-daygrid-day-frame {
  background: #f8f1f9;
  border-radius: 5px;
}

.fc-scrollgrid-liquid {
  height: 100%;
  border-radius: 5px;
  overflow: hidden;

}

.fc-scrollgrid-liquid thead {
  background: #F6F4FF;
  border: 1.19px solid #E0DCF3;
  border-radius: 50% !important;
  line-height: 50px;
  color: #4F536F;
}

.pl_calender_post_box {
  flex-shrink: 0;
  width: 200px;
  /* max-height: 215px; */
  height: 100%;
  padding: 5px 5px 20px;
  position: relative;
  transition-property: transform;
  display: block;
  border: 1px solid #E0DCF3;
  border-radius: 5px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
}

.slider {
  transition: all 1s ease;
}

.pl_calender_post_box {
  transform: translateZ(0);
  backface-visibility: hidden;
}

.pl_calender_post_box .pl_cal_img_div {
  margin-bottom: 4px;
  background: #F3EEF8;
}

.pl_slider {
  position: relative;
}

.pl_calender_post_box .pl_cal_img_div img {
  border-radius: 5px;
  width: 100%;
  /* height: 148px;
  object-fit: cover; */
  height: 117px;
  object-fit: cover;
  display: block;

  object-position: top;
}

.pl_calender_post_box .pl_cal_caption h6 {
  font-size: 12px;
  padding: 0 3px 2px 3px;
  margin-bottom: 6px;
  word-wrap: break-word;
  display: -webkit-box;
  width: 340px;
  max-width: 100%;
  max-height: 90px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.7;
}

.pl_slider .pl_calender_slider .pl_calender_prev {
  position: absolute;
  left: 0px;
  top: 30%;
  width: 20px;
  height: 20px;
  border: 0;
  background-color: rgb(219, 215, 215);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  border-radius: 50px;
  transform: rotate(90deg);
  z-index: 9;
}

.pl_slider .pl_calender_slider .pl_calender_next {
  position: absolute;
  right: 20px;
  top: 30%;
  width: 20px;
  height: 20px;
  border: 0;
  background-color: rgb(219, 215, 215);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  border-radius: 50px;
  transform: rotate(90deg);
  z-index: 9;
}

.pl_calender_options {
  display: block;
  padding: 0 0 5px 0;
  justify-content: space-between;
}

.cun-calendar-social {
  padding: 3px 0px 0px;
}

.cun-calendar-social span {
  padding: 0px 5px 0px 0px;
}

.cun-calendar-social span svg {
  width: 18px;
  height: 18px;
}

.pl_calender_options .pl_calender_options_div {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.pl_calender_options_div div {
  background: #F4F1FB;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 20px;
}

.pl_calender_options_div .pl_calendar_delete svg {
  width: 12px;
  fill: rgb(158, 143, 196);
}

.pl_navigation-button {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
  display: none;
}

.pl_navigation-button .pl_dot {
  width: 6px;
  height: 6px;
  background: #E3DDF8;
  border-radius: 50%;
}

.pl_navigation-button .pl_dot_active {
  background: linear-gradient(90.81deg, #6F10B9 8.52%, #C02A93 110.1%),
    linear-gradient(0deg, #E3DDF8, #E3DDF8);
}

.pl_cal_post_flex {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
  box-sizing: content-box;
  /* gap: 42px; */
  gap: 40px;
}

.cun-caption-threeLine {
  position: relative;
  width: calc(100% - 20px);
}

.cun-caption-threeLine h6 {
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 13px;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
}

.cun-caption-oneline {
  position: relative;
  width: calc(100% - 20px);
}

.cun-caption-oneline h6 {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  font-size: 13px;
  white-space: nowrap;
}

.fc .fc-button-primary:focus {
  box-shadow: unset !important;
}



.cal_post_dot_wrap {
  position: relative;
}

/* menu dropedown */
.sch-cal-submenubox {
  box-shadow: -7px 7px 25px 0px #0000001A;
  min-width: 130px;
  max-width: 130px;
  padding: 15px 0px 10px;
  border-radius: 4px;
  border: 1px solid #E9EDF6;
  background-color: var(--whiteColor);
  position: absolute;
  right: 10px;
  top: -20px;
  bottom: auto;
  z-index: 22;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-40px);
}

.sch-menuOpen .sch-cal-submenubox {
  transition: all 0.3s;
  opacity: 1;
  visibility: visible;
  transform: translateX(-20px);
}

.sch-cal-submenubox>ul>li>a {
  font-size: 14px;
  font-weight: 400;
  color: #72778F;
  display: flex;
  align-items: center;
  margin: 0 0 5px 0;
  padding: 0px 20px;
}

.sch-cal-submenubox>ul>li>a>span {
  position: relative;
  top: -2px;
  width: 25px;
}

.sch-cal-submenubox>ul>li>a>span>svg path {
  fill: #72778F;
}

.sch-templateMenu {
  position: absolute;
  right: 12px;
  top: 6px;
}
.pxl-calenderMain-wrapper-box {
  background-color: #ffffff;
  padding: 30px 0 30px;
}
.fc .fc-daygrid-day.fc-day-today{
  background-color: transparent!important;
}
td.fc-day.fc-day-mon.fc-day-today.fc-daygrid-day .fc-daygrid-day-frame{
  /* background-color:rgba(255,220,40,.15); */
}

.pl_calender_options span {
  padding: 0px 5px 0px 0px;
}
.fc-daygrid-day.fc-day-today .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
  /* background: #fdfddd; */
}
.fc-daygrid-day.fc-day-today .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
  background: #fdfddd;
}
.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 1;
  color: #b8b7b7;
}
/* ==============media css start ==============*/
@media(max-width:1700px) {
  .pl_calender_post_box {
    width: 180px;
}

  .cun-calendar-social {
    padding: 0px;
  }

  .pl_calender_options {
    padding: 0;
  }

  .cun-calendar-social span svg {
    width: 15px;
    height: 15px;
  }

  .fc-theme-standard .fc-scrollgrid-sync-table td {
    width: 200px;
  }



  .pl_calender_post_box .pl_cal_img_div img {
    /* height: 55px; */
    height: 96px;
  }

  .fc-theme-standard .fc-scrollgrid-sync-table td {
    height: 160px;
  }

  .fc .fc-daygrid-day-frame {
    /* height: 220px; */
  }

  .pl_calender_post_box {
    width: 150px;
  }

  .fc-theme-standard .fc-scrollgrid-sync-table td {
    width: 150px;
  }

  .fc .fc-list-sticky .fc-list-day>* {
    z-index: 1;
  }

  .sch-cal-submenubox {
    min-width: 100px;
    max-width: 100px;
    padding: 5px 0px 5px;
    top: auto;
    bottom: 0;
  }

  .sch-cal-submenubox>ul>li>a {
    padding: 0px 14px;
  }

}


@media(max-width:1550px) {
  .pl_calender_post_box .pl_cal_img_div img{
    /* height: 75px ; */
  }
}

@media(max-width:1500px) {
  .pl_calender_post_box {
    /* width: 110px; */
    /* width: 125px; */
    
  }

  .pl_calender_options {
    display: block;
  }

  .pl_calender_options_div div {
    background: transparent;
    width: auto;
    height: auto;
  }


  .sch-cal-submenubox {
    top: 0;
    bottom: auto;
}

}

@media(max-width:1300px) {
  .fc .fc-daygrid-day-frame {
    height: 220px;
}
.fc .fc-daygrid-day-events {
  margin: 25px 0 0 2px;
}
  .sch-cal-submenubox>ul>li>a {
    padding: 0px 5px;
}
.sch-cal-submenubox>ul>li>a {
  font-size: 13px;
}
.sch-cal-submenubox {
  min-width: 80px;
  max-width: 80px;
  padding: 2px 0px 0px;
}
.sch-cal-submenubox>ul>li>a>span {
  width: 19px;
}
.sch-cal-submenubox>ul>li>a>span>svg {
  width: 10px;
  height: 11px;
}
.sch-cal-submenubox>ul>li>a>span>img {
  width: 9px;
  height: 11px;
}
  .fc-theme-standard .fc-scrollgrid-sync-table td {
    width: 160px;
  }
  .pl_calender_post_box {
    /* width: 110px; */
    width: 128px;
    
  }
  .cun-caption-threeLine h6 {
    -webkit-line-clamp: 5;
}
}

@media(max-width:1200px) {
  .pl_calender_post_box {
    width: 115px;
  }

  .fc-theme-standard .fc-scrollgrid-sync-table td {
    width: 110px;
  }

  .cun-calendar-social span svg {
    width: 12px;
    height: 12px;
  }

}
@media(max-width:1150px) {
  .pl_calender_post_box {
    width: 105px;
  }

}
@media(max-width:1000px) {
  .fc .fc-list-day-cushion, .fc .fc-list-table td {
    padding: 20px 14px 50px;
}
  .pl_calender_post_box .pl_cal_img_div img {
    height: 140px;
}
  .pl_calender_post_box {
    width: 170px;
  }
}

@media (max-width: 900px) {
  .fc .fc-view-harness {
    flex-grow: 1;
    position: relative;
    height: 720px !important;
  }

  .fc-direction-ltr .fc-list-table .fc-list-event-graphic {
    display: none;
  }
}

@media(max-width:991px){
  .sch-cal-submenubox {
    min-width: 90px;
    max-width: 90px;
    padding: 4px 5px 2px;
}
}
@media(max-width:767px){

}
@media(max-width:580px){
  
}
@media (max-width: 500px) {
  .fc .fc-toolbar-title {
    font-size: 16px;
  }
  .sch-cal-submenubox>ul>li>a>span>img {
    top: 1px;
    position: relative;
}
}
@media(max-width:480px){
  
}
@media(max-width:380px){
  
}