@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

/*----- Comman Css -----*/
:root {
  --primaryColor: #6F10B9;
  --sch-title-color: #161930;
  --sch-pink-color: #AD249B;
  --sch-primary-color: #8417AF;
  --secondryColor: #C02A93;
  --body_bgColor: #fcfbff;
  --textColor: #2E253A;
  --placeholder_color: #8A9BB1;
  --headdingColor: #29325F;
  --whiteColor: #ffffff;
  --sch-color2: #F6F4FF;
  --sch-color3: #CDC3FC;
  --sch-color5: #9487CB;
  --fontFamily: 'Outfit', sans-serif;
  --borderColor: #D0DCEA;
  --sch-border-color1: #E5DFFE;
  --blueColor: #3a8ffd;
  --grayColor: #e7ebf1;
  --transition: all 0.3s ease-in-out;
  --bodyBGColor: linear-gradient(45deg, rgb(54 69 215 / 5%), rgb(116 12 188 / 5%));
  --gradientColor: linear-gradient(91deg, var(--primaryColor) 0%, var(--secondryColor) 101.1%);
  --pxl-placeholder: #e5e5e5;
  --sch-gradient-bg: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%), #FFF;
  --border-radius5px: 5px;
  --border-radius3px: 3px;
  --input-border: #E7EEF8;
  --bg-color1: #FAFCFF;
  --widget-color: #8417AF;
  --blue-color1: #3DB9FF;
  --pink-color1: #FF627E;
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: var(--fontFamily) !important;
  color: var(--textColor);
  font-size: 16px;
  font-weight: 400;
  background-color: #F7F3FC;
  scroll-behavior: smooth;
  /* scrollbar-width: 2px;
  scrollbar-color: var(--primaryColor) #E6ECF2; */
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: var(--whiteColor);
}

::-webkit-scrollbar {
  width: 5px;
  height: 3px;
  background-color: var(--whiteColor);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;

  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--secondryColor);
}

label {
  /* text-transform: capitalize; */
}

a {
  color: inherit;
  transition: all 0.3s;
  cursor: pointer;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--headdingColor);
  margin: 0;
  font-family: var(--fontFamily);
}

h4 {
  font-size: 20px;
}

p,
ul,
ol {
  margin: 0;
  padding: 0;
}

ul li,
ol li {
  list-style: none;
}

img {
  max-width: 100%;
}

.pxl-star-red {
  color: red;
}
.css-qr46ko{
  padding: 0px !important;
}
/* ::placeholder { */
/* text-transform: capitalize; */
/* } */

/* For Internet Explorer */
/* :-ms-input-placeholder { */
/* text-transform: capitalize; */
/* } */

input[type=checkbox][disabled] {
  cursor: unset !important;
  ;
}

a:hover,
a:focus {
  text-decoration: none;
  /* outline: none; */
}

.pxlText-capital {
  text-transform: capitalize !important;
}

.pxl-flex-divide {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pxl-body-white {
  background-color: #ffffff;
}

/* focus css start */
.css-19bb58m>input:focus-visible {
  padding: 0px 40px !important;
}

.css-19bb58m>input:focus {
  padding: 0px 40px !important;
}

input:focus-visible {
  box-shadow: 0 0 0 1px #2196f3 !important;
  border-radius: 3px;
}

select:focus-visible {
  box-shadow: 0 0 0 1px #2196f3 !important;
  border-radius: 3px !important;
  ;
  outline: none;
}

.css-d7l1ni-option {
  cursor: pointer !important;
}

.css-tr4s17-option {
  cursor: pointer !important;
}

/* a:focus{
color: #000000 !important;;
} */
a:focus-visible {
  box-shadow: 0 0 0 1px #2196f3 !important;
}

button:focus-visible {
  box-shadow: 0 0 0 1px #2196f3 !important;
}

textarea:focus {
  /* box-shadow: 0 0 0 1px #2196f3 !important; */
}

textarea:focus-visible {
  box-shadow: 0 0 0 1px #2196f3 !important;
}

button.sch-btn-iconLeft:focus {
  box-shadow: 0 0 0 1px #2196f3 !important;
}

.form-control:focus {
  border-color: #e5dffe !important;
}

/* default placeholder color css */
.pxl-placeholder-color1 input::placeholder {
  color: #8A9BB1 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}

.pxl-headingTop>h2 {
  margin: 0;
  font-size: 30px;
  font-weight: 700;
  color: #161930;
}

.pxl-upComing-box {
  padding: 30px 0 0 0;
}

/* default color css */
.pxl-blue-color {
  background-color: var(--blue-color1) !important;
}

.pxl-pink-color {
  background-color: var(--pink-color1) !important;
}

.cun-headreSelect.pxl-moveTo-project-select {
  padding: 0px;
}

/* default font size */
.pxl-font-20-weight-700 {
  color: var(--sch-title-color) !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  margin: 0;
}

.pxl-font-18-weight-600 {
  font-size: 18px !important;
  font-weight: 600 !important;
  margin: 0;
}

.pxl-font-16-weight-400 {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 0;
}

.pxl-color-black {
  color: var(--sch-title-color) !important;
}

/* button tag */
/* ===========default css start ==================*/
.sch-divide-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sch-divide-flex.sch-section-bottom-space {
  padding: 0 0 30px;
}

/* tooltip btn */
.pxl-tooltip-button-form button {
  border: none;
}

/* back btn css round */
a.pxl-backBtn-round {
  cursor: pointer;
  color: #161930;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #D7C9E5;
  height: 40px;
  min-width: 86px;
  border-radius: 100px;
  background-color: #ffffff;
  transition: all 0.5s;
}

a.pxl-backBtn-round>span {
  padding: 0 5px 0 0;
}

a.pxl-backBtn-round:hover {
  border-color: #6f10b9;
}

.css-19bb58m>input:focus-visible {
  padding: 0px 80px !important;
}

.css-19bb58m>input:focus {
  padding: 0px 80px !important;
}

/* ====common btn=== */
.pxlCommon-btn {
  cursor: pointer;
  position: relative;
  padding: 4px 20px 4px 20px;
  border-radius: 5px;
  color: var(--sch-white);
  height: 50px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border: 0;
  flex-wrap: wrap;
  background: -o-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#994E8F', endColorstr='#F3527C', GradientType=1);
  background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  min-width: 120px;
  max-width: fit-content;
  /* box-shadow: 0px 20px 50px 0px #6927F24D; */
}

.pxlCommon-btn:before {
  content: '';
  display: block;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.pxlCommon-btn:after {
  content: '';
  display: block;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.pxlCommon-btn:hover:before {
  background-position: left bottom;
}

.pxlCommon-btn:after {
  background-position: right bottom;
}

/*== button css ==*/
button.sch-btn-iconLeft {
  cursor: pointer;
  position: relative;
  background: var(--sch-primary-color);
  padding: 4px 20px 4px 5px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  color: var(--sch-white);
  height: 50px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  border: 0;
  flex-wrap: wrap;
  /* text-transform: capitalize; */
  background: -o-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#994E8F', endColorstr='#F3527C', GradientType=1);
  background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
}

button.sch-btn-iconLeft:before {
  content: '';
  display: block;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

button.sch-btn-iconLeft:after {
  content: '';
  display: block;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

button.sch-btn-iconLeft>span {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 13px 0 0;
  /* background: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%); */
  background: linear-gradient(91deg, #C02A93 8.52%, #6F10B9 110.1%);
  border-radius: 3px;
}

button.sch-btn-iconLeft:hover:before {
  background-position: left bottom;
}

button.sch-btn-iconLeft:after {
  background-position: right bottom;
}

/* anchor tag icon left button css start */
a.sch-btn-iconLeft {
  cursor: pointer;
  position: relative;
  background: var(--sch-primary-color);
  padding: 4px 20px 4px 5px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  color: var(--sch-white);
  height: 50px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-self: center;
  position: relative;
  overflow: hidden;
  border: 0;
  flex-wrap: wrap;
  /* text-transform: capitalize; */
  background: -o-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#994E8F', endColorstr='#F3527C', GradientType=1);
  background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
}

a.sch-btn-iconLeft:before {
  content: '';
  display: block;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

a.sch-btn-iconLeft:after {
  content: '';
  display: block;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

a.sch-btn-iconLeft>span {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 13px 0 0;
  /* background: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%); */
  background: linear-gradient(91deg, #C02A93 8.52%, #6F10B9 110.1%);
  border-radius: 3px;
}

a.sch-btn-iconLeft:hover:before {
  background-position: left bottom;
}

a.sch-btn-iconLeft:after {
  background-position: right bottom;
}

/*===== anchor tag icon left button css end =====*/

/*== button tag disable css start ==*/

button.sch-btn-iconLeft.sch-btn-disbale {
  background: #cccbcb !important;
  color: var(--sch-global-color);
  cursor: unset;
}

button.sch-btn-disbale {
  background: #cccbcb !important;
  color: var(--sch-global-color);
  cursor: unset;
  border: none;
}

button.sch-btn-iconLeft.sch-btn-disbale>span {
  background: #bdbcbc;
}

/*== button tag disable css end ==*/

a.sch-btn {
  position: relative;
  background: var(--sch-primary-color);
  padding: 4px 20px 4px 5px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  color: var(--sch-white);
  height: 50px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-self: center;
  position: relative;
  overflow: hidden;
  border: 0;
  flex-wrap: wrap;
  /* text-transform: capitalize; */
  background: -o-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#994E8F', endColorstr='#F3527C', GradientType=1);
  background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
}

a.sch-btn:before {
  content: '';
  display: block;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

a.sch-btn:after {
  content: '';
  display: block;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

a.sch-btn>span {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 13px 0 0;
  background: linear-gradient(91deg, #C02A93 8.52%, #6F10B9 110.1%);
  border-radius: 3px;
}

a.sch-btn:hover:before {
  background-position: left bottom;
}

a.sch-btn:after {
  background-position: right bottom;
}

/* pink button css start */
button.pxl-pink-btn-delete {
  cursor: pointer;
  position: relative;
  background: var(--sch-primary-color);
  padding: 4px 20px 4px 5px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  color: var(--sch-white);
  height: 50px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-self: center;
  position: relative;
  overflow: hidden;
  border: 0;
  flex-wrap: wrap;
  /* text-transform: capitalize; */
  background: linear-gradient(91.18deg, #FF406E 12.31%, #C02A93 159.14%);
}

button.pxl-pink-btn-delete:before {
  content: '';
  display: block;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

button.pxl-pink-btn-delete:after {
  content: '';
  display: block;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

button.pxl-pink-btn-delete>span {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 13px 0 0;
  /* background: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%); */
  background: linear-gradient(90.81deg, #FF6B8E 8.52%, #F03A76 110.1%);
  border-radius: 3px;
}

button.pxl-pink-btn-delete:hover:before {
  background-position: left bottom;
}

button.pxl-pink-btn-delete:after {
  background-position: right bottom;
}

/* ======= all button css end =======*/
/*============== popup flex css ===========*/
.sch-section-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sch-pupup-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.pxl_filterFeilds button {
  /* margin: 0px 0 5px; */
}

.pxl-templates-status-bar .cun-AllChecknoxSelect {
  margin: 0 0 5px;
}

/* default css start  */
.cun-FlexEnd {
  justify-content: flex-end !important;
}

.sch-bold {
  font-weight: 800 !important;
}

.sch-italic {
  font-style: italic !important;
}

.sch-hide {
  display: none !important;
}

.sch-text-left {
  text-align: left !important;
  margin: 0px 0px 10px !important;
}

.sch-input-50px {
  background-color: transparent;
  height: 50px;
  padding: 0px 20px;
  border: 1px solid var(--sch-color2);
  border-radius: 5px;
}

.sch-input-60px {
  border: 1px solid var(--input-border) !important;
  background: linear-gradient(0deg, #E7EEF8, #E7EEF8), linear-gradient(0deg, #FAFCFF, #FAFCFF) !important;

}

/* ===============top bottom spacing default css =============*/
.sch-margin-bottom15 {
  margin-bottom: 15px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #2E253A;
}

/*----- LoginPage Css -----*/
.pxl_loginWrapper {
  background-color: #EFEBFF;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pxl_mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 100%;
  width: 100%;
}

.pxl_leftBox {
  max-width: 100%;
  padding: 0px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left center;
  background-color: #FCFBFF;
  height: 100vh;
}

.pxl_overlayBox {
  max-width: 800px;
  height: 150px;
  background-color: rgba(255 255 255 / 20%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  border-radius: 20px;
  position: relative;
  margin: auto;
}

.pxl_overlayBox h2 {
  color: #F8EFFF;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: 40px;
}

.pxl_loginWrapper_after {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
}

.auth_img_box>img {
  max-width: 877px;
  width: 100%;
  height: auto;
  margin: 0 auto 0 65px;
}

.auth_img_box {
  padding: 80px;
  max-width: 100%;
  width: 100%;
  position: relative;
}

.auth_img_box>img {
  max-width: 877px;
  width: 100%;
  height: auto;
  margin: 0 auto 0 65px;
}

.pxl_loginIcon {
  position: absolute;
}

.pxl_moveIcon4,
.pxl_moveIcon5,
.pxl_moveIcon3 {
  -webkit-animation: action 1.5s infinite alternate;
  animation: action 1.5s infinite alternate;
}

@-webkit-keyframes action {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes action {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.pxl_moveIcon1,
.pxl_moveIcon2,
.pxl_moveIcon6 {
  -webkit-animation: action2 1.5s infinite alternate;
  animation: action2 1.5s infinite alternate;
}

@-webkit-keyframes action2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-10px);
  }
}

@keyframes action2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-10px);
  }
}

.pxl_moveIcon1 {
  top: 5%;
  left: 5%;
}

.pxl_moveIcon2 {
  bottom: 30%;
  right: 5%;
}

.pxl_moveIcon3 {
  top: 2%;
  right: 15%;
}

.pxl_moveIcon4 {
  bottom: 38%;
  left: 49%;
}

.pxl_moveIcon5 {
  top: 5%;
  left: 32%;
}

.pxl_moveIcon6 {
  bottom: 30%;
  left: 15%;
}

.pxl_leftForm>span>img {
  max-width: 190px;
}

.input_auth_box {
  width: 100%;
}

.pxl_loginForm {
  /* max-width: 30%; */
  max-width: 560px;
  width: 100%;
}

/* .pxl_leftForm { */
/* flex-direction: column;
  display: flex;
  width: 100%;
  padding: 0px 0px 0 0;
  align-items: flex-start;
  gap: 15px;
  flex-shrink: 0;
  position: relative; */
/* } */

.pxl_leftForm span {
  font-size: 14px;
  color: var(--placeholder_color);
  font-weight: 400;
}

.pxl_checkboxHolder.text-center.mt-4 {
  justify-content: center;
}

.pxl_form h4 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.48px;
  margin-bottom: 0px;
}

.pxl_form>p {
  font-size: 18px;
  line-height: 30px;
  color: var(--textColor);
  font-weight: 400;
  transition: all .4s;
  text-align: left;
}

/* .pxl_form p:hover { */
/* color: var(--primaryColor); */
/* } */

.pxl_logo_auth {
  text-align: left;
}

.pxl_logo_auth h3 {
  font-size: 18px;
  /* color: var(--whiteColor); */
  font-weight: 400;
  margin-top: 0;
  padding: 15px 0px 10px;
  /* text-transform: capitalize; */
  /* background: var(--gradientColor); */
}

.pxl_logo_auth>h3>span {
  font-size: 18px;
  line-height: 32px;
  color: var(--primaryColor);
  font-weight: 700;
}

.pxl_logo h3 {
  font-size: 34px;
  line-height: 24px;
  color: var(--textColor);
  font-weight: 600;
}

.pxl_logo h3 span {
  font-size: 35px;
  line-height: 24px;
  color: var(--primaryLight);
  font-weight: 900;
}

.pxl_rightHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 360px;
  padding-top: 2px;
}

.sch-btns-wrap .sch-headBtn button.sch-btn-iconLeft {
  /* height: 45px; */
}

.pxl_socialLogin_btn {
  border-radius: 14px;
  border: 1px solid #E8E2FF;
  background: var(--sch-color2);
  box-shadow: 0px 4px 0px 0px #E5DFFE;
  height: 70px;
  padding: 0 30px;
  width: 100%;
  margin-top: 20px;
  text-align: left;
  color: var(--sch-title-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  cursor: pointer;
  /* text-transform: capitalize; */
}

.pxl_socialLogin_btn:hover {
  transform: translateY(-3px);
  color: var(--primaryColor);
}

.pxl_socialLogin_btn:hover,
.pxl_socialLogin_btn {
  transition: all .3s;
}

.pxl_socialLogin_btn span {
  display: inline-block;
  margin-right: 10px;
  line-height: normal;
  position: relative;
  top: 0px;
}

.pxl_form {
  position: relative;
  z-index: 9;
  width: 100%;
  padding: 50px 70px 40px;
  border: none;
  margin: 50px 0px;
  background-color: var(--whiteColor);
  filter: drop-shadow(0px 0px 40px rgba(64, 30, 203, 0.06));
  border-radius: 15px;
}

.pxl_btnHolder {
  width: 100%;
  margin-top: 10px;
}

.pxl_custom_form input:-webkit-autofill,
.pxl_custom_form input:-webkit-autofill:hover,
.pxl_custom_form input:-webkit-auto,
.pxl_custom_form input:-webkit-autofill:active {
  border: 1px solid var(--headdingColor);
  -webkit-text-fill-color: var(--headdingColor);
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.pxl_leftForm .pxl_custom_form {
  margin-top: 10px;
  margin-bottom: 9px;
  width: 100%;
  position: relative;
  text-align: left;
}

.input_auth_box .pxl_custom_form .pxl_inputIcon {
  position: absolute;
  right: 10px;
  top: 7px;
}

.pxl_custom_form .pxl_inputIcon {
  position: absolute;
  right: 25px;
  transform: translate(-50%, 50%);
}

.pxl_custom_form label {
  font-size: 14px;
  line-height: 24px;
  color: var(--textColor);
  font-weight: 500;
}

.pxl_inputIcon svg {
  fill: var(--textColor);
}

.pxl_customInput:focus~.pxl_inputIcon svg {
  fill: var(--primaryColor);
}

.pxl_customInput_auth:focus~.pxl_inputIcon svg {
  fill: var(--primaryColor);
}

/*================= page title ==============*/
.pxl_pageTitle {
  margin: 0 0 30px;
}

.pxl_pageTitle h2 {
  margin: 0 0 10px;
  font-weight: 700;
  font-size: 26px;
  /* text-transform: capitalize; */
}

.pxl_customInput {
  border: none;
  height: 50px;
  border-radius: 6px;
  box-shadow: none;
  font-size: 14px;
  padding: 0px 10px;
  border: 1px solid var(--borderColor);
  width: 100%;
  background-color: var(--whiteColor);
  font-family: var(--fontFamily);
}

.pxl-pageTitleSpacing {
  padding: 14px 0px;
  margin-bottom: 20px;
}

.pxl_pageTitleInner>h2 {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}

/*================= page title ==============*/
.pxl_customInput::placeholder {
  font-size: 14px !important;
  line-height: 24px;
  color: var(--placeholder_color);

  opacity: .6;
}

.pxl_customInput:focus {
  outline: none;
  border: 1px solid var(--primaryColor);
  box-shadow: none;
}

.pxl_customInput_auth::placeholder {
  font-size: 14px !important;
  line-height: 24px;
  color: var(--placeholder_color);
  opacity: .6;
}

.pxl_customInput_auth:focus {
  outline: none;
  border: 1px solid var(--primaryColor);
  box-shadow: none;
}

.pxl_customInput_auth {
  height: 50px;
  box-shadow: none;
  font-size: 14px;
  padding: 0px 50px 0 20px;
  width: 100%;
  font-family: var(--fontFamily);
  transition: all .5s;
  border-radius: 6px;
  border: 1px solid var(--input-border);
  background-color: var(--bg-color1);
}

.pxl_btnHolder {
  width: 100%;
  margin-top: 10px;
}

.pxl_rightHeader .pxl_btn {
  margin-right: 25px;
  max-width: fit-content;
  line-height: 40px;
  height: 40px;
}

.btn_main {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  font-size: 14px !important;
  background-color: (rgba(249, 145, 58).1);
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  /* text-transform: capitalize; */
  line-height: 1.75;
  color: var(--primaryColor);
  min-width: 64px;
  font-weight: 600;
}

.schScheduleDate a.sch-btn img {
  padding: 0px 10px 0px 0px;
}

/* .pxl_btn {
  position: relative;
  color: var(--whiteColor);
  padding: 10px 12px;
  border: 0px;
  height: 60px;
  min-width: 170px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  background: var(--gradientColor);
  transition: all .5s ease;
  border-radius: 10px;
  box-shadow: 0px 20px 50px 0px rgba(105, 39, 242, 0.30);
} */
.pxl_btn {
  cursor: pointer;
  position: relative;
  padding: 4px 20px 4px 20px;
  border-radius: 5px;
  color: var(--sch-white);
  height: 50px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border: 0;
  flex-wrap: wrap;
  background: -o-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#994E8F', endColorstr='#F3527C', GradientType=1);
  background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  min-width: 120px;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.pxl_btn:after {
  content: '';
  display: block;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.pxl_btn:before {
  content: '';
  display: block;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.pxl_btn svg {
  width: 25px;
  fill: var(--whiteColor);
  transition: .5s all ease;
}

.pxl_btn:hover:before {
  background-position: left bottom;
}

.pxl_btn:after {
  background-position: right bottom;
}

.pxl_link svg {
  fill: var(--primaryColor);
}

.pxl_link {
  font-size: 16px;
  line-height: 24px;
  color: var(--placeholder_color);
  font-weight: 400;
}

.checkbox input[type="checkbox"] {
  display: none;
}

.checkbox {
  margin: 0 3px 0 0;
}

.checkbox label {
  margin: 0;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  width: 100%;
  height: 24px;
  display: inline-block;
  line-height: 24px;
  font-size: 15px;
  line-height: 24px;
  color: var(--placeholder_color);
  font-weight: 400 !important;
  transition: all .4s;
}

.checkbox label:hover {
  color: var(--primaryColor);
}

.checkbox label:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid var(--borderColor);
  border-radius: 3px;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  transition: all .3s;
  background: var(--whiteColor);
}

.checkbox label:after {
  content: "";
  position: absolute;
  top: 7px;
  left: 4px;
  width: 10px;
  height: 5px;
  border-left: 2px solid var(--whiteColor);
  border-bottom: 2px solid var(--whiteColor);
  -webkit-transform: rotate(-20deg);
  -moz-transform: rotate(-20deg);
  -ms-transform: rotate(-20deg);
  transform: rotate(-20deg);
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  transition: all .3s;
  opacity: 0;
}

.checkbox input[checked]~label:after {
  opacity: 1;
  visibility: visible;
}

.checkbox input:checked~label:after {
  opacity: 1;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.checkbox input:checked~label:before {
  background: var(--gradientColor);
  border: 1px solid transparent;
}

.pxl_checkboxHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px;
}

.pxl_form .pxl_link {
  color: #79719B;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pxl_form .pxl_remamberMe.checkbox label {
  color: #79719B;
  font-size: 16px;
  font-weight: 400;
}

.pxl_back_login {
  font-size: 15px;
  line-height: 38px;
  color: var(--placeholder_color);
  font-weight: 400;
  cursor: pointer;
  transition: all .4s;
  margin: auto;
}

.pxl_back_login:hover {
  color: var(--primaryColor);
}

/**/
.pxl_welcome_title {
  margin: 0 0 10px;
}

.pxl_welcome_title span {
  display: block;
  margin: 0 0 15px;
}

.pxl_welcome_title p {
  font-size: 22px;
  /* text-transform: capitalize; */
  font-weight: 400;
  line-height: 1.5;
}

.pxl_welcome_title p b {
  color: var(--sch-primary-color);
}

.pxl_auth_link {
  margin: 40px 0 0;
}

.pxl_auth_link p a {
  font-weight: 600;
  color: var(--sch-pink-color);
}

/* --------------- login page new css --------------- */
.pxl_custom_form.pxl_login-input input.pxl_customInput_auth {
  border: 1px solid var(--sch-border-color1);
  background: var(--sch-color2);
  height: 60px;
}

.pxl_custom_form.pxl_login-input>input.pxl_customInput_auth::placeholder {
  color: var(--sch-color5);
  font-size: 16px;
  opacity: 1;
}

.pxl-login-or {
  display: block;
  text-align: center;
  width: 100%;
}

.pxl-login-or>p {
  color: var(--sch-title-color);
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}

.pxl-login-social {
  display: block;
  width: 100%;
}

.pxl_btnHolder.pxl_btnHolder-login button.pxl_customBtn.pxl_btn {
  height: 60px;
  /* border-radius: 10px; */
  border-radius: 5px;
  box-shadow: unset;
  margin: 10px 0px 20px;
}

.pxl_createAccount-sec {
  padding: 22px 0 0 0;
}

.pxl_createAccount-sec>h2 {
  font-size: 18px;
  font-weight: 400;
  color: #2E253A;
}

.pxl_createAccount-sec>h2>span {
  color: var(--sch-pink-color);
  font-size: 18px;
  font-weight: 700;
  padding: 0 0 0 5px;
}

.input_auth_box .pxl_custom_form span.pxl_inputIcon.pxl_inputIcon_password {
  right: 13px;
}

span.pxl_back_login {
  color: var(--sch-pink-color);
  font-size: 18px;
  font-weight: 600;
  padding: 10px 0 0;
}

.pxl_logo_auth>h3.pxl-fill-details-title {
  padding: 15px 0px 15px;
}

.pxl_checkboxHolder.pxl_checkboxHolder-right {
  justify-content: flex-end;
}

/* --------------------- responsive header css--------------------- */
.sch-headerRes-wrapper {
  background-color: var(--whiteColor);
  padding: 20px 20px;
  box-shadow: 0px 0px 20px 0px #555F861A;
  display: none;
}

.sch-headerRes-wrapper .sch-headerRes-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sch-resLogo a img {
  max-width: 190px;
}

.cun-responsive-toggle {
  display: none;
}

/* -----------------responsive toggle css start ------------------*/
.sch-menu-btn {
  /* width: 45px;
  height: 45px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 5px;
  background: var(--sch-primary-color);
  align-items: center;
  display: none;
  box-shadow: 0 10px 37px -5px rgb(243 82 124 / 16%); */
}

.sch-menu-btn span>span {
  /* display: block;
  width: 25px;
  height: 3px;
  transition: var(--sch-transition);
  margin: 5px auto;
  background: var(--sch-white);
  border-radius: 100px; */
}

.pxl_logo.cun-sidebarLogo {
  background-color: var(--whiteColor);
  padding: 20px 10px;
  width: 100%;
  max-width: 200px;
  border-radius: 10px;
  margin: 0px 0px 20px;
  cursor: pointer;
}

/*----------------- SideBar Css ----------------*/
.pxl_sidebarMenu {
  /* background-color: #12091D; */
  background-color: #2e253a;
  width: 320px;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 999;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
  top: 0px;
  /* margin-top: 80px; */
}

.pxl_rightBar_assets {
  padding-left: 350px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  position: relative;
  z-index: 0;
}

.pxl-bg-back-color-wrap {
  background-color: #F7F3FC;
  height: auto;
}

.pxl_mainMenu {
  color: var(--whiteColor);
}

.pxl_mainMenu>ul {
  padding-left: 0;
  list-style: none;
  padding-top: 50px;
  min-height: calc(100vh - 150px);
  border-bottom: 1px solid rgba(255 255 255 / 20%);
  margin-bottom: 20px;
}

.pxl_mainMenu>ul>li>a {
  margin-bottom: 15px;
  position: relative;
  padding: 15px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  color: var(--whiteColor);
  text-decoration: none;
}

.pxl_mainMenu>ul>li.isActive a::after {
  left: 0;
}

.pxl_mainMenu>ul>li>a:after {
  content: '';
  width: 100%;
  position: absolute;
  left: -100%;
  background-color: #7010B9;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  transition: all 0.3s;
  z-index: -1;
}

.pxl_mainMenu ul.pxl_submenu li a .pxl_textMenu {
  position: relative;
  z-index: 1;
  color: #ffffffc2;
  font-size: 16px;
  font-weight: 400;
}

.pxl_mainMenu>ul>li>a.pxl_navlink.cun-dropdownLi {
  margin: 0px;
}


.pxl_mainMenu ul li a:hover::after,
.pxl_mainMenu ul li a.active::after,
.pxl_mainMenu>ul>li.isActive a::after {
  left: 0;
}

.pxl_menuIcon {
  position: relative;
  margin-right: 15px;
  z-index: 1;
}

.pxl_menuIcon>svg {
  fill: var(--whiteColor);
}

/*------ DashBoard Style  ------*/
/* .plx-title-wrap { */
/* margin-bottom: 20px; */
/* } */

.pxl_container {
  max-width: 100%;
  margin: auto;
}

.pxl-col-5 {
  padding: 0 15px;
  max-width: 20%;
  width: 100%;
}

.pxl-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -15px;
}

.pxl-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

/* .pxl-template-box {
  position: relative;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0 0 0 / 0.25);
  border-radius: 5px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
} */
.pxl-template-box>p {
  font-size: 16px;
  text-align: left;
}

.pxl-template-img>p {
  line-height: 150px;
  text-align: center;
  font-size: 20px;
  /* text-transform: capitalize; */
}

.pxl-templates-wrapper h2 {
  text-align: left;
}

.pxl-templates-wrapper .pxl_title {
  display: block;
  font-size: 16px;
  margin: 10px 0;
  text-align: left;
  cursor: pointer;
}

.pxl-templates-wrapper .pxl_title:hover {
  color: var(--primaryColor);
}

.pxl_editIcon {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 19px;
  top: 19px;
  background-color: #c3c3c3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

/* Counts  */
.pxl-count-cards {
  padding: 0px 0 30px;
}

.pxl-count-widget {
  background: #F9F5FF;
  padding: 20px 30px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.pxl-count-widget:before {
  content: "";
  position: absolute;
  left: 100%;
  width: 8px;
  height: 80%;
  background: #BF9FFD;
  margin: auto;
  bottom: 0;
  top: 0;
  border-radius: 0px 10px 10px 0px;
}

.pxl-count-widget:hover {
  -webkit-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
  -ms-transform: translateY(-6px);
  -o-transform: translateY(-6px);
  transform: translateY(-6px);
}

.pxl-count-widget,
.pxl-count-widget:hover {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.pxl-count-icon span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #C9B3F3 0%, rgba(202, 180, 244, 0) 100%);
  height: 44px;
  width: 44px;
  border-radius: 10px;
}

.pxl-count-icon {
  width: 100%;
  margin: 0 0 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 10px;
}

.pxl-count-widget p {
  color: #72778F;
  font-size: 14px;
}

.pxl-grid .pxl-count-widget:nth-child(2) {
  background: #FFFAEF;
}

.pxl-grid .pxl-count-widget:nth-child(2):before {
  background: #FFD478;
}

.pxl-grid .pxl-count-widget:nth-child(2) .pxl-count-icon span {
  background: linear-gradient(180deg, #FFDE99 0%, rgba(248, 232, 209, 0.22) 100%);
}

.pxl-grid .pxl-count-widget:nth-child(3) {
  background: #EAF9FF;
}

.pxl-grid .pxl-count-widget:nth-child(3):before {
  background: #73DBFF;
}

.pxl-grid .pxl-count-widget:nth-child(3) .pxl-count-icon span {
  background: linear-gradient(180deg, #97E5FF 0%, rgba(240, 254, 255, 0.35) 100%);
}

.pxl-grid .pxl-count-widget:nth-child(4) {
  background: #FFEFEF;
}

.pxl-grid .pxl-count-widget:nth-child(4):before {
  background: #FF9D9D;
}

.pxl-grid .pxl-count-widget:nth-child(4) .pxl-count-icon span {
  background: linear-gradient(180deg, #FFADB8 0%, rgba(255, 242, 240, 0.42) 100%);
}

.pxl_revenueLeftBox {
  width: 100%;
  max-width: 750px;
  text-align: left;
  padding: 20px;
  margin-bottom: 50px;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  border: 1px solid rgba(0 0 0 / 10%);
  border-radius: 10px;
  margin-top: 0px;
}

.px_revenueBox {
  display: flex;
  flex-wrap: wrap;
  /* width: calc(100% - 140px); */
  padding: 0 0 20px;
}

.px_revenueBox p {
  font-size: 16px;
  display: block;
  width: 100%;
  font-weight: 600;

}

.pxl_revenueLeftBox .px_revenueBox p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 5px;
}

/* .px_revenueBox p>span { */
/* font-weight: bold; */
/* } */

.pxl_btnright_Dv .pxl_btn {
  line-height: 43px;
  height: 40px;
  font-size: 14px;
}

/* Profile Screen Styling  */
.sch-profile-mainWrapper {
  position: relative;
}

.sch-profile-mainWrapper:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 960px;
  height: 6px;
  background: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%), #FFF;
  filter: drop-shadow(0px 0px 60px rgba(88, 37, 113, 0.06));
  margin: 0 auto;
  bottom: -5px;
  border-radius: 0px 0px 10px 10px;
}

.pxl_profile_wrapperr {
  margin: 30px auto 0;
  max-width: 1072px;
  border-radius: 20px;
  overflow: hidden;
  padding: 2px;
  filter: drop-shadow(0px 0px 60px rgba(88, 37, 113, 0.06));
  background: linear-gradient(180deg, #FF00F5 0%, #00F0FF 52.08%, #06F 100%);
  border: 1px solid var(--sch-border-color1);
  position: relative;

}

.pxl_profile_container {
  background: var(--whiteColor);
  border-radius: 17px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.pxl_user_info_box {
  border-radius: 0px 0px 40px 40px;
  max-width: 450px;
  margin: 0 auto 60px;
  width: 100%;
  border: 1px solid var(--sch-border-color1);
  border-top: none;
  text-align: center;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 6%);
  box-shadow: 0px 13px 50px 0px rgba(20, 86, 255, 0.29);
  background: linear-gradient(180deg, #FF00F5 0%, #00F0FF 52.08%, #06F 100%);
  padding: 0px 2px 2px;
}

.pxl_user_info_box_row {
  border-radius: 0px 0px 40px 40px;
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 35px 50px 35px;
  align-items: center;
  background: var(--whiteColor);
  grid-gap: 20px;
  text-align: center;
}

.pxl_user_img {
  width: 80px;
  height: 80px;
  background: #efe5f9;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
  justify-content: center;
}

.pxl_user_img_icon {
  position: absolute;
  display: inline-block;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 50%;
  background-color: var(--secondryColor);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: white;
}

.pxl_user_img_icon img {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  object-fit: cover;
  background-size: cover;
}

.pxl_edit_icon {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  display: inline-block;
  top: 80%;
  left: 0;
  right: 0;
  cursor: pointer;
  margin: auto;
  text-align: center;
  overflow: hidden;
}

.pxl_edit_icon input {
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.pxl_user_info {
  text-align: left;
}

.pxl_user_info h4 {
  color: var(--sch-title-color);
  font-size: 16px;
  margin: 0 0 2px;
  font-weight: 700;
  /* text-transform: capitalize; */
}

.pxl_user_info p {
  color: #4F536F;
  font-size: 14px;
  font-weight: 500;
  width: 230px;
  max-width: 100%;
  height: 16px;
  margin: 0 auto;
  line-height: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pxl_profile_row {
  width: 100%;
  padding: 0 100px 20px;
}

.pxl_profile_field_wrap {
  margin: 30px 0 0;
}

.pxl_profile_row.pxl_tab_style .pxl-custom-file-select-button {
  margin: 0px !important;
  padding: 2px 10px 0 20px !important;
}

.pxl_profile_field_wrap>h4 {
  color: var(--sch-title-color);
  font-size: 20px;
  font-weight: 700;
  /* text-transform: capitalize; */
  position: relative;
  margin: 0 0 30px;
  padding: 0 0 15px;
}

.pxl_profile_field_wrap>h4:before {
  content: "";
  background: linear-gradient(270deg, rgba(239, 229, 249, 0.00) 0%, #EFE5F9 100.17%);
  left: 0;
  right: 0;
  height: 1px;
  position: absolute;
  bottom: 0;
}

.pxl_profile_field_wrap>h4:after {
  content: "";
  background: linear-gradient(270deg, rgba(239, 229, 249, 0.00) 0%, #EFE5F9 100.17%);
  left: 0;
  right: 0;
  height: 1px;
  position: absolute;
  bottom: 3px;
}

.sch-modelInner {
  padding: 20px 0 0;
}

.cun-account-settingFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.pxl_input_feilds {
  width: 100%;
  margin: 0 0 20px;
}

.pxl_input_feilds label {
  color: var(--sch-title-color);
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  display: block;
  margin: 0 0 15px;
}

.pxl_input_feilds>input {
  border-radius: 6px;
  border: 1px solid var(--input-border);
  background-color: var(--bg-color1);
  width: 100%;
  min-height: 50px;
  padding: 10px 20px;
  font-size: 16px;
}

.pxl_input_feilds input:focus {
  border-color: #e7eef8;
}

.pxl_input_feilds input:focus,
.pxl_input_feilds input {
  transition: all .3s;
}

.pxl_col_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  margin: 0 0 20px;
}

.pxl_col_2 .pxl_input_feilds {
  margin: 0;
}

.pxl_profile_row .pxl-custom-file-upload .pxl-custom-file-select .pxl-custom-file-select-button {
  background: transparent;

}

.pxl_profile_row .pxl-custom-file-upload .pxl-custom-file-select {
  height: 50px;
  padding: 3px 0px;
}

.pxl_profile_row .pxl-custom-file-upload .pxl-custom-file-select .pxl-custom-file-select-button img {
  width: 35px;
  height: 35px;
  position: relative;
  top: -3px;
}

.pxl-custom-file-upload.pxl-upload-file-profile.pxl-profile-fileSelect .pxl-custom-file-select-name {
  padding: 0px;
  position: relative;
  top: -3px;
}

/* pxl_tab_style */
.pxl_tab_style .nav-tabs {
  display: flex;
  justify-content: flex-start;
  border-bottom: 0;
  position: relative;
  grid-gap: 50px;
}

.pxl_tab_style .nav-tabs:before {
  content: "";
  position: absolute;
  left: -100px;
  right: -100px;
  bottom: 0;
  border-bottom: 1px solid var(--sch-border-color1);
}

.pxl_tab_style .nav-tabs button {
  display: inline-block;
  padding: 21px 0px 20px;
  font-weight: 500;
  /* text-transform: capitalize; */
  position: relative;
  font-size: 16px;
  color: var(--textColor);
  border: 0;
  background-color: transparent !important;
}

.pxl_tab_style .nav-tabs button:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: var(--primaryColor);
  width: 80%;
  height: 5px;
  border-radius: 10px 10px 0px 0px;
  opacity: 0;
}

.pxl_tab_style .nav-tabs button.active {
  color: var(--primaryColor);
}

.pxl_tab_style .nav-tabs button.active:before {
  opacity: 1;
}

.pxl_tab_section {
  padding: 45px 0;
}

.pxl_title_with_desc h4 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 8px;
  display: flex;
  align-items: center;
}

.pxl_title_with_desc {
  max-width: 431px;
  margin: 0 0 30px;
}

.pxl_input_sm {
  max-width: 220px;
}

.pxl_input_note {
  margin: 30px 0 0;
}

.pxl_input_note p {
  font-weight: 700;
}

.pxl_input_note a {
  color: #7612B5;
}

/* Social Screen  */
.pxl_heading {
  color: var(--sch-title-color);
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  /* text-transform: capitalize; */
}

/* .pxl_social_platforms ul li a {
  color: #222222;
  font-size: 16px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  grid-gap: 5px;
  background: #DEF1FF;
  border: 1px solid;
  border-color: #222222;
  filter: drop-shadow(0px 0px 30px rgba(0, 76, 133, 0.10));
  border-radius: 100px;
  padding: 0 10px;
  min-width: 195px;
} */
.pxl_social_platforms ul li a {
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px;
  border-radius: 25px;
  filter: drop-shadow(0px 0px 60px rgba(88, 37, 113, 0.06));
  background-color: var(--whiteColor);
  border: 2px solid transparent;
  transition: all 0.5s;
}

.pxl_social_platforms ul li a:after {
  content: '';
  position: absolute;
  border-top: 9px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  bottom: -11px;
}

.pxl_social_platforms ul li a:hover {
  transform: translateY(-3px);
}

.pxl_social_container.pxl-social-integration-wrapper .pxl_social_platforms {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 50px 0 0;
  padding: 0px 20px;
}

.pxl_social_platforms ul {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 15px;
}

.pxl_social_acc_card {
  background: var(--whiteColor);
  border: 1px solid #E7E2FE;
  max-width: 695px;
  margin: 50px auto 50px;
  position: relative;
  border-radius: 10px;
  padding: 50px 30px 50px;
}

/* .pxl_social_acc_card:before {
  content: "";
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: -10px;
  height: 10px;
  background: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%), #FFF;
  filter: drop-shadow(0px 0px 60px rgba(88, 37, 113, 0.06));
  border-radius: 0px 0px 50px 50px;
  max-width: 700px;
  margin: auto;
} */
.pxl_social_acc_card>h4 {
  color: var(--sch-title-color);
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  /* text-transform: capitalize; */
  margin: 0 0 30px;
}

.pxl_social_no_users h4 {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  /* text-transform: capitalize; */
}

.pxlName {
  text-transform: capitalize;
}

.pxl_social_no_users {
  text-align: center;
}

.pxl_social_no_users .pxl_sm_img {
  padding: 20px 0 15px;
}

/**/
.pxl_social_acc_users.pxl_socialAccountWrap .cun-socialIcon.pxl-tooltip-button-form.pxl-deleteToltip .cun-show-tooltip {
  transform: translate(0px);
}

.pxl_social_acc_users ul {
  margin: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.pxl_social_acc_users>ul>li {
  display: flex;
  margin: 0 10px 15px;
}

.pxl_social_acc_card .pxl_btn_wrap button {
  margin: 0 auto;
}

.pxl_social_acc_users ul>li>a {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid #ECE7FD;
  background: #FBFAFF;
}

.cun-socialIcon.pxl-social-tooltip1 .cun-show-tooltip {
  width: max-content;
  transform: translate(-65px, 0px);
}

.cun-socialIcon.pxl-social-tooltip2.cun-socialIcon .cun-show-tooltip {
  transform: translate(-56px, 0px);
}

.sch-accountName {
  width: 180px;
  display: flex;
  align-items: center;
}

.sch-accountName>img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-size: cover;
  background-position: center;
}

.sch-accountName span.pxl_social_username {
  color: #747A91;
  font-size: 16px;
  font-weight: 400;
  padding: 0 0 0 10px;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  display: block;
}


.pxl_social_acc_users>ul>li>a>img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.cun-socail-delete {
  box-shadow: -4px 4px 4px 0px #FF627E33;
  background: #FF627E;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: 0 0 0 10px;
  transition: all 0.5s;
}

.cun-socialIcon {
  position: relative;
  transition: all 0.5s;
}

.cun-socialIcon .cun-show-tooltip {
  transform: translate(-10px, 0px);
}

.cun-socialIcon.cun-socialIcon-delete .cun-show-tooltip {
  transform: translate(4px, 0px);
}

.cun-socialIcon:hover .cun-show-tooltip {
  opacity: 1;
  visibility: visible;

  margin: 0 auto;

}

.pxl-add-new-accountBtn.cun-socialIcon .cun-show-tooltip {
  top: auto;
  bottom: -35px;
  transform: translate(5px, 0px);
}

.pxl-add-new-accountBtn.cun-socialIcon .cun-show-tooltip:before {
  top: -10px;
  transform: rotate(180deg);
}

/* Autoresponders  */
.pxl_autoresponder_list ul li a {
  font-size: 16px;
  font-weight: 500;
  /* text-transform: capitalize; */
  display: inline-flex;
  align-items: center;
  grid-gap: 5px;
  border: 1px solid #C0C5EB;
  border-radius: 40px;
  padding: 10px 20px;
  background: var(--whiteColor);
  filter: drop-shadow(0px 0px 30px rgba(0, 76, 133, 0.10));
  justify-content: center;
}

.pxl_autoresponder_list ul li a.sch-autoresponder-active {
  border-color: #198754;
  border-width: 2px;
  background: #19875421;
}

.pxl_autoresponder_content {
  background: var(--whiteColor);
  border: 1px solid;
  border-color: #E9EDF6;
  filter: drop-shadow(0px 0px 60px rgba(88, 37, 113, 0.06));
  max-width: 850px;
  margin: 50px auto 0;
  position: relative;
  border-radius: 10px;
  padding: 50px 50px 50px;
}

.pxl_from_title {
  color: var(--sch-title-color);
  font-size: 20px;
  font-weight: 500;
  /* text-transform: capitalize; */
}

.pxl_autoresponder_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px 0 0;
}

.pxl_autoresponder_list ul {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 20px;
  justify-content: center;
}

.pxl_autoresponder_list ul li {
  transition: all 0.5s;
}

.pxl_autoresponder_list ul li:hover {
  transform: translateX(2px);
}

.pxl_autoresponder_list ul li a img {
  max-height: 35px;
  margin: 0 10px 0 0;
}

.pxl_autoresponder_list a.pxl_autoresponder_selected {
  box-shadow: 0 0 0 1px #8417AF, inset 0 0 0 2px #fff;
  /* background: #f5e3f0; */
  background-color: var(--bg-color1);
}

.pxl_social_platforms a.pxl_account_selected {
  background: #FFF2FF;
  border-color: #991DA5;
}

.pxl_social_platforms ul li a.pxl_fb.pxl_account_selected:after {
  border-top-color: #991DA5;
}

.pxl_social_platforms ul li a.pxl_insta.pxl_account_selected:after {
  border-top-color: #991DA5;
}

.pxl_social_platforms ul li a.pxl_linkedin.pxl_account_selected:after {
  border-top-color: #991DA5;
}

.pxl_social_platforms ul li a.pxl_pinterest.pxl_account_selected:after {
  border-top-color: #991DA5;
}

.pxl_social_platforms ul li a.pxl_google.pxl_account_selected:after {
  border-top-color: #991DA5;
}

/* Submenu */
.pxl_mainMenu ul.pxl_submenu {
  padding: 0 0 0 30px;
}

.pxl_mainMenu ul.pxl_submenu li a {
  padding: 0 10px 5px;
  color: #ffffffc2;
}

/*------------------ Table Css ----------------*/
/* table  */
.pxl_table {
  box-shadow: 0 10px 20px rgb(26 115 232 / 4%);
  border-radius: 10px;
  width: 100%;
}

.pxl_table tr th {
  border: none;
  background-color: #e7dff2;
  color: var(--darkColor);
  padding: 18px 20px;
  text-align: left;
}

.pxl_table tr th:first-child {
  border-radius: 10px 0 0 0;
}

.pxl_table tr th:last-child {
  border-radius: 0 10px 0 0;
  text-align: center;
}

.pxl_table tr {
  background-color: var(--whiteColor);
}

.pxl_table tr td {
  padding: 18px 20px 12px 20px;
  /* background-color: var(--whiteColor); */
  border-color: #ddd8e3;
  color: #9a91be;
  vertical-align: middle;
  font-size: 16px;
  border-bottom: 1px solid #9a91be26;

}
.pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .css-b62m3t-container {
  width: 200px !important;
}
.pxl_table tr td p {
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pxl_table tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}

.pxl_table tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}

/* search  */
.pxl_search_wrapper {
  max-width: 500px;
  width: 100%;
  position: relative;
  display: inline-flex;
}

.pxl_search_wrapper>span {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.pxl_search_wrapper>span svg {
  fill: #d8dce2;
  width: 12px;
}

.form-control {
  height: 45px;
  border-radius: 8px;
  font-size: 14px;
  outline: none;
  box-shadow: none !important;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: var(--whiteColor);
  background-clip: padding-box;
  border: 1px solid var(--sch-border-color1);
  padding: 10px 20px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.pxl_tableBox_filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.pxl_filterFeilds {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
}



.pxl_statusFilter {
  min-width: 175px;
  margin: 0px 0 5px;
}

.pxl_filterFeilds .sch-search-section {
  /* margin: 0px 0 5px; */
}

.pxl_filterFeilds .pxl_btn {
  /* margin: 0px 0px 5px; */
}

.pxl_tableData h5 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;

}

/* Table Pagination */
.pxl_tablePagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.pxl_tablePagination ul {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.pxl_tablePagination ul li a {
  min-width: 30px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  line-height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0px 5px;
  padding: 0px 10px;
  transition: all 0.3s;
}

.pxl_tablePagination ul li a:hover,
.pxl_tablePagination ul li a.isActive {
  background-color: var(--primaryColor);
  color: var(--whiteColor) !important;
}

.pxl_tablePagination>ul>li .disabled {
  background-color: #efefef !important;
  color: var(--placeholder_color) !important;
  cursor: not-allowed;
}

/* checkbox */
.pxl_checkBox_wrapper {
  display: flex;
  align-items: center;
}

.pxl_checkBox>span {
  width: 18px;
  height: 18px;
  border: 1px solid var(--grayColor);
  display: inline-flex;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
}

.pxl_checkBox {
  align-items: center;
  margin-bottom: 0;
  display: inline-flex;
  cursor: pointer;
  transition: all 0.3s;
}

.pxl_checkBox>span:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 4px;
  border-width: 0 0 1px 1px;
  border-style: solid;
  transform: translate(0px, 0px);
  border-color: var(--blueColor);
  left: 3px;
  top: 5px;
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
}

.pxl_checkBox>input:checked+span:before {
  transform: translate(0px, 0px);
  transition: var(--transition);
}

.pxl_radioBox>span {
  border-radius: 100%;
  transition: var(--transition);
}

.pxl_radioBox>span:before {
  border: none;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: var(--blueColor);
  left: 4px;
  top: 4px;
  transition: var(--transition);
}

/* toggle */
.pxl_toggle>span {
  width: 100px;
  height: 30px;
  border-radius: 7px;
  background-color: var(--grayColor);
  margin-right: 5px;
  line-height: 28px;
  padding-left: 35px;
  transition: all 0.3s;
}

.pxl_toggle>span:before {
  transform: scale(1);
  width: 25px;
  height: 25px;
  border: none;
  background-color: #afb6c1;
  border-radius: 10px;
  top: 2px;
  left: 2px;
  transition: all 0.3s;
}

.pxl_toggle>input:checked+span:before {
  transform: translate(67px, 0px);
  left: 3px;
  /* right: 3px; */
  background-color: rgb(255 255 255 / 50%);
  transition: all 0.3s;
}

.pxl_toggle>input:checked+span {
  background-image: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%);
  color: var(--whiteColor);
  padding-right: 35px;
  padding-left: 18px;
  transition: all 0.3s;
}

.d-none {
  display: none;
}

.pxl_tableIcon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #f7f3fc;
  margin: 3px;
  border: 1px solid var(--sch-border-color1);
}

.pxl_tableIcon_holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pxl_tableIcon svg {
  fill: #9a91be;
  width: 14px;
}

.pxl_tableIcon.deleteIcon {
  background-color: #ff5959;
}

.pxl_planHolder {
  display: flex;
  flex-wrap: wrap;
  max-width: 265px;
}

.pxl_planHolder_table {
  display: flex;
  flex-wrap: wrap;
  min-width: 150px;
}

.pxl_plans {
  width: -moz-fit-content;
  margin: 0px 3px 6px;
  padding: 2px 10px 3px;
  background-color: #efefef;
  border-radius: 4px;
  /* min-width: -moz-fit-content; */
  white-space: nowrap;
  background-color: #f7f3fc;
  border: 1px solid var(--sch-border-color1);
  color: #9a91be;
  font-size: 14px;
  text-transform: capitalize;
  max-width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/*----- Responsive Css -----*/
/* Template  Box  */
.pxl-col-5 {
  width: 20%;
  padding: 0 15px;
}

.pxl-template-box {
  position: relative;
  margin: 0 0 30px;
  border-radius: 10px;
  overflow: hidden;
}

.pxl-template-img {
  position: relative;
}

.pxl-template-box img {
  width: 100%;
}

.pxl-template-img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100%;
  background: rgb(0 0 0 / 60%);
}

.pxl-edit-temp-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: var(--pxl-white);
  padding: 5px 10px;
  border-radius: 5px;
  transform: translateY(100px);
  opacity: 0;
  visibility: hidden;
}

.pxl-template-box:hover .pxl-edit-temp-btn {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}

.pxl-template-box:hover .pxl-template-img:before {
  bottom: 0;
}

.pxl-template-box,
.pxl-template-box:hover,
.pxl-template-box:hover .pxl-edit-temp-btn,
.pxl-template-box .pxl-template-img:before,
.pxl-template-box:hover .pxl-template-img:before {
  -webkit-transition: var(--pxl-transition);
  -moz-transition: var(--pxl-transition);
  -ms-transition: var(--pxl-transition);
  -o-transition: var(--pxl-transition);
  transition: var(--pxl-transition);
}

/*--------------------------- Section Title ---------------------------*/
.pxl-title-wrap {
  margin-bottom: 30px;
  border-bottom: 4px double #E9EDF6;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  justify-content: space-between;
  padding: 0 0 10px;
}

.pxl-title-wrap h2 {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  margin: 0;
  position: relative;
}

.pxl-title-wrap h2:before {
  content: "";
  position: absolute;
  bottom: -14px;
  left: 0;
  height: 4px;
  background: #AE249B;
  right: 0;
}

.pxl-title-link {
  color: var(--pxl-primary-color);
  font-weight: 500;
}

.pxl-title-link span {
  margin-left: 15px;
}

/*************** Modal Css *************/


.modal-body .form-group {
  margin-bottom: 15px;
}

.modal-body select[multiple] {
  height: 130px;
}

.modal-body .form-group label {
  margin-bottom: 5px;
  font-size: 14px;
}

.modal-body button.pxl_btn {
  height: 45px;
  margin-top: 20px;
}

/********* Profile Css *********/
.pxl_profileImg {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.pxl_imgHolder {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  background-color: var(--secondryColor);
  text-transform: uppercase;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.pxl_profileName {
  padding-left: 10px;
}

.pxl_profileName h4 {
  color: #fff;
  color: var(--whiteColor);
  font-size: 16px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.pxl_profileName p {
  margin-bottom: 0;
  white-space: normal;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.pxl_profileDetail {
  position: relative;
}

.pxl_profileDetail ul {
  padding: 10px 10px;
  background-color: #8718ae;
  border-radius: 10px;
  line-height: normal;
  position: absolute;
  left: calc(100% - 10px);
  bottom: 0;
  min-width: 180px;
  visibility: none;
  opacity: 0;
  transition: all 0.3s;
  display: none;
}

.pxl_profileDetail ul:before {
  content: '';
  position: absolute;
  width: 1px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 12px solid #8718ae;
  left: -12px;
  bottom: 20px;
}

.pxl_profileDetail:hover ul {
  /* visibility: visible;
  opacity: 1;
  display: block; */
}

.pxl_profileDetail.pxl-logutSection-wrap.loginMenuOpen ul.pxl-account-logout-wrap {
  display: block;
  opacity: 1;
}

.pxl_profileDetail>ul>li>.pxl_navlink {
  margin: 0 0 5px;
  padding: 5px 10px;
  display: block;
}

.pxl_profileDetail .pxl_menuIcon {
  position: relative;
  margin-right: 5px;
  z-index: 1;
}

/**-- Assets CSS Start --**/
/* .pxl-assets-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
} */
.pxl-assets-row {
  display: grid;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  word-wrap: break-word;
}

.pxl-assets-section {
  position: relative;
  /* width: 270px;
  height: 200px; */
  z-index: 11;
  transition: all 0.5s;
}

.pxl-assets-section p {
  padding: 10px 0 10px 0;
  font-size: 16px;
  /* text-transform: capitalize; */
  color: var(--sch-title-color);
}

/* .pxl-assets-section:hover .pxl-item-action {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}
.pxl-assets-over {
  position: absolute;
  top: 0;
  left: 0;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000078;
  border-radius: 5px;
} */
.pxl-assets-over>h2 {
  font-size: 16px;
  /* text-transform: capitalize; */
  color: var(--whiteColor);
}

.pxl-assets-over.pxl-processing>h2 {
  text-transform: capitalize;
}

.pxl-assets-inner {
  width: 100%;
  position: relative;
  background: var(--whiteColor);
  border-radius: 5px;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
  min-height: 200px;
  max-height: 200px;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 6%);
  overflow: hidden;
  padding: 5px 5px;
}

.pxl-assets-section .pxl-assets-inner .sch-custom-check-box.cun-selectAll {
  position: absolute;
  top: 5px;
  left: 5px;
}

.pxl-assets-img audio {
  /* width: 100%; */
  padding: 0px 40px;
}

.pxl_emptytag {
  position: absolute;
  left: 5px;
  top: 8px;
  background: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%);
  width: 16.5px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.pxl-inactive {
  position: absolute;
  right: 39px;
  top: 19px;
  font-size: 16px;
  color: var(--sch-title-color);
  font-weight: 400;
}

.pxl-audio {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pxl-audio-svg {
  background: var(--gradientColor);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
}

.pxl-video {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pxl-video-svg {
  background: var(--gradientColor);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
}

.pxl-assets-img img {
  border-radius: 5px;
  width: 100%;
  object-fit: contain;
  object-position: center;
  transition: all 1s;
  max-height: 170px;
}

.pxl-assets-img video {
  width: 100%;
  height: 100%;
  max-height: 170px;
  border-radius: 5px;
}








/* .pxl-item-action {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translateX(-100%);
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 85%);
  border-radius: 15px;
  opacity: 0;
  visibility: hidden;
} */
/* .pxl-item-box:hover .pxl-item-action {
  opacity: 1;
  visibility: visible;
  transform: translateX(0%);
} */
.pxl-item-action {
  position: absolute;
  top: 16px;
  right: 16px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  transition: 0.5s;
  /* opacity: 0;
  visibility: hidden; */
}


.pxl-assets-section:hover .pxl-item-action {
  opacity: 1;
  visibility: visible;
}

button:focus:not(:focus-visible) {
  /* outline: aliceblue;
  box-shadow: unset; */
}

.pxl-item-box:hover,
.pxl-item-box,
.pxl-item-box:hover .pxl-item-action,
.pxl-item-box .pxl-item-action {
  transition: all .3s;
}

/* .pxl-item-action ul { */
/* display: inline-flex;
  flex-wrap: wrap;
  grid-gap: 8px; */
/* } */

.pxl-item-action ul li {
  margin: 0 0 5px;
  transition: 0.5s;
}

.pxl-item-action ul li a {
  width: 30px;
  height: 30px;
  display: flex;
  background: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%);
  border-radius: 6px;
  color: var(--whiteColor);
  transition: 0.5s;
  align-items: center;
  justify-content: center;
}

.pxl-item-action ul li a svg {
  fill: currentColor;
  width: 14px !important;
  height: 14px !important;
}

.pxl-input-feilds input[type="file"] {
  padding: 9px 20px;
}

/* .pxl_btn_sm {
  height: 40px;
  min-width: 80px;
  padding: 0 20px;
  font-size: 14px;
} */
.pxl-head-btn-wrap {
  display: inline-flex;
  flex-wrap: wrap;
  grid-gap: 20px;
}

/**/
.pxl-template-search {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 30px;
  background-color: var(--whiteColor);
  padding: 10px 20px;
  border-radius: 10px;
}

.pxl-all-templates h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.pxl-templates-status-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.pxl-all-templates {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.pxl-filter-mobile-btn {
  width: 30px;
  height: 30px;
  background: var(--primaryColor);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  margin: 0 10px 10px 0;
}

.pxl-filter-mobile-btn img {
  height: 18px;
}

.pxl-input-feilds.pxl-find-template {
  margin: 0;
}

/* Admin Templates  */
.pxl-template-wrapper {
  width: 100%;
  position: relative;
}

.pxl-template-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 30px;
}

.pxl-template-categories {
  display: flex;
  flex-wrap: wrap;
  background: var(--whiteColor);
  border-radius: 5px;
  margin: 0 0 10px;
  width: 350px;
  height: fit-content;
  padding: 2px;
}

.pxl-templates-holder {
  width: calc(100% - 380px);
}

.pxl-template-row {
  width: 100%;
}

.pxl-template-filter-header {
  padding: 15px 15px;
  position: relative;
  background-color: #f4e9fd;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pxl-template-filter-header>h4 {
  font-size: 18px;
  /* text-transform: capitalize; */
  font-weight: 600;
  margin: 0;
}

.pxl-assets-plus>a {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #7010b996;
  border-radius: 6px;
}

.pxl-add-icon {
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 22px;
  height: 22px;
  text-align: center;
  background: #aaa;
  line-height: 22px;
  color: var(--whiteColor);
  border-radius: 50%;
  font-size: 18px;
}

.pxl-add-icon:hover {
  -webkit-transform: scale(.95);
  -moz-transform: scale(.95);
  -ms-transform: scale(.95);
  -o-transform: scale(.95);
  transform: scale(.95);
}

.pxl-add-icon:hover:focus,
.pxl-add-icon:hover:hover {
  color: var(--whiteColor);
}

.pxl-template-filter-body {
  padding: 15px 5px 0;
}

.pxl-template-cat-list ul li {
  position: relative;
  margin: 0 0 10px;
}

.pxl-template-cat-list ul li:last-child {
  margin: 0 0 5px;
}

.pxl-template-cat-list ul li a {
  position: relative;
  padding: 13px 15px 13px 40px;
  border: 1px solid transparent;
  display: inline-block;
  width: 100%;
  color: #aaa;
  border-radius: 5px;
  font-weight: 600;
}

.pxl-template-cat-list ul li a.active {
  background: var(#af249b);
  border-color: var(--primaryColor);
  color: var(--primaryColor);
}

.pxl-template-cat-list ul li a:hover {
  color: var(--primaryColor);
}

.pxl-template-cat-list ul li a.active:before,
.pxl-template-cat-list ul li a:hover:before {
  border-color: var(--primaryColor);
}

.pxl-template-cat-list ul li a.active:after,
.pxl-template-cat-list ul li a:hover:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.pxl-template-cat-list ul li a:before {
  position: absolute;
  content: "";
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 15px;
  width: 15px;
  border: 1px solid #aaa;
  border-radius: 50%;
}

.pxl-template-cat-list ul li a:after {
  position: absolute;
  content: "";
  left: 20px;
  top: 0px;
  bottom: 0;
  margin: auto;
  height: 5px;
  width: 5px;
  background: var(--primaryColor);
  border-radius: 50%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.pxl-template-cat-list ul li,
.pxl-template-cat-list ul li:hover,
.pxl-template-cat-list ul li a:hover:after,
.pxl-template-cat-list ul li a:hover,
.pxl-template-cat-list ul li a:after,
.pxl-template-cat-list ul li a:hover:before,
.pxl-template-cat-list ul li a,
.pxl-template-cat-list ul li a:before {
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.sch-headBtn.cun-socialIcon .cun-show-tooltip {
  top: 65px;
  transform: translate(3px, 0px);
}

.sch-headBtn .cun-show-tooltip:before {
  top: -10px;
  transform: rotate(180deg);
}

/**/
/* Input With Icon */
.pxl-input-feilds {
  position: relative;
  margin: 0 0 10px;
}

.pxl-input-feilds input {
  width: 100%;
  height: 50px;
  border: 1px solid var(--borderColor);
  color: var(--headdingColor);
  border-radius: 10px;
  padding: 0 20px;
  margin-bottom: 0;
  font-weight: 600;
  background: var(--whiteColor);
  font-size: 14px;
}

.pxl-input-feilds input:focus {
  border-color: var(--pxl-primary-color);
}

.pxl-input-feilds input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--pxl-placeholder);
  font-weight: 600;
}

.pxl-input-feilds input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--pxl-placeholder);
  font-weight: 600;
}

.pxl-input-feilds input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--pxl-placeholder);
  font-weight: 600;
}

.pxl-input-feilds input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--pxl-placeholder);
  font-weight: 600;
}

.pxl-input-feilds .pxl-input-icon {
  position: absolute;
  top: 0;
  height: 50px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  right: 10px;
}

.pxl-table-data-search .pxl-input-feilds input {
  padding: 0 50px 0 20px;
}

/* Admin assets page accordian css start */
.accordion-item {
  margin: 0 0 10px 0;
}

h2.accordion-header button.accordion-button {
  padding: 10px 10px;


}

.pxl-accordian-section h2.accordion-header button.accordion-button {
  border: 1px solid #bc9cd4;
  padding: 15px 10px;
}

h2.accordion-header>button>.pxl-accordian-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 10px 0 0px;
}

h2.accordion-header>button>.pxl-accordian-header>h2 {
  font-size: 16px;
  text-transform: capitalize;
  margin: 0;
  max-width: 160px;
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
}

h2.accordion-header>button>.pxl-accordian-header>h2>span {
  padding-right: 10px;
}

/* .pxl-accordion-icons-right > a {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 1px;
  background-color: #d8c4e8;
} */
.accordion-button:not(.collapsed) {
  background-color: #f4e9fd;
  box-shadow: none;
  border-radius: 5px !important;

}

.accordion-button {
  position: relative;
}

.accordion-button:not(.collapsed):before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  border-radius: 0px;
  background-color: var(--primaryColor);
  opacity: 0.2;
  border: 1px solid var(--primaryColor);
}

.accordion-body {
  border-top: none;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  /* padding: 15px 15px 5px; */
  padding: 0;
  /* height: calc(100vh - 210px) !important;
  min-height: calc(100vh - 210px) !important; */
  /* max-height: 230px;
  overflow-y: auto;
  overflow-x: hidden; */
  padding: 0px 0px 20px;
}

.pxl-accodian-sub-category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 15px 10px 10px 40px; */
  padding: 21px 10px 19px 12px;
  border-radius: 5px;
  margin: 0;
  position: relative;
  cursor: pointer;
}

.pxl-accodian-sub-category-body .pxl-accordion-icons-right .pxl-icon-tooltip {
  margin: 0;
}

.pxl-accodian-sub-category .pxl-accordion-icons-right {
  opacity: 1;
  visibility: visible;
}

.pxl-sub-categoryActive {
  background: #d9bdef59;
}

/* .pxl-sub-categoryActive .pxl-accordion-icons-right { */
/* top: 6px; */
/* } */

.pxl-accodian-sub-category>h2 {
  font-size: 16px;
  /* text-transform: capitalize; */
  margin: 0;
  max-width: 160px;
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* button.accordion-button:focus-visible {
  outline: none;
} */

button.accordion-button:focus {
  box-shadow: none;
}

button.accordion-button.collapsed {
  border-radius: 5px !important;
}

.pxl-accordian-section .accordion-button::after {
  width: 14px;
  height: 14px;
  background-size: contain;
  transform: rotate(275deg);
  margin: 3px 0px 0px 0px;
  opacity: 0;
}

.pxl-accordian-section .accordion-button:not(.collapsed)::after {
  transform: rotate(0);
}

.pxl-file-box {
  margin-top: 5px;
}

.pxl-file-box .pxl-file-name {
  border: 1px solid var(--sch-border-color1);
  padding: 3px 8px;
  margin: 2px;
  border-radius: 5px;
  display: inline-block;
}

/* ================== search css start ======================= */
/* input:focus-visible {
  outline: none;
} */

.sch-search-row {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--whiteColor);
  min-height: 45px;
  border-radius: 6px;
  /* padding: 3px; */
  position: relative;
  width: 100%;
}

.sch-search-row input {
  width: calc(100% - 160px);
  border: 0;
  background: transparent;
  padding: 1px 20px;
  font-size: 16px;
}

.sch-search-row.sch-search-bg-color-white input {
  width: auto;
  padding: 0px 40px 3px 20px;
  background-color: var(--whiteColor);
  border: 1px solid var(--sch-border-color1);
  border-radius: 6px;
  height: 50px;
}

.sch-search-btn {
  background: transparent;
  border: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  padding: 0;
  width: 50px;
  cursor: pointer;
}

.pxl_selectOption select.form-control {
  border-radius: 6px;
  border: 1px solid var(--sch-border-color1);
}

input[type=text]:focus {
  /* border-color: #8417af !important; */
  box-shadow: unset !important;
}

.pxl-smtp-host-wrapper-box .pxl-smtp-hostBox1 {
  width: 50%;
}

/* ================== sidebar css ======================== */

.pxl_logo {
  padding: 20px 0 40px;
}

.pxl_mainMenu>ul {
  height: calc(100vh - 210px) !important;
  min-height: calc(100vh - 210px) !important;
  overflow-y: auto;
  padding: 0px 0px 20px;

}

.pxl_mainMenu>ul>li {
  position: relative;
}

.pxl_menuIcon>svg {
  fill: var(--whiteColor);
  position: relative;
  top: -2px;
}

.pxl_mainMenu>ul>li>span.pxl_sidebar-right-arrow {
  position: absolute;
  top: 15px;
  right: 20px;
}

.pxl_mainMenu>ul>li>ul.pxl_submenu {
  background: #392E46;
  margin: 0px 0px 15px 0px;
  padding: 20px 10px 10px 10px;
  border-radius: 0px 0px 6px 6px;
  display: none;
  transition: all 0.5s;
  position: relative;
  top: -4px;
  z-index: 1;
}

.pxl_mainMenu>ul>li>ul.pxl_submenu.sub_menu_open {
  display: block;
}

.pxl_mainMenu>ul>li>ul.pxl_submenu.sub_menu_open>li.sub_menu_isActive span.pxl_textMenu {
  color: var(--whiteColor);
}

.pxl_mainMenu>ul>li>ul.pxl_submenu.sub_menu_open>li.sub_menu_isActive a.pxl_navlink.active {
  color: var(--whiteColor);
}

.pxl_mainMenu>ul>li>ul.pxl_submenu>li {
  /* padding: 0 0 10px 0; */
  transition: all 0.5s;
}

/* ================verfication alert message ========================== */
/* .pxl_verification_wrapper {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  padding-left: 320px;
  text-align: center;
  z-index: 1;
} */

/* .pxl_verification_wrapper p {
  background: #ff5555;
  display: inline-block;
  padding: 10px 40px;
  color: var(--whiteColor);
  border-radius: 0px 0px 10px 10px;
}

.pxl_verification_wrapper a {
  display: block;
  background-color: var(--whiteColor);
  width: 100%;
  max-width: 100px;
  margin: 10px auto 0;
  color: #000000;
  padding: 3px 0px;
  font-size: 14px;
  border-radius: 100px;
} */

.pxl_imgHolder>img {
  width: 50px;
  height: 50px;
}

.pxl_profileName {
  padding-left: 10px;
  width: calc(100% - 50px);
}



/* ================ common button css =====================*/
a.sch-common-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--whiteColor);
  position: relative;
  font-size: 14px;
  background: var(--sch-gradient-bg);
  transition: all 0.5s;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 25px;
  border-radius: 8px;
  box-shadow: 0px 16.667px 41.667px 0px rgba(105, 39, 242, 0.30);
}

.pxl-icon-tooltip>a {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 1px;
  background-color: #7010b996;
}

.pxl-accordion-icons-right .pxl-icon-tooltip span.pxl-tooltip-text {
  left: -15px;
  right: auto;
}

.pxlImageTemplate-main-wrapper .cun-headreSelect.cun-headreSelect-imagetemplate {
  padding: 0;
}

.pxlSchedule-singlepost-data .sch-schedule-post-row .sch-scheduleSec.sch-scheduleSecFullPage {
  width: 300px;
}

.pxl-icon-tooltip {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 99;
}

span.pxl-tooltip-text {
  background-color: var(--textColor);
  position: absolute;
  top: -25px;
  padding: 4px 13px;
  border-radius: 5px;
  font-size: 14px;
  opacity: 0;
  color: var(--whiteColor);
  /* text-transform: capitalize; */
  transition: all .3s;
  /* left: 0;
  right: 0; */
  margin: 0 auto;
  width: max-content;
}

span.pxl-tooltip-text::after {
  content: '';
  border-width: 5px;
  border-style: solid;
  border-color: var(--textColor) transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: 40%;
  margin-left: 5%;
}

.pxl-icon-tooltip:hover span.pxl-tooltip-text {
  opacity: 1;
  transform: translateY(-10px);
}

.accordion-item {
  position: relative;
  padding: 0;
  border-bottom: none;
}

.pxl-accordion-icons-right {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 7px;
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

.pxl-accordian-section .accordion-item:hover .pxl-accordion-icons-right {
  opacity: 1;
  visibility: visible;
  right: 7px;
}

.pxl-accodian-sub-category-body {
  padding: 5px 0;
}

.pxl-accodian-sub-category-body .pxl-accordion-icons-right {
  right: 7px;
  top: 14px;
}

.pxl-accordian-section .accordion-item {
  transition: all 0.5s;
  position: relative;
}

.accordion-header {
  margin: 0;
  position: relative;
}

.accordion-button:hover {
  z-index: 0;
}

/* ===================== assets popup css start ======================= */
.pxl-tokenfield {
  border: 1px solid var(--sch-border-color1);
  padding: 10px;
  border-radius: 5px;
  height: 100px;
  overflow-y: auto;
}

.pxl-tokenfield-inner {

  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.pxl-tokenfield-child {
  margin: 0 10px 10px 0;
  border: 1px solid var(--sch-border-color1);
  padding: 5px 10px;
  border-radius: 5px;
}

.pxl-tokenfield-child>p {
  /* text-transform: capitalize; */
  font-size: 14px;
  position: relative;
  display: inline-block;
  width: max-content;
}

.pxl-tokenfield-child>p>span>a {
  padding: 0 0 0 5px;
}

.pxl-tokenfield-input>input {
  display: block;
  border: none;
  font-size: 16px;
  width: 100%;
}

.sch-search-btn span svg {
  width: 14px;
  height: 14px;
}

span.cun-inputCross svg {
  width: 12px;
  height: 12px;
}

/*==================== custom file upload css start ====================*/
/* .pxl-custom-file-upload .pxl-custom-file-select {
  display: block;
  border: 1px solid var(--sch-border-color1);
  color: #34495e;
  cursor: pointer;
  height: 50px;
  text-align: left;
  background: var(--whiteColor);
  overflow: hidden;
  position: relative;
  border-radius: var(--border-radius5px);
} */
/* 
.pxl-custom-file-upload .pxl-custom-file-select .pxl-custom-file-select-button {
  background-color: var(--textColor);
  display: inline-block;
  height: 40px;
  line-height: 40px;
  color: var(--whiteColor);
  font-size: 14px;
  padding: 0px 15px;
  margin: 0px 4px;
  border-radius: 5px;
}
.pxl-custom-file-upload .pxl-custom-file-select .pxl-custom-file-select-name {
  line-height: 50px;
  display: inline-block;
  padding: 0 10px;
  height: 50px;
  font-size: 14px;
  color: var(--sch-title-color);
  font-weight: 400;
} */

.pxl-custom-file-upload.active .pxl-custom-file-select {
  border-color: #3fa46a;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.pxl-custom-file-upload.active .pxl-custom-file-select .pxl-custom-file-select-button {
  background: #3fa46a;
  color: var(--whiteColor);
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.pxl-custom-file-upload .pxl-custom-file-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.pxl-custom-file-upload .pxl-custom-file-select.pxl-custom-file-select-disabled {
  opacity: 0.65;
}

.pxl-custom-file-upload .pxl-custom-file-select.pxl-custom-file-select-disabled:hover .pxl-custom-file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.pxl-custom-file-upload .pxl-custom-file-select.pxl-custom-file-select-disabled:hover .pxl-custom-file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}

/*==================== custom file upload css end ====================*/
/*----------------------------- popup css start  -----------------------------*/
.modal-header {
  border-bottom: 1px solid var(--sch-border-color1);
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
  /* text-transform: capitalize; */
  color: var(--textColor);
  font-size: 18px;
  font-weight: 600;
}

.modal-body {
  padding: 30px 20px 30px;
}

.modal-body .form-group label {
  margin-bottom: 15px;
  font-size: 16px;
  /* text-transform: capitalize; */
}

.form-control {
  height: 50px;
  border-radius: 5px;
}

/* .form-control:focus {
  border-color: #e5dffe;
} */

.modal-lg,
.modal-xl {
  --bs-modal-width: 600px;
}

.pxl-model-assets-img img {
  width: 100%;
}

/* confirmation delet popup css start */
.pxa_confirmation-delet {
  text-align: center;
}

.pxa_confirmImg img {
  width: 70px;
  height: 70px;
}

.pxa_confirmation-delet .pxa_textContent h4 {
  padding: 20px 0px 0px;
  margin-bottom: 10px;
  /* text-transform: capitalize; */
}

.pxa_confirmation-delet>.pxa_textContent>p {
  max-width: 320px;
  margin: 0 auto 10px;
}

.modalBtn-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 0;
}

a.pxl-deleteBtn {
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff2147;
  color: var(--whiteColor);
  font-size: 16px;
  /* text-transform: capitalize; */
  height: 50px;
  border-radius: 5px;
  margin: 0 5px;
}

button.pxl-deleteBtn {
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff2147;
  color: var(--whiteColor);
  font-size: 16px;
  /* text-transform: capitalize; */
  height: 50px;
  border-radius: 5px;
  margin: 0 5px;
  border: none;
}

.pxl-cancelBtn {
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #b0aaaa; */
  background-color: #000000;
  color: var(--whiteColor);
  font-size: 16px;
  /* text-transform: capitalize; */
  height: 50px;
  border-radius: 5px;
  margin: 0 5px;
  overflow: hidden;
  transition: all 0.5s;
}

.pxl-cancelBtn:hover {
  background-color: #222222;
}

.sch-workspace-repear-single .sch-custom-check-box.cun-select.sch_hide_checkbox {
  top: 5px;
  left: 5px;
}

.pxl-cancelBtn:hover:before {
  background-position: 0 100%;
}

.pxl-trash-design-data .sch-uncategorized-mainwrapper-box.pxl-mydesign-createProjectBox .sch-container-fluid {
  padding: 0px 0px 20px;
}

/* ========================== default label spacing css ================================ */
label.pxl-label {
  padding: 0px 0px 10px;
  font-size: 16px;
}

.pxl-bottom-20 {
  padding: 0 0 20px;
}

/* ========================== FUll page Loader Css ================================ */

.pxl_loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.pxl_loader img {
  border-radius: 50%;
  width: 120px;
}

/* ========================== Common header all pages ================================ */
.pxl-common-headerPage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sch-space-left {
  margin: 0px 0px 0px 10px;
}

.pxl-button-disable {
  cursor: unset;
  opacity: 0.5;
}

/* no data found css common css */
.sch-no-data-found-wrapper {
  /* background-color: #cccccc; */
  /* padding: 20px 140px; */
  /* text-align: center; */
  /* border-radius: 10px; */
  /* width: max-content; */
  margin: 0 auto 30px;
  /* height: 70vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sch-no-data-inner {
  background-color: var(--whiteColor);
  padding: 110px 30px;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  margin: 0 auto 0px;
}

.sch-no-data-found-wrapper h2 {
  display: block;
  font-size: 20px;
  padding: 20px 0px 0px;
}

/* ====================custom file upload css ============================ */

/*=========== custom checkbox css start ===============*/
/* .sch-account-accordian .sch-custom-check-box {
  position: absolute;
  right: 13px;
} */

.sch-custom-check-box .form-group {
  display: block;
}

.sch-custom-check-box .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.sch-custom-check-box .form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: var(--sch-color2);
  border: 2px solid var(--sch-color3);
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  /* margin-right: 5px; */
  border-radius: 5px;
  position: absolute;
  top: 0px;
  right: 0px;
}

.sch-custom-check-box input[type=checkbox]:checked+label:before {
  background: var(--sch-gradient-bg);
  border-color: #c02a93;
}

.sch-custom-check-box .form-group input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: auto;
  width: 5px;
  height: 11px;
  border: 2px solid var(--sch-white);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  right: 8px;
}

.sch-relativeClass {
  position: relative;
}

/* edit popup checkbox css */
.sch-statusCheckbox .sch-custom-check-box .form-group label {
  padding: 0 0 0 30px;
}

.sch-statusCheckbox .sch-custom-check-box .form-group label:before {
  right: auto;
  left: 0;
}

.sch-statusCheckbox .sch-custom-check-box .form-group input:checked+label:after {
  left: 10px;
}

.sch-no-account-found {
  border-radius: 10px;
  background: var(--whiteColor);
  margin: 10px 10px 10px;
  padding: 15px;
}

/*============ drafts post page css start ==============*/
.sch-draft-post-wrpper {
  padding: 0px 75px 80px;
}

.sch-trashDraft .sch-draft-post-wrpper {
  padding: 0;
}

.sch-draft-post-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 25px 0;
}

.sch-schedule-mainRes {
  display: none;
}

.sch-draft-post-flex>h2 {
  margin: 0;
  color: var(--sch-title-color);
  font-size: 20px;
  font-weight: 700;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sch-drft-post-right-sec {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.sch-custom-check-box.cun-selectAll {
  position: relative;
  z-index: 1;
}

.sch-custom-check-box.cun-selectAll .form-group label {
  font-weight: 500;
  color: var(--sch-title-color);
  font-size: 14px;
  padding: 0px 0px 0px 25px;
}

.sch-custom-check-box.cun-selectAll .form-group label:before {
  left: 0;
  right: auto;
  border-color: #D4DAE7;
  background-color: var(--whiteColor);
  width: 18px;
  height: 18px;
  padding: 0px;
  top: 2px;
}

.sch-custom-check-box.cun-selectAll .form-group label:after {
  right: auto !important;
  left: 7px !important;
  border-color: #ffffff !important;
  top: 5px !important;
  width: 5px !important;
  height: 10px !important;
}

.sch-post-selected p {
  color: #495057;
  font-size: 14px;
  font-weight: 400;
  padding: 0px 5px 0 8px;
}

/* .sch-draft-box-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: start;
} */


.sch-draft-box-flex {
  display: grid;
  /* align-items: center; */
  flex-wrap: wrap;
  gap: 15px;
  justify-content: flex-start;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.sch-draft-box {
  border: 1px solid #E9EDF6;
  /* filter: drop-shadow(0px 0px 60px rgba(88, 37, 113, 0.06)); */
  background-color: var(--whiteColor);
  padding: 5px 5px 15px;
  /* width: 380px; */
  /* height: 100%; */
  position: relative;
  border-radius: 5px;
  transition: all 0.5s;
  height: fit-content;
}

/* .sch-draft-mansory .sch-draft-box {
  padding: 0px;
} */

.sch-draft-img-box {
  position: relative;
  background-color: #f4e9fd;
  border-radius: 10px;
}

.pxl-draftImg>img {
  width: 100%;
  border-radius: 5px;
}

.sch-schedule-btn-wrap button.pxl_btn {
  margin: 0;
}

.sch-draft-box .sch-custom-check-box.cun-selectAll {
  /* position: absolute;
  top: 10px;
  left: 10px; */
}

.pxl_leftForm.pxl_form .pxl_btnHolder.pxl_btnHolder-login button.pxl_customBtn.pxl_btn {
  min-width: 100%;
  height: 60px;
}

.sch-draft-img-box>img {
  width: 100%;
  max-height: 165px;
  min-height: 165px;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
}

.sch-draft-img-box>video {
  width: 100%;
  max-height: 165px;
  min-height: 165px;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
}

.sch-draft-mansory .sch-draft-img-box>img {
  max-height: 100%;
  min-height: 100%;
}


/* .sch-draft-img-box>img {
  width: 100%;
  max-height: 100px;
  min-height: 100px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
} */

.sch-draft-content {
  /* padding: 20px 10px 10px 10px; */
  padding: 20px 0px 0px 0px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.sch-draft-mansory .sch-draft-box.sch-draft-box-imgNone .sch-draft-content {
  /* padding: 40px 10px 10px 10px; */
}

/* .sch-draft-box.sch-draft-box-imgNone .sch-draft-content {
  padding: 40px 10px 10px 10px;
} */
.modal-body>div>img {
  border-radius: 5px;
}

.sch-menuSection {
  position: relative;
}

/* .sch-menuSection>a{ */
/* padding: 3px; */
/* } */
/* .sch-draft-content h2 {
  color: #161930;
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 10px;
  display: -webkit-box;
  width: 170px;
  max-width: 100%;
  height: 30px;
  line-height: 1.5;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
} */

/* .sch-draft-content p {
  color: #72778F;
  font-size: 14px;
  font-weight: 400;
  display: -webkit-box;
  width: 340px;
  max-width: 100%;
  height: 90px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.sch-draft-post-title>p {
  color: #72778F;
  font-size: 14px;
  font-weight: 400;
  max-height: 90px;
  padding: 5px 0 0 0;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: pre-line;
}

.cun-headreSelect.cun-selectProject-select2 {
  padding: 0;
}

.sch-draft-post-title {
  padding: 0px 0px;
  width: calc(100% - 20px);
  position: relative;
}

.sch-draft-post-title>h2 {
  /* text-transform: capitalize; */
  margin: 0;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
}

.sch-draft-post-title span {
  font-size: 16px;
}

/* .sch-draft-post-title span.sch-drfat-postTitle {
  color: var(--sch-primary-color);
  padding: 0 5px 0 0;
  
  font-weight: 600;
} */

.sch-draft-edit a {
  /* position: absolute;
  right: 20px;
  top: -20px; */
  background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}

.sch-draft-edit a svg {
  width: 14px !important;
  height: 14px !important;
}

.sch-draft-delet a {
  /* position: absolute;
  right: 60px;
  top: -20px; */
  background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  cursor: pointer;
}

.sch-draft-delet a svg {
  fill: var(--whiteColor);
  width: 14px !important;
  height: 14px !important;
}

/* img none css */
/* .sch-draft-box.sch-draft-box-imgNone .sch-draft-content {
  padding: 45px 10px 10px 10px;
}

.sch-draft-box.sch-draft-box-imgNone .sch-draft-content .sch-draft-edit {
  top: 3px;
}

.sch-draft-box.sch-draft-box-imgNone .sch-draft-content .sch-draft-delet {
  top: 3px;
} */

/* schedule post page css start */
.sch-schedule-postWrap {
  display: flex;
}

.sch-schedule-main {
  width: 100%;
  height: 100%;
}

.sch-schedule-post-row {
  background: white;
  /* width: 100%; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 60px rgba(88, 37, 113, 0.06));
  border: 1px solid #E9EDF6;
  padding: 10px 20px;
  margin: 0px 0px 10px 50px;
  border-radius: 15px;
}

.sch-scheduleSec {
  display: flex;
  align-items: center;
  width: 100%;
}

.sch-scheduleSecRight {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.sch-scheduleSec .sch-custom-check-box.cun-selectAll .form-group label:before {
  background-color: var(--sch-color2);
}

.sch-scheduleImage {
  width: 150px;
  height: 100px;
  padding: 0px 30px 0px 20px;
}

.sch-scheduleImage>img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  object-position: center;
  background-size: cover;
  border-radius: 5px;
}

.sch-schedulePost-heading h2 {
  color: var(--sch-title-color);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  display: block;
  width: 100%;
  max-width: 50px;
  /* height: 20px; */
  /* -webkit-line-clamp: 1; */
  /* -webkit-box-orient: vertical; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.sch-scheduleCalender a {
  color: #4F536F;
  font-size: 15px;
  font-weight: 500;
}

.sch-scheduleCalender a:hover {
  color: #4F536F;
}

.sch-scheduleCalender a img {
  position: relative;
  top: -2px;
}

.sch-scheduleCalender a span.sch-scheduleDate-text {
  padding: 0 0 0 10px;
}

.sch-schedulePreview {
  padding: 0 0 0 100px;
}

.sch-schedulePreview a {
  color: #4F536F;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  /* text-transform: capitalize; */
}

.sch-schedulePreview a span.sch-eyeSvg {
  width: 28px;
  height: 28px;
  background: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  filter: drop-shadow(0px 0px 60px rgba(88, 37, 113, 0.06));
}

.sch-schedulePreview a span.sch-eyeSvg svg {
  width: 14px;
  height: 14px;
  fill: var(--whiteColor);
}

.sch-schedulePreview a span.schPreview {
  padding: 0 0 0 10px;
  color: #4F536F;
  font-size: 15px;
  font-weight: 500;
}


/* Rsuit datepicker  */
.rs-picker-popup {
  z-index: 9999 !important;
}

.rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  color: #000000 !important;
}

/*=============== trash page css start ==============*/
.sch-trash-tabs-wrapper {
  padding: 0 0 100px 0;
}

.sch-trashDraft .sch-container-fluid {
  padding: 0;
}

.sch-trash-tabs-wrapper .tab-content {
  padding: 30px 20px;
}

.sch-trash-tabs-wrapper ul.nav.nav-tabs li {
  position: relative;
}

.sch-trash-tabs-wrapper>.nav.nav-tabs {
  border-bottom: 1px solid #F2E1F9;
  /* padding: 14px 20px 0; */
  padding: 0 20px 0px;
}

.sch-trash-tabs-wrapper .nav-tabs .nav-link:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: var(--primaryColor);
  width: 50%;
  height: 5px;
  border-radius: 10px 10px 0px 0px;
  opacity: 0;
}

.sch-trash-tabs-wrapper .nav-tabs .nav-link.active:before {
  opacity: 1;
}

button.nav-link:focus-visible {
  box-shadow: none;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: transparent;
}

ul.nav.nav-tabs {
  border-bottom: 1px solid #F2E1F9;
}

.nav-tabs .nav-link {
  color: var(--sch-title-color);
  font-weight: 600;
  /* text-transform: capitalize; */
  padding: 0px 14px 14px;
  border: 0px solid;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
}

/* verification css start */
.pxl-verifyMain-wrap {
  position: fixed;
  right: 10px;
  top: -60px;

  /* display: flex; */
  z-index: 1;
  transition: all 0.5s;
}

.pxl-verify-toggle>a {
  width: 90px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FF9E9E;
  background-color: #FFDFDF;
  margin: 0px 0px 0px 170px;
  border-radius: 0px 0 5px 5px;
  border-top: none;
}

.pxl-verify-toggle>a>h2 {
  font-size: 12px;
  font-weight: 600;
}

.pxl-verify-toggle>a>img {
  position: relative;
  left: 3px;
}

.pxl_verification_wrapper {
  background-color: #FFDFDF;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  border: 1px solid #FF9E9E;
  border-radius: 5px;
  transition: all 0.5s;
  border-top: none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.pxl_verification_wrapper.pxlVerify-box>p {
  font-size: 12px;
  font-weight: 500;
  color: #222222;
  width: 240px;
}

.pxl_verification_wrapper.pxlVerify-box a.pxl-verifyBtn {
  background-color: #FF5555;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  width: 97px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin: 0 0 0 25px;
}

.pxl-verifyMain-wrap.pxl-OpenVerifyBox {
  top: 0;
}



.pxl_socialError_wrapper.false.pxl-account-errorBoxOpen {
  top: 0;
}

.pxl_socialError_wrapper {
  position: fixed;
  top: -93px;
  right: 0px;
  left: 280px;
  text-align: center;
  z-index: 1;
  width: 260px;
  margin: 0 auto;
  transition: all 0.5s;
}

.pxl_socialError_wrapper .pxl-social-error-innerBox {
  background-color: #FFDFDF;
  display: inline-block;
  padding: 10px 20px;
  color: var(--whiteColor);
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #FF9E9E;
  border-top: none;
  width: 260px;
}

.pxl_socialError_wrapper h5 {
  font-size: 16px;
  color: #222222;
  font-weight: 500;
  padding: 0 0 10px;
}

.pxl_socialError_wrapper .pxl-social-error-innerBox a p {
  /* text-transform: capitalize; */
}

.pxl-socail-error-boxInner a p {
  color: #000000;
}

.pxl-socialerror-scroll {
  max-height: 42px;
  overflow: auto;
  padding: 0 5px 0 0px;
}
.pxl-socail-error-boxInner {
  padding: 5px 0 0;
}

.pxl_socialError_wrapper div span {
  display: inline-block;
  background-color: #FF5555;
  width: 100px;
  color: #ffffff;
  padding: 5px 0px;
  font-size: 14px;
  border-radius: 50px;
  text-transform: capitalize;
}

.pxl-socail-error-boxInner a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pxl_socialError_wrapper.secondary_Alert {
  top: 87px;
}

.pxl-socialErro-box>button {
  color: #ffffff;
  background-color: #FF5555;
  max-width: fit-content;
  margin: 0 auto;
  padding: 1px 10px;
  text-transform: capitalize;
  border-radius: 0px 0px 5px 5px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  transition: all 0.5s;
}

.pxl_socialError_wrapper.secondary_Alert div {
  border-radius: 10px;
}

.pxl_imgHolder>img {
  width: 50px;
  height: 50px;
}

.pxl_profileName {
  padding-left: 10px;
  width: calc(100% - 50px);
}

/* ============select2 css start =================*/
/* .css-t3ipsp-control {
  height: 60px;
  line-height: 40px;
}

div#react-select-3-placeholder {
  grid-area: auto;
  line-height: 43px;
  height: 43px;
}

.css-qbdosj-Input {
  height: 50px;
  width: 100%;
  display: block !important;
  padding: 0 !important;
  margin: 0 !important;
}

.css-1fdsijx-ValueContainer {
  text-align: left;
  height: 50px;
  padding: 0px 15px !important;
}

div#react-select-3-placeholder {
  grid-area: unset;
  line-height: 50px;
  height: 50px;
  padding: 7px 0px 0px;
}

.css-1jqq78o-placeholder {
  padding: 15px 0px 0 !important;
  height: 50px;
}
.sch-banner-select-sec .css-1jqq78o-placeholder {
  padding: 0 !important;
  position: relative;
  top: 20px;
  left: 10px;
}
.sch-banner-select-sec .css-1fdsijx-ValueContainer {
  padding: 0px 0px 0 !important;
  height: auto;
  position: relative;
  top: -14px;
}
.sch-banner-select-sec .css-1fdsijx-ValueContainer{
	background-color: #F2E2FF;
}
.sch-banner-select-sec .css-13cymwt-control {
    height: 36px;
    width: 160px;
	overflow: hidden;
    position: relative;
}


.sch-banner-select-sec .css-1fdsijx-ValueContainer {
    padding: 0px 0px 0 !important;
    height: 50px;
}
.sch-banner-select-sec .css-t3ipsp-control{
	height: 36px;
    width: 160px;
	overflow: hidden;
    position: relative;
}
.css-t3ipsp-control .css-1jqq78o-placeholder {
  padding: 0px !important;
}

.css-t3ipsp-control {
  height: 50px;
  line-height: 50px;
  padding: 0 0 0 0;
  border: 1px solid var(--sch-border-color1);
  box-shadow: none !important;
}

.css-tr4s17-option {
  font-size: 13px;

  background: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%);
}

.css-10wo9uf-option {
  font-size: 13px !important;
  border-bottom: 1px solid #dddddd96;
  color: #161930!important;
}
.css-d7l1ni-option{
  color: #161930!important;
  background-color: #ffffff !important;
}
.css-10wo9uf-option:active {
  background-color: #dddddd4a !important;
}
.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
.css-13cymwt-control{
  position: relative;
}
.pxl-button-disable a.sch-btn-iconRight {
  cursor: unset;
}
.css-b62m3t-container:after{
  content: '' !important;
  position: absolute !important;
  right: 15px;
  top: 20px;
  width: 10px;
  height: 10px;
  border: 6px solid transparent;
  border-top-color: #8417AF;
}
.css-d7l1ni-option {
  font-size: 13px !important;

}
.css-1n6sfyn-MenuList {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
text-align: left;
} */

/*============ date time picker css ================*/
.rs-input-group.rs-input-group-inside .rs-input {
  background-color: transparent;
  height: 50px;
  min-height: 50px;
  padding: 0px 10px;
  border-radius: 5px;
}

.rs-input-group {
  border: 1px solid var(--sch-border-color1);
}

.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:not(.rs-input-group-disabled):hover {
  border-color: var(--sch-border-color1) !important;
}

.cun-datePicker .rs-input-group.rs-input-group-inside {
  border-color: var(--sch-border-color1) !important;
  border: 1px solid var(--sch-border-color1) !important;
}

.cun-datePicker .rs-picker-error>.rs-input-group:focus-within {
  outline: unset !important;
}

/* ===================all popup css start ====================*/
/* schdeule post model css start */
.pxl-domain-input button.pxl_btn {
  margin: 0;
  height: 50px;
}

.pxl_model_body.pxl-scdulePost-model .pxa_textContent h4 {
  font-size: 20px;
  font-weight: 600;
  padding: 0 0 10px;
}

.pxl_model_body.pxl-scdulePost-model>.pxa_textContent>p {
  font-size: 16px;
  margin: 0 auto 20px;
}

/* span.rs-input-group-addon {
  display: none;
} */

.sch-select-field select {
  border-radius: 6px;
  border: 1px solid var(--input-border);
  background-color: var(--bg-color1);
  width: 100%;
  min-height: 60px;
  padding: 10px 20px;
  font-size: 16px;
}

.rs-picker-toggle-wrapper {
  display: block !important;
}

.pxl-popupClose a {
  position: absolute;
  top: -10px;
  right: -10px;
}

/* preview popup */
/* .fade.pxl_modalDv.schPreviewPopup.modal .modal-dialog.modal-lg.modal-dialog-centered {
  max-width: 360px;
} */
.sch-draft-modelcontent h2 {
  margin: 0;
  padding: 10px 0px 10px;
  font-size: 20px;
  font-weight: 700;
}

.sch-draft-modelcontent p {
  font-size: 14px;
  font-weight: 400;
}

.pxl_modalDv.pxl-modelDiv1.modal .modal-header {
  border: none;
  padding: 20px 20px 0 0px;
}

.fade.pxl_modalDv.schPreviewPopup.modal .modal-header {
  border: none;
  padding: 0px;
}

.pxl-newCustomer-popup .css-13cymwt-control {
  min-height: 50px;
  border: 1px solid var(--sch-border-color1);
}

.pxl-newCustomer-popup .css-13cymwt-control:hover {
  border: 1px solid var(--sch-border-color1) !important;
}

.pxl-newCustomer-popup .css-t3ipsp-control {
  min-height: 50px;
  border: 1px solid var(--sch-border-color1) !important;
}

/* social popup css start */

/* .pxl_modalDv.schPreviewPopup .modal-dialog {
  width: 410px;
} */
.sch-model-tab-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F6F9FF;
  border-radius: 100px;
  padding: 10px 10px;
  max-width: fit-content;
  margin: 0 auto;
}

.sch-model-tab-header .nav-pills .nav-link {
  border-radius: 100px;
  /* padding: 13px 20px; */
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  /* text-transform: capitalize; */
}

.sch-model-tab-header .sch-social-model-wrap a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.sch-model-tab-header .sch-social-model-wrap a span {
  padding: 0px 0px 0px 10px;
  display: none;
  transition: all 0.5s;
}

/* active css */
.sch-model-tab-header a.nav-link.active .sch-social-model-wrap a span {
  display: block;
  color: #1E4BA0;
}

.sch-model-tab-header a.sch-fbActive.nav-link.active {
  background-color: #C7D8F8;
}

.sch-model-tab-header a.sch-fbActive.nav-link.active .sch-social-model-wrap a span {
  color: #1E4BA0;
}

.sch-model-tab-header a.sch-instaActive.nav-link.active {
  background-color: #FFDFF5;
}

.sch-model-tab-header a.sch-instaActive.nav-link.active .sch-social-model-wrap a span {
  color: #C33697;
}

.sch-model-tab-header a.sch-linkdinActive.nav-link.active {
  background-color: #C7D8F8;
}

.sch-model-tab-header a.sch-pinterestActive.nav-link.active {
  background-color: #FFDCDC;
}

.sch-model-tab-header a.sch-pinterestActive.nav-link.active .sch-social-model-wrap a span {
  color: #C80000;
}

/* model body css start */
.sch-model-social-body {
  margin: 30px 10px 0;
  border: 1px solid #f0f2f5;
  padding: 20px 0px 0;
  border-radius: 10px;
}

.sch_post_view_wrap {
  margin: 10px 10px 0;
}

.sch_post_view_wrap .sch_post_page span {
  color: #000;
}

.sch_post_view_wrap .sch_post_page a {
  color: #1E4BA0;
}

.sch-model-body-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
}

.sch-model-user-name-area {
  display: flex;
  align-items: center;
}

.sch-model-user-img>img {
  max-width: 40px;
  max-height: 40px;
  object-fit: cover;
  object-position: top;
}

.sch-model-user-name {
  padding: 0 0 0 10px;
}

.sch-model-user-name h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.sch-model-user-name p {
  font-size: 14px;
  font-weight: 400;
  color: #72778F;
}

.sch-social-img-content>p {
  padding: 10px 15px 0px;
  margin: 0 0 5px;
  display: -webkit-box;
  max-width: 100%;
  max-height: 300px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  color: #72788f;
  white-space: pre-line;
}

/* img1 */
.sch-uploadpost-img1 {
  padding: 10px 0 0 0;
}

.sch-uploadpost-img1 {
  text-align: center;
}

.sch-uploadpost-img1 img {
  width: 100%;
  /* max-height: 300px; */
  object-fit: contain;
  object-position: top center;
}

/* img2 */
.sch-uploadpost-img2 {
  padding: 10px 0 0 0;
  display: flex;
}

.sch-uploadpost-img2 img {
  width: 208px;
  max-height: 200px;
  padding: 0px 1px;
}

/* img3 */
.sch-uploadpost-img3 .sch-uploadpost-imgDiv:nth-child(2) img {
  width: 50%;
  max-height: 220px;
  object-fit: cover;
  object-position: center;
}

.sch-uploadpost-img3 .sch-uploadpost-imgDiv:nth-child(3) img {
  width: 50%;
  max-height: 220px;
  object-fit: cover;
  object-position: center;
}






.sch-uploadpost-img3>img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  object-position: center;
}

.sch-uploadpost-img3>img:nth-child(2) {
  width: 208px;
  padding: 2px 1px 0px 0px;
}

.sch-uploadpost-img3>img:nth-child(3) {
  width: 208px;
  padding: 2px 0px 0px 1px;
}



.sch-uploadpost-img3 .sch-uploadpost-imgDiv:nth-child(1) img {
  width: 100%;
}

/* img4 */
.sch-uploadpost-img4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.sch-uploadpost-img4 .sch-uploadpost-imgDiv {
  width: 50%;
}

.sch-uploadpost-img4 img {
  max-height: 208px;
  object-fit: cover;
  object-position: top;
  padding: 0px 1px 2px;
  border-radius: 5px;
  width: 50%;
}

/* img5 */
.sch-uploadpost-img5 .sch-uploadpost5-img2 {
  display: flex;
  align-items: center;

}

.sch-uploadpost-img5 .sch-uploadpost5-img2 img {
  max-height: 208px;
  object-fit: cover;
  object-position: top;
  padding: 0px 1px 2px;
  width: 208px;
}

.sch-uploadpost-img5 .sch-uploadpost5-img3 {
  display: flex;
  position: relative;
}

.sch-uploadpost-img5 .sch-uploadpost5-img3 img {
  /* max-height: 125px; */
  object-fit: contain;
  /* object-position: top; */
  padding: 0px 1px 2px;
  border-radius: 5px;
  width: 138px;
}

.sch-imgOverlay {
  position: relative;
  max-height: 137px;
  z-index: 1;
}

.sch-imgOverlay:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #00000061;
  z-index: 0;
  border-radius: 5px;
}

.sch-imgOverlay span {
  position: absolute;
  top: 0;
  left: 0px;
  right: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--whiteColor);
  font-size: 30px;
  z-index: 1;
}

ul.sch-social-review-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 7px 10px 0px;
  margin: 0px 0px 8px;
}

ul.sch-social-review-sec li a {
  padding: 4px 10px;
  width: 103px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #1c293b;
  /* text-transform: capitalize; */
  cursor: unset;
}

ul.sch-social-review-sec li a img {
  width: 17px;
}

ul.sch-social-review-sec li a span {
  padding: 0 0 0 5px;
  font-size: 15px;
}

.sch-commentAreaMain {
  padding: 0px 10px;
}

/*================= instagram ===============*/

.schInstagram-social ul.sch-likeEmoji {
  padding: 0px;
  display: flex;
  align-items: center;
}

.schInstagram-social ul.sch-likeEmoji li {
  padding: 0 15px 0 0;
}

.schInstagram-social ul.sch-likeEmoji li a {
  cursor: unset;
}

.sch-InstaSlider {
  padding: 15px 0 0 0;
}

.sch-InstaSlider .swiper {
  max-height: 285px;
  height: 285px;
}

.sch-InstaSlider img {
  width: 100%;
  max-height: 285px;
  min-height: 285px;
  display: block;
  object-fit: cover;
  object-position: top;
}

.sch-pinterest-social .preview_note {
  padding: 0px 0px 20px;
  text-align: center;
}

.sch-instaBottomArea {
  padding: 10px 15px 10px;
}

.sch-instaBottomArea h6 {
  font-size: 13px;
  color: #72788f;
  display: -webkit-box;
  max-width: 100%;
  max-height: 300px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.sch-instaBottomArea h6 span {
  line-height: 1.6;
  /* display: block; */
  white-space: pre-line;

}

.sch-instaBottomArea p {
  font-size: 13px;
  color: #aca7a7;
}

.sch-InstaSlider .swiper-button-next:after {
  width: 30px;
  height: 26px;
  background-color: var(--whiteColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  border-radius: 50px;
}

.sch-InstaSlider .swiper-button-prev:after {
  width: 30px;
  height: 26px;
  background-color: var(--whiteColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  border-radius: 50px;
}

.sch-InstaSlider .swiper-button-prev:after,
.swiper-button-next:after {
  color: #000000;
  font-weight: 900;
}

/*=================== linkdin ==============*/
.sch-linkdin-post-likeArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 0;
}

.schLinkdin-social ul.sch-social-review-sec li a {
  background-color: transparent;
  display: block;
  text-align: center;
  width: auto;
}

.schLinkdin-social ul.sch-social-review-sec li a span {
  padding: 0px;
  display: block;
  color: #1c293b;
}

.schLinkdin-social .sch-linkdin-post-likeArea {
  padding: 13px 10px 0;

}

.schLinkdin-social ul.sch-social-review-sec {
  padding: 0px 10px 0px;
  margin: 9px 30px 3px;
  border-top: 1px solid #dddddda6;
}

.sch-linkdin-comment-count p {
  /* text-transform: capitalize; */
}

/* image icon  */
.social_user_img_icon {
  border-radius: 50%;
  background-color: var(--secondryColor);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  width: 100%;
  height: 100%;
}

.sch-template-box.pxl-schedular-recentDraft-index.schSchedular .sch-draft-post-title h2 {
  max-width: 120px;
}

.sch-section-row.pxlrecent-schedualer-indexbox .sch-template-box.schSchedular .sch-draft-post-title h2 {
  max-width: 130px;
}

/* ============pinterest============= */
.sch-pinterest-social {
  border: none;
  padding: 0;
  margin: 15px 0 0 0;
}

.sch-pinterest-img {
  position: relative;
}

.sch-pinterest-img img {
  width: 100%;
  border-radius: 10px;
}

.sch-pinterest-copy {
  display: flex;
  align-items: center;
  font-size: 16px;
  width: 50px;
  height: 35px;
  background-color: #000000;
  justify-content: center;
  border-radius: 50px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.sch-pinterest-copy span {
  color: var(--whiteColor);
  padding: 0 0 0 3px;
}

/* AI Assist popup css start */

.sch-genrate-code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 20px;
}

.pxl_model_body.pxl-aiAssistmodel .sch-textareaSection>button {
  margin: 4px 0px 0px 0px;
}


/* social page facebook popup */

.sch-social-account-wrapper {
  max-height: 230px;
  overflow-y: auto;
  padding: 10px 10px 0 0;
}

.sch-social-account-wrapper .sch-search-row {
  width: 345px !important;
}

.sch-social-account-wrapper .sch-search-row.sch-search-bg-color-white input {
  height: 40px !important;
}

.sch-social-account-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dddddd42;
  padding: 10px 10px;
  margin: 0px 0px 10px 0px;
  border-radius: 5px;
  border: 1px solid #dfdbdb;
}

.sch-socail-pageName {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.sch-social-account-flex .pxl_btn {
  height: auto;
}

.sch-connectedAccount {
  /* text-transform: capitalize; */
  color: #00cc58;
  padding: 10px 12px;
  font-size: 14px;
  background-color: #00cc5814;
  border-radius: 5px;
}

.sch-socialDefault-img {
  width: 40px;
  height: 40px;
  margin: 0 15px 0 0;
}

.sch-socialDefault-img img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.sch-continue-social-wrap p {
  font-size: 13px;
  padding: 10px 0 5px;
}

.sch-continueBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sch-continueBtn a.pxl_btn {
  height: auto;
}

.fc-daygrid-event {
  white-space: unset !important;
  width: 100% !important;
  display: block !important;
}


.btn-close {
  background-image: url(../public/assets/images/popup-close.png);
  opacity: 1;
  background-size: cover;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
}

.btn-close:hover {
  opacity: 1;
}

/* Date time picker css start */
/* .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: var(--sch-pink-color) !important;
  background-color: var(--sch-pink-color) !important;
}
.rs-btn-primary {
  background: var(--gradientColor) !important;
  background: var(--gradientColor) !important;
} */


/* =============select 2 css ===================*/
.sch-selectWrap .css-13cymwt-control {
  height: 50px;
  border: 1px solid var(--sch-border-color1) !important;
  border-radius: 5px;
}

.sch-selectWrap .css-t3ipsp-control {
  height: 50px;
  border: 1px solid var(--sch-border-color1) !important;
  border-radius: 5px;
}




/*========= select option css start ==========*/
.sch-banner-select-sec {
  margin: 0px;
  position: relative;
  top: 7px;
  left: 10px;
  width: 160px;
  text-align: left;
}

.sch-banner-select-sec .css-13cymwt-control {
  background-color: #F2E2FF !important;
  border-color: #F2E2FF !important;
}

.sch-banner-select-sec .css-t3ipsp-control {
  background-color: #F2E2FF !important;
  border-color: #F2E2FF !important;
}

/* .css-1dimb5e-singleValue {
  color: #8417AF !important;
  font-weight: 600;
} */
.css-tj5bde-Svg {
  fill: #7f7f7f !important;
  width: 17px;
}

/* .css-qbdosj-Input {
  color: #8417AF !important;
} */

.sch-banner-select-sec .css-1jqq78o-placeholder {
  color: var(--sch-primary-color) !important;
  font-weight: 600 !important;
}

.css-10wo9uf-option {
  color: #000000 !important;
}

.css-tr4s17-option {
  background-color: var(--sch-primary-color) !important;
  border-radius: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.css-1nmdiq5-menu {
  padding: 1px 1px;
}

.css-10wo9uf-option:active {
  background-color: #dddddd !important;
}

.css-d7l1ni-option {
  background-color: #cccccc3d !important;
  color: #000000 !important;
}

.css-1n6sfyn-MenuList {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.css-t3ipsp-control {
  box-shadow: unset !important;
  /* border-color: hsl(0, 0%, 80%) !important; */
}

.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}

.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  text-align: left;
}

button.sch-btn-iconLeft.Pxl-cancel-btn>span>img {
  width: 17px;
  height: 17px;
}

/* menu restore delete */
.sch-menuOpen {
  position: relative;
}

.sch-menuOpen>a>span {
  display: block;
  width: 28px;
  height: 2px;
  margin-bottom: 4px;
  background-color: var(--sch-primary-color);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.sch-menuOpen>a>span:nth-child(2) {
  width: 17px;
}

.sch-menuBox {
  border: 1px solid var(--sch-border-color1);
  background-color: var(--whiteColor);
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  width: fit-content;
  padding: 10px 15px 5px;
  transition: all 0.5s;
  position: absolute;
  left: -150px;
  top: 0px;
  min-width: 130px;
  display: none;
}

.sch-menuBox.menu_show {
  display: block;
}

.sch-menuBox:after {
  content: '';
  position: absolute;
  border: 8px solid transparent;
  display: block;
  right: -17px;
  top: 5px;
  border-left-color: #393e4f;
}

.sch-menuBox>a {
  display: block;
  padding: 0px 0px 10px;
  position: relative
}

.sch-menuBox>a>span {
  width: 20px;
  display: inline-block;
  padding: 0px 30px 0px 0px;
}

.sch-post-reload .pxl-icon-tooltip a span.pxl-tooltip-text {
  left: -55px;
  top: -40px;
}

.sch-post-reload .pxl-icon-tooltip:hover a span.pxl-tooltip-text {
  right: auto;
  transform: unset;
}

/*  */
.pxl-icon-tooltip.cun-tooltipIcon a {
  width: auto;
  height: auto;
  background-color: transparent;
}

.pxl-icon-tooltip.cun-tooltipIcon a span.pxl-tooltip-text {
  left: -13px;
  font-size: 10px;
}

/* ===========custom toggle on off css ============ */
.centering {
  width: 1300px;
  height: auto;
  margin: 200px auto;
  text-align: center;
}

.sch-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 23px;
}

.sch-switch input {
  display: none;
}

.sch-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dedede;
  border-radius: 40px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sch-switch .slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background-color: var(--whiteColor);
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sch-switch input:checked+.slider {
  background-color: #18eb82;
}

.sch-switch input:checked+.slider:before {
  -webkit-transform: translateX(17px);
  -moz-transform: translateX(17px);
  transform: translateX(17px);
}

/* .sch-switch input:focus+.slider {} */

.sch-toggleButton {
  display: flex;
  align-items: center;
  padding: 0px 0px 25px;
}

.sch-toggleButton label.sch-toggleLabel {
  font-size: 16px;
  /* text-transform: capitalize; */
  color: var(--textColor);
  font-weight: 500;
  padding: 0 0 0 10px;
}

/* icon tooltip */
.cun-iconTooltip {
  position: relative;
}


.pxl-custom-file-upload .cun-show-tooltip {    
  top: -50px;
  left: 10px;
      
}
.cun-show-tooltip {
  position: absolute;
  top: -33px;
  background-color: var(--textColor);
  padding: 2px 10px;
  border-radius: 3px;
  color: var(--whiteColor);
  font-size: 13px;
  left: -6px;
  right: 0px;
  width: fit-content;
  margin: 0 auto;
  opacity: 0;
  text-align: center;
  /* text-transform: capitalize; */
  visibility: hidden;
  z-index: 111;
  transition: all 0.5s;
  cursor: auto;
}
.xs_support_projectName .cun-show-tooltip {
  left: 0;
  right: auto;
  text-transform: capitalize;
}
.xs_support_projectName .cun-show-tooltip p {
  color: var(--whiteColor);
}

.cun-show-tooltip:before {
  content: '';
  border-width: 5px;
  border-style: solid;
  border-color: var(--textColor) transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: 40%;
  margin-left: 5%;
  transition: all 0.5s;
}

.cun-iconTooltip:hover .cun-show-tooltip {
  opacity: 1;
  visibility: visible;
}

.pxl-dashboard-appbox .cun-show-tooltip {
  top: auto;
  bottom: calc(100% + 20px);
}

/* select2 */
.cun-selectWhite {
  position: relative;
  z-index: 111;
}

.cun-selectWhite .css-13cymwt-control {
  height: 50px;
  border: 1px solid var(--sch-border-color1) !important;
}

.cun-selectWhite .css-t3ipsp-control {
  height: 50px;
  border: 1px solid var(--sch-border-color1);
}

.css-13cymwt-control:hover {
  border-color: var(--sch-border-color1);
}

.cun-selectWhite .css-t3ipsp-control {
  box-shadow: unset !important;
  border-color: var(--sch-border-color1) !important;
}

.cun-selectWhite .css-1dimb5e-singleValue {
  color: #495057 !important;
  font-weight: 500;
  font-size: 14px;
}

.cun-selectWhite .css-tj5bde-Svg {
  fill: #495057 !important;
}

input::placeholder {
  color: #495057 !important;
  font-size: 14px;
}

.css-1jqq78o-placeholder {
  color: #495057 !important;
  font-weight: 400 !important;
}

/* menu dropedown */
.sch-submenubox {
  box-shadow: -7px 7px 25px 0px #0000001A;
  min-width: 130px;
  max-width: 130px;
  padding: 15px 0px 10px;
  border-radius: 4px;
  border: 1px solid #E9EDF6;
  background-color: var(--whiteColor);
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 22;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-40px);
}

.sch-submenubox.sch-submenubox-apiDocument {
  min-width: 160px;
  max-width: 150px;
}

.sch-submenubox.sch-submenubox-width140 {
  min-width: 140px;
  max-width: 140px;
}

.sch-submenubox.sch-submenubox-agent {
  min-width: 150px;
  max-width: 150px;
}

.sch-menuOpen .sch-submenubox {
  transition: all 0.3s;
  opacity: 1;
  visibility: visible;
  transform: translateX(-20px);
}

.sch-submenubox>ul>li>a {
  font-size: 14px;
  font-weight: 400;
  /* text-transform: capitalize; */
  color: #72778F;
  display: flex;
  align-items: center;
  margin: 0 0 5px 0;
  padding: 0px 20px;
  /* display: block; */
}

.sch-submenubox>ul>li>a>button {
  border: none;
  padding: 0;
  background-color: transparent;
  color: #72778F;
}

.sch-submenubox>ul>li>a>button>span {
  padding: 0 8px 0 0px;
}

/* .sch-submenubox>ul>li>a>span {
  position: relative;
  top: -2px;
  width: 25px;
  padding: 0 10px 0 0;
} */
.sch-submenubox>ul>li>a>span>img {
  position: relative;
  top: -2px;
  padding: 0 10px 0 0;
  width: 22px;
  height: 22px;
}

.sch-submenubox>ul>li>a>span>svg path {
  fill: #72778F;
}

.sch-templateMenu {
  position: absolute;
  right: 12px;
  top: 6px;
}

/*=============== color picker css start ================*/
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background: var(--gradientColor);
}

.rs-calendar-table-cell-content:hover {

  background-color: #efefef;
}

.rs-btn-primary {
  background: var(--gradientColor);
}

.rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  background-color: #efeeee !important;
}

.rs-calendar-table-cell-content:hover {
  background-color: #efeeee !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  -webkit-box-shadow: inset 0 0 0 1px #efeeee;
  -webkit-box-shadow: inset 0 0 0 1px #efeeee;
  box-shadow: inset 0 0 0 1px #efeeee;
  box-shadow: inset 0 0 0 1px #efeeee;
}

/* header select all box */
.cun-headerSelect-wrap {
  display: flex;
  align-items: center;
  position: relative;
}

.cun-headreSelect {
  position: relative;
  z-index: 111;
  min-width: 200px;
  padding: 0 15px 0 20px;
}

.pxl_filterFeilds.cun-users-resheader .cun-headreSelect {
  padding: 0;
}

.cun-headreSelect .css-16xfy0z-control {
  height: 50px;
}

.cun-headreSelect span.css-894a34-indicatorSeparator {
  background-color: transparent;
}

.sch-drft-post-right-sec .cun-headerSelect-wrap .cun-headreSelect {
  padding: 0 15px 0 10px;
}

.cun-headreSelect.cun-headreSelect-imagetemplate1 {
  padding: 0px;
}

.cun-headreSelect.cun-headreSelect-imagetemplate {
  padding: 0px 0 0 10px;
}

.cun-headreSelect .css-13cymwt-control {
  height: 45px;
  border: 1px solid var(--sch-border-color1);
}

.cun-headreSelect .css-13cymwt-control:hover {
  border: 1px solid var(--sch-border-color1);
}

.cun-headreSelect .css-t3ipsp-control {
  border: 1px solid var(--sch-border-color1) !important;
  height: 50px;
}

.cun-headreSelect .css-1nmdiq5-menu {
  box-shadow: none;
  border: 1px solid var(--sch-border-color1);
}

.cun-AllChecknoxSelect {
  position: relative;
  width: fit-content;
  font-size: 16px;
}

.cun-AllChecknoxSelect .form-group label {
  padding-right: 40px;
}

.sch-imgDescription {
  position: relative;
  padding: 10px 0 10px;
}

.sch-imgTitle {
  width: 100%;
}

.sch-imgTitle>h6 {
  margin: 0;
  font-size: 14px;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove .cun-noDesign-inner {
  padding: 80px 0px;
  text-align: center;
  /* background-color: #f4ecfc57; */
}

/* .remove.cun-no-design-wrapper .cun-noDesign-inner {
  padding: 80px 0px;
} */

.cun-headreSelect .css-13cymwt-control {
  height: 50px;
  border: 1px solid var(--sch-border-color1) !important;
}

li.cun-resSelect .css-t3ipsp-control {
  border: 1px solid var(--sch-border-color1) !important;
  height: 50px;
}

li.cun-resSelect .css-b62m3t-container .css-13cymwt-control {
  height: 50px;
}

.cun-backBtn-sec {
  padding: 10px 8px 0;
  color: #000000;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.cun-backBtn-sec span {
  position: relative;
  top: -1px;
  padding: 0 8px 0 0;
}

/* =========================== media css start ============================== */


/* ===========================API EndPoint Page Css Start  ===========================*/

.api_Leftsection {
  transition: left .3s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  bottom: 0;
  width: 230px;
  background-color: var(--textColor);
  font-size: 13px;
  font-weight: 700;
  padding: 10px;
}

.api_logo_wrap {
  background-color: var(--whiteColor);
  padding: 10px;
  width: 100%;
  max-width: 200px;
  border-radius: 10px;
  margin: 10px auto 20px;
}

.single_Comp_api_wrap {
  padding-left: 8px;
}

.single_Comp_api_wrap p {
  font-size: 14px;
  padding: 4px 0;
  cursor: pointer;
  font-size: 14px;
  padding: 6px 7px;
  margin-bottom: 3px;
  border-radius: 4px;
  cursor: pointer;
  transition: all .5s;
  /* text-transform: capitalize; */
}

.single_Comp_api_wrap p:hover {
  background: var(--primaryColor);
}

.single_Comp_api_wrap .activefile {
  background: var(--primaryColor);
}

.api-d-flex {
  display: flex;
}

.api_page_wrapper {
  margin-left: 230px;
  position: relative;
  z-index: 10;
  min-height: 100%;
  padding-bottom: 1px;
  flex: 1;
}

.api_page_wrapper .api_right_wrapper {
  background-color: var(--textColor);
  flex: 1;
  padding-bottom: 20px;
}

.api_right_wrapper .seprator {
  height: 40px;
  background-color: #433851;
}

.api_page_wrapper .api_content_wrapper {
  position: relative;
  z-index: 30;
  box-sizing: border-box;
  display: block;
  text-shadow: 0 1px 0 #fff;
  border-bottom: 1px solid var(--borderColor);
  padding: 20px 0;
  flex: 1;
  width: 50%;
  word-wrap: break-word;
}

.api_content_main_heading {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  padding: 15px 28px;
  border: 1px solid rgba(0 0 0 / 10%);
  background-image: linear-gradient(to right, var(--bg-color1) 50%, #433851 50%);
}

.api_para_text {
  background-image: linear-gradient(to right, var(--bg-color1) 50%, #433851 50%);
  padding: 30px 28px
}

.api_highLight_area {
  padding: 15px 28px;
  font-size: 16px;
  background-image: linear-gradient(to right, #f6e8f5 50%, #433851 50%);
}


.api_p-28 {
  padding: 0 28px;
}

.api_content_wrapper p {
  padding: 0 28px;
  margin-bottom: 20px;
}

.api_content_wrapper .api_name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 40px;
}

.api-btn {
  position: relative;
  padding: 10px 20px;
  border: 0px;
  height: 35px;
  /* text-transform: capitalize; */
  font-size: 14px;
  font-weight: 500;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  transition: all .5s ease;
  border-radius: 5px;
}

.api_request_wrap {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 30px;
}

.api_try_btn {
  background-color: var(--primaryColor);
  color: var(--whiteColor);
}

.api_cancel_btn {
  background: #ffa4a4;
}

.api_send_btn {
  background: #abefa3;
}

.api_method_type span {
  padding: 4px 10px;
  background: var(--textColor);
  color: var(--whiteColor);
  border-radius: 10px;
  text-transform: uppercase;
  margin-right: 15px;
}

.api_head_body h5 {
  background: #d3d2d2;
  padding: 6px 15px;
  font-size: 15px;
  color: var(--textColor);
  border-radius: 5px;
  font-weight: 400;
  margin-bottom: 20px;
}

.mb-5 {
  margin-bottom: 15px;
}

.api_content_text_wrap h5 {
  font-size: 14px;
  margin-bottom: 10px;
}

.api_content_text_wrap h5 span {
  font-weight: 400;
  margin-left: 15px;
}

.api_content_text_wrap div {
  font-size: 16px;
}

.api_html_code {
  word-wrap: break-word;
  line-height: 22px;
}

.api_example_wrap {
  padding: 28px;
}

.api_example_wrap .head {
  font-size: 20px;
  color: var(--whiteColor);
  margin-bottom: 20px
}

.code_Section {
  background-color: var(--whiteColor);
  padding: 10px;
  border-radius: 4px;
  scrollbar-width: thin;
  /* white-space: pre-wrap;  */
  word-wrap: break-word;
}

.api_example_wrap .code_Section pre code.hljs {
  width: 100%;
  max-width: 700px;
  word-wrap: break-word;
}

.code_Section pre code.hljs {
  max-height: 350px;
  scrollbar-width: thin;
  /* white-space: pre-wrap;  */
  word-wrap: break-word;
}

/* Admin Section */
.api_admin_flex {
  display: flex;
  margin-bottom: 20px;
}

.api_folder_wrap {
  width: 30%;
  margin-right: 20px;
  max-width: 345px;
}

.api_folder_box {
  width: 100%;
  padding: 5px 5px 0px 5px;
  border: 1px solid var(--borderColor);
  border-radius: 5px;
  background-color: var(--whiteColor);
}

.ap_single_folder_wrap {
  display: flex;
  justify-content: space-between;
  padding: 15px 15px;
  border-radius: 5px;
  margin-bottom: 8px;
  background: #f4e9fd;
}

.api_action_wrap {
  display: flex;
  gap: 10px;
  color: var(--whiteColor);
}

.pxl-api-addCancel-btn .api_action_btn {
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #bc9cd4;
  border-radius: 6px;
  padding: 6px;
  border: none;
  color: var(--whiteColor);
  cursor: pointer;
}

.pxl-api-addCancel-btn .api_action_btn.cancel {
  background-color: var(--textColor);
}
.api_folder_box .add_btn {
  padding: 15px;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  color: var(--whiteColor);
  /* align-items: center; */
  justify-content: flex-start;
  background: #bc9cd4;
  border-radius: 5px;
  cursor: pointer;
}


.api_add_inp_sec {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 0;
}

.api_add_inp_sec input {
  width: 100%;
  padding: 1px 20px 0px 20px;
  background-color: var(--whiteColor);
  border: 1px solid var(--sch-border-color1);
  border-radius: 6px;
  height: 38px;
}

.ap_single_folder_wrap .floder_title {
  color: #000000;
  font-size: 16px;
  /* width: 90%; */
  font-weight: 400;
}

.api_second_wrap {
  width: 100%;
}

.api_form_wrap {
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #E9EDF6;
  background-color: var(--whiteColor);
}
.pxl-api-doc-sec4 .api_response_box h4 {
  color: var(--sch-title-color);
  font-size: 16px;
  font-weight: 500;
  padding: 0 0 15px;
}
.api_form_box input {
  /* width: 100%;
  padding: 1px 20px 0px 20px;
  background-color: var(--whiteColor);
  border: 1px solid var(--sch-border-color1);
  border-radius: 6px;
  height: 38px; */
}

.api_form_box textarea {
  width: 100%;
  padding: 1px 20px 0px 20px;
  background-color: var(--whiteColor);
  border: 1px solid var(--sch-border-color1);
  border-radius: 6px;
}

.api_form_box.sample_res {
  /* margin: 30px 0; */
}

.api_form_box.sample_res>div {
  /* margin-bottom: 8px; */
}

.api_form_box.sample_res textarea {
  /* height: 150px; */
}

.api_form_box .api_input_box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.api_form_box .api_input_box>div:first-child {
  max-width: 100px;
  width: 100%;
}

.api_post_method_box {
  display: flex;
}

.api_post_method_box .cun-headreSelect {
  border-radius: 5px 0 0 5px;
  padding: 0;
  min-width: 100px;
}

.api_post_method_box .cun-headreSelect .css-1dimb5e-singleValue {
  padding-bottom: 10px;
}

.api_post_method_box input {
  border-radius: 0 5px 5px 0;
  width: 100%;
  height: 50px;
}

.api_tabs div {
  padding: 5px 20px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.api_tabs .active_tab {
  color: var(--primaryColor);
  border-bottom-color: var(--primaryColor);
}

.api_table {
  width: 100%;
  background-color: #ffff;
}

.api_table thead {
  border: 1px solid var(--borderColor);
}

.api_table thead th {
  padding: 5px 10px;
  border-right: 1px solid var(--borderColor);
}

.api_table tbody td {
  padding: 5px 5px;
  border: 1px solid var(--borderColor);
}

.headers td {
  text-align: center !important;
}

.api_table tbody tr {
  height: 40px;
}

.api_table thead .api_add_field {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #bc9cd4;
  border-radius: 6px;
  cursor: pointer;
}

thead .headers .api_add_field {
  right: 3px;
}

.api_table tbody td input {
  height: 30px;
}

.api_table tbody td .cun-headreSelect .css-13cymwt-control {
  height: 30px !important;
}

.api_table tbody td .cun-headreSelect .css-t3ipsp-control {
  height: 30px !important;
  z-index: 9;
}

.css-1nmdiq5-menu {
  z-index: 999 !important;
}

.api_btn_sav {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.api_btn_sav .api_try_btn {
  /* background: #abefa3;
  color: #18890b; */
}

.api_table tbody .d-flex {
  display: flex;
  justify-content: space-between;
  height: 50px;
  width: 150px;
  align-items: center;
}

.api_row_del img {
  width: 15px;
  cursor: pointer;
}

.select2-container {
  z-index: 9999;
  /* Adjust the z-index value as needed */
}

.pxl-pixasupport-project-head-tooltip.cun-iconTooltip .cun-show-tooltip:before {
  left: 0;
}
.pxl-pixasupport-project-head-tooltip.cun-iconTooltip .cun-show-tooltip p{
      max-width: 150px;
    text-overflow: ellipsis; 
  white-space: nowrap; 
    overflow: hidden;
}
.pxl-api-doc-sec4 .api_response_box {
  margin-top: 20px;
  max-width: 100%;
  /* border: 1px solid #E9EDF6; */
}

.api_folder_text_bold {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 50%;
}

.api_mobile_header_wrap {
  display: none;
}

.api_table tbody td input[type=file] {
  padding-left: 1px;
}

.api_table tbody td input::file-selector-button {
  background-color: #bc9cd4;
  color: var(--whiteColor);
  border-radius: 6px;
  border: none;
}

/*=========================== API EndPoint Page Css ENd =========================== */


/*=========================== Support CSS Start  ===========================*/
.support_tabs_wrapper {
  margin-bottom: 22px;
  min-height: 90px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.support_tab_inner {
  padding: 0px 23px;
  border-bottom: 2px solid #E0E0E0;
}

.support_tab_inner ul {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding-bottom: 6px;
}

.support_tab_inner ul li {
  padding: 10px;
  position: relative;
}

.support_tab_inner ul li a {
  font-size: 16px;
  font-weight: 400;
  color: var(--sch-title-color);
  transition: all .5s;
  padding: 10px 0;
}

.support_tab_inner ul li:hover a {
  color: var(--primaryColor);
}

.support_tabs_wrapper .support_tab_inner>ul>li>a:before {
  content: "";
  position: absolute;
  bottom: -7px;
  left: 0;
  right: 0;
  margin: auto;
  background: var(--sch-primary-color);
  width: 80%;
  height: 5px;
  border-radius: 10px 10px 0px 0px;
  opacity: 0;
}

.support_tabs_wrapper .support_tab_inner>ul>li.active a:before {
  opacity: 1;
}


.sup_dash_wrap {
  /* max-width: 1660px;
  width: 100%;
  margin: 0 auto; */

  margin: auto;
  max-width: 1800px;
  padding: 0 20px;
}

.dash_grid {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 2fr));
}

.dash_grid .single_dash_box {
  border: 1px solid #EDDAF4;
  box-shadow: -31px 17px 50px 0px #F0EBF4;
  border-radius: 24px;
  padding: 20px;
  display: flex;
  align-items: center;
  background-color: var(--whiteColor);
  transition: all .5s;
}

.single_dash_box:hover {
  /* transform: translateY(-5px); */
  /* box-shadow: 0 .3125rem .3125rem 0 rgba(82, 63, 105, .05); */
}

.dash_head {
  padding: 25px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dash_head.dash_head_topNone {
  padding-top: 0 !important;
}

.dash_head h3 {
  font-size: 20px;
  font-weight: 700;
  color: var(--sch-title-color);
  margin: 0;
}

.fc .fc-toolbar-title {
  font-size: 22px;
}

.single_dash_box .dah_color_box {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  margin-right: 20px;
}

.dah_color_box.color_1 {
  background-color: rgba(0, 163, 137, 0.1);
}

.dah_color_box.color_1 svg path {
  stroke: #00A389;
}

.dah_color_box.color_2 {
  background-color: rgba(255, 91, 91, 0.1);
}

.dah_color_box.color_2 svg path {
  stroke: #FF5B5B;
}

.dah_color_box.color_3 {
  background-color: rgba(171, 84, 219, 0.1);
}

.dah_color_box.color_3 svg path {
  stroke: #AB54DB;
}

.dah_color_box.color_4 {
  background-color: rgba(57, 77, 255, 0.1);
}

.dah_color_box.color_4 svg path {
  stroke: #394DFF;
}

.dah_color_box.color_5 {
  background-color: rgba(255, 82, 134, 0.1);
}

.dah_color_box.color_5 svg path {
  stroke: #FF5286;
}

.single_dash_box h4 {
  font-size: 32px;
  font-weight: 600;
  color: var(--textColor);
}

.single_dash_box p {
  font-size: 16px;
}

.pxl-dashboard-grid-curve .single_dash_box {
  padding: 9px;
  border-radius: 8px;

}

.pxl-dashboard-grid-curve .single_dash_box .dah_color_box {
  border-radius: 5px;
}

.pxl_dash_box_content>h2 {
  margin: 0;
  font-size: 26px;
  font-weight: 600;
  color: #36274E;
}

.pxl_dash_box_content>p {
  font-size: 16px;
  font-weight: 400;
  color: #4F536F;
}

.support_table.pxl_table {
  background-color: #fff;
  padding: 4px;
  box-shadow: 0px 0px 60px 0px #5825710F;
}

.PxlRestore-trash-tooltip.cun-iconTooltip .cun-show-tooltip {
  transform: translate(-8px, 0px);
}

.PxlDelete-trash-tooltip.cun-iconTooltip .cun-show-tooltip {
  transform: translate(-5px, 0px);
}

.support_table .datatable_flex {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.support_table .pxl_status {
  color: white;
  background-color: var(--sch-primary-color);
  padding: 5px 15px;
  text-align: center;
  border-radius: 18px;
  display: inline-block;
}

.support_table .pxl_tableIcon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: var(--gradientColor);
  margin: 2px;
  border: none;
}

.support_table tbody tr:nth-child(even) {
  background: #FCF8FF !important;
}

.support_table tbody tr:nth-child(odd) {
  background: #fff !important;
}

.support_table .tab_name_wrap {
  display: flex;
  gap: 10px;
  align-items: center;
}

.support_table .tab_name_wrap div span {
  width: 40px;
  height: 40px;
  background: #e7dff2;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--textColor);
}

.support_table .tab_name_wrap div img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.xs_switch {
  display: inline-block;
}

.xs_switch>input {
  position: absolute;
  left: -9999999px;
  opacity: 0;
}

.xs_switch>label {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 15px;
  font-weight: 600;
  color: var(--color-body);
}

.xs_switch>label>.xs_switch_icon {
  width: 40px;
  height: 18px;
  border-radius: 20px;
  background-color: #dedfe0;
  box-shadow: inset 0 -2px 3px rgba(0, 0, 0, .06);
  position: relative;
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
}

.xs_switch>label>.xs_switch_icon:before {
  position: absolute;
  top: 3px;
  left: 3px;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #fff;
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
}

.xs_switch input:checked~label {
  color: #70db78;
}

.xs_switch input:checked~label>.xs_switch_icon {
  background-color: #70db78;
}

.xs_switch input:checked~label>.xs_switch_icon:before {
  left: 25px;
}

/* Project Setting css start */
.pxl-project-setting-inner-wrap .pxl-custom-file-select {
  height: 98px;
  padding: 23px 0px !important;
}

.proj_Setting_first_flex .pxl-custom-file-select {
  border: 1px solid var(--input-border) !important;
}

.sup_proj_setting_form .pu_color_picker_toggle {
  border-radius: 6px;
  height: 78px !important;
}

.sup_proj_setting_form .pu_color_picker_toggle span.pu_color_preview {
  width: 65px !important;
  height: 65px !important;
}

.project_setting_wrap .sch-header-col {
  min-height: 50px;
  margin-bottom: 55px;
}

.project_setting_wrap .sch-main-menu .menu>li>a {
  padding: 3px 15px;
}

.project_setting_wrap .sch-main-menu .menu {
  padding-bottom: 7px;
  border-bottom: 2px solid #E0E0E0;
}

.project_setting_wrap .sch-main-menu .menu>li>a {
  color: var(--sch-title-color);
}

.sup_proj_setting_form {
  border: 1px solid #E9EDF6;
  background-color: var(--whiteColor);
  width: 100%;
  padding: 30px;
  box-shadow: 0px 0px 60px 0px #5825710F;
  border-radius: 10px;

}

.sup_proj_setting_form.pxl-seoSetting-wrap {
  max-width: 1290px;
  margin: 0 auto;
}

.sup_proj_setting_form.pxl-ticketStatusBox {
  max-height: 450px;
  overflow: auto;
}
.sup_proj_setting_form.pxl-ticketStatusBox .pxl_settingboxMain {
  /* overflow: auto;
  max-height: 380px; */
}
.sup_proj_setting_form .proj_main_head {
  font-size: 18px;
  font-weight: 600;
  color: var(--sch-title-color);
  padding: 0 0 20px;
  margin: 0;
}

.sup_proj_setting_form .pxl_input_feilds {
  margin: 0;
}

.sup_proj_setting_form.pxl-seoSetting-wrap .proj_Setting_first_flex {
  gap: 25px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  display: grid;
}

.sup_proj_setting_form.pxl-seoSetting-wrap .proj_Setting_first_flex .pxl_input_feilds {
  max-width: 100%;
}

.proj_Setting_first_flex {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.sup_proj_setting_form .pxl_input_feilds {
  max-width: 390px;
}

.sup_proj_setting_form .pxl_input_feilds .pxl-tokenfield {
  background-color: var(--bg-color1);
}

.sup_proj_setting_form .pxl_input_feilds .pxl-tokenfield-input>input {
  background-color: var(--bg-color1);
}

.sup_proj_setting_form .pxl_input_feilds .image_url_wrap {
  width: 100%;
  text-align: center;
}

.sup_proj_setting_form .pxl_input_feilds .image_url_wrap img {
  width: 50px;
}

.sup_proj_setting_form .pxl_input_feilds .img_Cross {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  padding: 0px 6px;
  border-radius: 20px;
  position: absolute;
  top: -10px;
  right: -10px;
}

.sup_proj_setting_form .pxl_input_feilds .img_Cross svg path {
  fill: #fff;
}

.sup_proj_setting_form .pxl_input_feilds label {
  font-size: 14px;
  margin-bottom: 8px;
}

.sup_proj_setting_form .pxl-custom-file-upload .pxl-custom-file-select .pxl-custom-file-select-button {
  background-color: transparent;
}

.sup_proj_setting_form .pxl_input_feilds .pxl-custom-file-upload .pxl-custom-file-select {
  overflow: visible !important;
}

.sup_proj_Color .pu_color_picker_wrapper {
  max-width: 402px;
}

.sup_proj_Color .pu_color_picker_wrapper>.pu_color_picker {
  height: 60px;
  background-color: var(--bg-color1);
}

.sup_proj_Color .pu_color_picker_wrapper>.pu_color_picker_toggle {
  padding: 5px;
  height: 60px;
  background-color: var(--bg-color1);
  border: 1px solid var(--sch-border-color1);
  border-radius: 5px;
}

.sup_proj_Color .pu_color_picker_wrapper>.pu_color_picker_toggle>.pu_color_preview {
  width: 50px;
  height: 50px;
}

.lower_proj_settings {
  display: flex;
  margin-top: 27px;
  gap: 25px;
}

.lower_proj_settings .flex1 {
  flex: 1;
}

.lower_proj_settings .proj_main_head {
  font-size: 16px;
  padding: 0;
}

.lower_proj_settings .pxl-custom-file-upload .pxl-custom-file-select {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 0;
}

.lower_proj_settings .col-flex-side {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.proj_head_Flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.proj_head_Flex .sch-btn {
  gap: 5px;
  height: 40px;
  align-items: center;
  padding: 10px;
}

.pxl-agent-head .agent_wrap {
  padding: 13px;
}

.agent_wrap {
  border: 1px solid var(--borderColor);
  background-color: var(--bg-color1);
  padding: 12px;
  margin-top: 30px;
  border-radius: 6px;
}

.agents_list_wrap {

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  /* gap: 15px; */

}

.agents_list_wrap .single_agent_wrap {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 29px 10px 10px;
}

.agents_list_wrap .single_agent_wrap .img_box {
  width: 70px;
  height: 50px;
  background: antiquewhite;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.agents_list_wrap .single_agent_wrap .img_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
}

.single_agent_wrap .flex_box_img_contet {
  display: flex;
  align-items: center;
  gap: 10px;
}

.agents_list_wrap .single_agent_wrap .agent_detail {
  max-width: 225px;
  width: 100%;
}

.agents_list_wrap .single_agent_wrap .agent_detail h5 {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 600;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.agents_list_wrap .single_agent_wrap .agent_detail p {
  font-size: 14px;
  font-weight: 400;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.agents_list_wrap .single_agent_wrap .agent_del {
  width: 40px;
  height: 40px;
  background-color: #FFE7E7;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sup_proj_setting_form .save_btn {
  margin: 30px 0;
}

/* login page content */
.pxl-login-page-content h4 {
  color: #161930;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 20px !important;
}

/* Project Domain Setting */
.proj_domai_set_form {
  max-width: 513px;
  margin: 0 auto;
  padding: 20px;
}

.proj_domai_set_form .sup_proj_setting_form {
  padding: 25px;
}

.domain_wrapper h4 {
  font-size: 16px;
  font-weight: 600;
}

.domain_wrapper p {
  font-size: 14px;
  margin-top: 9px;
}

.proj_domai_set_form .pxl_input_feilds {
  max-width: 100%;
  margin: 20px 0;
}

.custom_domain_inp_wrap {
  display: flex;
  align-items: center;
  margin: 20px 0;
  border: 1px solid var(--borderColor);
  border-radius: 6px;
}

.custom_domain_inp_wrap .pxl_input_feilds {
  margin: 0;
}

.custom_domain_inp_wrap .pxl_input_feilds>input {
  border: none;
}

.custom_domain_inp_wrap .domain_inp_second_div {
  height: 50px;
  background: #EAF0F6;
  margin-right: 5px;
  border-radius: 6px;
  padding: 12px 14px
}

.custom_connected_wrap .section_heading {
  font-size: 18px;
  font-weight: 400;
}

.custom_connected_wrap .connected_dom_wrap {
  width: 100%;
  border: 1px solid var(--borderColor);
  background: var(--bg-color1);
  padding: 17px 25px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 14px;
  margin: 15px 0;
}

.custom_connected_wrap .section_heading_p {
  margin-top: 10px;
}

.custom_connected_wrap .flex-btn-wrap {
  display: flex;
  gap: 15px;
  margin-top: 30px;
}

.custom_connected_wrap .edit_btn {
  padding: 12px 20px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  color: var(--primaryColor);
  height: 50px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-self: center;
  position: relative;
  overflow: hidden;
  border: 1px solid var(--primaryColor);
  cursor: pointer;
  flex-wrap: wrap;
  /* text-transform: capitalize; */
}

.connected_dom_wrap h3 {
  font-size: 16px;
  font-weight: 600;
}

/* Project Mail Setting  */
.proj_mail_set_form {
  max-width: 1326px;
  margin: 0 auto;
}

.proj_Setting_first_flex h4,
.proj_mail_Set_lower h4 {
  font-size: 16px;
  font-weight: 600;
}

.proj_Setting_first_flex h5,
.proj_mail_Set_lower h5 {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.proj_Setting_first_flex h6,
.proj_mail_Set_lower h6 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.proj_Setting_first_flex p,
.proj_mail_Set_lower p {
  font-size: 14px;
  font-weight: 400;
}

.mail_back_color_box {
  border: 1px solid var(--borderColor);
  background: #EDF5FF;
  padding: 22px 16px;
  border-radius: 6px;
  margin: 10px 0;
  position: relative;
}

.mail_back_color_box .land_Action_edit,
.mail_back_color_box .land_action_del {
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.mail_back_color_box .land_Action_edit {
  top: 7px;
  right: 50px;
  background-color: var(--primaryColor);
}

.mail_back_color_box .land_Action_edit svg path {
  fill: #fff;
}

.mail_back_color_box .land_action_del {
  top: 7px;
  right: 14px;
  background-color: #fb6d6d;
}

.mail_back_color_box .land_action_del svg path {
  fill: #fff;
}

.proj_mail_Set_lower {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
}

.max-402 {
  max-width: 402px;
  width: 100%;
}

.mail_forward_copy {
  background: var(--whiteColor);
  border-radius: 6px;
  display: flex;
  padding: 11px 18px;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  border: 1px solid var(--borderColor);
}

.mail_popup .mail_forward_copy {
  background: var(--bg-color1);
}

.mail_forward_copy svg {
  cursor: pointer;
  width: 18px;
}

.support_checkbox {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 20px
}

.support_checkbox .cun-AllChecknoxSelect {
  width: 25px;
}

.support_checkbox.pxl-use-ssl-checkbox .sch-custom-check-box .form-group input:checked+label:after {
  border-color: var(--sch-title-color);
  top: 3px;
  left: 7px;
}

.sup_proj_setting_form .pxl_input_feilds>textarea {
  background-color: var(--bg-color1);
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  height: 110px;
  resize: none;
  border: 1px solid var(--sch-border-color1);
  position: relative;
  overflow-y: auto;
  color: var(--sch-title-color);
  font-size: 16px;
  font-weight: 400;
}

.sup_proj_setting_form .sch-no-data-inner {
  background: transparent;
  padding: 110px 76px;
}

.sup_proj_setting_form .sch-no-data-inner img {
  margin-bottom: 16px;
}

.btn_center_add {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.model .pxl_input_feilds {
  max-width: 100%;
}

.support_mail_verified_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  border: 1px solid var(--primaryColor);
  background-color: #f5f3f7;
  padding: 16px 24px 16px 30px;
  border-radius: 6px;
}

.support_mail_verified_box h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 600;
  max-width: 380px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* 
.support_mail_verified_box h4 span {
  color: var(--primaryColor);
} */
.support_mail_verified_box>div>h4>span {
  display: block;
  color: var(--primaryColor);
  /* text-transform: capitalize; */
}

.suppprt_mail_verified_box_btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.suppprt_mail_verified_box_btns .xs_btn_verify {
  border-radius: 18px;
  border: 1px solid var(--primaryColor);
  background: #fff;
  color: var(--primaryColor);
  padding: 9px 15px;
}

.actions_btn {
  cursor: pointer;
}

.pxl_mail_setting_mail_box {
  padding: 25px;
  border: 1px solid var(--borderColor);
  border-radius: 6px;
  background: var(--bg-color1);
  margin: 20px 0;
}

.pxl_mail_setting_mail_box.error_box {
  border: 1px solid rgba(250, 60, 60, .5) !important;
  background: rgba(250, 60, 60, .02) !important;
}

.pxl_mail_setting_mail_box.error_box  a {
color: var(--primaryColor);
cursor: pointer;
font-weight: 500;
}
.pxl_email_swipper_align {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 25px;
}

.pxlhost-input input {
  min-height: 50px;
}

/* Install Script section */
.install_script_wrap {
  max-width: 891px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 55px;
  padding: 40px 37px;
}

.install_script_wrap h5 {
  font-size: 14px;
  font-weight: 400;
  max-width: 580px;
  margin: 0 auto 25px !important;
}

.install_script_wrap .sup_script_text {
  background-color: #FAFCFF;
  border: 1px solid var(--borderColor);
  padding: 26px;
  color: #8A9BB1;
  border-radius: 6px;
  word-break: break-word;
}

.install_script_wrap .btn_center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.install_script_wrap .btn_center .sch-btn {
  box-shadow: 0px 20px 50px 0px #6927F24D;
}

.sup_script_text p {
  font-size: 16px;
  font-weight: 400;
  color: #8A9BB1;
}

.pxl-verify-edit {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--sch-primary-color);
  border-radius: 7px;
}

/* mail setting css start */
.pxl-mail-setting-wrap h4 {
  margin: 0 0 30px 0;
}

.pxl-use-ssl p {
  font-size: 12px;
  font-weight: 400;
  color: #899BB1;
  padding: 5px 0 0;
}

.pxl-use-ssl-checkbox .sch-custom-check-box .form-group label:before {
  border-color: #C7D8F8;
  padding: 8px;
  border-radius: 3px;
}

.mail_forward_copy p {
  font-size: 16px;
  font-weight: 400;
  color: #899BB1;
}

.pxl-use-ssl-checkbox .sch-custom-check-box input[type=checkbox]:checked+label:before {
  background: transparent;
  border-color: var(--sch-title-color);
}

/* .sch-custom-check-box .form-group input:checked+label:after {
  border-color: var(--sch-title-color);
  top: 4px;
  right: 3px;
} */

/* Widget appearance setting */
.widget_app_wrap {
  max-width: 1327px;
  width: 100%;
}

.widget_login_Sec h4 {
  font-size: 16px;
  font-weight: 600;
}

.support_widget_type_wrap {
  display: flex;
  gap: 20px;
}

.support_widget_type_wrap .widget_type_btn {
  border: 1px solid var(--input-border);
  border-radius: 6px;
  background: var(--bg-color1);
  display: flex;
  align-items: center;
  height: 80px;
  cursor: pointer;
  padding: 0px 20px 0px 20px;
}

/* .widget_type_btn .widget_bubble {
  background: #cecece;
  width: 40px;
  height: 40px;
  border-radius: 15px;
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
} */
/* .widget_type_btn .widget_bubble:before{
    content: "";
    position: absolute;
    top: 60%;
    right: 75%;
    border-top: 20px solid transparent;
    border-bottom: 0 solid transparent;
    border-right: 20px solid #cecece;
    transform: rotate(-25deg);
    border-right-color: #cecece;
 } */
.widget_type_btn.active {
  border-color: var(--primaryColor);
}

/* .widget_type_btn.active .widget_bubble{
    background: var(--gradientColor);
 } */
.widget_type_btn.active .widget_bubble:before {
  border-right-color: var(--primaryColor);
}

.widget_type_btn .widget_btn {
  /* text-transform: capitalize; */
  width: 190px;
  height: 50px;
  border-radius: 15px 15px 0 0;
  position: relative;
  /* margin-bottom: 30px; */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  gap: 14px;
  background-color: #cecece
}

.widget_type_btn.active .widget_btn {
  background: var(--gradientColor);
}

.widget_login_Sec {
  margin-top: 20px;
}

.widget_section_wrap {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}

/* Landing Page setting  */
.support_single_feature_wrap {
  background: var(--whiteColor);
  padding: 20px 10px;
  border-radius: 6px;
  position: relative;
}

.support_single_feature_wrap .img_box {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.support_single_feature_wrap .img_box img {
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
  height: 100%;
}

.support_single_feature_wrap .feat_action_del {
  position: absolute;
  top: 0;
  right: 3px;
  cursor: pointer;
}

.support_single_feature_wrap .feat_Action_edit {
  position: absolute;
  top: 0;
  right: 30px;
  cursor: pointer;
}

.support_single_feature_wrap .feat_detail {
  width: 100%;
  /* word-break: break-word; */
  max-width: 290px;
}

.support_single_feature_wrap .feat_detail h5 {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 600;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.support_single_feature_wrap .feat_detail p {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 400;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.support_table .tab_name_wrap .pxl-customerName p {
  /* text-transform: capitalize; */
}

/* PixaSupport setting csss start */
.pixa_box_single_wrap {
  display: flex;
  justify-content: space-between;
  background-color: gainsboro;
}

.pixa_setting_Action_Wrap {
  display: flex;
  gap: 10px;
}

.pxl-setting-button-wrap {
  display: flex;
  gap: 10px;
}

.pxl-setting-button-wrap.pxl-addCancelBtn button.sch-btn-iconLeft {
  width: 120px;
  justify-content: flex-start;
}

/* PixaSupport setting csss end */
/*=========================== Support CSS End =========================== */

/* *===========================Team Member section start *=========================== */
.pxl_scope_wrapper {
  margin-bottom: 15px;
}

.pxl_scope_wrapper .pxl_fields {
  display: flex;
  align-items: center;
  gap: 28px;
  padding-left: 16px;
  margin-bottom: 5px;
}

.pxl_scope_wrapper h3 {
  font-size: 16px;
  margin-bottom: 6px;
}

.pxl_scope_wrapper .form-group {
  margin-bottom: 0;
}

.pxl_scope_wrapper .check_box_wrap {
  display: flex;
  gap: 16px;
  align-items: center;
}

.pxl_scope_wrapper .cun-AllChecknoxSelect {
  width: 25px;
}

.pxl_scope_wrapper .form-group label {
  margin-bottom: 0 !important;
  color: unset !important;
}

.pxl_scope_wrapper .pxl_fields p {
  font-size: 15px;
  font-weight: 500;
  /* text-transform: capitalize; */
  max-width: 115px;
  width: 100%
}

.scope .css-13cymwt-control {
  min-height: 50px !important;
  border-color: #e5dffe;
}

.css-13cymwt-control:hover {
  border-color: #e5dffe !important;
}

.scope .css-t3ipsp-control {
  min-height: 48px !important;
  border-color: #e5dffe !important;
}

/* *===========================Team Member section end  *=========================== */

/**=========================== Brand Section Css Start  *===========================*/
.create_brand .pxl-custom-file-upload .pxl-custom-file-select .pxl-custom-file-select-button {
  background-color: transparent;
}

.create_brand .image_url_wrap {
  text-align: center;
}

.create_brand .img_Cross {
  position: absolute;
  top: -7px;
  right: -4px;
  background: var(--primaryColor);
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create_brand .pxl-custom-file-upload .pxl-custom-file-select {
  overflow: visible;
}

.create_brand .img_Cross svg path {
  fill: #fff;
}

.create_brand .brand_note {
  font-size: 16px;
  margin-bottom: 15px;
  color: #FF5B5B;
  /* text-transform: capitalize; */
}

.brand_wrapper .sch-post-image-item {
  padding: 10px;
  background: #fff;
  border-radius: 6px;
}

.brand_wrapper .sch-post-image-item .pxl-ImgBox img {
  border-radius: 6px;
}

.pxl-brand-record-head>h5 {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.support_tab_inner ul li.active a {
  color: #8417af;
}

/**=========================== Brand Section Css End *=========================== */

/*/**=========================== NineDot header css Start /**===========================*/
.header_nine_dot_wrapper {
  position: relative;
}

.header_nine_dot_wrapper a {
  display: block;
}

.header_nine_dot_wrapper .menu_dot {
  cursor: pointer;

}

.header_nine_dot_wrapper .menu_dot a {
  display: block;
}

.header_nine_dot_wrapper .app_dropdown_wrapper {
  /* background: #EDF5FF; */
  position: absolute;
  top: 60px;
  border-radius: 15px;
  min-width: 275px;
  right: 0px;
  /* padding: 5px; */
  transform: translateY(50px);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  box-shadow: 0px 0px 60px 0px #58257129;
  transition: var(--sch-transition);
}

.header_nine_dot_wrapper .app_dropdown_wrapper.show {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.sch-draft-post-wrpper.pxl-draftSingle-pageWrapper {
  padding: 0px 0px 80px;
}

.app_drop_grid {
  background-color: var(--whiteColor);
  border-radius: 10px;
  padding: 10px 10px 5px;
  /* max-height: 330px; */
  height: 100%;
  overflow: auto;
  border: 1px solid #E9EDF6;
}

/* .app_drop_grid ul{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
} */
.app_drop_grid>ul>li {
  margin: 0 0 5px 0;
}

.app_drop_grid .li_first_div {
  cursor: pointer;
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px 8px 8px;
  border-radius: 5px;
}

.pxl-headerbox1 {
  display: flex;
  align-items: center;
  width: fit-content;

}

.app_drop_grid .li_first_div .app_label {
  display: block;
  max-width: 100px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: var(--transition);
  font-size: 14px;
  font-weight: 500;
  padding: 0 0 0 10px;
}

.app_drop_grid .app_icon_wrap {
  border-radius: 6px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}

.app_drop_grid .app_icon_wrap svg {
  width: 25px;
  height: 25px;
}

.app_color0 .pxl-headerBox1-arrow svg {
  fill: #ff6464;
}

.app_color1 .pxl-headerBox1-arrow svg {
  fill: #FFA91B;
}

.app_color2 .pxl-headerBox1-arrow svg {
  fill: #B75CFF;
}

.app_color3 .pxl-headerBox1-arrow svg {
  fill: #48B2FF;
}

.app_color0 {
  color: #ff6464;
  background-color: #ffc8c8;
}

.app_color1 {
  color: #FFA91B;
  background-color: #FFECD0;
}

.app_color2 {
  color: #B75CFF;
  background-color: #EBD1FF;
}

.app_color3 {
  color: #48B2FF;
  background-color: #D0EBFF;
}

.app_color5 {
  color: #fa5ee5;
  background-color: #f7cbf1;
}

.app_color5 .app_icon_wrap {
  background: #fa5ee5;
}

.app_color5 .pxl-headerBox1-arrow svg {
  fill: #fa5ee5;
}

.app_color5 .app_icon_wrap svg {
  fill: #ffffff;
  width: 20px;
  height: 20px;
}

.app_color0 .app_icon_wrap {
  background: #ff6464;
}

.app_color1 .app_icon_wrap {
  background: #FFA91B;
}

.app_color2 .app_icon_wrap {
  background: #B75CFF;
}

.app_color3 .app_icon_wrap {
  background: #48B2FF;
}

.app_drop_grid>ul>li .pxl-app-active {
  border: 2px solid;
}

/*/**=========================== NineDot header css end /**===========================*/

/***========================== video Preview Css Start **========================== */
.youtube_wrap_lower {
  margin: 10px;
}

.youtube_wrap_lower .sch-model-user-name-area {
  margin-bottom: 4px;
}

.youtube_tab_wrapper {
  /* display: flex; */
}

.youtube_tab_wrapper .sch-social-img-content {
  background-color: var(--sch-color2);
  min-height: 200px;
}

.youtube_tab_wrapper .sch-model-social-body {
  padding: 0;
}

.youtube_wrap_lower .sch-model-body-head {
  display: block;
}

.youtube_tab_wrapper ul.sch-social-review-sec {
  width: 40px;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0;
  gap: 15px;
}

.youtube_tab_wrapper ul.sch-social-review-sec li a {
  flex-direction: column;
  width: 40px;
}

.youtube_tab_wrapper ul.sch-social-review-sec li a .social_yt_icon {
  width: 35px;
  height: 35px;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
}

.youtube_tab_wrapper ul.sch-social-review-sec li a .social_yt_icon svg {
  transform: rotate(90deg);
}

/***========================== video preview css end **==========================*/
/* ================== dashboard app section css start ======================*/
/* .pxl-dashboard-appWrap {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
} */
.pxl-dashboard-appWrap {
  gap: 20px;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  display: grid;
}

.pxl-dashboard-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 15px 15px;
  border-radius: 5px;
  cursor: pointer;
  width: 280px;
  transition: all 0.5s;
}

.pxl-dashboard-appbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 15px 15px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.5s;
}

.pxl-dashboard-app-color-box0:hover {
  border-color: #ff6464;
}

.pxl-dashboard-app-color-box1:hover {
  border-color: #FFA91B;
}

.pxl-dashboard-app-color-box2:hover {
  border-color: #B75CFF;
}

.pxl-dashboard-app-color-box3:hover {
  border-color: #48B2FF;
}

.pxl-apps-heading h2 {
  padding: 0 0 20px;
}

.pxl-dashboard-box-left {
  display: flex;
  align-items: center;
}

.pxl-dashboard-app-img {
  width: 68px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.pxl-dashboard-app-content>p {
  font-size: 20px;
  font-weight: 500;
  padding: 0 0 0 13px;
}

.pxl-dashboard-app-color-box0 {
  background-color: #ffc8c8;
}

.pxl-dashboard-app-color-box0 .pxl-dashboard-app-img {
  background-color: #ff6464;
}

.pxl-dashboard-app-color-box0 .pxl-dashboard-app-content p {
  color: #ff6464;
  text-transform: capitalize;
}

.pxl-dashboard-app-color-box0 .pxl-dashboard-box-right svg {
  fill: #ff6464;
}

.pxl-dashboard-app-color-box1 {
  background-color: #FFECD0;
}

.pxl-dashboard-app-color-box1 .pxl-dashboard-app-img {
  background-color: #FFA91B;
}

.pxl-dashboard-app-color-box1 .pxl-dashboard-app-content p {
  color: #FFA91B;
}

.pxl-dashboard-app-color-box1 .pxl-dashboard-box-right svg {
  fill: #FFA91B;
}

.pxl-dashboard-app-color-box2 {
  background-color: #EBD1FF;
}

.pxl-dashboard-app-color-box2 .pxl-dashboard-app-img {
  background-color: #B75CFF;
}

.pxl-dashboard-app-color-box2 .pxl-dashboard-app-content p {
  color: #B75CFF;
}

.pxl-dashboard-app-color-box2 .pxl-dashboard-box-right svg {
  fill: #B75CFF;
}

.pxl-dashboard-app-color-box3 {
  background-color: #D0EBFF;
}

.pxl-dashboard-app-color-box3 .pxl-dashboard-app-img {
  background-color: #48B2FF;
}

.pxl-dashboard-app-color-box3 .pxl-dashboard-app-content p {
  color: #48B2FF;
}

.pxl-dashboard-app-color-box3 .pxl-dashboard-box-right svg {
  fill: #48B2FF;
}

.pxl-dashboard-box:hover {
  transform: translateX(-5px);
}

/* / Coming Soon css / */
.UpComing {
  margin-top: 30px;
  pointer-events: none;
}

/* / Coming Soon css / */

/* ================== dashboard app section css end ======================*/
/*==================== setting ticket status css start ==================== */

.pxl-ticket-status-wrap {
  padding: 15px 15px 15px 27px;
  border-radius: 8px;
  position: relative;
  border: 1px solid;
  margin: 0 0 15px;
}

.pxl-ticket-status-wrap:after {
  content: '';
  position: absolute;
  left: 0px;
  top: 4px;
  height: 50px;
  width: 6px;
  border-radius: 0px 10px 10px 0;
}

.pxl-ticket-status-name h2 {
  text-transform: capitalize;
  max-width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pxl-ticket-status-icons ul li {
  padding: 0px 5px;
}

/* .pxl-ticket-status-icons ul li button {
  border-radius: 8px;
  width: 30px;
  height: 30px;
} */
.pxl-ticket-status-icons>ul>li>button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.pxl-ticket-status-icons>ul>li:nth-child(2)>button {
  /* box-shadow: -4px 4px 4px 0px #FF627E33; */
}

/* color1 */
.pxl-ticket-status-color1 ul.pxl-flex-divide li:nth-child(2) button {
  background-color: #3DB9FF;
  /* box-shadow: -4px 4px 4px 0px #3DB9FF4D; */
}

.pxl-ticket-status-color1 .pxl-ticket-status-icons ul li button svg,
.pxl-ticket-status-color1 .pxl-ticket-status-icons ul li a svg {
  fill: #3DB9FF;
}

.pxl-ticket-status-color1 {
  background-color: #E5F6FF;
  border-color: #A6DFFF;
}

.pxl-ticket-status-color1:after {
  background-color: #3DB9FF;
}

/* .pxl-ticket-status-color1 .pxl-ticket-status-icons.pxl_ticket_Set_type_prior ul li:nth-child(1) button{
  background-color: #3DB9FF;
} */
.pxl_ticket_Set_type_prior>ul>li {
  padding: 0px 5px;
}

.pxl-ticket-status-color1 .pxl_ticket_Set_type_prior ul li:nth-child(1) button {
  background-color: #3DB9FF;
}

.pxl-ticket-status-color1 .pxl_ticket_Set_type_prior ul li a svg {
  fill: #3DB9FF;
}

.pxl-ticket-status-color2 .pxl_ticket_Set_type_prior ul li:nth-child(1) button {
  background-color: #FF9F3D;
}

.pxl-ticket-status-color2 .pxl_ticket_Set_type_prior ul li a svg {
  fill: #FF9F3D;
}

.pxl-ticket-status-color3 .pxl_ticket_Set_type_prior ul li:nth-child(1) button {
  background-color: #D354FF;
}

.pxl-ticket-status-color3 .pxl_ticket_Set_type_prior ul li a svg {
  fill: #D354FF;
}

.pxl-ticket-status-color4 .pxl_ticket_Set_type_prior ul li:nth-child(1) button {
  background-color: #66C074;
}

.pxl-ticket-status-color4 .pxl_ticket_Set_type_prior ul li a svg {
  fill: #66C074;
}

.pxl-ticket-status-color5 .pxl_ticket_Set_type_prior ul li:nth-child(1) button {
  background-color: #7660FF;
}

.pxl-ticket-status-color5 .pxl_ticket_Set_type_prior ul li a svg {
  fill: #7660FF;
}

.pxl_ticket_Set_type_prior>ul>li>button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

/* color2 */
.pxl-ticket-status-color2 ul.pxl-flex-divide li:nth-child(2) button {
  background-color: #FF9F3D;
  box-shadow: -4px 4px 4px 0px #FF9F3D4D;
}

.pxl-ticket-status-color2 .pxl-ticket-status-icons ul li button svg,
.pxl-ticket-status-color2 .pxl-ticket-status-icons ul li a svg {
  fill: #FF9F3D;
}

.pxl-ticket-status-color2 {
  background-color: #FFF9F3;
  border-color: #FFD9B2;
}

.pxl-ticket-status-color2:after {
  background-color: #FF9F3D;
}

.pxl-ticket-status-color2 .pxl-ticket-status-icons ul li:nth-child(1) button {
  background-color: #FF9F3D;
}

/* color3 */
.pxl-ticket-status-color3 ul.pxl-flex-divide li:nth-child(2) button {
  background-color: #D354FF;
  box-shadow: -4px 4px 4px 0px #D254FF4D;
}

.pxl-ticket-status-color3 .pxl-ticket-status-icons ul li button svg,
.pxl-ticket-status-color3 .pxl-ticket-status-icons ul li a svg {
  fill: #D354FF;
}

.pxl-ticket-status-color3 {
  background-color: #FBF0FF;
  border-color: #E8A9FF;
}

.pxl-ticket-status-color3:after {
  background-color: #D354FF;
}

.pxl-ticket-status-color3 .pxl-ticket-status-icons ul li:nth-child(1) button {
  background-color: #D354FF;
}

/* color4 */
.pxl-ticket-status-color4 ul.pxl-flex-divide li:nth-child(2) button {
  background-color: #66C074;
  box-shadow: -4px 4px 4px 0px #66C0744D;
}

.pxl-ticket-status-color4 .pxl-ticket-status-icons ul li button svg,
.pxl-ticket-status-color4 .pxl-ticket-status-icons ul li a svg {
  fill: #66C074;
}

.pxl-ticket-status-color4 {
  background-color: #E5FEE8;
  border-color: #9AD8A3;
}

.pxl-ticket-status-color4:after {
  background-color: #66C074;
}

.pxl-ticket-status-color4 .pxl-ticket-status-icons ul li:nth-child(1) button {
  background-color: #66C074;
}

/* color5 */
.pxl-ticket-status-color5 ul.pxl-flex-divide li:nth-child(2) button {
  background-color: #7660FF;
  box-shadow: -4px 4px 4px 0px #7660FF4D;
}

.pxl-ticket-status-color5 .pxl-ticket-status-icons ul li button svg,
.pxl-ticket-status-color5 .pxl-ticket-status-icons ul li a svg {
  fill: #7660FF;
}

.pxl-ticket-status-color5 {
  background-color: #F0EEFF;
  border-color: #B9AEFF;
}

.pxl-ticket-status-color5:after {
  background-color: #7660FF;
}

.pxl-ticket-status-color5 .pxl-ticket-status-icons ul li:nth-child(1) button {
  background-color: #7660FF;
}

/* setting tooltip */


/* setting popup */
.pxl-add-status-heading>h3 {
  padding: 20px 0 0 0;
}

.pxl_input_feilds.pxl-setting-color-picker .pu_color_picker_wrapper {
  max-width: 100%;
}

.pxl_input_feilds.pxl-setting-color-picker .pu_color_picker_wrapper .pu_color_picker_toggle {
  border-radius: 5px;
  background-color: var(--bg-color1);
}

.pxl-setting-color-picker .pu_color_picker_wrapper .pu_color_picker_toggle {
  padding: 10px 10px 10px 5px;
}


.pxl_ticket_Set_type_prior li:nth-child(1).cun-socialIcon.pxl-tooltip-button-form.pxl-deleteToltip .cun-show-tooltip {
  /* transform: translate(0px, 0px); */
}
.pxl_ticket_Set_type_prior li.cun-socialIcon .cun-show-tooltip {
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.pxl_ticket_Set_type_prior li:nth-child(2).cun-socialIcon.pxl-tooltip-button-form.pxl-deleteToltip .cun-show-tooltip {
  transform: translate(-5px, 0px);
}

.pxl_ticket_Set_type_prior li:nth-child(3).cun-socialIcon.pxl-tooltip-button-form.pxl-deleteToltip .cun-show-tooltip {
  transform: translate(-10px, 0px);
}

/*==================== setting ticket status css end ==================== */
/* ========== custom toggle on off btn ===============*/
.pxl-custom-toggle-btn {
  display: flex;
  align-items: center;
}

.pxl-custom-toggle-btn .switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
}

.pxl-custom-toggle-btn .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.pxl-custom-toggle-btn .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50px;
}

.pxl-custom-toggle-btn .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50px;
}

.pxl-custom-toggle-btn input:checked+.slider {
  background-color: #91ED48;
}

.pxl-custom-toggle-btn input:focus+.slider {
  box-shadow: 0 0 1px #91ED48;
}

.pxl-custom-toggle-btn input:checked+.slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* ===== Project widget appearance css start ======*/
.pxl-widget-wrap>h4 {
  padding: 0 0 30px 0;
}

.pxl-widget-wrap .widget_login_Sec h4 {
  padding: 0 0 20px 0;
}

.widget_login_Sec.pxl-widget-head2 h4 {
  padding: 0;
}

.widget_login_Sec.pxl-widget-head3 h4 {
  padding: 0 0 4px;
}

.widget_login_Sec.pxl-widget-head4 h4 {
  padding: 0 0 5px;
}

.pxl-widget-type .pxl_input_feilds label {
  font-size: 14px;
  font-weight: 500;
}

.pxl-widget-buble-text h2 {
  margin: 0;
  /* text-transform: capitalize; */
  font-size: 16px;
  font-weight: 400;
  padding: 0px 0px 0px 15px;
}

.pxl-widget-position h4 {
  position: relative;
  top: -59px;
}

.pxl-widget-position .cun-custom-radioBtn {
  margin: 0px 30px 10px 0;
}

/* widget preview css start */

/* ===== Project widget appearance css end ======*/
/* ====== brand page css start ========*/
.pxl-brand-header-bottom {
  padding: 0 0 25px;
}

.pxl-brandMain {
  /* display: flex; */
  align-items: center;
  flex-wrap: wrap;
  gap: 35px 15px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  display: grid;
}

.pxl-vrandBox {
  /* width: 270px; */
  width: 100%;
  background-color: #ffffff;
  height: 141px;
  position: relative;
  border: 1px solid #E9EDF6;
  padding: 0 20px 14px 20px;
  border-radius: 10px;
  transition: all 0.5s;
}

.pxl-vrandBox:hover {
  transform: translateY(3px);
}

.pxl-vrandBox:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: 230px;
  background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  height: 8px;
  margin: 0 auto;
  border-radius: 0px 0px 8px 8px;
  bottom: -8px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

.pxl-vrandBox:hover:after {
  opacity: 1;
  visibility: visible;
}

.pxl-brand-profile-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pxl-brand-profile-wrap:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #F3EBFA;
  left: 0;
  right: 0;
}

.pxl-brandImg {
  position: relative;
  top: -15px;
}

.pxl-brandImg img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  object-position: center top;
  border-radius: 50px;
  border: 1px solid #E8E4EE;
  background-color: #ddd;
}

.pxl-brand-profile {
  display: flex;
  align-items: center;
}

.pxl-brandName>h2 {
  /* text-transform: capitalize; */
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #181719;
  padding: 0 0 0 10px;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pxl-brand-menu {
  cursor: pointer;
  width: 10px;
  text-align: center;
}

.pxl-brand-bottom-sec>ul {
  display: flex;
  align-items: center;
  padding: 10px 0px 0;
}

.pxl-brand-bottom-sec>ul>li {
  padding: 0 15px 0px 0;
}

/* tooltip */
.pxl-brand-bottom-sec li.cun-socialIcon .cun-show-tooltip {
  width: max-content;
  transform: translate(-13px, 0px);
}

.pxl-brand-bottom-sec ul li:nth-child(2).cun-socialIcon .cun-show-tooltip {
  transform: translate(-5px, 0px);
}

.pxl-brand-bottom-sec ul li:nth-child(3).cun-socialIcon .cun-show-tooltip {
  transform: translate(-20px, 0px);
}

.pxl-brand-bottom-sec ul li:nth-child(4).cun-socialIcon .cun-show-tooltip {
  transform: translate(-21px, 0px);
}

/* .pxl-brand-bottom-sec li.cun-socialIcon .cun-show-tooltip:before {
  top: -10px;
  transform: rotate(180deg);
} */

/* =========== banner images position css start ============*/
img.sch-banner-pos1 {
  position: absolute;
  left: 25px;
  top: 50px;
  border-radius: 5px;
  width: 104px;
  animation: 5.4s floating linear infinite;
}

img.sch-banner-pos2 {
  position: absolute;
  left: 140px;
  bottom: 33px;
  border-radius: 5px;
  animation: 7.4s floating linear infinite;
}

img.sch-banner-pos3 {
  position: absolute;
  left: 180px;
  border-radius: 5px;
  width: 82px;
  bottom: 130px;
  animation: 9.4s floating linear infinite;
}

img.sch-banner-pos4 {
  position: absolute;
  left: 240px;
  border-radius: 5px;
  width: 80px;
  bottom: 65px;
  animation: 5.4s floating linear infinite;
}

img.sch-banner-pos5 {
  position: absolute;
  left: 385px;
  border-radius: 5px;
  bottom: 43px;
  width: 96px;
  animation: 6.4s floating linear infinite;
}

img.sch-banner-pos6 {
  position: absolute;
  right: 354px;
  top: 41px;
  border-radius: 5px;
  width: 120px;
  animation: 5.4s floating linear infinite;
}

img.sch-banner-pos7 {
  position: absolute;
  right: 260px;
  border-radius: 5px;
  bottom: 42px;
  width: 118px;
  animation: 7.4s floating linear infinite;
}

img.sch-banner-pos8 {
  position: absolute;
  right: 110px;
  bottom: 40px;
  border-radius: 5px;
  width: 89px;
  animation: 9.4s floating linear infinite;
}

img.sch-banner-pos9 {
  position: absolute;
  right: 35px;
  top: 30px;
  border-radius: 5px;
  width: 57px;
  animation: 5.4s floating linear infinite;
}

@keyframes floating {
  from {
    -webkit-transform: rotate(0deg) translate(-5px) rotate(0deg);
    -moz-transform: rotate(0deg) translate(-5px) rotate(0deg);
    -ms-transform: rotate(0deg) translate(-5px) rotate(0deg);
    -o-transform: rotate(0deg) translate(-5px) rotate(0deg);
    transform: rotate(0deg) translate(-5px) rotate(0deg);
    transition: 1s ease-in-out;
  }

  to {
    -webkit-transform: rotate(360deg) translate(-5px) rotate(-360deg);
    -moz-transform: rotate(360deg) translate(-5px) rotate(-360deg);
    -ms-transform: rotate(360deg) translate(-5px) rotate(-360deg);
    -o-transform: rotate(360deg) translate(-5px) rotate(-360deg);
    transform: rotate(360deg) translate(-5px) rotate(-360deg);
    transition: 1s ease-in-out;
  }
}


/* integration social icon tooltip css */
.cun-socialIcon button.sch-btn-disbale {
  box-shadow: unset;
}

.cun-socialIcon.pxl-tooltip-button-form .cun-show-tooltip {
  transform: translate(-18px, 0px);
}

.cun-socialIcon.pxl-tooltip-button-form.cun-show-tooltip {
  width: max-content;
  transform: translate(-54px, 0px);
}

/* .cun-socialIcon.pxl-tooltip-button-form.pxl-deleteToltip .cun-show-tooltip {
  transform: translate(2px, 0px);
} */

.cun-socialIcon.pxl-tooltip-button-form.pxl-tooltip-btn-diable .cun-show-tooltip {
  width: 70px;
  top: -75px;
  padding: 0px;
}
.cun-socialIcon.pxl-tooltip-button-form.pxl-reload-tooltip.pxl-tooltip-btn-diable .cun-show-tooltip {
  transform: translate(-65px, 0px);
}
.pxl_ticket_Set_type_prior .DragTooltip .cun-show-tooltip.pxldrag-disable {
  transform: translate(-10px, 0px);
}
.pxl-ticket-status-tooltip.cun-socialIcon .cun-show-tooltip.cundisabletooltip {
  transform: translate(-10px, 0px);
}
.cun-socialIcon.pxlDrag .cun-show-tooltip {
  transform: translate(-21px, 0px);
}
.pxl_ticket_Set_type_prior li.pxl-ticketType-drag.cun-socialIcon.pxl-tooltip-button-form.pxl-deleteToltip .cun-show-tooltip {
  transform: translate(-10px, 0px);
}
/* setting ticket type tooltip css */
.pxl-ticket-status-tooltip.cun-socialIcon .cun-show-tooltip {
  transform: translate(0, 0px);
  left: 0;
  right: auto;
}

/*  */
/* domain popup design css */
.pxl-domain-instructions {
  padding: 20px 0 0 0;
}

.pxl-domain-instructions>ul>li {
  position: relative;
  padding: 0 0 10px 30px;
  font-size: 16px;
}

.pxl-domain-instructions>ul>li>span {
  position: absolute;
  left: 0;
  top: 0;
}

.cun-headreSelect.pxl-supportModel {
  padding: 0;
}

.cun-headreSelect.pxl-supportModel .save_btn {
  margin: 30px 0 0;
}

.pxl-supportProject-wrap {
  text-align: left;
}

.pxl-select-projectLabel label {
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--sch-title-color);
}

.support_mail_verified_box>div>h4 {
  margin: 0;
}

.suppprt_mail_verified_box_btns button.sch-btn_verify {
  border: none;
  background-color: var(--sch-primary-color);
  padding: 14px 20px;
  border-radius: 5px;
  color: #ffffff;
}

.suppprt_mail_verified_box_btns button.sch-btn_verify svg {
  margin: 0 5px 0 0;
}








/* .pxl-ticket-status-icons ul.pxl-flex-divide li:nth-child(2).cun-socialIcon.long-tooltip-data .cun-show-tooltip {
  transform: translate(-62px, 0px);
}
.pxl-ticket-status-icons ul.pxl-flex-divide li:nth-child(3).cun-socialIcon.long-tooltip-data .cun-show-tooltip{
  transform: translate(-62px, 0px);
} */
.pxl_ticket_Set_type_prior li:nth-child(1).cun-socialIcon.long-tooltip-data .cun-show-tooltip {
  transform: translate(-61px, 0px);
}

/* seo setting css */
.pxl-seo-uploadFile .pxl-custom-file-select {
  padding: 5px 0px !important;
  height: 50px;
}

.pxl-seo-uploadFile .sup_proj_setting_form .pxl_input_feilds .image_url_wrap img {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}

.pxl-seo-uploadFile .pxl-custom-file-select .pxl-custom-file-select-button img {
  width: 40px;
  height: 40px;
  background-size: cover;
  border-radius: 50px;
  background-position: center;
  position: relative;
  top: -3px;
}

.pxl-custom-file-upload.pxl-upload-file-profile.pxl-seo-uploadFile .pxl-custom-file-select .image_url_wrap img {
  width: 38px;
  height: 38px;
}

.pxl-seo-uploadFile .pxl-custom-file-select .pxl-custom-file-select-name {
  padding: 0 0 0 !important;
  position: relative;
  top: -3px !important;
}

.pxl-seo-uploadFile .image_url_wrap img {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}

.pxl-doaminAdd-input label {
  font-size: 14px;
  margin-bottom: 8px;
  color: var(--sch-title-color);
  font-weight: 500;
  margin: 0 0 8px 0;
}

.pxl-doaminAdd-input .pxl_input_feilds {
  max-width: 100%;
  position: relative;
}

.pxl-doaminAdd-input .pxl_input_feilds input {
  padding: 10px 140px 10px 20px;
}

.pxl-doaminAdd-input .pxl-addDomain-btn {
  position: absolute;
  top: 0px;
  right: 0px;
}

.pxl-doaminAdd-input .pxl-addDomain-btn button.sch-btn {
  height: 50px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.pxl-builder-projectWrapper .dash_head {
  padding-bottom: 0;
}

.pxl-builder-projectWrapper.pxlBuilderMain-wrapper .dash_head.dash_head_topNone {
  padding: 0 0 20px 0;
}

.pxl-projectMainWrapper .dash_head {
  padding: 25px 0 0px;
}

/* trash builder css */
.pxl-trash-builder-data .xs_projects_box {}

/* readonly access tooltip css css */
.cun-iconTooltip.pxl-editReadOnly button .cun-show-tooltip {
  top: -35px;
  right: -20px;
  left: auto;
}

.cun-iconTooltip.pxl-editReadOnly button .cun-show-tooltip:before {
  margin-left: 24px;
}

.pxl-replyBtn.pxl-replyBtn-disable-tooltip .cun-socialIcon .cun-show-tooltip {
  left: 0;
  right: auto;
}

.sch-scheduleSecFullPage .sch-schedulePost-heading h2 {
  max-width: 300px;
}

.pxl-uploadFile-height .pxl-custom-file-select {
  padding: 0px !important;
}

.pxl-uploadFile-height .pxl-custom-file-select .pxl-custom-file-select-button img {
  width: 40px;
  height: 40px;
}

.pxl-uploadFile-height .pxl-custom-file-select .pxl-custom-file-select-button {
  line-height: 48px !important;
}

.pxl-uploadFile-height .pxl-custom-file-select .image_url_wrap {
  padding: 5px 0px;
}

.pxl-uploadFile-height .pxl-custom-file-select .image_url_wrap img {
  border-radius: 50px;
  height: 40px!important;
  width: 40px!important;
  object-fit: cover;
  object-position: center top;
}

.pxl-trash-builder-data.tab-pane .sch-container-fluid {
  padding: 0px 0px 20px;
}

.pxl-trash-ticket-data .pxl_mainWrapper .sch-container-fluid {
  padding: 0px 0px 20px;
}

.pxl-trash-builder-data .sch-container-fluid {
  padding: 0;
}

.pxl_social_acc_users.pxl_socialAccountWrap .cun-socialIcon.pxl-tooltip-button-form.pxl-deleteToltip .cun-show-tooltip {
  transform: unset;
  left: -60px;
}

.pxl-clientTooltip-edit .cun-show-tooltip {
  left: -6px;
  top: -35px;
}

.pxl-table-editTooltip.pxl-editReadOnly .cun-iconTooltip.sch-btn-disbale .cun-show-tooltip {
  left: unset;
  right: unset;
  min-width: 100%;
  padding: 2px 4px;
  margin: 0px 40px;
  display: block;
}

.pxl_scope_wrapper.pxlAddnew-member-field .pxl_fields {
  padding: 0;
}

.sch-tooltipWrap.pxlRoted-svg>a.moveIcon>img {
  transform: rotate(60deg);
}

.pxl_scope_wrapper.pxlAddnew-member-field {
  background-color: #FDFBFF;
  padding: 10px 10px 0;
  border-radius: 3px;
  border: 0.83px solid #F3E7FF;
}

.pxl-schduler-newPost-wrapper .sch-postInnerSec .sch-account-accordian .sch-accordian-body .accordion-body {
  padding: 0px 10px 20px;
}

.pxl_autoresponder_container .pxl_autoresponder_content h4.pxl_from_title {
  padding: 0 0 15px 0;
}

/* pixaSupport mail setting css start */
.pxl-outgoingMail-setting-box {
  max-width: 402px;
  width: 100%;
}

.pxl-username-email-accountDetails {
  max-width: 402px;
  width: 100%;
}

.pxl-outgoingMail-setting-box .mail_back_color_box p {
  padding: 10px 0px 20px;
}

.pxl-mailsetting-wrap .pxl_input_feilds.pxl-placeholder-color1.pxlhost-input.bottomSpace {
  margin: 0 0 15px 0;
}

.support_checkbox.pxl-use-ssl-checkbox {
  gap: 5px;
}

.support_checkbox.pxl-use-ssl-checkbox>h6 {
  position: relative;
  top: -2px;
}

.pxl-username-email-accountDetails .pxl_input_feilds.pxl-placeholder-color1.bottomSpace {
  margin: 0 0 15px;
}

.pxl-mailSetting-mainWrapper-box {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  margin: 30px 0px 0px 0px;
}

.pxl-mail-setting-saveBtn {
  margin: 20px 0 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.cun-mailSetting-radiobox-wrapper-box {
  display: flex;
  gap: 10px;
  position: relative;
  top: -5px;
}

.pxl-malserver-forwarder {
  max-width: 402px;
  width: 100%;
}

.pxl-malserver-forwarder>h5 {
  font-size: 16px;
  font-weight: 500;
  padding: 0 0 3px;
}

.pxl-projectMainWrapper .dash_head h3.xs_page_title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 220px;
}

.pxl-pixaSupport-mainWrapper .pxl-projectMainWrapper .dash_head {
  padding: 25px 0px;
}

.pxl-tooltip-button-form.pxl-reload-tooltip button.cun-socialIcon .cun-show-tooltip {
  left: -15px;
}

.pxl-reload-tooltip.pxl-deleteToltip button.cun-socialIcon.cun-socail-delete {
  border: none;
}

.pxl-trash-project-data main.sch-main-wrapper .pxl-mydesign-createProjectBox .sch-container-fluid {
  padding: 0;
}

.sch-banner-content .sch-banner-select-search-box .cun-headreSelect .css-1nmdiq5-menu {
  /* width: max-content !important; */
}

.pxl-builder-projectWrapper.pxlBuilderMain-wrapper .dash_head.dash_head_topNone.pxl-builder-project-heading h3 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
}

.sup_proj_setting_form.pxl-pixasupport-projectSetting-wrap {
  max-width: 600px;
  margin: 0 auto;
}

.sup_proj_setting_form.pxl-pixasupport-projectSetting-wrap .pxl_input_feilds {
  max-width: 100%;
}

.sup_proj_setting_form.pxl-pixasupport-projectSetting-wrap .save_btn {
  margin: 30px 0px 0px;
}

.pxl-builderTrash {
  padding: 30px 10px 10px 10px !important;
}

.pxl-builderTrash-box {
  padding: 25px 15px 15px !important;
}

.pxl-trashpage-mainWrapper .sch-trash-tabs-wrapper .tab-content {
  padding: 30px 0px;
}

.pxl-trash-builder-data .pxlBuilder-mainBox {
  padding: 0;
}

.css-1jqq78o-placeholder {
  max-width: 150px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.css-14f8ivi-MenuList {
  max-width: 530px !important;
}

.modal.show .modal-dialog {
  padding: 0 0 70px;
}

.css-10wo9uf-option {
  word-break: break-word !important;
}


.pxl_custom_form.pxl_login-input.pxl-loginPrivacyPolicy .pxl_remamberMe.checkbox label:before {
  top: 5px;
  border: 1px solid #e5dffe;
}

.pxl_custom_form.pxl_login-input.pxl-loginPrivacyPolicy .pxl_remamberMe.checkbox label::after {
  top: 10px;
}

.pxl_custom_form.pxl_login-input.pxl-loginPrivacyPolicy .pxl_remamberMe.checkbox input:checked~label:before {
  background: var(--gradientColor);
  border-color: #b62798 !important;
}

.pxl_custom_form.pxl_login-input.pxl-loginPrivacyPolicy label {
  text-transform: unset;
  font-size: 15px;
}

.pxl_custom_form.pxl_login-input.pxl-loginPrivacyPolicy label a {
  color: #2c4ecf;
  font-weight: 500;
}

.pxl_custom_form.pxl_login-input.pxl-loginPrivacyPolicy label a:hover {
  color: #6f10b9;
}

.pxl_mainWrapper.pxl-puxaSupport-customer-wrapper {
  padding: 30px 0px 30px 0px;
}

.pxlCreate-ticketWrap-box {
  padding: 30px 0px 30px 0px;
}

.sup_proj_setting_form.pxl-seoSetting-wrap .save_btn {
  margin: 30px 0 0;
}

/* select2 option ellipse */
.pxl-selectBrand .css-10wo9uf-option {
  max-width: 530px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.pxl-selectBrand .css-d7l1ni-option {
  max-width: 530px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

/*  */
.css-12a83d4-MultiValueRemove:hover {
  background-color: #cfcece !important;

}

.pxl-accordianInner-height {
  margin: 10px 0 0;
}

.pxl-accordianInner-height .sch-accodian-body1 {
  padding: 10px 10px 5px;
  margin: 0px 10px 10px;
}

.pxl-support-head1.pxl-head-leftsec.pxl-ticketBackArrow-sec h3 {}

.pxl_planHolder_table.pxl-teamMember-plans span.pxl_plans {
  text-overflow: unset;
}

span.pxl-ticket-reply-backIcon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F4F6FD;
  border-radius: 8px;
  margin: 0 10px 0 0;
}

span.pxl-ticket-reply-backIcon img {
  /* transform: rotate(90deg); */
}

.proj_mail_set_form.pxl-createTicket-wrapper {
  margin-top: 30px;
  margin-bottom: 40px;
}

.pxl-brandName.cun-socialIcon .cun-show-tooltip {
  top: -70px;
  left: 20px;
}

.pxl-brandName.cun-socialIcon .cun-show-tooltip p {
  display: -webkit-box;
  max-width: 190px;
  min-height: 18px;
  margin: 0 auto;
  font-size: 14px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  line-height: 1.2;
}

.pxl-supportBtn-assignBack {
  margin: 30px 0 0;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

button.sch-btn-iconLeft>span>img {
  width: 20px;
  height: 20px;
}

.support_tabs_wrapper.pxlBuilderTabs {
  max-width: 890px;
  margin: 0 auto 20px;
  position: relative;
}

.pxlBack-btn {
  position: absolute;
  left: 0px;
  top: 30px;
  width: 150px;
}

.pxlPixaSupport-projectTabs .support_tabs_wrapper {
  position: relative;
  max-width: 1100px;
  margin: 0 auto 22px;
}

.pxl-widget-appearance-btn {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin: 10px 0 0;
}

.pxlInstall-script-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 30px 0px 0px;
}

.pxlTeamMember-popup .pxl-memberWrap .check_box_wrap {
  display: inline-flex;
  gap: 3px;
  align-items: unset;
}

.pxlTeamMember-popup .pxl-memberWrap .check_box_wrap .cun-AllChecknoxSelect {
  width: auto;
}

.pxl_scope_wrapper.pxlAddnew-member-field.Scheduler_newMember-popup {
  font-weight: 600;
}

.pxl_scope_wrapper.pxlAddnew-member-field .pxl_fields {
  gap: 0;
  margin-bottom: 20px;
}

.pxl_scope_wrapper.pxlAddnew-member-field .pxl_fields p {
  font-size: 14px;
  font-weight: 500;
  color: #423A4D;
}

.pxl_scope_wrapper.pxlAddnew-member-field h3 {
  padding: 0 0 13px;
}

.pxl_scope_wrapper.pxlAddnew-member-field .pxl-memberWrap {
  display: flex;
  align-items: center;
  gap: 40px;
}

.pxl_scope_wrapper.pxlAddnew-member-field .check_box_wrap {
  font-weight: 400;
  font-size: 14px;
  color: #4F536F;
}

.cun-downloadMneu>ul>li>.cun-custom-radioBtn [type="radio"]:checked+label,
.cun-custom-radioBtn [type="radio"]:not(:checked)+label {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.cun-download-para p {
  color: #161930;
  font-size: 14px;
  padding: 10px 0 0;
  text-align: center;
}

.cun-download-para p b {
  font-weight: 600;
  color: #7E15B1;
}

.sch-model-social-body.schLinkdin-social .sch-uploadpost-img1 img {
  max-height: 700px;
}

.sch-model-social-body.sch-pinterest-social .sch-uploadpost-img1 img {
  max-height: 700px;
}

/* notification message css */
.pxl-notication-section {
  display: flex;
  max-width: 285px;
  /* height: 80px; */
  background-color: #ffffff;
  box-shadow: 0px 0px 40px 0px #401ECB0F;
  align-items: center;
  border-radius: 20px;
  padding: 5px 5px 5px 15px;
  justify-content: flex-start;
  position: fixed;
  top: 20px;
  right: 20px;
  transition: all 0.3s;
  z-index: 9999;
  display: none;
}

.success.pxl-notication-section {
  display: flex;
}

.error.pxl-notication-section {
  display: flex;
}

.success .pxl-success-notification {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 24.73%, rgba(211, 241, 219, 0.8) 101.77%);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 10px 35px 10px 0px;
  display: block;
  width: calc(100% - 60px);
}

.pxl-notification-cross {
  position: absolute;
  top: -5px;
  right: -5px;
}

.success img.pxl-succesCros {
  display: block;
}

.error img.pxl-errorCros {
  display: block;
}

.pxl-error-notification {
  display: none;
}

img.pxl-notiError {
  display: none;
}

img.pxl-notiSuccess {
  display: none;
}

.success img.pxl-notiSuccess {
  display: block;
}

.pxl-success-notification {
  display: none;

}

.pxl-succesCros {
  display: none;
}

.pxl-errorCros {
  display: none;
}

.success .pxl-success-notification {
  display: block;
}

.success .pxl-notification-content {
  width: 70px;
}

.error .pxl-notification-content {
  width: 70px;
}

.pxl-success-notification>h2 {
  color: #36B133;
  font-size: 16px;
  font-weight: 800;
  padding: 0 0 5px;
}

.pxl-success-notification>p {
  color: #1E1E1E;
  font-size: 12px;
  font-weight: 400;
}



.pxl-notication-section.success .pxl-success-notification {
  display: block;
}

.pxl-notication-section.error .pxl-success-notification h2 {
  color: #36B133;
  font-size: 16px;
  font-weight: 800;
}

.pxl-notication-section.error .pxl-success-notification>p {
  color: #36B133;
  font-size: 12px;
  font-weight: 400;
}

/* .pxl-notication-section.error .pxl-error-notification {
  display: block;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 24.73%, rgba(253, 228, 233, 0.8) 101.77%);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 7px 0px 7px 10px;
} */

.error img.pxl-notiError {
  display: block;
}

.error .pxl-error-notification {
  display: block;
}

.pxl-notication-section.error .pxl-success-notification {
  display: block;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 24.73%, rgba(253, 228, 233, 0.8) 101.77%);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 10px 35px 10px 0px;
  width: calc(100% - 60px);
}

.pxl-notication-section.error .pxl-success-notification h2 {
  color: #FF4242;
  font-size: 16px;
  font-weight: 800;
}

.pxl-notication-section.error .pxl-success-notification>p {
  color: #1E1E1E;
  font-size: 12px;
  font-weight: 400;
  /* padding: 3px 0 0; */
}

/* notification message css */
.cun-datePicke .MuiStack-root.css-v0pjtz-MuiStack-root {
  padding: 0;
}

.cun-datePicke .MuiStack-root.css-v0pjtz-MuiStack-root .MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root input {
  height: 50px;
  padding: 0px 10px 0px;
  border: transparent !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: 1px solid var(--sch-border-color1) !important;
}

.pxl-scheduleContent {
  min-width: 500px;
  text-align: center;
}

.pxl-scheduleContent>p {
  font-size: 15px;
  color: #4F536F;
  font-weight: 400;
  display: -webkit-box;
  max-height: 300px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  max-width: 500px;
}

.sch-schedule-main.pxlSchedule-singlepost-data .sch-scheduleSecRight {
  width: 250px;
}

.sch-schedule-main.pxlSchedule-singlepost-data .sch-scheduleSecRight .sch-schedulePreview {
  padding: 0;
}

.sch-schedule-main.pxlSchedule-singlepost-data .sch-schedule-post-row {
  justify-content: space-between;
}

.sch-schedule-main.pxlSchedule-singlepost-data .sch-scheduleSec.sch-scheduleSecFullPage {
  width: 100px;
}

.sch-schedule-main.pxlSchedule-singlepost-data .sch-scheduleSecFullPage .sch-schedulePost-heading h2 {
  max-width: 300px;
  white-space: nowrap;
}

.sch-schedule-main.pxlSchedule-singlepost-data .pxl-scheduleContent p {
  word-break: break-word;
}

.pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .css-1dimb5e-singleValue {
  width: 120px;
}

.pxl-dashboard-trendingTemplates {
  padding: 0 0 30px 0;
}

.pxl-dashboard-featuredTemplate {
  padding: 0 0 30px 0;
}

.pxl-dashboard-recentDesign {
  padding: 0 0 30px 0;
}

.pxl-clientMain-wrapper-box .sch-search-section.sch-searchButton-wrap {
  display: flex;
  gap: 10px;
}

.pxl-clientMain-wrapper-box .sch-search-row.sch-search-bg-color-white input {
  width: 100%;
  height: 52px;
}

.pxl-addNew-client-btn button.pxlCommon-btn {
  min-width: 140px;
}

.pxl-trash-draft-data .pxl-draftSingle-mainWrapper-box {
  padding: 0;
}

/* focus default css */
button.pxl_socialLogin_btn:hover {
  box-shadow: unset;
}

.pxl_loginWrapper .pxl_link a:hover {
  color: #6f10b9;
}

.pxl-ticketDelete-agent-res ul li a span {
  padding: 0 10px 0 0;
}

/* calender */
.pxldatepicker .css-v0pjtz-MuiStack-root>.MuiTextField-root,
.css-v0pjtz-MuiStack-root>.MuiPickersTextField-root {
  min-width: auto;
}

.pxldatepicker .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  /* border: 1px solid var(--sch-border-color1) !important; */
}


.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  /* border: 1px solid var(--sch-border-color1); */
}

.pxl_profileDetail ul>li>a>span>svg {
  width: 14px;
  height: 14px;
}

.pxl_profile_wrapperr.pxl-profile-wrapBox .pxl_user_info h4 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 230px;
}

.pxl-draft-single-count-select {
  display: flex;
  gap: 10px;
}

li.pxl-userloginAchor a {
  display: block;
  margin-right: 10px;
}

.pxl_rightBar_assets.pxl-bg-back-color-wrap.pxlBrand-mainWrapper-box {
  overflow: hidden;
}

.pxl_input_note.pxlresendText p {
  font-weight: 500;
}

.pxl_input_note.pxlresendText p a {
  color: #8417af;
}

.sch-profile-mainWrapper .pxl-custom-file-upload.pxl-upload-file-profile.pxl-profile-fileSelect .pxl-custom-file-select-name {
  width: 100%;
  text-align: center;
}
.Mui-focused {
  border: 1px solid var(--sch-border-color1) !important;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiClock-pmButton.Mui-selected.css-1kozby0-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton {
  background: #8417af !important;
}
.sch-profile-mainWrapper .pxl-custom-file-upload.pxl-upload-file-profile.pxl-profile-fileSelect .pxl-custom-file-select-name .image_url_wrap img {
  width: 40px;
  height: 40px;
  margin: 4px 0;
  border-radius: 50px;
}

.sch-profile-mainWrapper .pxl-custom-file-upload .pxl-custom-file-select {
  border: 1px solid var(--input-border);
  background-color: var(--bg-color1);
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover {
  /* border: none !important; */
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.css-144rneq-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton:hover {
  background-color: #8417af !important;
}

.css-144rneq-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton {
  background-color: #8417af !important;
}

.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
  /* width: 36px !important;
  height: 30px !important; */
}

.css-23p0if-MuiButtonBase-root-MuiPickersDay-root {
  /* width: 30px !important;
  height: 30px !important;
  font-size: 14px !important;
  line-height: 10px !important; */
}

.css-23p0if-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #8417af !important;
}

.css-23p0if-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  border-color: #8417af !important;
}

.css-d0vs79-MuiClockPointer-root {
  background-color: #8417af !important;
}

.css-f53ilk-MuiClockPointer-thumb {
  background-color: #8417af !important;
  border-color: #8417af !important;
}

.css-umzx0k-MuiClock-pin {
  background-color: #8417af !important;
}

.css-1h2qg9i-MuiClockPointer-root {
  background-color: #8417af !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: #ffffff !important;
  background-color: #8417af !important;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  background-color: #8417af !important;
}

.css-i4bv87-MuiSvgIcon-root {
  fill: #ffffff !important;
}

.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #8417af !important;
}

.pxl-schduler-newPost-wrapper h2.accordion-header button.accordion-button .pxl-accordian-header h2 {
  text-transform: capitalize;
}

.pxl-pixasupport-dashboard {
  padding: 40px 20px 20px !important;
}

.pxl-pixasupport-tickets-status-wrap {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  display: grid;
  grid-gap: 20px;
}

.pxl-pixasupport-ticket-statusBox {
  padding: 35px 35px;
  border-radius: 10px;
  position: relative;
  transition: all 0.5s;
}

.pxl-pixasupport-ticket-statusBox h2 {
  font-size: 35px;
  font-weight: 600;
}

.pxl-pixasupport-ticket-statusBox p {
  font-size: 16px;
  font-weight: 500;
  color: #5e5d5d;
  padding: 5px 0 0 0;
}

.pxl-pixasupport-ticket-statusBox:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 220px;
  height: 5px;
  margin: 0 auto;
  bottom: 0px;
  border-radius: 5px 5px 0px 0px;
}

.single_dash_box {
  position: relative;
  transition: all 0.5s;
}

.single_dash_box:hover {
  transform: translateY(-5px);
}

.bodercolor {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 220px;
  height: 5px;
  margin: 0 auto;
  bottom: 0px;
  border-radius: 5px 5px 0px 0px;
}


.pxl-ticketBoxColor1 {
  background-color: #ae89ec24;
}

.pxl-ticketBoxColor1:after {
  background-color: #ae89ec;
}

.pxl-ticketBoxColor1 h2 {
  color: #ae89ec;
}

/*  */
/* .pxl-ticketBoxColor2 {
  background-color: #0e7e130f;
} */
.pxl-ticketBoxColor2:after {
  background-color: #528321;
}

.pxl-ticketBoxColor2 h2 {
  color: #528321;
}

/*  */
.pxl-ticketBoxColor3 {
  background-color: #fe76721c;
}

.pxl-ticketBoxColor3:after {
  background-color: #fe7672;
}

.pxl-ticketBoxColor3 h2 {
  color: #fe7672;
}

/*  */
.pxl-ticketBoxColor4 {
  background-color: #ffc95529;
}

.pxl-ticketBoxColor4:after {
  background-color: #ffc955;
}

.pxl-ticketBoxColor4 h2 {
  color: #ffc955;
}

/*  */
.pxl-ticketBoxColor5 {
  background-color: #0373b314;
}

.pxl-ticketBoxColor5:after {
  background-color: #0e8cd3;
}

.pxl-ticketBoxColor5 h2 {
  color: #0e8cd3;
}

.pxl_mainWrapper.sch-nav-open.pxl-pixaSupport-settingTabs .support_tabs_wrapper {
  min-height: auto;
  margin: 30px 0 20px 0;
}

.pxl-smtp-host-wrapper-box {
  display: flex;
  gap: 10px;
}

.pxl-mailSetting-mainWrapper-box.pxl-outgointMail-settingBox .pxl-outgoingMail-setting-box {
  max-width: 700px;
}

input:is(:-webkit-autofill, :autofill) {
  /* background-color: red !important; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* -webkit-text-fill-color: #000000;
  -webkit-box-shadow: 0 0 0px 1000px #d2d2d2 inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s; */
}

.pxl-mailsetting-wrap01 .pxl_input_feilds {
  padding: 0 0 15px 0;
}

.pxl-setupForwarding-box h5 {
  font-size: 18px;
  padding: 0 0 10px;
}

.pxl-setupForwarding-box .mail_forward_copy {
  margin: 10px 0 20px;
}

.pxl_profileDetail.pxl-logutSection-wrap a {
  display: block;
}

.sch-schedule-main.pxlSchedule-singlepost-data .sch-schedule-post-row .sch-schedulePost-heading h2 {
  max-width: 200px;
}

.sch-schedule-main.pxlSchedule-singlepost-data .sch-schedule-post-row .sch-scheduleSec.sch-scheduleSecFullPage {
  width: 220px;
}

.pxl-assets-area.pxl-imageTemplateRow-wrap .pxl-assets-row {
  gap: 15px;
}

.pxl-login-inputBox {
  position: relative;
}

.pxl-login-boxBottom {
  position: absolute;
  z-index: 1;
  bottom: -50px;
  left: 0;
  right: 0;
}

.pxl-login-boxBottom>h2 {
  font-size: 13px;
  font-weight: 400;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border: 1px solid #989b9e;
  padding: 10px 10px;
  border-radius: 3px;
  position: relative;
  width: 100%;
}

/* .pxl-login-boxBottom h2:after {
  content: '';
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #989b9e;
  left: 20px;
  top: -6px;
} */
.pxl-login-boxBottom>h2>span {
  position: relative;
  top: -2px;
  padding: 0px 10px 0px 0px;
}

.pxl-login-boxBottom>h2>span>img {
  width: 15px;
  height: 15px;
}
input.pxlInputDisableField {
  cursor: not-allowed !important;
  background-color: #dddddd !important;
}
.pxl_input_feilds.pxlprofile-imgUpload {
  width: 49%;
}
.pxl-profile-wrapBox .pxl_input_feilds input {
  height: 60px;
}
.sch-profile-mainWrapper .pxl_profile_row .pxl-custom-file-upload .pxl-custom-file-select {
  height: 80px;
}
.pxl_input_feilds.pxlprofile-imgUpload .pxl-custom-file-upload .pxl-custom-file-select .pxl-custom-file-select-name {
  height: 80px;
  padding: 14px 0px;
}
.pxl-sample-responseBox .pxl_input_feilds textarea {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  padding: 10px 10px;
  border: 1px solid var(--input-border);
  background-color: var(--bg-color1);
  resize: unset;
}
/* admin api documentation css start */
ul.pxl_folder_list {
  background-color: #f4e9fd;
  padding: 10px 10px 3px;
  border-radius: 5px;
  margin: 0px 0px 15px 0px;
}

ul.pxl_folder_list>li>.ap_single_folder_wrap {
  background: white;
  padding: 8px 8px;
}

ul.pxl_folder_list>li>ul.pxl_api_list {
  background: #ffffff;
  margin: 0 0 10px;
  border-radius: 5px;
  padding: 5px;
}

ul.pxl_folder_list>li>ul.pxl_api_list>li {
  background-color: #f4e9fd;
  padding: 7px 7px;
  border-radius: 5px;
  margin: 0 0 5px;
}
.pxl-api-doc-sec1 {
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  display: grid;
  grid-gap: 10px;
  border-bottom: 1px solid #e7eef8;
}
.pxl-apiPost-select.pxl_input_feilds {
  position: relative;
}
.pxl-apiPost-select.pxl_input_feilds > input {
  padding: 10px 20px 10px 150px;
}

.pxl-apiPost-select.pxl_input_feilds .cun-headreSelect {
  position: absolute;
  top: 0px;
  left: 0;
  padding: 0px;
  min-width: 120px;
}
button.pxl-tryItOut-btn {
  background-color: #abefa3;
  color: #000000;
  height: 50px;
  padding: 20px 30px;
}
.pxl-apiTable-wrap-box table.api_table thead tr th {
  color: var(--sch-title-color);
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 15px;
  text-transform: capitalize;
}
.api_tabs.pxl-apiTab-wrap-box {
  margin: 0 0 20px;
  display: flex;
  align-items: center;
}
.pxl-apiTable-wrap-box table.api_table > tbody > tr > td > input {
  border-radius: 6px;
  border: 1px solid var(--input-border);
  background-color: var(--bg-color1);
  width: 100%;
  min-height: 50px;
  padding: 10px 20px;
  font-size: 16px;
}
/*  */
.sch-headBtn button.pxlCommon-btn {
  height: 45px;
}
.header_nine_dot_wrapper .menu_dot img {
  height: 45px;
  width: 45px;
}
.cun-headreSelect.pxl-tableType-select {
  padding: 0px;
  height: 50px;
}
.cun-headreSelect.pxl-tableType-select .cun-selectWrap .css-b62m3t-container .css-13cymwt-control {
  height: 50px !important;
  border: 1px solid var(--input-border);
  background-color: var(--bg-color1);
}
.cun-headreSelect.pxl-tableType-select .cun-selectWrap .css-b62m3t-container .css-t3ipsp-control
{
  height: 50px !important;
  border: 1px solid var(--input-border);
  background-color: var(--bg-color1);
}
span.api_row_del {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid var(--input-border);
  background-color: var(--bg-color1);
}
.pxl-ticket-status-icons ul.pxl-flex-divide{
  gap: 10px;
}
.pxl-ticket-status-icons ul.pxl-flex-divide li {
  padding: 0;
}
.pxl-ticket-status-icons ul.pxl-flex-divide li.cun-socialIcon {
  left: unset;
  right: unset;
}
.pxl-ticket-status-icons ul.pxl-flex-divide li.cun-socialIcon.pxl-tooltip-button-form.pxl-deleteToltip .cun-show-tooltip {
  transform: none;
}
.pxl-ticket-status-icons ul.pxl-flex-divide li.cun-socialIcon:hover .cun-show-tooltip {
  opacity: 1;
  visibility: visible;
  margin: 0 auto;
  left: unset;
  right: unset;
}
.ptw_widget_wrapper {
  width: fit-content !important;
}
.pxl-api-doc-sec2 {
  padding: 20px 0 20px 0;
  border-bottom: 1px solid #e7eef8;
  margin: 0px 0 25px 0;
}
ul.pxl_folder_list>li>ul.pxl_api_list>li>a {
  color: #000000;
}
.pxl-api-addCancel-section input {
  border: 1px solid var(--input-border);
  background-color: var(--bg-color1);
  width: 100%;
  height: 50px;
  padding: 10px 10px;
  border-radius: 6px;
}
.pxl-api-addCancel-btn {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  padding: 10px 0 20px;
}



.css-1e4tpwf-MuiTypography-root-MuiPickersToolbarText-root-MuiDateTimePickerToolbar-separator {
  font-size: 26px !important;
  line-height: unset!important;
  color: #000000!important;
  position: relative!important;
}
.css-mvmxd9-MuiTypography-root-MuiPickersToolbarText-root{
  color: #000000!important;
  font-size: 20px !important;
}
.css-ollblj-MuiDateTimePickerToolbar-amPmSelection .MuiDateTimePickerToolbar-ampmLabel {
  font-size: 16px!important;
  color: #000000!important;
  padding: 0px 2px;
}
.css-1b9e08i-MuiTypography-root-MuiPickersToolbarText-root {
  font-size: 18px!important;
  color: #000000!important;
  top: 4px;
  position: relative;
  left: 6px;
}
.css-1hbyad5-MuiTypography-root {
  color: #000000 !important;
  font-weight: 500!important;
  font-size: 18px!important;
  letter-spacing: 0px!important;
  text-transform: capitalize!important;
}
.css-1b9e08i-MuiTypography-root-MuiPickersToolbarText-root.Mui-selected {
  font-size: 25px!important;
  padding: 0 0 0 10px !important;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #000000 !important;
}
.css-1d7o9mv-MuiDateTimePickerToolbar-dateContainer {
  flex-direction: unset!important;

}
.css-12t0dn4-MuiClockPointer-thumb{
  border: 16px solid #8417af !important;
}
.css-1b9e08i-MuiTypography-root-MuiPickersToolbarText-root.Mui-selected {
  color: rgba(0, 0, 0, 0.87);
  font-size: 19px !important;
  position: relative;
  top: 3px;
}
.css-13vtpvu-MuiTypography-root-MuiPickersToolbarText-root{
  font-size: 18px!important;
  color: #000000!important;
}
.css-ollblj-MuiDateTimePickerToolbar-amPmSelection {
  flex-direction: unset !important;
}
/* ============== media css start =====================*/
@media(max-width:1800px) {}

@media(max-width:1879px) {
  .sch-draft-post-wrpper {
    padding: 0px 0 80px;
  }

  .sch-draft-box-flex {
    gap: 15px;
  }

  .sup_dash_wrap {
    padding: 0 40px 100px;
  }

  .pxl-widgetWrap.pxl-widgetWrapBox .sup_proj_setting_form.widget_app_wrap.pxl-widget-wrap .widget_login_Sec.pxl-widget-head2 .proj_Setting_first_flex.pxl-widgetSetting-flex .pxl_input_feilds {
    max-width: 350px;
  }
}

@media(max-width:1700px) {
  .pxl-widget-position h4 {
    top: 0;
  }

  .pxl-widgetSetting-flex {
    gap: 25px;
  }
}

@media(max-width:1600px) {
  .sch-schedule-main.pxlSchedule-singlepost-data .pxl-scheduleContent {
    max-width: 300px;
    min-width: 300px;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .pxl-scheduleContent p {
    max-width: 300px;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .sch-scheduleSecFullPage .sch-schedulePost-heading h2 {
    max-width: 190px;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .sch-scheduleSec.sch-scheduleSecFullPage {
    width: 220px;
  }
}

@media(max-width:1530px) {
  .api_admin_flex {
    flex-direction: column;
  }

  .api_folder_wrap {
    width: 60%;
    max-width: 100%;
  }

  .api_table_resp {
    overflow-x: auto;
    scrollbar-width: thin;

  }

  .api_example_wrap .code_Section pre code.hljs {
    max-width: 470px;
  }
}

@media(max-width:1500px) {
  .sch-profile-mainWrapper:after {
    max-width: 580px;
  }

  .pxl-doaminAdd-input .pxl_input_feilds.pxl-placeholder-color1 {
    max-width: 100%;
  }

  .sch-banner-position-wrap {
    display: none;
  }

  .sch-header-wrapper {
    position: fixed;
    width: 100%;
  }

  .sch-header-wrapper.header-sticky {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    animation: 0 !important;
  }

  .sch-draft-box-flex {
    gap: 15px;
    justify-content: center;
  }

  .pxl-template-categories {
    width: 280px;
  }

  .pxl-templates-holder {
    width: calc(100% - 310px);
  }

  .pxl-widget-appearance-btn {
    margin: 20px 0 0;
  }

}

@media(max-width: 1366px) {

  /* .pxl_pageTitle {
    margin: 65px 0 30px;
  } */
  /* .proj_Setting_first_flex .pxl_input_feilds {
    max-width: 46%;
  } */

  /* .lower_proj_settings {
    flex-direction: column;
  }

  .lower_proj_settings .proj_Setting_first_flex .pxl_input_feilds {
    max-width: 100%;
  }

  .proj_mail_Set_lower {
    flex-direction: column;
    margin-bottom: 15px;
  }

  .max-402 {
    max-width: 76%;
  }

  .proj_mail_Set_lower .pxl_input_feilds {
    max-width: 100%;
  } */

}

@media(max-width:1321px) {}

@media(max-width:1300px) {


  .pxl-widgetWrap.pxl-widgetWrapBox .sup_proj_setting_form.widget_app_wrap.pxl-widget-wrap .widget_login_Sec.pxl-widget-head2 .proj_Setting_first_flex.pxl-widgetSetting-flex .pxl_input_feilds {
    max-width: 300px;
  }

  .pxl-dashboard-box {
    width: 260px;
  }
}

@media(max-width:1200px) {
  .sch-schedule-main.pxlSchedule-singlepost-data .sch-scheduleSecRight {
    width: 250px;
  }

  .pxl_rightBar_assets.pxl-bg-back-color-wrap.pxlBrand-mainWrapper-box {
    overflow: hidden;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .sch-draft-post-flex h2 {
    max-width: 500px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 0 0 30px;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .sch-draft-post-flex {
    display: flex;
    justify-content: space-between;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .sch-schedule-mainRes {
    padding: 0;
  }

  .pxl-widgetWrap.pxl-widgetWrapBox .sup_proj_setting_form.widget_app_wrap.pxl-widget-wrap .widget_login_Sec.pxl-widget-head2 .proj_Setting_first_flex.pxl-widgetSetting-flex .pxl_input_feilds {
    max-width: 280px;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .sch-scheduleSec.sch-scheduleSecFullPage {
    width: 150px;
  }

  .max-402 {
    max-width: 100%;
  }

  .pxl-widgetSetting-flex {
    gap: 10px 20px;
  }

  .widget_section_wrap {
    display: block;
  }

  .sch-socialAccount {
    width: 0;
  }

  .sch-headerRes-wrapper {
    display: block;
  }

  a.sch-menu-btn {
    /* opacity: 1;
    visibility: visible; */
  }

  .sch-draft-post-flex.sch-schedule-desktop {
    display: none;
  }

  .sch-schedule-mainRes {
    display: block;
    padding: 0px 0px 0px 50px;
  }

  .pxl_socialError_wrapper {
    padding: 0;
    max-width: 390px;
    margin: 0 auto;
    left: 0;
  }

  .cun-responsive-toggle {
    display: block;
    position: relative;
    z-index: 111;
  }

  .pxl_sidebarMenu.opensidebar {
    left: -320px;
    top: 0;
  }

  .pxl_sidebarMenu.closesidebar {
    left: 0;
    top: 0px;
  }

  .pxl_rightBar_assets {
    padding: 70px 50px;
  }

  .sch-scheduleImage {
    padding: 0px 10px 0px 5px;
  }

  .sch-schedulePreview {
    padding: 0 0 0 15px;
  }

  .sch-schedulePost-heading h2 {
    font-size: 16px;
  }

  .sch-scheduleImage>img {
    width: 50px;
    height: 50px;
  }

  .sch-scheduleSec {
    width: 340px;
  }

  .sch-schedulePost-heading h2 {
    width: 90px;
  }

  .sch-schedule-post-row {
    border-radius: 10px;
    margin-left: 0;
  }

  .pxl-head-btn-wrap.cun-pixaStockHead {
    grid-gap: 0;
  }

  .pxl-head-btn-wrap.cun-pixaStockHead a.sch-btn-iconRight.pxl-button-disable {
    margin: 0px 0px 0px 5px;
  }

  .cun-noDesign-inner {
    padding: 60px 0px;
  }

  /* APi doc */
  .api-d-flex {
    flex-direction: column;
  }

  .api_content_main_heading {
    background-image: linear-gradient(to right, var(--bg-color1) 50%, var(--bg-color1) 50%);
  }

  .api_highLight_area {
    background-image: linear-gradient(to right, #f6e8f5 50%, #f6e8f5 50%);
  }

  .api_para_text {
    background-image: linear-gradient(to right, var(--bg-color1) 50%, var(--bg-color1) 50%);
  }

  .api_example_wrap .code_Section pre code.hljs {
    max-width: 100%;
  }

  .api_page_wrapper .api_content_wrapper {
    width: 100%;
  }

  .pxl_profileDetail ul {
    padding: 10px 10px 10px 0;
    border-radius: 10px;
    min-width: 160px;
    right: 0;
    left: auto;
    bottom: 40px;
  }

  .pxl_profileDetail ul:before {
    left: auto;
    bottom: -10px;
    right: 20px;
    transform: rotate(30deg);
  }
}

@media(max-width:1199px) {
  .emoj_mainwrapper[placement="left"] {
    left: 0 !important;
    right: auto;
    bottom: 70px;
  }

  .sch-post-area-box {
    z-index: 0;
  }

  .support_tab_inner ul {
    gap: 5px;
  }

  .support_tab_inner {
    padding: 0 5px;
  }
}

@media(max-width:1024px) {}

@media(min-width: 992px) {
  .pxl-filter-mobile-btn {
    display: none;
  }

}

@media(max-width:991px) {
  .pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .dash_head{
    display: flex;
}
  .pxl-clientMain-wrapper-box .sch-search-row {
    width: 230px;
}
  .pxl-teamMember-wrapper .sch-search-section {
    max-width: 200px;
}
  .pxl-teamMember-wrapper .sch-search-row.sch-search-bg-color-white input{
    width: 100%;
  }
  .sch-schedule-main.pxlSchedule-singlepost-data .sch-scheduleSecRight {
    width: 180px;
  }

  .sch-schedulePreview a span.schPreview {
    display: none;
  }

  .pxl_socialError_wrapper .pxl-social-error-innerBox {
    padding: 5px 20px 10px;
  }

  .pxl_socialError_wrapper {
    top: -84px;
  }

  .modal.show .modal-dialog {
    padding: 0 20px 70px;
  }

  .pxlPixaSupport-projectTabs .sup_proj_setting_form .pxl_input_feilds {
    max-width: 100%;
  }

  .sch-container-fluid.pxlPixaSupport-projectTabs .pxlBack-btn {
    position: unset;
  }

  .pxlPixaSupport-projectTabs .support_tabs_wrapper {
    justify-content: flex-start;
  }

  .pxl_ticket_Set_type_prior ul.pxl-flex-divide {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 0;
  }

  .sup_proj_setting_form.pxl-seoSetting-wrap .pxl-custom-file-upload .pxl-custom-file-select .pxl-custom-file-select-button {
    padding-left: 5px;
  }

  .single_dash_box .dah_color_box {
    width: 60px;
    height: 60px;
  }

  .pxl_dash_box_content>h2 {
    font-size: 22px;
    ;
  }

  .single_dash_box .dah_color_box img {
    width: 35px;
  }

  .pxl-dashboard-box {
    width: 200px;
  }

  .pxl-dashboard-app-img svg {
    width: 20px;
    height: 20px;
  }

  .pxl-dashboard-app-img {
    width: 48px;
    height: 48px;
  }

  .pxl-dashboard-appWrap {
    gap: 10px;
  }

  .pxl-dashboard-app-content>p {
    font-size: 18px;
  }






  .pxlBuilder-mainBox .dash_head {
    display: flex;
  }

  .pxl-trashpage-mainWrapper .pxl_rightBar_assets .pxl_pageTitle h2 {
    text-align: left;
    padding: 0 0 15px;
  }

  .pxl-trashpage-mainWrapper .fullPage .sch-library-header {
    display: flex;
    justify-content: space-between;
  }

  .pxl-clientMain-wrapper-box .pxl_tableBox_filter .pxl_tableData h5 {
    text-align: left;
  }

  .pxl-brandMain .pxl-vrandBox:after {
    display: none;
  }

  .pxlBrand-mainWrapper-box .pxl-flex-divide.pxl-brand-header-bottom .pxl-brand-record-head h5 {
    text-align: left;
  }

  .pxlBrand-mainWrapper-box .pxl_pageTitle h2 {
    text-align: left;
    padding: 0 0 20px;
  }

  .pxlBrand-mainWrapper-box .pxl-flex-divide.pxl-brand-header-bottom {
    display: flex;
  }

  .pxl-clientMain-wrapper-box .pxl_pageTitle h2 {
    text-align: left;
    padding: 0 0 20px;
  }

  .pxl-clientMain-wrapper-box .pxl_tableBox_filter .pxl_filterFeilds.cun-users-resheader .pxl-addNew-client-btn {
    display: flex;
    justify-content: flex-end;
  }

  .pxl-teamMember-wrapper .pxl_rightBar_assets .pxl_pageTitle h2 {
    text-align: left;
    padding: 0px 0px 20px;
  }

  .pxl_tableBox_filter.pxlTeamMemberHead {
    display: flex;
    align-items: unset;
  }

  .pxl-clientMain-wrapper-box .pxl_tableBox_filter {
    display: flex;
    align-items: unset;
  }

  .pxl-pixaSupport-mainWrapper .pxl-projectMainWrapper .dash_head {
    display: flex;
    padding: 25px 0px;
  }

  .pxl-widgetWrap.pxl-widgetWrapBox .sup_proj_setting_form.widget_app_wrap.pxl-widget-wrap .widget_login_Sec.pxl-widget-head2 .proj_Setting_first_flex.pxl-widgetSetting-flex .pxl_input_feilds {
    max-width: 100%;
  }

  .proj_Setting_first_flex.pxl-project-mailSettingTop-field .pxl_input_feilds.pxl-placeholder-color1.marginBottom {
    margin: 0 0 15px;
  }

  .proj_Setting_first_flex.pxl-project-mailSettingTop-field .cun-mailSetting-radiobox-wrapper-box {
    top: 0px;
    margin: 20px 0 0 0;
  }

  .proj_Setting_first_flex.pxl-project-mailSettingTop-field .pxl_input_feilds {
    max-width: 100%;
  }

  .proj_Setting_first_flex.pxl-project-mailSettingTop-field {
    display: block;
  }

  .cun-mailSetting-radiobox-wrapper-box {
    padding-bottom: 20px;
  }

  .pxl-outgoingMail-setting-box h4.proj_main_head {
    padding: 0;
  }

  .pxl-malserver-forwarder {
    max-width: 100%;
  }

  .pxl-username-email-accountDetails .pxl_input_feilds {
    max-width: 100%;
  }

  .pxl-outgoingMail-setting-box .mail_back_color_box {
    background: #edf5ff54;
  }

  .pxl-username-email-accountDetails {
    max-width: 100%;
    border: 1px solid var(--borderColor);
    background-color: #edf5ff54;
    padding: 22px 16px;
    border-radius: 6px;
    margin: 10px 0;
    position: relative;
  }

  .pxl-mailSetting-mainWrapper-box .mail_back_color_box .pxl_input_feilds {
    max-width: 100%;
  }

  .pxl-outgoingMail-setting-box {
    max-width: 100%;
    margin: 0 0 20px;
  }

  .pxl-mailSetting-mainWrapper-box {
    display: block;
    margin-top: 0;
  }

  .pxl-puxaSupport-customer-wrapper .dash_head h3 {
    text-align: center;
    padding: 0 0 15px 0;
  }

  .pxl-custom-file-upload.pxl-upload-file-profile.pxl-profile-fileSelect .pxl-custom-file-select-name {
    padding: 0px 0 0 10px;
  }

  .pxl-custom-file-upload.pxl-upload-file-profile.pxl-profile-fileSelect .pxl-custom-file-select-button {
    padding: 2px 0 0px 10px !important;
  }

  .pxl-teamMember-wrapper .pxl_tableData {
    padding: 10px 0px 15px;

  }

  .pxl_tableBox_filter.pxlTeamMemberHead .pxl_tableData {
    padding: 10px 0px 10px;
    text-align: left;
  }

  .pxl_mainWrapper.pxl-admin-dashboard-wrap .pxl_pageTitle {
    margin: 0px 0px 30px;
  }

  .pxl_mainWrapper.pxl-admin-dashboard-wrap .pxl_pageTitle h2 {
    text-align: left;
  }

  .pxl_mainWrapper.pxl-apiDocumentation-mainWrap .pxl_pageTitle {
    margin: 0 0 30px;
  }

  .pxl_mainWrapper.pxl-apiDocumentation-mainWrap .pxl_pageTitle h2 {
    text-align: left;
  }

  .sup_proj_setting_form .pxl-ticket-status-icons ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px 0px;
  }

  .pxl_table tr td {
    max-width: 250px;
  }

  .pxl_table tr td p {
    max-width: 100px;
  }

  /* table.pxl_table figure.table tbody, td, tfoot, th, thead, tr {
    max-width: fit-content !important;
} */
  .pxl_input_feilds>input {
    padding: 10px 10px;
    font-size: 14px;
  }

  .pxl-projectMainWrapper .dash_head h3 {
    margin: 0 0 20px 0 !important;
  }

  .sch-container-fluid {
    margin: 0 auto 30px;
  }

  .pxl-custom-file-upload.pxl-upload-file-profile.pxl-seo-uploadFile .pxl-custom-file-select-name {
    padding: 10px 0px 0px 10px;
  }

  .pxl-builder-projectWrapper>.dash_head>h3 {
    padding: 0 0 20px 0;
  }

  .dash_head {
    display: block;
  }

  .pxl-flex-divide {
    display: block;
  }

  .sch-search-section.pxl-dashboardSearch1 {
    margin: 10px 0 0 0;
  }

  .sch-divide-flex.sch-section-bottom-space.pxl-dashboardFlex {
    display: flex;
  }

  .sch-divide-flex {
    display: block;
  }

  .pxl-brand-profile {
    /* padding:0px 0px 20px; */
  }

  .pxl-vrandBox {
    height: auto;
  }


  .pxl-brand-btn button.sch-btn-iconLeft {
    margin: 0 auto;
  }

  .pxl_pageTitle {
    margin: 0px;
  }

  .pxl-brand-record-head>h5 {
    padding: 0 0 10px;
    text-align: center;
  }

  .brand_wrapper {
    padding: 10px 0 0 0;
  }

  .pxl_filterFeilds {
    justify-content: center;
  }

  .pxl_tab_style .nav-tabs:before {
    border-bottom: none;
  }

  .pxl-templates-status-bar {
    margin: 30px 0;
    display: block;
  }

  .pxl_user_info_box {
    box-shadow: none;
    margin: 0 auto 30px;
  }

  .sch-profile-mainWrapper:after {
    display: none;
  }

  .pxl_profile_row {
    padding: 0 30px 20px;
  }

  .pxl-head-btn-wrap {
    display: block;
  }

  .pxl-template-container {
    display: block;
  }

  .pxl-template-categories {
    width: 100%;
  }

  .pxl-templates-holder {
    width: 100%;
  }

  /* .pxl-head-btn-wrap.cun-pixaStockHead {
  padding: 30px 0 0;
} */
  .sch-menu-btn {
    width: 40px;
    height: 40px;
  }

  .sch-trash-tabs-wrapper .tab-content {
    padding: 30px 0px;
  }

  .sch-trash-tabs-wrapper>.nav.nav-tabs {
    padding: 14px 0px 0;
  }

  .fullPage .sch-library-header {
    margin: 0px 0px 20px 0px;
  }

  .emoj_mainwrapper[placement="left"] {
    left: 0 !important;
  }

  .sch-schedule-post-row {
    margin: 0px 0px 10px 0px;
  }

  .sch-draft-post-flex>h2 {
    font-size: 20px;
  }

  /* .sch-schedule-mainRes {
    padding: 0;
  } */

  .sch-scheduleImage {
    width: fit-content;
    height: fit-content;
  }

  /* 
  .pxl_pageTitle {
    margin: 100px 0 30px;
  } */


  .sch-draft-box-flex {
    justify-content: center;
  }

  .sch-schedule-main {
    width: 100%;
    height: auto;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    padding: 0px 30px;
  }

  .sch-headerRes-wrapper {
    display: block;
  }

  .pxl-templates-status-bar .cun-headreSelect {
    padding: 0;
  }

  .widget_login_Sec .max-402 {
    max-width: 100%;
  }

  .pxl-ticket-status-wrap:after {
    bottom: 0;
    left: 0;
    right: 0;
    width: 180px;
    height: 4px;
    margin: 0 auto;
    top: auto;
    border-radius: 10px 10px 0px 0px;
  }

  .sup_proj_setting_form .pxl-ticket-status-name h2 {
    text-align: center;
    max-width: 100%;
  }
}
@media(max-width:767px) {
  .pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .dash_head .sch-search-section .sch-search-row input {
    padding: 0px 10px 3px 10px;
}
  .pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .cun-headreSelect {
    min-width: 150px;
}
.pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .css-b62m3t-container {
  width: 150px !important;
}
.pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .dash_head .sch-search-section {
  margin: 0;
  width: 150px;
}
.pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .dash_head .sch-search-section button.sch-search-btn {
  display: none;
}
  .pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .dash_head .pxl_filterFeilds {
    display: flex;
}
.pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .dash_head .pxl_filterFeilds .cun-headreSelect {
  margin: 0;
}

  .pxl-teamMember-wrapper .pxl_filterFeilds.cun-users-resheader .sch-search-section {
    margin: 0;
}
  .pxl-teamMember-wrapper .pxl_filterFeilds {
    display: flex;
}
  .pxl_rightBar_assets {
    padding: 70px 20px;
  }

  .sch-draft-post-wrpper.pxl-draftSingle-pageWrapper .sch-post-selected p {
    padding: 0;
  }

  .sch-draft-post-wrpper.pxl-draftSingle-pageWrapper .sch-draft-post-flex {
    display: flex;
  }

  .sch-draft-post-wrpper.pxl-draftSingle-pageWrapper .sch-draft-post-flex .sch-drft-post-right-sec .cun-headerSelect-wrap .cun-AllChecknoxSelect {
    margin: 0;
  }

  .sch-draft-post-wrpper.pxl-draftSingle-pageWrapper .sch-draft-post-flex .sch-drft-post-right-sec {
    display: flex;
  }

  .sch-draft-post-wrpper.pxl-draftSingle-pageWrapper .sch-draft-post-flex .sch-drft-post-right-sec .cun-headerSelect-wrap {
    display: flex;
    margin: 0;
    gap: 10px;
  }

  .sch-draft-post-wrpper.pxl-draftSingle-pageWrapper .sch-draft-post-flex .sch-drft-post-right-sec .cun-headerSelect-wrap .cun-headreSelect {
    width: 140px;
    max-width: 140px;
    min-width: 140px;
  }

  .pxl_verification_wrapper.pxlVerify-box a.pxl-verifyBtn {
    width: 77px;
    height: 32px;
  }

  .pxl-dashboard-appWrap {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  }

  .pxl-clientMain-wrapper-box .pxl_tableBox_filter {
    /* display: block; */
  }
  .pxl-clientMain-wrapper-box .pxl_tableData {
    padding: 0;
}
.pxl-clientMain-wrapper-box .pxl_filterFeilds.cun-users-resheader .sch-search-section{
  margin: 0;
}
  .sch-schedule-main.pxlSchedule-singlepost-data .pxl-scheduleContent p {
    max-width: 200px;
    -webkit-line-clamp: 2;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .pxl-scheduleContent {
    max-width: 200px;
    min-width: 200px;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .sch-scheduleSec.sch-scheduleSecFullPage {
    width: 110px;
  }

  .pxl_scope_wrapper.pxlAddnew-member-field {
    padding: 10px 10px 15px;
  }

  .pxlPixaSupport-projectTabs .support_tabs_wrapper .pxlBack-btn {
    position: unset;
  }

  .sup_proj_setting_form.pxl-seoSetting-wrap .proj_Setting_first_flex {
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  }

  .pxl-admin-dashboard-wrapper .dash_grid {
    grid-template-columns: repeat(auto-fill, minmax(230px, 2fr));
    gap: 10px;
  }

  .pxl-teamMember-wrapper .pxl_filterFeilds.cun-users-resheader .pxl-temMember-add-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .pxl-puxaSupport-customer-wrapper .dash_head h3 {
    text-align: left;
    padding: 0;
  }

  .pxl_socialError_wrapper {
    max-width: 340px;
    margin: 0 auto;
  }

  .sch-main-menu .menu>li>a:before {
    display: none;
  }

  .pxl-ticket-status-wrap {
    padding: 15px 15px 25px 27px;
  }

  span.pxl-upload-file-text {
    display: none;
  }

  .pxl-seoSetting-wrap .proj_Setting_first_flex .pxl_input_feilds {
    max-width: 100%;
  }

  .pxl-seoSetting-wrap .proj_Setting_first_flex {
    display: inline-flex;
  }

  .pxl-dashboard-box {
    /* width: 100%; */
    margin: 0px 0px 15px;
  }

  .pxl_social_acc_card {
    max-width: 100%;
    margin: 50px 40px;
  }

  .pxl-vrandBox {
    width: 100%;
  }

  .sch-headBtn.pxl-add-new-accountBtn.cun-socialIcon .cun-show-tooltip {
    top: 55px;
  }

  .pxl-brand-profile-wrap:after {
    width: 100%;
    left: 0;
  }

  .cun-Heading {
    padding: 0 0 20px;
  }

  .pxl-templates-status-bar .cun-AllChecknoxSelect {
    margin: 0 0 10px;
  }

  /* .cun-headreSelect {
    z-index: unset;
  } */
  .pxl_scope_wrapper.pxlAddnew-member-field .pxl_fields {
    display: block;
  }

  .pxl_scope_wrapper.pxlAddnew-member-field .pxl-memberWrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin: 10px 0 0 5px;
  }

  .pxl_scope_wrapper.pxlAddnew-member-field .pxl-memberWrap .check_box_wrap {
    gap: 8px;
  }

  .cun-headreSelect {
    z-index: 111;
  }

  .sch-search-row.sch-search-bg-color-white input {
    width: 100%;
  }

  .pxl-templates-status-bar .cun-headreSelect {
    margin: 0 0 10px;
  }

  .pxl_filterFeilds .sch-search-section {
    margin: 0px 0 10px;
  }

  .pxl_filterFeilds {
    display: block;
  }

  .pxl_filterFeilds.cun-users-resheader .cun-headreSelect {
    margin: 10px 0px;
  }

  .pxl_filterFeilds.cun-users-resheader .sch-search-section .sch-search-row input {
    width: 100%;
  }

  .pxl_filterFeilds.cun-users-resheader .sch-search-section {
    margin: 0px 0px 10px;
  }

  .sch-drft-post-right-sec .cun-headerSelect-wrap .cun-headreSelect {
    padding: 0;
  }

  .cun-noDesign-inner {
    padding: 70px 0px;
  }

  .pxl_social_acc_card .pxl_btn_wrap button {
    margin: 0 auto;
  }

  .pxl_social_acc_users ul {
    justify-content: center;
  }

  .cun-AllChecknoxSelect {
    margin: 0 0 10px;
  }

  .pxl_scope_wrapper.pxlAddnew-member-field .check_box_wrap .cun-AllChecknoxSelect {
    margin: 0;
  }

  .cun-headreSelect {
    padding: 0;
  }

  .cun-headerSelect-wrap {
    margin: 0 0 10px;
    display: block;

  }

  .sch-myDesignFlex .cun-headerSelect-wrap {
    display: flex;
  }

  .pxl_user_info_box_row {
    padding: 15px 10px 25px;
  }

  .pxl_tab_style .nav-tabs {
    grid-gap: 20px;
  }

  .pxl_logo_auth h3 br {
    display: none;
  }

  .fullPage .pxl_uploadwrapper a.sch-btn.pxl-button-disable {
    margin: 0 0 12px;
  }

  .fullPage .pxl_uploadwrapper a.sch-btn {
    margin: 0 0 12px;
  }

  .sch-menu-btn span>span {
    height: 2px;
  }

  .sch-menu-btn {
    width: 38px;
    height: 36px;
  }

  .pxl_planHolder {
    width: 265px;
    display: inline-block;
  }

  .sch-draft-post-flex>h2 {
    font-size: 22px;
  }

  .sch-drft-post-right-sec {
    display: block;
  }

  .sch-draft-post-flex {
    display: block;
  }

  .sch-draft-post-flex>h2 {
    padding: 0 0 13px 0;
  }

  .sch-post-selected p {
    padding: 10px 0 10px;
  }



  .sch-schedulePreview {
    padding: 0 0 0 45px;
  }

  /* Apidoc */
  .api_Leftsection {
    left: -245px;
  }

  .api_page_wrapper {
    margin-left: 0;
  }

  .api_mobile_header_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 20px;
    background-color: var(--whiteColor);
    position: sticky;
    top: 0;
    z-index: 99;
  }

  .api_mobile_header_wrap .api_logo_wrap {
    margin: 0;
  }

  .api_Leftsection.show_folder_sidebar {
    left: 0;
  }

  .max-402 {
    max-width: 100%;
  }

  .install_script_wrap h5 {
    padding: 0;
  }



  .sup_proj_Color .pu_color_picker_wrapper {
    max-width: 100%;
  }

  .pxl-accodian-sub-category>h2 {
    max-width: 500px;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .sch-draft-post-flex h2 {
    max-width: 350px;
  }
  .bodercolor {
    max-width: 160px;
    bottom: 0;
}
}

@media(max-width:634px) {
  .pxl-brandName>h2 {
    width: 300px;
  }
}

@media(max-width:581px) {
  .pxl_loginForm {
    max-width: 450px;
  }

  .proj_Setting_first_flex .pxl_input_feilds {
    max-width: 100%;
  }

  .lower_proj_settings .proj_Setting_first_flex {
    flex-direction: column;
  }

  .support_tabs_wrapper {
    justify-content: flex-start;
  }

  /* .support_tabs_wrapper .support_tab_inner>ul>li>a:before {
    bottom: 20px;
    left: -100%;
    width: 30px;
    transform: rotate(90deg);
  } */

  /* .support_tab_inner ul {
    flex-direction: column;
  } */

  .support_tab_inner {
    width: 100%;
  }


}

@media(max-width:585px) {
  .pxlBack-btn {
    position: unset;
  }
}

@media(max-width:580px) {
  .pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .cun-headreSelect {
    min-width: 130px;
}
.pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .css-b62m3t-container {
  width: 130px !important;
}
.pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .dash_head .sch-search-section {
  margin: 0;
  width: 130px;
}
  .cun-noDesign-inner>h2 br {
    display: none;
}
  .pxl_input_feilds.pxlprofile-imgUpload {
    width: 100%;
}
  .sch-uncategorized-mainwrapper-box .cun-uncategorizedWrapper .cun-headerSelect-wrap .cun-headreSelect {
    min-width: 120px;
}
  .pxl-teamMember-wrapper .pxl_filterFeilds {
    justify-content: flex-start;
}
  .pxl-dashboard-trendingTemplates {
    padding: 30px 0 30px 0;
  }

  .sch-count-cards.pxlBanner-countCard-index {
    padding: 20px 20px 0;
  }

  .pxl-schedular-dash-recent-draft.pxl-recentDraft-indexBox .sch-divide-flex.sch-divide-flex-res.sch-section-bottom-space {
    padding: 10px 0 30px;
  }

  .pxl-schedular-dash-recent-schedular.pxl-recent-schdule-indexBox .sch-divide-flex.sch-divide-flex-res.sch-section-bottom-space {
    padding-top: 10px;
  }

  .pxl-dashboard-appWrap {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .sch-draft-post-flex h2 {
    max-width: 250px;
    padding: 0;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .pxl-scheduleContent {
    max-width: 160px;
    min-width: 160px;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .pxl-scheduleContent p {
    max-width: 160px;
  }

  .cun-show-tooltip {
    display: none;
  }

  .pxlBuilder-mainBox .dash_head {
    display: block;
  }

  .pxl-brandName>h2 {
    width: 260px;
  }

  .pxlBrand-mainWrapper-box .pxl-brand-btn button.sch-btn-iconLeft {
    margin: unset;
  }

  .pxlBrand-mainWrapper-box .pxl-flex-divide.pxl-brand-header-bottom {
    display: block;
  }

  .pxl-clientMain-wrapper-box .pxl_tableBox_filter .pxl_tableData {
    padding: 0 0 10px;
  }

  .pxl-clientMain-wrapper-box .pxl_tableBox_filter {
    display: block;
  }

  .pxl-clientMain-wrapper-box .pxl_tableBox_filter .pxl_filterFeilds.cun-users-resheader .pxl-addNew-client-btn {
    justify-content: flex-start;
  }

  .pxl_pageTitle h2 {
    font-size: 22px;
  }

  .pxl-teamMember-wrapper .pxl_filterFeilds.cun-users-resheader .pxl-temMember-add-btn {
    justify-content: flex-start;
  }

  .pxl_tableBox_filter.pxlTeamMemberHead {
    display: block;
  }

  .pxl-pixaSupport-mainWrapper .pxl-projectMainWrapper .dash_head {
    display: block;
  }

  .pxl_social_acc_users.pxl_socialAccountWrap .cun-socialIcon.pxl-tooltip-button-form.pxl-deleteToltip .cun-show-tooltip {
    right: -20px;
    width: 80px;
    top: -100px;
    left: auto;
  }

  .pxl_social_acc_users.pxl_socialAccountWrap .cun-socialIcon.pxl-tooltip-button-form.pxl-reload-tooltip.pxl-tooltip-btn-diable .cun-show-tooltip {
    width: 80px;
    top: -100px;
    left: 40px;
  }

  .pxl_socialError_wrapper h5 {
    font-size: 16px;
  }

  .sch-banner-wrapper .sch-container-fluid {
    padding: 0px 20px 0px;
  }

  .pxl-dashboard-app-content>p {
    font-size: 16px;
  }

  .single_dash_box .dah_color_box {
    width: 60px;
    height: 60px;
  }

  .pxl-dashboard-app-img {
    width: 50px;
    height: 50px;
  }

  .pxl-dashboard-box-right>svg {
    width: 15px;
    height: 15px;
  }

  .pxl-dashboard-app-img svg {
    width: 22px;
    height: 22px;
  }

  .pxl-dashboard-box {
    padding: 10px 10px;
  }

  .pxl-dashboard-grid-curve .single_dash_box img {
    max-width: 30px;
  }

  .pxl_dash_box_content>h2 {
    font-size: 22px;
  }

  .pxl_dash_box_content>p {
    font-size: 14px;
  }

  .support_mail_verified_box>div>h4>span {
    font-size: 18px;
  }

  .support_mail_verified_box h4 {
    font-size: 15px;
  }

  .support_mail_verified_box {
    display: block;
  }

  .suppprt_mail_verified_box_btns {
    justify-content: unset;
    margin: 20px 0 0 0;
  }

  .pxl_imgHolder {
    width: 35px;
    height: 35px;
  }

  .sch-btns-wrap .sch-headBtn button.sch-btn-iconLeft {
    height: 35px;
  }

  .pxl-doaminAdd-input .pxl_input_feilds input {
    padding: 10px 102px 10px 10px;
  }

  .pxl-admin-dashboard-wrapper .dash_grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 2fr));
  }

  /* .dash_grid.pxl-dashboard-grid-curve .single_dash_box {
  margin: 0 0 15px 0;
} */
  .pxl-headingTop>h2 {
    font-size: 22px;
  }

  .pxl-brandImg {
    width: 50px;
    height: 50px;
  }

  .pxl-brandImg img {
    width: 50px;
    height: 50px;
  }

  .dash_grid .single_dash_box {
    border-radius: 10px;
    padding: 10px;
  }

  .cun-socialIcon.pxl-social-tooltip1 .cun-show-tooltip {
    top: -100px;
    transform: translate(-8px, 0px);
    font-size: 11px;
    width: fit-content;
  }

  .cun-socialIcon.pxl-social-tooltip2.cun-socialIcon .cun-show-tooltip {
    transform: translate(5px, 0px);
  }

  .cun-account-settingFlex {
    display: block;
  }

  .pxl_tab_style .nav-tabs button {
    padding: 5px 0px 10px;
  }


  .sch-resLogo a img {
    max-width: 140px;
  }

  .sch-header-col {
    min-height: auto;
  }

  .sch-schedulePreview {
    padding: 0 0 0 25px;
  }

  .sch-drft-post-right-sec {
    display: block;
  }


  .sch-draft-post-flex {
    padding: 0 0 20px 0;
  }

  .sch-scheduleCalender a {
    font-weight: 500;
    display: -webkit-box;
    width: 90px;
    max-width: 100%;
    height: 20px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pxl_user_info_box {
    background: none;
    margin: 20px 10px;
    border: none;
  }

  .pxl_user_info_box_row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pxl_tab_section {
    padding: 30px 0 0px;
  }

  .pxl_profile_row {
    padding: 0 20px 20px;
  }

  .pxl_socialError_wrapper.secondary_Alert {
    top: 106px;
  }

  .api_folder_wrap {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }

  .api_form_box .api_input_box {
    flex-wrap: wrap;
  }

  .api_form_box .api_input_box>div:first-child {
    margin-bottom: 5px;
  }

  thead .headers .api_add_field {
    right: 0;
  }

  .headers span {
    padding: 0 8px;
  }

  .sch-schedular-heading-name>h2 {
    margin: 0;
  }

  .sch-schedular-heading-name h2 {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  .pxl-widget-appearance-btn {
    margin: 10px 0 0;
  }

  .pxl_social_container.pxl-social-integration-wrapper .pxl_social_platforms {
    margin: 30px 0 0 0;
  }

  .pxl-dashboard-box {
    width: 185px;
  }

  .pxl-apps-heading h2 {
    padding: 20px 0 20px;
  }

  .pxl-dashboard-appbox {
    padding: 10px 20px 10px 10px;
  }

  .pxl-dashboard-box-right {
    display: none;
  }
}

@media(max-width:550px) {
  .sch-draft-post-wrpper.pxl-draftSingle-pageWrapper .sch-draft-post-flex .sch-drft-post-right-sec .cun-headerSelect-wrap .cun-headreSelect {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
    margin: 10px 0 0 0;
  }

  .sch-draft-post-wrpper.pxl-draftSingle-pageWrapper .sch-draft-post-flex {
    display: block;
  }

  .sch-draft-post-wrpper.pxl-draftSingle-pageWrapper .sch-draft-post-flex .sch-drft-post-right-sec {
    display: block;
  }

  .sch-draft-post-wrpper.pxl-draftSingle-pageWrapper .sch-draft-post-flex .sch-drft-post-right-sec .cun-headerSelect-wrap {
    display: flex;
  }
}

@media(max-width:480px) {
  .pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .cun-headreSelect {
    min-width: 160px;
}
.pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .css-b62m3t-container {
  width: 160px !important;
}
  .pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .dash_head .pxl_filterFeilds {
    justify-content: flex-start;
}
.pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .dash_head .sch-search-section {
  width: 160px;
}
  .pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .pxl_filterFeilds .cun-headreSelect{
    width: auto;
  }
  .pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .dash_head .pxl_filterFeilds {
    padding: 15px 0 0 0;

}
.pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .dash_head{
  display: block;
}
  .pxl-pixasupport-dashboard {
    padding: 30px 20px 20px !important;
}
  main.sch-container-fluid.pxl-pixasupport-dashboard .dash_head {
    padding: 0px 0 20px;
}
  .ptw_chat_box_wrapper {
    width: 260px !important;
}
.ptw_chat_toggle {
  width: 45px!important;
  height: 45px!important;
}
.ptw_chat_toggle svg {
  width: 15px;
  height: 15px;
}
.ptw_chat_toggle>.ptw_chat_bubble_arrow{
  border-top: 15px solid transparent !important;
}
.ptw_chat_box_wrapper>.ptw_chat_box_inner>.ptw_cb_header {
  padding: 5px 14px 5px !important;
}
.ptw_chat_box_wrapper>.ptw_chat_box_inner>.ptw_login_box, .ptw_chat_box_wrapper>.ptw_chat_box_inner>.ptw_offline_box {
  padding: 15px 15px 20px!important;
}
.ptw_chat_box_wrapper>.ptw_chat_box_inner>.ptw_login_box>p, .ptw_chat_box_wrapper>.ptw_chat_box_inner>.ptw_offline_box>p {
  margin: 5px 0 10px !important;
}
  .bodercolor {
    max-width: 120px;
}
  .pxl-teamMember-wrapper .sch-search-section button.sch-search-btn {
    display: none;
}
.pxl-teamMember-wrapper .sch-search-section .sch-search-row input {
  padding: 0px 20px 3px 20px;
}
  .pxl-teamMember-wrapper .sch-search-section {
    max-width: 150px;
}
  .pxl_mainWrapper.sch-nav-open.pxl-pixaSupport-settingTabs .support_tabs_wrapper {
    margin: 30px 0 0px 0;
  }

  .support_tab_inner {
    border-bottom: 2px solid transparent;
  }

  .support_tab_inner ul li.active a {
    color: #ffffff !important;
  }

  .pxl_socialError_wrapper {
    top: -89px;
  }

  .pxl-profile-wrapBox .pxl_tab_style .nav-tabs button:before {
    display: none;
  }

  .pxl-profile-wrapBox .pxl_tab_style .nav-tabs button {
    padding: 5px 0px 10px;
    background-color: transparent !important;
    color: #8417af;
    border-radius: 5px;
    padding: 6px 8px;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid #8417af;
  }

  .pxl-profile-wrapBox button.nav-link.active {
    background-color: #8417af !important;
    color: #ffffff;
    border-color: #8417af;
  }

  .pxl-profile-wrapBox .pxl_tab_style .nav-tabs {
    grid-gap: 5px;
  }

  .pxl_mainWrapper.sch-nav-open.pxl-pixaSupport-settingTabs {
    padding: 30px 0 0;
  }

  .pxl-responsiveTabs li {
    padding: 6px 8px !important;
    background: transparent;
    border-radius: 5px;
  }

  .pxl-responsiveTabs li {
    background-color: transparent;
    color: #8417af;
    border: 1px solid #8417af;
  }

  .pxl-responsiveTabs li.active {
    background-color: #8417af;
  }

  .pxl-responsiveTabs li a {
    color: #8417af !important;
    padding: 0 !important;
    font-size: 15px !important;
  }

  .support_tabs_wrapper .support_tab_inner>ul>li>a:before {
    display: none;
  }

  .pxl_mainMenu>ul {
    height: calc(100vh - 230px) !important;
    min-height: calc(100vh - 230px) !important;
  }

  .sch-draft-post-wrpper.pxl-draftSingle-pageWrapper .sch-draft-post-flex .sch-drft-post-right-sec .cun-headerSelect-wrap {
    display: block;
  }

  .sch-draft-post-wrpper.pxl-draftSingle-pageWrapper .sch-draft-post-flex .sch-drft-post-right-sec .cun-headerSelect-wrap .cun-headreSelect {
    width: 210px;
    max-width: 210px;

    min-width: 210px;
  }

  .pxl_profile_wrapperr.pxl-profile-wrapBox .pxl_user_info h4 {
    margin: 0 auto 10px !important;
  }

  .pxl-verify-toggle>a {
    margin: 0 auto;
  }

  .pxl_tab_section.pxlProfileTab .pxl_input_feilds.pxl_input_sm {
    margin: 20px 0 20px;
  }

  .pxl_tab_section.pxlProfileTab .pxl_profile_field_wrap {
    margin: 0px;
  }

  .pxl_profileDetail ul {
    bottom: 50px;
  }

  .pxl_verification_wrapper.pxlVerify-box>p {
    width: 225px;
  }

  .pxl-upComing-box {
    padding: 0;
  }

  .pxl-dashboard-app-img {
    width: 40px;
    height: 40px;
  }

  .pxl-dashboard-appWrap {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .pxl-dashboard-box {
    width: 155px;
    display: block;
    text-align: center;
    padding: 30px 10px;
    margin: 0;
  }


  .sch-schedule-main.pxlSchedule-singlepost-data .sch-draft-post-flex h2 {
    padding: 0 0 10px;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .sch-draft-post-flex {
    display: flex;
    justify-content: space-between;
    display: block;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .sch-custom-check-box.cun-selectAll {
    position: absolute;
    left: 10px;
    top: 10px;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .sch-scheduleSec.sch-scheduleSecFullPage {
    width: 200px;
    margin: 0 auto;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .sch-scheduleSecFullPage .sch-schedulePost-heading h2 {
    max-width: 200px;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .pxl-scheduleContent {
    max-width: 100%;
    min-width: 100%;
    margin: 0 auto;
  }

  .sch-schedule-main.pxlSchedule-singlepost-data .pxl-scheduleContent p {
    max-width: 250px;
    margin: 0 auto 10px;
  }

  .pxl_scope_wrapper.pxlAddnew-member-field .pxl-memberWrap {
    gap: 10px;
  }

  .sup_script_text p {
    font-size: 14px;
  }

  .sch-container-fluid.pxlPixaSupport-projectTabs {
    padding: 40px 20px 20px;
  }

  .support_tabs_wrapper.pxlBuilderTabs {
    margin: 30px auto 20px;
  }

  .pxl-brandName>h2 {
    width: 200px;
  }

  .pxl-admin-dashboard-wrapper .pxl-count-cards {
    padding: 0 0 10px;
  }

  .pxl_pageTitleInner>h2 {
    font-size: 18px;
  }

  h2.accordion-header>button>.pxl-accordian-header>h2 {
    font-size: 14px;
  }

  .sch-draft-post-title>h2 {
    max-width: 100%;
  }

  .pxl_mainWrapper.pxl-admin-dashboard-wrap .pxl_pageTitle {
    margin: 0px 0px 20px;
  }

  .pxl_socialError_wrapper .pxl-social-error-innerBox {
    padding: 10px 10px;
  }

  .pxl-seo-uploadFile .pxl-custom-file-select .pxl-custom-file-select-button img {
    top: 1px;
  }

  .sup_proj_setting_form {
    padding: 20px;
  }

  .widget_type_btn .widget_btn {
    height: 34px;
  }

  .support_mail_verified_box h4 {
    font-size: 14px;
  }

  .support_mail_verified_box>div>h4>span {
    font-size: 16px;
    font-weight: 500;
  }

  .support_mail_verified_box {
    padding: 10px 10px;
  }

  .support_widget_type_wrap .widget_type_btn {
    height: 50px;
    padding: 0px 10px;
  }

  .pxl_input_feilds>input {
    font-size: 14px;
  }

  .pxl-seo-uploadFile .pxl-custom-file-select .pxl-custom-file-select-button img {
    width: 40px;
    height: 40px;
  }

  .pxl-domain-instructions>ul>li {
    font-size: 15px;
  }

  .pxl_imgHolder {
    width: 28px;
    height: 28px;
    font-size: 12px;
  }

  .pxl_headerWrapper .sch-main-menu {
    max-width: 180px;
  }

  .pxl_social_platforms ul li a {
    border-radius: 10px;
  }

  /* .cun-socialIcon.pxl-tooltip-button-form.pxl-tooltip-btn-diable .cun-show-tooltip {
    transform: translate(-115px, 0px);
  }

  .cun-socialIcon.pxl-tooltip-button-form.pxl-tooltip-btn-diable .cun-show-tooltip:before {
    left: auto;
    right: 30px;
  } */

  .pxl_social_no_users h4 {
    font-size: 18px;
  }

  .pxl_logo.cun-sidebarLogo {
    max-width: 130px;
    border-radius: 5px;
    margin: 20px 0px 20px;
    padding: 10px 20px;
  }

  .pxa_confirmImg img {
    width: 50px;
    height: 50px;
  }

  .pxl_model_body.pxl-scdulePost-model .pxa_textContent h4 {
    font-size: 22px;
  }

  .sch-model-tab-header a.nav-link.active .sch-social-model-wrap a span {
    display: none;
  }

  .schLinkdin-social ul.sch-social-review-sec li a span {
    display: none;
  }

  ul.sch-social-review-sec li a span {
    display: none;
  }

  .sch-model-tab-header .nav-pills .nav-link {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 0;
  }

  .sch-schedule-mainRes {
    padding: 0px 0px 0px 10px;
  }

  .sch-model-tab-header {
    border-radius: 10px;
  }

  .pxl_user_info_box_row {
    display: block;
  }

  .pxl_user_info {
    text-align: center;
  }

  .pxl_profile_row.pxl_tab_style .pxl_profile_row .pxl-custom-file-upload .pxl-custom-file-select {
    height: auto;
  }

  .pxl_filterFeilds .cun-headreSelect {
    padding: 0px;
    width: 100%;
  }

  .pxl_profile_container {
    border-radius: 7px;
  }

  .pxl_user_img {
    width: 50px;
    height: 50px;
    margin: 0 0 10px 0;
  }

  .pxl_user_img_icon img {
    width: 50px;
    height: 50px;
  }

  .pxl_user_info_box {
    background: none;
    border: none;
  }

  .pxl_profile_wrapperr {
    border-radius: 10px;
  }

  ul.sch-apps-flex {
    justify-content: flex-start;
  }

  ul.sch-apps-flex>li>a>img {
    width: 40px;
    height: 40px;
  }

  .pxl_pageTitle {
    margin: 0;
  }

  .pxl_rightBar_assets {
    padding: 30px 30px;
  }

  .pxl_tablePagination button {
    margin: 20px auto 0;
  }

  .sch-pupup-flex {
    display: block;
  }

  .pxl_sidebarMenu {
    width: 250px;
  }

  .sch-menu-btn span>span {
    width: 15px;
  }

  .sch-menu-btn {
    width: 33px;
    height: 33px;
  }

  .sch-draft-post-flex>h2 {
    font-size: 20px;
    font-weight: 600;
  }

  .pxl_autoresponder_list ul li a img {
    max-height: 25px;
    margin: 0 5px 0 0;
  }

  .pxl_autoresponder_list ul {
    grid-gap: 10px;
  }

  .pxl_autoresponder_list ul li a {
    padding: 10px 15px;
  }

  .pxl_tablePagination ul {
    margin: 10px 0 0;
    justify-content: flex-start;
  }

  .pxl_tablePagination {
    display: block;
    /* text-align: center; */
  }

  .pxl_statusFilter {
    margin: 0px 0px 10px;
  }

  .pxl_filterFeilds .sch-search-section {
    margin: 0px 0px 10px;
    width: 100%;
  }

  /* .pxl_filterFeilds {
    display: inline-flex;
  } */

  .pxl_filterFeilds .sch-search-section .sch-search-row.sch-search-bg-color-white input {
    width: 100%;
  }

  .pxl_statusFilter {
    min-width: 100%;
  }

  .schLinkdin-social ul.sch-social-review-sec {
    padding: 0px;
    margin: 9px 10px 3px;
  }

  .sch-scheduleSec {
    display: block;
    width: 100%;
  }

  .sch-schedule-post-row {
    display: block;
    height: auto;
    padding: 20px 20px;
    text-align: center;
  }

  .sch-schedulePreview a {
    display: block;
  }

  .sch-schedulePreview {
    padding: 0;
    margin: 0px 5px;
  }

  .sch-scheduleSecRight {
    justify-content: center;
  }

  .sch-scheduleCalender {
    padding: 0;
    width: 40px;
    height: 40px;
    background-color: #0000001c;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin: 0px 5px;
  }

  .sch-schedulePost-heading h2 {
    display: block;
    height: auto;
    width: 100%;
    padding: 10px 0px;
    color: #4F536F;
    font-weight: 500;
  }

  .sch-scheduleSec {
    display: block;
  }

  .sch-scheduleCalender a {
    width: 100%;
  }

  .sch-scheduleCalender a span.sch-scheduleDate-text {
    display: none;
  }

  .sch-scheduleImage>img {
    width: 100%;
    height: 100%;
  }

  ul.sch-social-review-sec li a {
    width: auto;
  }

  .sch-scheduleImage {
    padding: 0px 10px 0px 5px;
    margin: 0 auto;
  }

  .sch-schedulePreview a span.sch-eyeSvg svg {
    width: 18px;
    height: 15px;
    /* fill: #4F536F; */
  }

  .sch-schedulePreview a span.sch-eyeSvg {
    padding: 0;
    width: 40px;
    height: 40px;
    background-color: #0000001c;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }

  .pxl_form {
    padding: 50px 30px 30px;
  }

  .pxl_createAccount-sec>h2>span {
    padding: 0;
    display: block;
    width: 100%;
    margin: 0;
    line-height: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .pxl_socialLogin_btn {
    padding: 0 15px;
  }

  .pxl_createAccount-sec>h2 {
    font-size: 16px;
  }

  .pxl_socialLogin_btn {
    border-radius: 10px;
    height: 50px;
  }

  .pxl_social_platforms ul {
    justify-content: center;
  }

  .pxl_social_platforms ul li a span img {
    width: 40px;
    height: 40px;
  }

  .pxl_social_platforms ul li a {
    width: 60px;
    height: 60px;
  }

  .pxl_social_acc_card {
    padding: 30px 10px 30px;
    margin: 30px 20px 0;
  }

  .pxl_social_acc_card>h4 {
    font-size: 20px;
  }

  .pxl_autoresponder_content {
    padding: 30px 30px 30px;
  }

  .fullPage .pxl-custom-file-upload .pxl-custom-file-select .pxl-custom-file-select-name span {
    display: none;
  }

  .pxl_pageTitle h2 {
    font-weight: 600;
    font-size: 20px;
  }

  .sch-submenubox>ul>li>a>span img {
    /* width: 12px;
    height: 12px; */
  }

  .fullPage .sch-library-header h2 {
    font-size: 18px;
  }

  .fullPage .sch-library-header h2 {
    font-size: 18px;
    font-weight: 500;
  }

  .pxl_title_with_desc h4 {
    font-size: 18px;
  }

  .pxl_user_info_box_row {
    padding: 15px 10px 0px;
  }

  .pxl_user_info_box {
    margin: 10px 10px;
  }

  .pxl_title_with_desc {
    margin: 0;
  }

  .pxl_profile_wrapperr {
    margin-top: 0;
  }

  .pxl_sidebarMenu {
    padding: 10px 20px 30px;
  }

  .agents_list_wrap {
    grid-template-columns: repeat(auto-fill, minmax(266px, 1fr));
  }

  .agents_list_wrap .single_agent_wrap .agent_detail {
    max-width: 100px;
  }

  .agents_list_wrap .single_agent_wrap .img_box {
    width: 40px;
    height: 40px
  }

  .support_widget_type_wrap {
    flex-direction: column;
    gap: 0;
  }

  .proj_Setting_first_flex {
    gap: 10px;
  }

  .pxl-seo-uploadFile .pxl-custom-file-select .pxl-custom-file-select-button img {
    top: -2px !important;
  }

  .pxl_social_container.pxl-social-integration-wrapper h4.pxl_heading {
    font-size: 22px;
  }

  .pxl-dashboard-app-content>p {
    padding-left: 5px;
  }

  .pxl-dashboard-app-content>p {
    font-size: 15px;
  }

  .pxl-dashboard-appbox {
    padding: 10px 10px 10px 10px;
  }

  .pxl-upComing-box .pxl-apps-heading h2 {
    padding: 10px 0 20px;
  }

}

@media(max-width:380px) {
  .css-3dah0e-MuiModal-root-MuiDialog-root .MuiDialog-paper {
    min-width: 280px !important;
}
  .bodercolor{
    display: none;
  }
  .pxl-clientMain-wrapper-box .sch-search-row {
    width: 100%;
}
  .pxl_input_feilds.pxlprofile-imgUpload .pxl-custom-file-upload .pxl-custom-file-select .pxl-custom-file-select-name {
    height: 50px;
}
  .pxl-teamMember-wrapper .sch-search-section {
    max-width: 100%;
}
  .sch-profile-mainWrapper .pxl-custom-file-upload .pxl-custom-file-select {
    height: 50px !important;
  }

  .sch-profile-mainWrapper .pxl-custom-file-upload.pxl-upload-file-profile.pxl-profile-fileSelect .pxl-custom-file-select-name {
    padding: 0;
    top: 0px;
  }

  .pxl-verify-toggle>a {
    margin: 0 auto;
  }

  .pxl_verification_wrapper.pxlVerify-box a.pxl-verifyBtn {
    margin: 5px auto 0;
  }

  .pxl_verification_wrapper.pxlVerify-box>p {
    width: 100%;
    text-align: center;
  }

  .pxl-verifyMain-wrap {
    left: 0;
    right: 0;
    margin: 0px 30px;
    top: -98px;
  }

  .pxl_verification_wrapper {
    display: block;
  }

  .pxl-dashboard-box-right {
    display: block;
  }

  .pxl-dashboard-appWrap {
    display: block;
  }

  .pxl-dashboard-appbox {
    margin: 0 0 10px 0;
  }

  .pxl-dashboard-box {
    width: 125px;
    padding: 20px 10px;
  }

  .pxl-dashboard-app-img {
    width: 40px;
    height: 40px;
  }

  .pxl-clientMain-wrapper-box .sch-search-section.sch-searchButton-wrap {
    display: block;
  }

  .pxl-addNew-client-btn {
    margin: 10px 0 0;
  }

  .pxl-brandName>h2 {
    width: 140px;
  }

  .pxl_profile_row.pxl_tab_style .pxl-custom-file-upload .pxl-custom-file-select {
    height: auto;
    padding: 3px 0px;
    display: flex;
    align-items: center;
  }

  .pxl_profile_row.pxl_tab_style .pxl-custom-file-select-button img {
    width: 35px !important;
    height: 35px !important;
  }

  .pxl_profile_row.pxl_tab_style .pxl-custom-file-select-name {
    height: auto;
    display: -webkit-box;
    max-width: 100%;
    line-height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80px;
  }

  .sch-accountName {
    width: 150px;
  }

  .sch-accountName span.pxl_social_username {
    width: 160px;
  }

  .sch-trash-tabs-wrapper .nav-tabs .nav-link {
    padding: 10px 10px 14px;
  }

  .pxl_mainMenu>ul>li>ul.pxl_submenu {
    margin: 0px 0px 15px 20px;
  }

  .pxl_loginForm {
    max-width: 290px;
  }

  .pxl_socialLogin_btn span img {
    width: 20px;
  }

  .pxl_socialLogin_btn {
    font-size: 14px;
  }

  .sup_dash_wrap {
    padding: 0 10px;
  }

  .agents_list_wrap {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  }

  .agents_list_wrap {
    padding: 0;
  }

  .proj_domai_set_form {
    padding: 0;
  }
}

.preview_note {
  padding: 21px;
  text-align: center;
}

.dots_menu img {
  width: 30px;
}












.sch-tooltipWrap a {
  position: relative;
}

.sch-tooltipShow {
  position: absolute;
  top: -40px;
  background-color: #11122E;
  line-height: normal;
  color: #fff;
  padding: 3px 15px;
  border-radius: 4px;
  text-align: center;
  transition: all linear 0.1s;
  opacity: 0;
  visibility: hidden;
  width: fit-content;
  z-index: 999;
}

.sch-tooltipShow:before {
  content: "";
  position: absolute;
  bottom: -7px;
  width: 7px;
  height: 7px;
  left: 10px;
  opacity: 0;
  visibility: hidden;
  border-top: 6px solid #000;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  transition: all linear 0.1s;

}

.sch-tooltipShow p {
  white-space: nowrap;
  color: #fff;
}

.sch-tooltipWrap:hover .sch-tooltipShow {
  opacity: 1;
  visibility: visible;
}

.sch-tooltipWrap:hover .sch-tooltipShow:before {
  opacity: 1;
  visibility: visible;
}



/* Pixastock admin css */
.sch-draft-box span.sch-audioPlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sch-draft-box span.sch-audioPlay a {
  background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  width: 40px;
  height: 40px;
  display: flex;
  animation: c;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

/* .sch-draft-box span.sch-audioPlay a svg { */
/* margin: 0px 0 0 5px; */
/* } */

.sch-draft-box span.sch-audioPlay.sch-audioPause a svg {
  margin: 0;
}

.sch-draft-box video {
  height: 110px;
  width: 100%;
}

.sch-draft-box>img {
  width: 100%;
  /* max-width: 100px; */
  /* height: 100px; */
  background-size: cover;
  object-fit: cover;
  border-radius: 5px;
}

.sch-draft-box span.sch-audioPlay a {
  background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  width: 30px;
  height: 30px;
  display: flex;
  animation: c;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

/* admin pixastock css start */
/* .cun-adminPixastockWrap .sch-draft-box .sch-draft-img-box > img {
  max-height: 100px;
  min-height: 100px;
  object-position: center;
}
.cun-adminPixastockWrap .sch-draft-box .sch-draft-img-box > video{
  max-height: 90px !important;
  min-height: 90px !important;
  height: 90px !important;
}
.pxl-assets-row.cun-adminPixastockWrap > .sch-draft-box > .sch-draft-img-box > video {
  height: 100px !important;
} */


.pxl-assets-row.cun-adminPixastockWrap .sch-draft-box>video {
  height: 165px !important;
  background-color: #f4e9fd;
  border-radius: 10px;
}

.pxl-assets-row.cun-adminPixastockWrap .sch-draft-box>audio {
  height: 165px;
}

.pxl-assets-row.cun-adminPixastockWrap>.sch-draft-box>.sch-audioWrpa {
  height: 165px;
}

.sch_hide_checkbox {
  opacity: 0;
  transition: all .3s;
}

.sch-draft-box:hover .sch_hide_checkbox {
  opacity: 1;
}

/* .sch-draft-box  */
.active_post {
  box-shadow: 0 0 0 2px var(--sch-primary-color), inset 0 0 0 2px #fff !important;
}

.img_preview_box {
  text-align: center;
  background-color: #f4e9fd;
  padding: 10px;
}

.three_dot_bg {
  width: 12px;
  height: 26px;
  background: rgba(0 0 0 / 5%);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .03s;
}

.three_dot_bg:hover {
  background: var(--sch-border-color1);
}

.pxl-metaDetails-popup .modal-dialog.modal-xl.modal-dialog-centered {
  max-width: 1380px;
}

.pxl_edit_toggle .pxl-custom-toggle-btn .slider {
  cursor: not-allowed;
}

.pxl_edit_toggle ul li .pxl_dis_btn {
  cursor: not-allowed;
  opacity: 0.5;
}

.pxl-login-inputBox {
  position: relative;
}

.pxl-login-boxBottom {
  position: absolute;
  z-index: 1;
  bottom: -50px;
  left: 0;
  right: 0;
}

.pxl-login-boxBottom>h2 {
  font-size: 13px;
  font-weight: 400;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border: 1px solid #494949;
  padding: 10px 10px;
  border-radius: 3px;
  position: relative;
  width: 100%;
}

/* .pxl-login-boxBottom h2:after {
  content: '';
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #989b9e;
  left: 20px;
  top: -6px;
} */
.pxl-login-boxBottom>h2>span {
  position: relative;
  top: -2px;
  padding: 0px 10px 0px 0px;
}

.pxl-login-boxBottom>h2>span>img {
  width: 15px;
  height: 15px;
}

.pxl-login-boxBottom>h2>img {
  position: absolute;
  top: -6px;
  width: 11px;
  left: 17px;
}