
@media(max-width:1800px){}
@media(max-width:1500px){}
@media(max-width: 1366px){
  /* .pxl_pageTitle {
    margin: 65px 0 30px;
  } */
  .sch-draft-post-wrpper {
    padding: 0px 0 80px;
}
}
@media(max-width:1200px){
  
}

@media(min-width: 992px) {
  .pxl-filter-mobile-btn {
    display: none;
  }
}
@media(max-width:1024px){

}
@media(max-width:991px){
    .pxl_tableBox_filter {
    display: block;
    text-align: center;
}
.pxl_table {
  padding: 10px 0 0 0;
}
.pxl_tableData {
  padding: 0 0 20px 0;
}

.sch-post-center {
    padding: 0 0px 100px 0px;
}
.sch-post-center {
    padding: 0 0px 100px 0px;
}





}
@media(max-width:767px){
   
    .sch-menu-btn span>span {
        height: 2px;
    }
   
    .user-info {
        grid-gap: 7px;
    }
    .sch-header-row .sch-header-col:last-child {
        width: auto;
    }
    .sch-btns-wrap a.sch-btn {
        padding: 8px 15px;
        font-size: 14px;
        height: auto;
    }
    .sch-btns-wrap a.sch-btn span {
        display: none;
    }
}
@media(max-width:581px){
  .pxl_loginForm {
    max-width: 450px;
}
}
@media(max-width:580px){
  
}
@media(max-width:480px){
  
    span.pxl_back_login {
        font-size: 16px;
        padding: 0;
    }
    .pxl_leftForm>span>img {
        max-width: 150px;
    }
    .pxl_loginForm {
        max-width: 380px;
    }
    .pxl_custom_form.pxl_login-input input.pxl_customInput_auth {
        height: 50px;
    }
    .pxl_form {
        padding: 50px 30px 30px;
    }
     .input_auth_box .pxl_custom_form 
    span.pxl_inputIcon.pxl_inputIcon_password {
        right: 10px;
    }
  .pxl_createAccount-sec > h2 > span {
    padding: 0;
    display: block;
    width: 100%;
    margin: 0;
    line-height: normal;
    font-weight: 600;
    font-size: 16px;
  }
  .pxl_socialLogin_btn {
    padding: 0 15px;
  }
  .pxl_createAccount-sec > h2 {
    font-size: 16px;
  }
  .pxl_socialLogin_btn {
    border-radius: 10px;
    height: 50px;
  }
  .sch-banner-select-sec {
    width: 120px;
  }
  .sch-banner-box .sch-banner-content .sch-search-box button.sch-search-btn {
    display: none;
  }

.sch-title-wrap h2 {
  font-size: 14px;
  font-weight: 600;
}
.sch-title-wrap h2:before {
  height: 2px;
}
.sch-workspace-box-fix a img {
  width: 100%;
  max-width: 40px;
}
}

@media(max-width:380px){
  .sch-banner-box .sch-banner-content .sch-search-row input {
    font-size: 12px;
}
.sch-banner-select-sec {
  width: 100px;
  font-size: 12px;
}
.css-tj5bde-Svg {
  width: 13px;
  height: 13px;
}
  .pxl_loginForm {
    max-width: 290px;
  }
  .pxl_socialLogin_btn span img {
    width: 20px;
  }
  .pxl_socialLogin_btn {
    font-size: 14px;
  }
}