.pxl_widget_preview_box {
    background: #ffffff;
    border: 1px solid #E9EDF6;
    width: 443px;
    border-radius: 10px;
    padding: 30px 30px;
}
.pxl_widget_heading1 h3{
    font-size: 18px;
    font-weight: 600;
}
/* widget preview start */
/* .ps_wa_preview {
    flex: none;
    width: 340px;
    margin-left: 10px;
    min-height: 200px;
    padding: 0 20px;
} */
.pxl_widget_scroll{
    max-height: 1080px;
    overflow-y: auto;
}
.ps_wa_preview .ps_heading{
    font-size: 18px;
    font-weight: 600;
}
.ps_wa_wrapper>.ps_wa_preview>h3 {
    margin: 0;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 500;
}

.ps_wa_preview .psw_widget_wrapper {
    left: auto !important;
}

.ps_wa_preview .psw_widget_wrapper>.psw_widget_inner {
    display: flex;
    flex-direction: column-reverse;
    /* background-color: #F6FBFC;
    padding: 5px;
    border-radius: 6px; */
}

.ps_wa_preview .psw_chat_box_wrapper {
    width: 320px;
    position: relative;
    display: block;
    margin-bottom: 15px;
    background: #F6FBFC;
    padding: 10px 10px;
    border-radius: 10px;
    margin: 0px 10px 0px 0px;
}

.ps_wa_preview .psw_chat_box_wrapper>.psw_chat_box_inner {
    box-shadow: none;
}

.ps_wa_preview .psw_chat_box_wrapper>.psw_chat_box_inner>.psw_chat_box {
    display: flex;
}

.ps_wa_preview .psw_chat_box_wrapper>.psw_chat_box_inner>.psw_offline_box,
.ps_wa_preview .psw_chat_box_wrapper>.psw_chat_box_inner>.psw_login_box {
    border-bottom: 5px solid #f5f5f5;
}

.ps_wa_preview .psw_widget_wrapper.psw_widget_right>.psw_widget_inner {
    align-items: flex-end;
}

/* widget preview end */

.psw_widget_wrapper *,
.psw_widget_wrapper *::before,
.psw_widget_wrapper *::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* scrollbar start */
.psw_cb_msg_wrapper *::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

.psw_cb_msg_wrapper *::-webkit-scrollbar-track {
    background-color: var( --pixalab-widget-color);
    border-radius: 10px;
}

.psw_cb_msg_wrapper *::-webkit-scrollbar-thumb {
    background-color: var( --pixalab-widget-color);
    border-radius: 10px;
}

.psw_cb_msg_wrapper * {
    scrollbar-color: var( --pixalab-widget-color) var( --pixalab-widget-color);
    scrollbar-width: thin;
}

/* scrollbar end */
.pxl_widget_heading1{
    margin-bottom: 15px;
}
.psw_widget_wrapper.psw_widget_left {
    left: 30px;
}

.psw_widget_wrapper.psw_widget_right {
    right: 30px;
}

.psw_widget_wrapper>.psw_widget_inner {
    position: relative;
}

/* chat toggle button start */
.psw_chat_toggle {
    width:50px;
    height: 50px;
    border-radius: 60px;
    background-color: var( --pixalab-widget-color);
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    margin: 10px 0 0;
}

.psw_chat_toggle>span {
    display: none;
}

/* .psw_chat_toggle>.psw_chat_bubble_arrow {
    content: "";
    position: absolute;
    top: 60%;
    right: 75%;
    display: block;
    border-top: 20px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 20px solid var( --pixalab-widget-color);
    -webkit-transform: rotate(-25deg);
    -moz-transform: rotate(-25deg);
    transform: rotate(-25deg);
} */

.psw_button .psw_chat_toggle {
    width: 190px;
    height: 50px;
    border-radius: 15px 15px 0 0;
    margin: 10px 14px 20px 0;
    -webkit-animation: pswchattogglebutton_anim 0.3s;
    -moz-animation: pswchattogglebutton_anim 0.3s;
    animation: pswchattogglebutton_anim 0.3s;
}

.psw_button .psw_chat_toggle>span {
    display: inline-flex;
    margin-left: 8px;
}

.psw_button .psw_chat_toggle>.psw_chat_bubble_arrow {
    display: none;
}

.psw_widget_wrapper.psw_button {
    bottom: 0;
}

/* chat toggle button end */

/* chat box start */
.psw_chat_box_wrapper {
    position: absolute;
    bottom: 100%;
    right: 0px;
    width: 300px;
    display: none;
    -webkit-animation: pswchatbox_anim 0.3s;
    -moz-animation: pswchatbox_anim 0.3s;
    animation: pswchatbox_anim 0.3s;
}

.psw_button .psw_chat_box_wrapper {
    /* bottom: 30px; */
}

.psw_chat_box_wrapper>.psw_chat_box_inner {
    background-color: #ffffff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    /* min-height: 350px; */
    width: 300px;
    position: relative;
    overflow: hidden;
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_offline_box,
.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_login_box {
    padding: 30px;
    /* max-height: 350px; */
    min-height: 350px;
    position: relative;
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_offline_box>h3,
.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_login_box>h3 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_offline_box>p,
.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_login_box>p {
    margin: 0;
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 400;
    text-transform: capitalize;
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_chat_box {
    display: none;
    flex-direction: column;
    position: relative;
    min-height: 350px;
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_cb_header {
    flex: none;
    padding: 15px 14px 14px;
    position: relative;
    background-color: var( --pixalab-widget-color);
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_cb_header>.psw_cb_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_cb_header>.psw_cb_title>h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_cb_header>.psw_cb_title .psw_cb_minimize_chat {
    width: 15px;
    height: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 2px;
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_cb_header>.psw_cb_title .psw_cb_minimize_chat::before {
    content: "";
    width: 12px;
    height: 2px;
    background-color: #ffffff;
    border-radius: 3px;
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_cb_header>.psw_cb_title .psw_cb_close_chat {
    width: 15px;
    height: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    vertical-align: middle;
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_cb_header>.psw_cb_title .psw_cb_close_chat>span {
    width: 12px;
    height: 3px;
    background-color: #ffffff;
    border-radius: 5px;
    position: absolute;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_cb_header>.psw_cb_title .psw_cb_close_chat>span:nth-child(2) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_chat_box>.psw_cb_body {
    flex: 1;
    position: relative;
}

.psw_widget_left .psw_chat_box_wrapper {
    left: 0;
    top: 0px;
    right: auto;
}

.psw_cb_msg_wrapper {
    position: inherit;
    top: 0;
    max-height: 245px;
    min-height: 240px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    padding: 15px;
    scroll-behavior: smooth
}

.psw_cb_msg_wrapper>.psw_cb_msg {
    margin-bottom: 15px;
    -webkit-animation: pscmsg_anim 0.3s;
    -moz-animation: pscmsg_anim 0.3s;
    animation: pscmsg_anim 0.3s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.psw_cb_msg_wrapper>.psw_cb_msg>.psw_cb_msg_inner {
    display: inline-block;
    max-width: 70%;
}

.psw_cb_msg_wrapper>.psw_cb_msg>.psw_cb_msg_inner>.psw_cb_msg_text {
    background-color: #f1f1f1;
    color: #222222;
    padding: 10px 12px;
    border-radius: 10px 10px 10px 0;
    font-size: 13px;
    margin: 0;
    word-break: break-word;
}

.psw_cb_msg_wrapper>.psw_cb_msg>.psw_cb_msg_inner>.psw_cb_msg_text a {
    color: inherit;
    text-decoration: underline;
}

.psw_cb_msg_wrapper>.psw_cb_msg.psw_right>.psw_cb_msg_inner>.psw_cb_msg_text a {
    color: #ffffff;
    text-decoration: underline;
}

.psw_cb_msg_wrapper>.psw_cb_msg>.psw_cb_msg_inner>.psw_cb_msg_time {
    font-size: 11px;
    margin: 0;
    margin-bottom: 2px;
}

.psw_cb_msg_wrapper>.psw_cb_msg.psw_right {
    text-align: right;
}

.psw_cb_msg_wrapper>.psw_cb_msg.psw_right>.psw_cb_msg_inner>.psw_cb_msg_text {
    background-color: var( --pixalab-widget-color);
    color: #ffffff;
    border-radius: 10px 10px 0 10px;
    margin: 0;
    display: inline-block;
    text-align: left;
}

.psw_cb_msg_wrapper>.psw_cb_msg.psw_right>.psw_cb_msg_inner>.psw_cb_msg_time {
    padding-right: 5px;
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_chat_box>.psw_cb_footer {
    flex: none;
    background-color: #ffffff;
    border-top: 1px solid #f1f1f1;
    position: relative;
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_chat_box>.psw_cb_footer>textarea.psw_cb_textarea {
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    padding: 10px;
    padding-right: 45px;
    height: 50px;
    font-size: 14px;
    background-color: transparent;
    resize: none;
    font-family: inherit;
}

.psw_chat_box_wrapper>.psw_chat_box_inner>.psw_chat_box>.psw_cb_footer>textarea.psw_cb_textarea::-webkit-input-placeholder {
    color: #cccccc;
}

.psw_cb_footer>.psw_send_message_btn {
    position: absolute;
    top: 13px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: #f1f1f1;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b1b1b1;
    padding-right: 3px;
    cursor: pointer;
}

.psw_cb_footer>.psw_send_message_btn.active {
    background-color: var( --pixalab-widget-color);
    color: #ffffff;
}

.psw_input_wrapper {
    margin-bottom: 10px;
}

.psw_input_wrapper>label {
    font-size: 14px;
    color: var(--sch-title-color);
    margin: 0;
    font-weight: 500;
    display: block;
    margin-bottom: 4px;
}

.psw_input_wrapper>.psw_input {
    width: 100%;
    border: 1px solid var(--input-border);
    background-color: var(--bg-color1);
    padding: 10px;
    border-radius: 5px;
    outline: none;
}

.psw_input_wrapper>.psw_input:focus {
    border-color: var( --pixalab-widget-color);
}

.psw_input_wrapper>textarea.psw_input {
    resize: none;
}

.psw_btn {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: var( --pixalab-widget-color);
    color: #ffffff;
    border: none;
    box-shadow: none;
    cursor: pointer;
    outline: none;
    font-size: 14px;
}

/* Error mess */
.pt_error {
    color: rgb(221, 42, 42);
    font-size: 12px;
    padding-left: 5px;
    margin: 0;
}

/* Create Ticket Css Start */
.psw_create_ticket_btn {
    position: absolute;
    bottom: 10%;
    width: 85%;
    transform: translate(-50%, 25%);
    left: 50%;
    z-index: 999;

}

.psw_create_ticket_close_btn {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #fff;
    color: var( --pixalab-widget-color);
    border: 1px solid var( --pixalab-widget-color);
    box-shadow: none;
    cursor: pointer;
    outline: none;
    margin-left: 10px;
    font-size: 13px;
}

.create_ticket_hide {
    width: 85%;
    height: 100%;
    padding: 10px;
    max-height: 300px;
    position: absolute;
    top: 10px;
    overflow: auto;
    transition: all .5s;
    transform: translate(0px, 397px);
    background-color: #fff;
}

.hide {
    display: none;
}

.show {
    transform: translate(0px, 11px)
}

.create_ticket_hide h3 {
    margin: 0 0 5px 0;
    font-size: 16px;
}

.psw_ticket {
    max-height: 245px;
    overflow: auto;
}

.psw_ticket .tickets {
    min-height: 45px;
    border-radius: 6px;
    background-color: #f9f9f9;
    margin-bottom: 10px;
    border-left: 2px solid var( --pixalab-widget-color);
    padding: 8px;
    position: relative;
    display: flex;
    align-items: center;
    word-break: break-word;
    cursor: pointer;
    margin: 10px 0 0;
}

.psw_ticket .tickets:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06), 0px 3px 6px rgba(0, 0, 0, 0.23);
}

.psw_login_box .psw_ticket h5 {
    margin: 0;
    font-size: 13px;
}

.ticket_mess_heading {
    padding: 10px 0px 10px 15px;
    display: flex;
    border-bottom: 1px solid #f1f1f1;
    align-items: baseline;
}

.ticket_mess_heading h3 {
    font-size: 13px;
    padding-bottom: 7px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 5px;
}

.ticket_mess_heading span {
    width: 22px;
    height: 22px;
    border-radius: 50px;
    background-color: var( --pixalab-widget-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex: none;
    margin: 0 8px;
}

.ticket_mess_heading span svg {
    width: 12px !important;
    height: 12px !important;
}

.ticket_mess_heading span svg path {
    fill: #fff !important;
}

/* chat box end */
/* widget module css start */

.pxl-widget-preview-box {
    width: 443px;
    background: #ffffff;
    border: 1px solid #E9EDF6;
    border-radius: 10px;
    padding: 30px;
  }
  .pxl-widget-preview-box .pxl-widget-preview-inner {
    background-color: #F6FBFC;
    border-radius: 10px;
    /* width: 330px; */
  }


  @media(max-width:1200px){
    .pxl_widget_preview_box{
        width: 100% !important;
        margin: 30px 0 0;
    }
    .psw_chat_box_wrapper>.psw_chat_box_inner {
        width: 100% !important;
    }
    .ps_wa_preview .psw_chat_box_wrapper{
        width: 100% !important;
    }
  }

  @media(max-width:480px){
    .pxl_widget_preview_box {
        padding: 20px 20px;
    }
  }