.css-19bb58m > input:focus-visible {
    padding: 0px 40px!important;
  }
  .css-19bb58m > input:focus {
    padding: 0px 40px!important;
    
  }
  input:focus-visible {
	box-shadow: 0 0 0 1px #2196f3 !important;
	border-radius: 3px; 
  }
  .css-t3ipsp-control input:focus-visible{
    box-shadow: unset !important;
  }

  .css-qr46ko{
    padding: 0px !important;
  }
  .pxl-ticket-content.pxlFullpage-ticketbox > h2 {
    padding: 0 0 5px 0;
   }
  select:focus-visible {
	box-shadow: 0 0 0 1px #2196f3 !important;
	border-radius: 3px !important;;
	outline: none;
  }
  a:focus-visible{
	box-shadow: 0 0 0 1px #2196f3 !important;
  }

  button:focus-visible{
	box-shadow: 0 0 0 1px #2196f3 !important;
  }
  /* textarea:focus{
	box-shadow: 0 0 0 1px #2196f3 !important;
  } */

  textarea:focus-visible{
	box-shadow: 0 0 0 1px #2196f3 !important;
  }
  button.sch-btn-iconLeft:focus {
    box-shadow: 0 0 0 1px #2196f3 !important;
}
.css-d7l1ni-option{
	cursor: pointer !important;
  }
  .css-tr4s17-option{
	cursor: pointer !important;
  }
  input:focus{
    box-shadow: 0 0 0 1px #2196f3 !important;
  }
  input:focus-visible{
    box-shadow: 0 0 0 1px #2196f3 !important;
  }
/* default btn css */

button.pxl-pink-btn-delete {
    cursor: pointer;
    position: relative;
    background: var(--sch-primary-color);
    padding: 4px 20px 4px 20px;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    color: var(--sch-white);
    height: 50px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-self: center;
    position: relative;
    overflow: hidden;
    border: 0;
    flex-wrap: wrap;
    text-transform: capitalize;
    background: linear-gradient(91.18deg, #FF406E 12.31%, #C02A93 159.14%);
  }
  button.pxl-pink-btn-delete:before {
    content: '';
    display: block;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
    background-size: 210% 100%;
    background-position: right bottom;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  button.pxl-pink-btn-delete:after {
    content: '';
    display: block;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
    background-size: 210% 100%;
    background-position: right bottom;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  button.pxl-pink-btn-delete > span {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 13px 0 0;
    /* background: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%); */
    background: linear-gradient(90.81deg, #FF6B8E 8.52%, #F03A76 110.1%);
    border-radius: 3px;
  }
  button.pxl-pink-btn-delete:hover:before {
    background-position: left bottom;
  }
  
  button.pxl-pink-btn-delete:after {
    background-position: right bottom;
  }
/*  */
.pxl-pink-delete-btn {
    margin: 0 10px 0 10px;
}
.pxl-support-flex-header .xs_pagetitle_wrapper.ticket_right .xs_ticket_chat_flex {
    display: flex;
    align-items: center;
    gap: 10px;
}
.pxl-support-flex-header .xs_pagetitle_wrapper.ticket_right .xs_ticket_chat_flex .xs_pagetitle_right {
    display: flex;
    align-items: center;
    gap: 10px;
}
.pxl-ticket-row > ul{
    border: 2px solid #E9EDF6;
    border-radius: 5px;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px;
}
.pxl-ticket-row > ul > li {
    width: 200px;
}
.pxl-ticket-row > ul > li:nth-child(1) {
    /* width: 40px; */
}
.pxl-ticket-row > ul > li:last-child{
    /* width: 20px; */
}
.pxl-project-row-letter {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d4dae7;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    border-radius: 6px;
}
.pxl-support-flex-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 25px;
}
/* flex divide right sec css start */
.pxl-head-rightsec {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.pxl-head-rightsec .xs_ticket_chat_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.pxl-head-rightsec .xs_ticket_chat_flex .xs_pagetitle_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}
/* flex divide right sec css end */
/* select all checkbox css */
.pxl-select-all-ticket {
    padding: 0px 10px 0px 0px;
}
.pxl-select-all-ticket.pxl-ticket-checkbox .sch-custom-check-box .form-group input:checked+label:after{
    left: 10px;
}
.pxl-select-all-ticket .sch-custom-check-box .form-group label {
    color: #161930;
    font-size: 14px;
    font-weight: 500;
}
/* selected posts */
.pxl-selected-ticket-post {
    margin: 0 24px 0px 5px;
}
.pxl-selected-ticket-post h2{
    font-size: 14px;
    font-weight: 400;
    color: #72778F;
    margin: 0;
}
/* heading css */
.pxl-support-tickets-wrap .pxl-support-head1 h3 {
    font-size: 20px;
    font-weight: 700;
    color: #161930;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    white-space: nowrap;
}
/* searchbox */
.pxl-support-searchbar {
    /* padding: 0 0 0 10px; */
    width: fit-content;
}
.pxl-support-searchbar .xs_search_wrapper {
    position: relative;
}
.pxl-support-searchbar .xs_search_wrapper input {
    border: 1px solid #F8EDFD;
    background-color: #FDF9FF;
    min-height: 38px;
    padding: 0px 40px 0 20px;
    width: 297px;
}
.pxl-support-searchbar .xs_search_wrapper input::placeholder{
    color: #9C88A6;
    font-size: 14px;
    font-weight: 400;
}
.pxl-support-searchbar span.xs_search_icon {
    position: absolute;
    right: 12px;
    top: 7px;
}
.pxl-support-searchbar span.xs_search_clear {
    position: absolute;
    right: 18px;
    top: 8px;
}
/*  */
/* ticket filter btn */
.xs_back_btn_round.filter_btn{
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
    border-radius: 5px;
    cursor: pointer;
}
.pxl-support-filter-ticket-btn {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
    border-radius: 5px;
    cursor: pointer;
}
.pxl-support-filter-ticket-btn svg {
    fill: #ffffff;
}
/* ticket filter btn css end*/
/* create ticket btn css */
.pxl-ticketReply {
    padding: 10px 0 0;
}
/* create ticket btn end */
/* sorted by ticket css start */
.pxl-sortedBy-ticket-sec {
    display: flex;
    align-items: center;
    /* padding: 0 10px 0 0; */
}
.pxl-sortedBy-ticket-sec .css-13cymwt-control {
    border: 1px solid #F8EDFD;
    background-color: #FDF9FF;
}
.pxl-sortedBy-ticket-sec .css-13cymwt-control:hover {
    border: 1px solid #F8EDFD !important;
    background-color: #FDF9FF;
}
.pxl-sortedBy-ticket-sec .css-t3ipsp-control{
    border-color: #F8EDFD;
}
.pxl-sortedBy-ticket-sec .css-t3ipsp-control:hover {
    border-color: #F8EDFD;
}
.pxl-sortedBy-ticket-sec span{
    color: #161930;
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px 0 0;
    text-transform: capitalize;
}
/* sorted by ticket css end */
/* ticket delete css start */
.pxl-support-delete-ticket button{
    border: 1px solid #F8EDFD;
    background-color: #FDF9FF;
    color: #9C88A6;
    min-height: 38px;
    width: fit-content;
    padding: 0px 20px;
    outline: none;
    display: flex;
    align-items: center;
    margin:5px;
}
.pxl-support-delete-ticket button span.btn_create {
    padding: 0 8px 0 0;
}
/*====== table design css start =======*/
/* checkbox css start */
.pxl-ticket-checkbox .sch-custom-check-box .form-group label:before {
    position: relative !important;
    right: 0 !important;
}

.pxl-ticket-checkbox .sch-custom-check-box{
   position: relative;
   width: fit-content;
}
.pxl-ticket-checkbox .sch-custom-check-box .form-group {
    display: block;
}
.pxl-ticket-checkbox .sch-custom-check-box .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}
.pxl-ticket-checkbox .sch-custom-check-box .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #D4DAE7;
    padding: 10px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
    position: absolute;
    top: 0px;
    right: -10px;
}
.pxl-ticket-checkbox .sch-custom-check-box .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: auto;
    width: 5px;
    height: 11px;
    border: 2px solid var(--sch-white);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    right: 4px;
}
.pxl-ticket-checkbox .sch-custom-check-box .form-group input:checked+label:after {
    border-color: #ffffff;
    top: 6px;
    right: 13px;
}
/* checkbox css end */
/* ticket number css */
.pxl-ticket-profile-name1 {
    display: flex;
    align-items: center;
}
.pxl-ticket-profile-name1 > p {
    font-size: 16px;
    padding: 0px 0px 0px 10px;
    /* text-transform: capitalize; */
    color: #161930;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    font-weight: 500;
}
.pxl-ticket-number {
    padding: 22px 15px 24px;
    border-radius: 5px;
    width: fit-content;
}
.pxl-ticket-number > h2{
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pxl-ticket-number > h2 > span {
    font-size: 12px;
    font-weight: 500;
    /* text-transform: capitalize; */
    padding: 9px 18px;
    border-radius: 50px;
    color: #ffffff;
    margin: 0 0 0 20px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 110px;
    max-width: 110px;
    text-align: center;
}
/*=== ticket color css ====*/
.pxlticket-color1{
    background-color: #E1EDFF;
}
.pxlticket-color1 > h2{
    color:#2A7FFF ;
}
.pxlticket-color1 > h2 > span{
    background-color: #2A7FFF;
}

.pxlticket-color2{
    background-color: #FFF5E1;
}
.pxlticket-color2 > h2{
    color:#FFBC39 ;
}
.pxlticket-color2 > h2 > span{
    background-color:#FFBC39;
}
/* 3 */
.pxlticket-color3{
    background-color:#FFE9EE;
}
.pxlticket-color3 > h2{
    color:#FF698D ;
}
.pxlticket-color3 > h2 > span{
    background-color:#FF698D;
}
/* 4 */
.pxlticket-color4{
    background-color:#F9EBFF;
}
.pxlticket-color4 > h2{
    color:#D479FF;
}
.pxlticket-color4 > h2 > span{
    background-color:#D479FF;
}
/* 5 */
/* 4 */
.pxlticket-color5{
    background-color:#E5FFD6;
}
.pxlticket-color5 > h2{
    color:#87C561;
}
.pxlticket-color5 > h2 > span{
    background-color:#87C561;
}
/* ticket content */
.pxl-ticket-content > h2{
    font-size: 18px;
    font-weight: 500;
    color: #161930;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:200px;
    margin: 0 0 5px;
    white-space: nowrap;
    /* text-transform: capitalize; */
}
.pxl-ticketPara{
    font-size: 14px;
    font-weight: 400;
    color: #72778F;
     /* max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap; */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.pxl-ticket-content.pxlFullpage-ticketbox {
    max-height: 70px;
    overflow: hidden; 
}
.pxl-ticketPara > p {
    font-size: 14px;
    font-weight: 400;
    color: #72778F;
     max-width:200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.pxl-ticketPara > ul,.pxl-ticketPara > ol{
    height: 30px;
}
.pxl-ticket-reply-left > a > span.pxl-ticket-reply-email-txt {
    color: #161930;
    padding: 0 0 0 8px;
    text-overflow: ellipsis;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
}
.pxl-ticket-content > p {
    font-size: 14px;
    font-weight: 400;
    color: #72778F;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 450px;
}
/* profile css */
.pxl-ticket-profile .pxl-ticket-profile-name img {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center top;
    margin: 0px 11px 0px 0px;
}
.pxl-ticket-profile .pxl-ticket-profile-name span {
    font-size: 16px;
    font-weight: 500;
    color: #161930;
    display: block;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}
/* ticket date css */
.pxl-ticket-date > h2{
    font-size: 14px;
    font-weight: 400;
    color: #72778F;
    margin: 0;
    cursor: pointer;
}

.pxl-ticket-delet-btn {
    cursor: pointer;
    width: 10px;
    text-align: center;
}
/*============= table design css end ===========*/
/* =========== ticket right sidebar css start =================*/

.pxl-ticket-sidebar-filter {
    position: fixed;
    right: -410px;
    top: 0;
    background-color: #ffffff;
    box-shadow: 0px 21px 30px 0px #3714311A;
    width: 410px;
    height: 100vh;
    overflow: auto;
    padding: 40px 30px 120px;
    margin-top: 80px;
    transition: all 0.5s;
}
.filter_sidebar{
    position: fixed;
    right: -410px;
    top: 0;
    background-color: #ffffff;
    box-shadow: 0px 21px 30px 0px #3714311A;
    width: 410px;
    height: 100vh;
    overflow: auto;
    padding: 40px 30px 120px;
    margin-top: 70px;
    z-index: 1;
    transition: all 0.5s;
}
.filter_sidebar.filter_show{
    right: 0;
}
.filter_sidebar_heading {
    display: flex;
    align-items: center;
    color: #161930;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}
.xs_back_btn_round.pxl-sidebar-arrow{
    width:28px;
    height:28px;
    background-color: #F4F6FD;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin: 0 10px 0 0;
}
.pxl-status-list-box.pxl-support-date-box .pxl_input_feilds .rs-picker.rs-picker-daterange.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-top-end.rs-picker-cleanable span.rs-input-group-addon {
    position: absolute;
    right: 8px;
    top: 10px;
}
.rs-picker-daterange>.rs-input-group.rs-input-group-inside .rs-input {
    padding: 0 20px;
}
/* status list css */
.pxl-status-list-box {
    /* padding: 35px 0px 0px; */
    padding: 15px 0px 0px;;
    
}
.pxl-status-list-box > label {
    color: #161930;
    font-size: 16px;
    font-weight: 600;
    padding: 0 0 10px;
    display: block;
    width: 100%;
    text-transform: capitalize;
}
.pxl-status-radio-btn .cun-custom-radioBtn label{
    font-size: 16px;
    font-weight: 400;
}
/* color1 */
.pxl-open-status {
    /* margin:0px 20px 24px 0; */
    margin: 0px 20px 10px 0;
}
.pxl-open-status [type="radio"]:checked, .pxl-open-status [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.pxl-open-status [type="radio"]:checked+label, .pxl-open-status [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #161930;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
}
.pxl-open-status [type="radio"]:checked+label:before, .pxl-open-status [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    /* border: 1px solid #2A7FFF; */
    border-radius: 100%;
    background: #fff;
}
.pxl-open-status [type="radio"]:checked+label:after, .pxl-open-status [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 8px;
    height: 8px;
    /* background: #2A7FFF; */
    position: absolute;
    top: 7px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
}
.pxl-open-status [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0);
}
/* color2 */
.pxl-waiting-status {
    /* margin: 0px 20px 24px 0; */
    margin: 0px 20px 10px 0;
}
.pxl-waiting-status [type="radio"]:checked, .pxl-waiting-status [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.pxl-waiting-status [type="radio"]:checked+label, .pxl-waiting-status [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #161930;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
}
.pxl-waiting-status [type="radio"]:checked+label:before, .pxl-waiting-status [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    border: 1px solid #2A7FFF;
    border-radius: 100%;
    background: #fff;
}
.pxl-waiting-status [type="radio"]:checked+label:after, .pxl-waiting-status [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #2A7FFF;
    position: absolute;
    top: 7px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
}
.pxl-waiting-status [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0);
}
/* color3 */
.pxl-pending-status {
    margin: 0px 20px 24px 0;
}
.pxl-pending-status [type="radio"]:checked, .pxl-pending-status [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.pxl-pending-status [type="radio"]:checked+label, .pxl-pending-status [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #161930;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
}
.pxl-pending-status [type="radio"]:checked+label:before, .pxl-pending-status [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    border: 1px solid #FFBC39;
    border-radius: 100%;
    background: #fff;
}
.pxl-pending-status [type="radio"]:checked+label:after, .pxl-pending-status [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #FFBC39;
    position: absolute;
    top: 7px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
}
.pxl-pending-status [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0);
}
/* color 4 */
.pxl-resolve-status {
    margin: 0px 20px 24px 0;
}
.pxl-resolve-status [type="radio"]:checked, .pxl-resolve-status [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.pxl-resolve-status [type="radio"]:checked+label, .pxl-resolve-status [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #161930;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
}
.pxl-resolve-status [type="radio"]:checked+label:before, .pxl-resolve-status [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    border: 1px solid #FF698D;
    border-radius: 100%;
    background: #fff;
}
.pxl-resolve-status [type="radio"]:checked+label:after, .pxl-resolve-status [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #FF698D;
    position: absolute;
    top: 7px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
}
.pxl-resolve-status [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0);
}
/* color5 */
.pxl-close-status {
    margin: 0px 20px 10px 0;
}
.pxl-close-status [type="radio"]:checked, .pxl-close-status [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.pxl-close-status [type="radio"]:checked+label, .pxl-close-status [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #161930;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
}
.pxl-close-status [type="radio"]:checked+label:before, .pxl-close-status [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    border: 1px solid #D479FF;
    border-radius: 100%;
    background: #fff;
}
.pxl-close-status [type="radio"]:checked+label:after, .pxl-close-status [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #D479FF;
    position: absolute;
    top: 7px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
}
.pxl-close-status [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0);
}
img.pxl-filterImg {
    width: 20px;
    height: 20px;
    position: relative;
    top: 1px;
}
/* ===== support type box css start ======*/
.pxl-status-list-box.pxl-support-type-box {
    padding: 10px 0 0;
}
.pxl-support-type-box .css-13cymwt-control {
    min-height: 52px;
    padding: 0px 8px;
    border: 1px solid #EDEEED;
}
.pxl-support-type-box .css-13cymwt-control:hover {
    border: 1px solid #EDEEED;
}
.pxl-support-type-box .css-t3ipsp-control{
    min-height: 52px;
    padding: 0px 8px;
    border: 1px solid #EDEEED;
}
.pxl-support-type-box .css-t3ipsp-control:hover{
    border: 1px solid #EDEEED;
}
.pxl-support-type-box .css-1jqq78o-placeholder{
    color: #72778F !important;
    font-size: 15px !important;
    font-weight: 400 !important;
}

/*======= support date box css ========*/
.pxl-status-list-box.pxl-support-date-box {
    padding: 18px 0px 0px;
}
.pxl-status-list-box.pxl-support-date-box .pxl_input_feilds {
    position: relative;
    margin: 0;
}
.pxl-support-date-box .pxl_input_feilds input{
    border: 1px solid #EDEEED;
    background-color: transparent;
    min-height: 52px;
    padding: 10px 50px 10px 20px;
}
.pxl-support-date-box .pxl_input_feilds input::placeholder{
    color: #72778F;
    font-size: 15px;
    font-weight: 400;
}
.pxl-status-list-box.pxl-support-date-box .pxl_input_feilds span {
    position: absolute;
    right: 18px;
    top: 13px;
}
/* ===== support sidebar email css ====== */
/* ===================== create ticket page css start ==========================*/
.pxl-ticket-create-row12 .pxl_input_feilds {
    max-width: 100%;
}
.pxl-ticket-status-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}
.pxl_input_feilds.pxl_input_feilds_placeholder {
    max-width: 100%;
}
.cun-radiobox-wrapper .pxl_input_feilds {
    max-width: 100%;
}
.pxl_input_feilds_placeholder .css-13cymwt-control {
    border-radius: 6px;
    border: 1px solid var(--input-border);
    background-color: var(--bg-color1);
    width: 100%;
    min-height: 50px;
    height: 50px;
    padding: 0px 20px;
    /* font-size: 16px; */
}
.pxl_input_feilds_placeholder .css-13cymwt-control:hover{
    border: 1px solid #e7eef8;
}
.pxl_input_feilds_placeholder .css-t3ipsp-control{
    min-height: 50px !important;
    padding: 0px 20px !important;
    border: 1px solid #e7eef8 !important;
    background-color: var(--bg-color1);

}

.pxl_input_feilds_placeholder .css-1fdsijx-ValueContainer {
    padding: 0;
}
.pxl_input_feilds_placeholder .css-t3ipsp-control .css-1fdsijx-ValueContainer {
    padding: 0;
}
.pxl_input_feilds_placeholder .css-1jqq78o-placeholder{
    color: #8A9BB1 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important; 
  }


.pxl_input_feilds.pxl-ticket-editor {
    max-width: 100%;
}

/* ck-editor css start */
.ck-editor__editable {
    min-height: 200px!important;
}
.ck.ck-list__item>.ck-button.ck-on {
    background: #eae8e8;
    color: #000000;
}


.ck-editor__editable {
    min-height: 200px!important;
    height: 50px;
    overflow: visible;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    border-radius: 0 0 5px 5px!important
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border-radius: 5px 5px 0 0!important
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    border:1px solid var(--input-border)!important;
    box-shadow: none!important
}
.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
    border: 1px solid #e7eef8 !important;
}
.ck.ck-button.ck-on,a.ck.ck-button.ck-on {
    color: var(--primary-color)!important
}

.ck.ck-button:active,.ck.ck-button:focus,a.ck.ck-button:active,a.ck.ck-button:focus {
    border: 1px solid var(--input-border)!important
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: #e7eef8 !important;
}
.ck.ck-list__item .ck-button.ck-on {
    background-color: rgba(var(--primary-color-rgb),.1)!important
}
.ck.ck-button.ck-disabled:active, .ck.ck-button.ck-disabled:focus, a.ck.ck-button.ck-disabled:active, a.ck.ck-button.ck-disabled:focus {
    box-shadow: unset;
}
.ck.ck-button:active, .ck.ck-button:focus, a.ck.ck-button:active, a.ck.ck-button:focus {
    border: transparent !important;
}

/* ==ck editor css end */
.ck-content ul, .ck-content ol {
    padding-left: 20px;
}
.ck-content ol li {
    list-style: auto !important;
}
.ck-content ul li {
    list-style: disc !important;
}
.pxl-ck-editor-list ol {
    padding-left: 15px;
    margin-top: 10px;
}
.pxl-ck-editor-list ul {
    padding-left: 15px;
    margin-top: 10px;
}
.pxl-ck-editor-list ol li {
    list-style: auto !important;
}
.pxl-ck-editor-list li {
    list-style: disc !important;
}
.pxl-editorArea ol li{
    margin: auto;
    padding: unset;
}
.pxl-create-ticketBox {
    background: var(--bg-color1);
    padding: 10px 10px;
    border-radius: 10px;
    border: 1px solid var(--input-border);
}
.pxl-ticketBtn {
    padding: 20px 0 0;
}
.pxl-uploadAttachment label {
    font-size: 14px;
    margin-bottom: 8px;
    color: var(--sch-title-color);
    font-weight: 500;
}
.pxl-ticket-input-bottom-space {
    padding: 0 0 20px 0;
}
.pxl-custom-file-upload.pxl-upload-file-profile.pxl-ticket-file-uplod .pxl-custom-file-select {
    width: 50px;
    height: 50px;
}
.pxl-custom-file-upload.pxl-upload-file-profile.pxl-ticket-file-uplod .pxl-custom-file-select {
    width: 90px;
    height: 90px;
    background-color: var(--bg-color1);
    display: flex;
    align-items: center;
    justify-content: center;
}
.pxl-custom-file-upload.pxl-upload-file-profile.pxl-ticket-file-uplod .pxl-custom-file-select .pxl-custom-file-select-button p {
    color: #161930;
    font-size: 30px;
}
.pxl-create-ticket-radio-box .cun-radiobox-wrapper {
    padding: 0px 0 7px 0;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners p {
    font-size: 16px;
   
}
.delete_tic_btn_wrap {
    /* margin: 0 10px 0 0; */
}
.pxl-custom-file-upload.pxl-upload-file-profile.pxl-ticket-file-uplod {
    display: flex;
    gap: 5px;
    position: relative;
    flex-wrap: wrap;
}
.pxl-custom-file-upload.pxl-upload-file-profile.pxl-ticket-file-uplod div label div{
    border: 1px solid var(--sch-border-color1);
    padding: 10px 10px;
    border-radius: 5px;
}
.pxl-custom-file-upload.pxl-upload-file-profile.pxl-ticket-file-uplod div label div img {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
.pxl-custom-file-upload.pxl-upload-file-profile.pxl-ticket-file-uplod div {
    position: relative;
}
/* =======================Ticket reply page css start ==========================*/
.pxl-ticket-reply-mainWrap.pxlTicketGenarate-wrap .pxl-ticketReplyLetft {
    margin: 30px 50px 80px 80px;
}
.pxl-ticketReplyLetft {
    margin: 0px 50px 80px 80px;
    width: calc(100% - 540px);
    margin-right: 470px;
}
.pxl-ticket-reply-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 50px 0;
    flex-wrap: wrap;
    gap: 10px;
}
.pxl-ticket-reply-left > a > span.pxl-ticket-reply-backIcon {
    background-color: #F4F6FD;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.pxl-ticket-reply-left > a{
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.pxl-ticket-reply-left > a > span.pxl-ticket-reply-email-txt {
    color: #161930;
    padding: 0 0 0 8px;
}

.pxl-ticket-reply-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    flex-wrap: wrap;
    gap: 10px;
}

.pxl-ticket-replyBtn {
    /* margin: 0px 10px 0px 0px; */
}
.pxl-ticket-replyBtn button.sch-btn-iconLeft {
    height: 40px;
    padding: 4px 20px 4px 20px;
}
.pxl-ticket-reply-delete-Btn button.pxl-pink-btn-delete.sch-btn-iconLeft {
    height: 40px;
}
.pxl-ticket-reply-delete-Btn button.pxl-pink-btn-delete.sch-btn-iconLeft > span{
    height: 32px;
}
.pxl-ticket-replyBtn > button.sch-btn-iconLeft > span {
    height: 32px;
    display: none;
}

.pxl-ticket-reply-delete-Btn > button.pxl-pink-btn-delete{
    height: 40px;
}
.pxl-ticket-reply-delete-Btn > button.pxl-pink-btn-delete > span{
    height: 32px;
}

.delete_tic_btn_wrap > button.pxl-pink-btn-delete{
    height: 40px;
}
.delete_tic_btn_wrap > button.pxl-pink-btn-delete > span{
    height: 32px;
}

/* ticket profile img name css */
.pxl-custom-file-upload.pxl-upload-file-profile.pxl-ticket-file-uplod .pxl-custom-file-select .pxl-custom-file-select-button {
    background-color: transparent;
    padding: 5px 0;
}
.pxl-ticketLect-content {
    /* max-width: 1224px; */
    max-width: 1400px;
}
.pxl-ticket-reply-box {
    background-color: #ffffff;
    box-shadow: 0px 0px 60px 0px #5825710F;
    border: 1px solid #E9EDF6;
    padding: 20px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}
.pxl-ticket-img-text {
    display: flex;
    align-items: center;
    padding: 0 0 10px;
    border-bottom: 1px solid #f4ecfc;
    margin: 0 0 10px;
}
.pxl-ticket-profileBox .table tbody tr td, .ck-widget_with-selection-handle tbody tr td {
    border: 1px solid #dbd8d8 !important;
    padding: 5px;
}
.pxl-ticket-userName {
    padding: 0px 0px 0px 15px;
}
.pxl-ticket-img-text > img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
}
.pxl-ticket-img-text > h2 {
    margin: 0;
    text-transform: capitalize;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    /* background-color: #FDF9FF; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid #F8EDFD;
    background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
}
.pxl-ticket-userName > h2 {
    margin: 0;
    font-size: 16px;
    /* text-transform: capitalize; */
    color: #161930;
    font-weight: 500;
}
.pxl-ticket-userName > p {
    color: var(--sch-global-color);
    font-size: 14px;
    font-weight: 400;
    /* text-transform: capitalize; */
    padding: 3px 0 0 0;
}
.pxl-ClientMsg {
    border-top: 1px solid #E9EDF6;
    margin: 15px 0px 0px;
    padding: 15px 0 0 0;
}
.pxl-ClientMsg > p {
    color: var(--sch-global-color);
    font-size: 15px;
    margin-bottom: 20px;
}
/* right box css start */
.pxl-ticket-reply-rightMain-box {
    /* position: relative; */
    width: 410px;
    background-color: #ffffff;
    box-shadow: 0px 21px 30px 0px #3714311A;
    padding: 30px 30px 100px;
    position: fixed;
    right: 0;
    top: 80px;
    height: 100vh;
    /* overflow: auto; */
    z-index: 1;
    transition: all 0.5s;
}
.pxl-ticketReply-right-res {
    position: absolute;
    left: -40px;
    display: none;
}
.pxl-ticketReply-right-res > a {
    background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
    box-shadow: 0px 0px 20px 0px #555F861A;
    width: 40px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px 0px 0px 30px;
}
.pxl-ticketReply-right-res > a > img {
    transform: rotate(180deg);
}
.pxl-ticket-reply-mainWrap {
    display: flex;
    justify-content: space-between;
}

.pxl-ticketReplyRight {
    /* width: 410px;
    background-color: #ffffff;
    box-shadow: 0px 21px 30px 0px #3714311A;
    padding: 30px 30px 100px;
    position: fixed;
    right: 0;
    top: 80px;
    height: 100vh;
    overflow: auto;
    z-index: 1; */
    position: relative;
    height: 100vh;
    overflow: auto;
    padding: 0 10px 110px 0;
}
.pxl-ticketReplyRight::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    border-radius: 10px;
    background-color: var(--whiteColor);
  }
  
  .pxl-ticketReplyRight::-webkit-scrollbar {
    width: 5px;
    background-color: var(--whiteColor);
  }
  
  .pxl-ticketReplyRight::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); */
    background-color: #ffffff;
  }
.pxl-userProfileDeatils {
    background-color: #F4ECFC;
    text-align: center;
    padding: 30px 20px;
    border-radius: 10px;
    margin: 0 0 50px;
}
.pxl-userProfileDeatils > img {
    width: 90px;
    height: 90px;
    border-radius: 50px;
}

.pxl-userProfileDeatils .pxl-userName-letter > h2 {
    /* text-transform: capitalize; */
    width: 90px;
    height: 90px;
    border: 1px solid #F8EDFD;
    background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #ffffff;
    margin: 0 auto !important;
}
.pxl-user-profile-mail-info > h2{
    font-size: 16px;
    font-weight: 600;
    color: #161930;
    margin: 0;
    padding: 25px 0 0 0;
    /* text-transform: capitalize; */
}
.pxl-user-profile-mail-info > h3{
    font-size: 14px;
    font-weight: 400;
    color: #72758D;
    margin: 0;
    padding: 5px 0px 15px;
}
.pxl-user-profile-mail-info > button {
    font-size: 14px;
    font-weight: 600;
    background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
    border-radius: 50px;
    color: #ffffff;
    /* text-transform: capitalize; */
    border: none;
    padding: 10px 20px;
    cursor: unset;
}
/* recent ticket css start */

.pxl-recent-tickets-wrap > h2 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #222444;
    padding: 0px 0px 15px;
}
.pxl-recent-ticket-box {
    margin: 0px 0px 10px;
    padding: 15px 20px;
    border-radius: 5px;
    background-color: #f4f4f4;
    border: 1px solid #dddddd;
}
.pxl-recent-ticket-box > a > .pxl-recent-ticket-row1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 15px;
}
.pxl-recent-ticket-box > a > .pxl-recent-ticket-row1 > h2{
    font-size: 15px;
    font-weight: 500;
    margin: 0;
}
.pxl-recent-ticket-box > a > .pxl-recent-ticket-row1 > h3 {
    /* background-color: red; */
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    display: inline-block;
    padding: 5px 13px;
    border-radius: 30px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
    white-space: normal;
}
.pxl-recent-ticket-box > a > .pxl-recent-ticket-row2 > h2{
    font-size: 18px;
    font-weight: 500;
    color: #161930;
    margin: 0;
    padding: 0 0 5px;
    /* text-transform: capitalize; */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 280px;
}
.pxl-recent-ticket-box > a > .pxl-recent-ticket-row2 > h3{
    font-size: 14px;
    font-weight: 400;
    color: #72778F;
    margin: 0;
}
.pxl-recent-ticket-color1{
    border: 1px solid #FF698D;
    background-color: #FFF9FA;
}
.pxl-recent-ticket-color1 a > .pxl-recent-ticket-row1 > h3{
    background-color: #FF698D;
}

.pxl-recent-ticket-color2{
    border: 1px solid #D479FF;
    background-color: #FFF9FA;
}
.pxl-recent-ticket-color2 a > .pxl-recent-ticket-row1 > h3{
    background-color: #D479FF;
}
.psl-client-addImg > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* padding: 40px 0 0; */
    padding: 20px 0 0;
}

.psl-client-addImg > ul >li {
    border: 1px solid #E9EDF6;
    padding: 5px 5px;
    border-radius: 5px;
}
.psl-client-addImg > ul > li > a > img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    object-position: top center;
    border-radius: 5px;
}
.pxl-replyBtn {
    padding: 20px 0 0 0;
}
.pxl-custom-file-upload.pxl-upload-file-profile.pxl-ticket-file-uplod div span.xs_remove_icon {
    position: absolute;
    top: -8px;
    right: 0px;
    background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
    width: 15px;
    height: 15px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 20px;
}
/* select2 */
.pxl-ticket-priority .css-b62m3t-container .css-13cymwt-control {
    border-color: #E9EDF6 !important;
}
.pxl-ticket-priority .css-t3ipsp-control:hover{
    border-color: #E9EDF6 !important;
}
.pxl-ticket-priority .css-t3ipsp-control{
    border-color: #E9EDF6 !important;
}
/* calender css */
.rs-picker-error>.rs-input-group {
    border-color: #e5e5ea;
    border-color: #e5e5ea!important;
}
.rs-btn-link:focus, .rs-btn-link:hover {
    text-decoration: unset!important;
}
.rs-btn-link {
    color: #8417af;
    color: #8417af !important;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    -webkit-box-shadow: inset 0 0 0 1px #e5e5ea;
    -webkit-box-shadow: inset 0 0 0 1px #e5e5ea;
    box-shadow: inset 0 0 0 1px #e5e5ea;
    box-shadow: inset 0 0 0 1px #e5e5ea;
}
.rs-calendar-table-cell-in-range:before{
    background-color: #e5e5ea !important;
    background-color: #e5e5ea !important;
}
.rs-btn-close:focus svg path, .rs-btn-close:hover svg path {
    stroke: #161930 !important;
}
.pxl-ticketPara figure.table {
    margin: 0;
}
.pxl-ticketPara figure.table tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: none !important;
    border-width: 0;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    max-width: 200px;
    margin: 0 0 5px;
    white-space: nowrap;
}
.pxl-ticketPara figure.table table tbody tr {
    padding: 0;
    margin: 0;
    display: block;
}
.pxl-ticketPara figure.table table tbody tr td br{
    display: none !important;
}
.pxl-ticketListRight {
    display: flex;
    gap: 10px;
}
.pxl_ticket_Set_type_prior li:nth-child(1).cun-socialIcon.pxl-tooltip-button-form.pxl-deleteToltip.pxl-tooltip-btn-diable .cun-show-tooltip {
    transform: translate(-60px, 10px);
    top: -45px;
}
.pxl_ticket_Set_type_prior li:nth-child(2).cun-socialIcon.pxl-tooltip-button-form.pxl-deleteToltip.pxl-tooltip-btn-diable .cun-show-tooltip {
    transform: translate(-63px, 10px);
    top: -45px;
}
.pxl-ticket-status-icons ul li:nth-child(2).cun-socialIcon.pxl-deleteToltip.pxl-tooltip-btn-diable .cun-show-tooltip {
    /* left: 0;
    right: auto;
    transform: translate(-67px, 10px) !important;
    top: -45px;
    bottom: auto; */
}
.pxl-ticket-status-icons ul li:nth-child(3).cun-socialIcon.pxl-deleteToltip.pxl-tooltip-btn-diable .cun-show-tooltip {
    /* left: 0;
    right: auto;
    transform: translate(-67px, 10px) !important;
    top: -45px;
    bottom: auto; */
}
.pxl-support-head1.pxl-head-leftsec.pxl-ticketBackArrow-sec {
    display: flex;
    align-items: center;
}
.xs_page_fixed_position .pxl-ticket-reply-left a span.pxl-ticket-reply-backIcon img {
    transform: unset;
}
.css-1dimb5e-singleValue {
    max-width: 150px;
}
li.pxl-responsiveBlock-dot {
    display: none;
}
/* li.pxl-responsiveBlock-dot .sch-menuSection .pxl-ticketDelete-agent-res ul {
    display: flex;
}
li.pxl-responsiveBlock-dot .pxl-ticketDelete-agent-res ul li {
    margin: 0 10px 0px 0;
} */
.pxl-ticketReply p {
    word-break: break-word;
}
.pxl-ticketRes1 {
    display: flex;
    gap: 10px;
}
.pxl-ticket-res2 {
    display: flex;
    gap: 10px;
}
.delete_tic_btn_wrap button.pxl-pink-btn-delete.sch-btn-iconLeft {
    padding: 4px 20px 4px 20px;
    height: 39px;
}
.pxl-pixasupport-project-head-tooltip.cun-iconTooltip .cun-show-tooltip {
    margin: 0 0 0 10px;
}
/* =================== Media css start ====================*/
@media(max-width:1500px){
    .pxl-ticketReply-right-res{
        display: block;
    }
    .pxl-ticket-reply-rightMain-box.pxl-ticketopen {
        right: 0;
    }
    .pxl-ticket-reply-rightMain-box {
        right: -410px;
    }
    .pxl-ticketReplyLetft {
        margin: 0;
        width: 100%;
        padding: 0px 60px;
    }
    
    .pxl-ticketReply-right-res {
        display: block;
    }
   
    .pxl-ticket-row > ul {
        gap: 50px;
    }
    .pxl-ticket-content > h2 {
        max-width: 120px;
    }
    .pxl-ticket-number > h2 {
        display: block;
        text-align: center;
    }
    .pxl-ticket-number > h2 > span{
        display: block;
        margin: 10px auto 0;
    }
    .pxl-ticketPara > p {
        max-width: 150px;
    }
    .pxl-ticket-profile-name1 > p {
        max-width: 90px;
    }
}
@media(max-width:1400px){
    .proj_mail_set_form.pxl-createTicket-wrapper {
        margin: 0px 40px;
        max-width: 100%;
    }
    .proj_mail_set_form.pxl-createTicket-wrapper.pxlTicketPopup {
        margin: 40px 40px;
    }
}
@media(max-width:1200px){
    .pxl-ticket-row > ul {
        gap: 20px;
    }
}
@media(max-width:991px){
    li.pxl-responsiveBlock-dot {
        display: block;
    }
    .modal-dialog {
		padding: 0 20px 70px;
	}
    .pxlCreate-ticketWrap-box .xs_bg_transparent.pxl-support-tickets-wrap.pxl-support-flex-header .pxl-support-head1.pxl-head-leftsec.pxl-ticketBackArrow-sec {
        padding: 0 0 20px;
    }
    .pxl-ticket-reply-mainWrap.pxlTicketGenarate-wrap .pxl-ticketReplyLetft {
        margin: 30px 0px 80px 0px;
    }
    .pxl-head-rightsec .xs_ticket_chat_flex .xs_pagetitle_right {
        justify-content: flex-start;
    }
    .pxl-ticket-reply-right {
        justify-content: flex-start;
    }
    .pxl-ticket-row > ul > li {
        min-width: 130px !important;
    }
    .pxl-ticket-content > h2 {
        max-width: 120px;
    }
    .pxl-ticketPara > p {
        max-width: 70px;
    }
    .pxl-support-head1.pxl-head-leftsec {
        padding: 0 0 20px 0;
    }
    .pxl-support-flex-header{
        display: block;
    }
    .pxl-ticket-row > ul {
        padding: 10px 10px;
        /* max-width: 730px; */
        overflow: auto hidden;
        width: 100%;
    }
    .pxl-ticket-status-type{
        display: block;
    }
    .pxl-custom-file-upload.pxl-upload-file-profile.pxl-ticket-file-uplod .pxl-custom-file-select .pxl-custom-file-select-name {
        padding: 0px;
    }
    .pxl-ticket-number {
        padding: 10px 10px 10px;
    }
    /* .pxl-ticketPara figure.table tbody, td, tfoot, th, thead, tr {
        max-width: 120px !important;
    } */
  
    .pxl-ticket-row > ul > li:nth-child(6){
        display: none;
    }
    .pxl-ticket-row > ul > li:nth-child(7){
        display: none;
    }
    .pxl-ticket-reply-rightMain-box {
        top: 68px;
    }
}
@media(max-width:767px){
    .pxl-support-flex-header .xs_pagetitle_wrapper.ticket_right .xs_ticket_chat_flex {
        display: block;
    }
    .pxl-support-flex-header .xs_pagetitle_wrapper.ticket_right .xs_ticket_chat_flex .xs_pagetitle_right{
        /* display: block; */
    }
    /* .xs_back_btn_round.filter_btn{
        margin: 10px auto;
    } */
    .pxl-support-tickets-wrap .pxl-support-head1 h3{
        text-align: center;
    }
   
    .pxl-ticket-row > ul {
        padding: 10px 10px;
        max-width: 730px;
        overflow: auto hidden;
        width: 100%;
    }
    .pxl-ticket-row > ul::-webkit-scrollbar{
        height: 2px;
        background-color: #ffffff;
    }
    .pxl-sortedBy-ticket-sec {
        /* justify-content: center; */
        /* margin: 10px 0 0; */
    }
    .pxl-ticketRes1 .pxl-sortedBy-ticket-sec.pxl-prioritySelect {
        margin: 0;
    }
    .pxl-head-rightsec .xs_ticket_chat_flex {
        display: block;
    }
    .pxl-head-rightsec{
        /* justify-content: center; */
    }
    .pxl-ticketListRight {
        margin: 10px 0 0;
    }
    .pxl-ticketReplyLetft {
        padding: 0px 20px;
    }
    .pxl-head-rightsec.pxl-resCenter {
        justify-content: center;
    }
  
}
@media(max-width:580px){
    .pxl-ticket-reply-rightMain-box {
        top: 59px;
    }
    .pxl-recent-ticket-box > a > .pxl-recent-ticket-row1 > h3 {
        margin: 5px 0 0;
    }
    .pxl-userProfileDeatils .pxl-userName-letter > h2{
        width: 70px;
        height: 70px;
    }
    .pxl-recent-ticket-box > a > .pxl-recent-ticket-row1 {
        display: block;
    }
    .pxl-ticketReply-right-res > a {
        width: 30px;
        height: 30px;
    }
    .pxl-ticketReply-right-res {
        left: -30px;
    }
    .pxl-ticket-reply-rightMain-box {
        width: 270px;
        right: -270px;
        padding: 20px 20px;
    }
    .proj_mail_set_form.pxl-createTicket-wrapper {
        margin: 40px 30px;
    }
    .ck-editor__editable {
        height: 200px;
    }
    .pxl-ticket-number > h2{
        font-size: 14px;
    }
   
  
    .pxl-ticket-number > h2 > span {
        width: fit-content;
        max-width:100px;
        margin: 5px auto 0;
        padding: 5px 20px;
    }
    .pxl-ticket-number {
        padding: 10px 10px 12px;
        border-radius: 5px;
    }
    .pxl-ticket-content > h2 {
        font-size: 14px;
    }
    .pxl-ticket-profile-name1 > p{
        font-size: 14px;
    }
    .pxl-support-searchbar .xs_search_wrapper input {
        width: 100%;
    }
    .filter_sidebar {
        margin-top: 50px;
    }
    .pxl-support-flex-header .xs_pagetitle_wrapper.ticket_right .xs_ticket_chat_flex{
        display: block;
    }
    .pxl-support-flex-header .xs_pagetitle_wrapper.ticket_right .xs_ticket_chat_flex .xs_pagetitle_right{
        display: block;
    }
    .pxl-head-rightsec .xs_ticket_chat_flex .xs_pagetitle_right{
        display: block;
    }
    .pxl-sortedBy-ticket-sec {
        margin: 10px 0 0;
    }
    img.pxl-filterImg {
        width: 15px;
    }
    .pxl-support-head1.pxl-head-leftsec {

        text-align: center;
    }
    .pxl-user-profile-mail-info > h2 {
        padding: 15px 0 0 0;
    }
    .pxl-userProfileDeatils {
        padding: 10px 10px;
    }
    .pxl-user-profile-mail-info > button {
        padding: 7px 20px;
        font-size: 12px;
    }
    .psl-client-addImg > ul > li > a > img {
        width: 60px;
        height: 60px;
    }
}
@media(max-width:480px){
    .proj_mail_set_form.pxl-createTicket-wrapper.pxlTicketPopup {
        margin: 30px 30px;
    }
    .pxl-ticket-reply-rightMain-box {
        top: 55px;
    }
    .pxl-support-tickets-wrap .pxl-support-head1 h3 {
        font-size: 16px;
    }
    .filter_sidebar{
        width: 300px;
        padding: 20px 20px;
    }
    .pxl-ticket-reply-left > a > span.pxl-ticket-reply-email-txt {
        max-width: 150px;
    }
}
@media(max-width:380px){
    .pxl-head-rightsec .xs_ticket_chat_flex .xs_pagetitle_right{
        /* display: block; */
    }
    .delete_tic_btn_wrap > button.pxl-pink-btn-delete {
        /* margin: 0 auto 10px; */
    }
    .pxl-ticket-reply-rightMain-box {
        top: 50px;
    }
}