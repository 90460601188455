.sch-container-fluid-header {
    max-width: 1800px;
    margin: auto;
    padding: 0px 20px 0px;
}
.pxl_headerWrapper {
    height: 80px;
    /* margin-bottom: 30px; */
}
.sch-header-wrapper {
    background: var(--sch-white);
    box-shadow: 0px 0px 20px 0px #555F861A;
    /* margin: 0 0 30px; */
    position: fixed;
    z-index: 999;
    width: 100%;
    left: 0;
    right: 0;
}
.sch-container-fluid {
    max-width: 1800px;
    margin: auto;
    padding: 0px 20px;
}
.sch-header-row {
    display: flex;
    flex-wrap: wrap;
    /* grid-gap: 20px; */
    justify-content: space-between;
    align-items: center;
}
.sch-header-col {
    min-height: 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.sch-header-col-sm {
    width: 380px;
}
.sch-logo {
    text-align: left;
    width: 100%;
}
.sch-header-col {
    min-height: 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.sch-header-col-lg {
    position: relative;
}
.sch-header-col.sch-header-col-sm.sch-headLogo {
    /* width: 300px; */
}
/* .sch-main-menu .menu {
    display: inline-flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    justify-content: center;
    width: 100%;
} */
.sch-main-menu .menu>li {
    position: relative;
}
.sch-main-menu .menu>li>a {
    display: flex;
    align-items: center;
    padding: 21px 10px 20px;
    font-weight: 400;
    text-transform: capitalize;
    position: relative;
    font-size: 16px;
    color: var(--sch-global-color);
}
.sch-main-menu .menu>li>a:before {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 0;
    right: 0;
    margin: auto;
    background: var(--sch-primary-color);
    width: 80%;
    height: 5px;
    border-radius: 10px 10px 0px 0px;
    opacity: 0;
}
.sch-main-menu .menu>li.active a {
    color: var(--sch-primary-color);
}
.sch-main-menu .menu>li.active a:before {
    opacity: 1;
}

.sch-header-col.sch-header-col-sm.sch-headerRight {
    width: auto;
}
.sch-header-col.sch-header-col-sm.sch-headerRight .sch-btns-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.sch-btns-wrap>ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 6px;
    justify-content: flex-end;
}
.sch-header-col.sch-header-col-sm.sch-headerRight .sch-btns-wrap ul {
    grid-gap: 6px;
}

/*  */
.cun-socialIcon {
    position: relative;
    transition: all 0.5s;
}
/* toggle css */
.sch-menu-btn {
    width: 45px;
    height: 45px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 5px;
    background: var(--sch-primary-color);
    align-items: center;
    box-shadow: 0 10px 37px -5px rgb(243 82 124 / 16%);
    display: none;
}
.sch-menu-btn span>span {
	display: block;
	width: 25px;
	height: 3px;
	-webkit-transition: var(--sch-transition);
	-moz-transition: var(--sch-transition);
	-ms-transition: var(--sch-transition);
	-o-transition: var(--sch-transition);
	transition: var(--sch-transition);
	margin: 5px auto;
	background: var(--sch-white);
	border-radius: 100px;
}
.sch-btns-wrap.sch-nav-open .sch-menu-btn span>span:nth-child(1) {
	transform: translate(0px, 6px) rotate(-48deg);
}
.sch-btns-wrap.sch-nav-open .sch-menu-btn span>span:nth-child(2) {
	opacity: 0;
	visibility: hidden;
}
.sch-btns-wrap.sch-nav-open .sch-menu-btn span>span:nth-child(3) {
	transform: translate(0px, -10px) rotate(48deg);
}

/* toggle css end */
/* user menu css start */
.user-info-wrapper {
    display: flex;
    position: relative;
    flex-wrap: wrap;
}
.user-info {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
	grid-gap: 15px;
}
.user-info:after {
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid #72778F;
	content: "";
}

.user-info img.user-img {
	height: 45px;
	width: 45px;
	object-fit: cover;
	border: none;
	border-radius: 50%;
}


.drop-down-header h4 {
    margin: 0;
    font-size: 16px;
    color: var(--sch-global-color);
    font-weight: 400;
    max-width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.user-info-box {
	max-width: 220px;
	background: #ffffff;
	position: absolute;
	top: 60px;
	border-radius: 15px;
	min-width: 220px;
	right: 0;
	-webkit-transform: translateY(50px);
	-moz-trans-webkit-transform: translateY(50px);
	-ms-trans-webkit-transform: translateY(50px);
	-o-trans-webkit-transform: translateY(50px);
	transform: translateY(50px);
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	box-shadow: 0 0 20px rgb(0 0 0 / 8%);
}

.user-info-box:before {
    content: "";
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 6px solid #393e4f;
	position: absolute;
	right: 35px;
	top: -6px;
}
.user-info-box > ul {
	padding: 18px 30px 14px;
	border-top: 1px solid #F3EBFA;
}

.user-info-box > ul > li {
	width: 100%;
	display: inline-block;
	margin-bottom: 10px;
	font-weight: 500;
	text-transform: capitalize;
	text-align: left;
	color: #72778F;
}
.user-info-box > ul > li > a {
    font-size: 16px;
    font-weight: 400;
    color: #72778F;
}
.user-info-box > li > a > svg {
	height: 18px;
	vertical-align: middle;
	margin-right: 10px;
}
.user-info-box > ul > li > a > span {
    padding-right: 20px;
}
.user-info-box > ul > li > a:hover {
	padding-left: 5px;
}
.pxl-user-info-userName-box {
    padding: 10px 10px 19px 10px;
}
.pxl-user-info-userName-box > ul > li {
    position: relative;
}
.pxl-user-info-userName-box > ul > li > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EDF0FF;
    padding: 6px 9px;
    border-radius: 5px;
    position: relative;
    transition: all 0.5s;
}
.pxl-user-info-userName-box > ul > li>a:after {
    content: '';
    position: absolute;
    left: -10px;
    width: 219px;
    height: 1px;
    background-color: #F3EBFA;
    bottom: -20px;
}
.pxl-user-info-userName-box > ul > li > a > .pxl-userInfoBox {
    display: flex;
    align-items: center;
}

.pxl-user-info-userName-box > ul > li > a > .pxl-userInfoBox img {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    object-fit: cover;
    object-position: top;
}
.pxl-user-info-userName-box > ul > li > a > .pxl-userInfoBox > p {
    font-size: 14px;
    font-weight: 400;
    padding: 0 0 0 10px;
	color: #181719;
	text-transform: capitalize;
	max-width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.pxl-profile-brandText > h2 {
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
    display: inline-block;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 25px;
    position: absolute;
    right: 15px;
    bottom: -32px;
    z-index: 1111;
    margin: 0;
}
.pxl-userInfoBox1 {
    margin: 25px 0 0 10px;
    max-height: 170px;
    position: relative;
    overflow: auto;
}

.pxl-userInfoBox1 > ul > li > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 9px;
    border-radius: 5px;
    transition: all 0.5s;
}

.pxl-userInfoBox1 > ul > li > a > .pxl-userInfoBox {
    display: flex;
    align-items: center;
}
.pxl-userInfoBox1 {
    margin: 25px 0 0 10px;
    max-height: 170px;
    position: relative;
    overflow: auto;
}
.pxl-userInfoBox1 > ul > li > a > .pxl-userInfoBox > p {
    font-size: 13px;
    font-weight: 400;
    padding: 0 0 0 10px;
	color: #181719;
	text-transform: capitalize;
	max-width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.pxl-userInfoBox1 > ul > li > a > .pxl-userInfoBox > .pxl-userName-letter > h2 {
    font-size: 12px;
    width: 30px;
    height: 30px;
    background-color: #c6cbe5;
    justify-content: center;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #181719;
    border-radius: 50px;
    border: 1px solid #c6cbe5;
}
.pxl-userInfoBox1 > ul > li > a .pxl-userInfoBox img {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    object-fit: cover;
    object-position: top;
	border: 1px solid #C6CBE5;
}

.sch-main-menu .menu {
    display: inline-flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    justify-content: center;
    width: 100%;
}
.sch-logo a {
    display: block;
    width: fit-content;
}








@media(max-width:1500px){
    .sch-header-wrapper {
        position: fixed;
        width: 100%;
    }
    .sch-header-wrapper.header-sticky {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		animation: 0 !important;
	}
}
@media(max-width:1300px){
    .sch-main-menu .menu {
        grid-gap: 10px;
    }
}
@media(max-width:1200px){
    .sch-main-menu .menu>li>a:before{
        display: none;
    }
   
    .sch-btns-wrap.sch-nav-open .sch-menu-btn span>span:nth-child(2) {
        opacity: 0;
        visibility: hidden;
    }
    
    .sch-btns-wrap.sch-nav-open .sch-menu-btn span>span:nth-child(1) {
        transform: translate(0px, 6px) rotate(-48deg);
    }
    
   
    
}
@media(max-width:1200px) {
    .sch-btns-wrap.sch-nav-open .sch-menu-btn span>span:nth-child(2) {
        opacity: 0;
        visibility: hidden;
    }
	.sch-main-menu .menu>li>a {
		padding: 10px 10px;
		width: 100%;
	}
	.sch-header-wrapper {
		z-index: 999;
	}
	/* Mobile Menu */
	.pxl_headerWrapper .sch-main-menu {
		max-width: 320px;
		position: fixed;
		left: -400px;
		bottom: 0;
		top: 0;
		width: 100%;
		background: var(--sch-white);
		z-index: 9;
		align-items: normal;
		overflow: hidden auto;
		box-shadow: 2px 4px 28px 0px rgba(0, 0, 0, 0.1);
	}

	.pxl_headerWrapper .sch-main-menu .menu>li:hover .sch-menu-toggle {
		transform: unset;
	}

	.pxl_headerWrapper .sch-main-menu .menu .sub-menu li {
		padding: 0px 30px 10px;
		border: 0;
	}

	.pxl_headerWrapper .sch-header-wrapper.sch-nav-open .sch-main-menu {
		left: 0;
	}

	.sch-main-menu::-webkit-scrollbar {
		width: 3px;
	}

	.sch-main-menu::-webkit-scrollbar-track {
		background-color: var(--sch-border-color);
	}

	.sch-main-menu::-webkit-scrollbar-thumb {
		background-color: var(--sch-title-color);
	}

	.sch-main-menu,
	.sch-header-wrapper.sch-nav-open .sch-main-menu {
		-webkit-transition: var(--sch-transition);
		-moz-transition: var(--sch-transition);
		-ms-transition: var(--sch-transition);
		-o-transition: var(--sch-transition);
		transition: var(--sch-transition);
	}

	.sch-main-menu .menu li {
		width: 100%;
		border-bottom: 1px solid var(--sch-border-color);
	}

	.sch-main-menu .menu li:last-child {
		border: 0;
	}

	.sch-main-menu .menu {
		grid-gap: 0;
	}

	.sub-menu {
		display: none;
	}

	.sch-main-menu .menu>li>a {
		padding: 10px 10px;
		width:100%;
	}

	/* .sch-header-row .sch-header-col:first-child {
		width: 30%;
		justify-content: flex-start;
	} */

	/* .sch-header-row .sch-header-col:last-child {
		width: calc(70% - 50px);
		justify-content: flex-end;
	} */

	.sch-menu-btn {
		display: inline-flex;
	}

	.sch-main-menu .menu>li .sch-menu-toggle {
		width: 20%;
		text-align: center;
		padding: 0 10px;
		pointer-events: auto;
	}
}
@media(max-width:991px){
    .sch-header-wrapper {
        padding: 10px 0;
    }
    .sch-btns-wrap.sch-nav-open .sch-menu-btn span>span:nth-child(3) {
        transform: translate(0px, -6px) rotate(48deg);
    }
    .sch-main-menu .menu>li>a {
        padding: 10px 10px 10px;
    }
}
@media(max-width:767px){
    .sch-btns-wrap.sch-nav-open .sch-menu-btn span>span:nth-child(3) {
        transform: translate(0px, -6px) rotate(48deg);
    }
}
@media(max-width:767px){
    .sch-btns-wrap.sch-nav-open .sch-menu-btn span>span:nth-child(1) {
        transform: translate(0px, 4px) rotate(-48deg);
    }
   
    .sch-btns-wrap.sch-nav-open .sch-menu-btn span>span:nth-child(3) {
        transform: translate(0px, -6px) rotate(48deg);
    }
    .user-info-box{
        top: 50px;
    }
    .sch-headBtn button.pxlCommon-btn {
        height: 42px;
    }
    .header_nine_dot_wrapper .menu_dot img {
        height: 40px;
        width: 40px;
    }
}

@media(max-width:580px){
    .sch-headBtn button.pxlCommon-btn {
        height: auto;
        min-width: auto;
        padding: 8px 8px;
        font-size: 12px;
    }
    span.pxlNewBtn-text {
        display: none;
    }
}
@media(max-width:480px){
    .sch-header-col.sch-header-col-sm.sch-headLogo {
        width: 90px;
    }
    .sch-headBtn button.pxlCommon-btn {
        padding: 4px 8px;
    }
    .app_drop_grid .li_first_div {
        padding: 5px 15px 5px 5px;
    }
    .header_nine_dot_wrapper .app_dropdown_wrapper {
        min-width: 215px;
        top: 50px;
    }
    .sch-container-fluid-header {
        padding: 0px 10px 0px;
    }
    .sch-header-col.sch-header-col-sm.sch-headerRight .sch-btns-wrap ul {
        grid-gap: 4px;
    }
    .pxl_headerWrapper {
        height: 50px;
    }
    .app_drop_grid .app_icon_wrap {
        width: 35px;
        height: 35px;
    }
    .app_color5 .app_icon_wrap svg {
        width: 14px;
        height: 14px;
    }
    .app_drop_grid .app_icon_wrap svg{
        width: 14px;
        height: 14px;
    }
}
@media(max-width:380px){
    .pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .dash_head .pxl_filterFeilds {
        display: block;
    }
    .pxl_mainWrapper.pxl-puxaSupport-customer-wrapper .dash_head .sch-search-section {
        width: 100%;
        margin: 10px 0 0;
    }
    .sch-header-wrapper {
        padding: 10px 0px;
    }
    .sch-header-col.sch-header-col-sm.sch-headerRight .sch-btns-wrap ul {
        grid-gap: 4px;
    }
    li.pxl-userloginAchor a {
        margin-right: 0px;
    }
    .sch-header-col.sch-header-col-sm.sch-headerRight .sch-btns-wrap ul {
        grid-gap: 4px;
    }
    .sch-container-fluid-header {
        padding: 0px 5px 0px;
    }
}