h1,h2,h3,h4,h5,h6{

}
button:focus:not(:focus-visible) {
  /* border: 1px solid #6f10b9; */
}
button:hover {
  /* border: 1px solid #6f10b9; */
}
.css-19bb58m > input:focus-visible {
  padding: 0px 40px!important;
}
.css-19bb58m > input:focus {
  padding: 0px 40px!important;
}
.css-d7l1ni-option{
	cursor: pointer !important;
  }
  .css-tr4s17-option{
	cursor: pointer !important;
  }
/* common btn css */
.pxlCommon-btn {
  cursor: pointer;
  position: relative;
  padding: 4px 20px 4px 20px;
  border-radius: 5px;
  color: var(--sch-white);
  height: 50px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border: 0;
  flex-wrap: wrap;
  background: -o-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#994E8F', endColorstr='#F3527C', GradientType=1);
  background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  min-width: 120px;
  max-width: fit-content;
  /* box-shadow: 0px 20px 50px 0px #6927F24D; */
}
.pxlCommon-btn:before {
  content: '';
  display: block;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.pxlCommon-btn:after {
  content: '';
  display: block;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
  background-size: 210% 100%;
  background-position: right bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.pxlCommon-btn:hover:before {
  background-position: left bottom;
}

.pxlCommon-btn:after {
  background-position: right bottom;
}

/*===================== center top section css ===================*/
/* default css start */
.cun_hide {
  display: none;
}
.cun_hide {
  display: none;
}
.cun_hide {
  visibility: hidden;
  opacity: 0;
  z-index: -999;
  transition: all 0.3s;
}
/*======== default css start ========*/
.cun_img_editor_bg {
  position: relative;
  background-size: cover;
  /* background-repeat: no-repeat; */
  background-position: center;
  padding: 10px 50px;
  min-height: 100%;
  width: 100%;
  /* min-height: 100vh; */
  z-index: 1;
  background-color: #F7F3FC;
}
.cun_img_editor_bg:after{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  background-image: url(../../../../public/assets/images/image-editor/editor-bg.png);
  z-index: -1;
}
.loaderCenter{
  display: flex;
  justify-content: center;
}
/*========= back btn header css ========*/
.cun_image_editor_heading_text {
  /* width: 100%; */
  /* height: 100%; */
  display: flex;
  /* align-items: center; */
  transition: all .5s;
  position: relative;
  z-index: 1;
  margin: 0px 0px 20px;
}
.cun_image_editor_heading_text span a {
  font-size: 13px;
  font-weight: 500;
  color: var(--whiteColor);
  transition: all .5s;
}
.cun_addAccBtn_blk {
  color: var(--whiteColor);
  padding: 0px 15px;
  line-height: 45px;
  border: 0px;
  height: 45px;
  position: relative;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all .6s;
  text-align: center;
  box-shadow: 0px 8px 33.12px 2.88px rgba(141, 97, 254, 0.12);
  background-image: linear-gradient(180deg, #484766 0%, #181E35 100%);
  background-image: -ms-linear-gradient(180deg, #484766 0%, #181E35 100%);
  background: linear-gradient(180deg, #484766 0%, #181E35 100%);
}
.cun_image_editor_heading_text p {
  font-size: 14px;
  font-weight: 500;
  color: #29325f;
  padding: 5px 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 110px;
}
.cun_editor_tittle_name_md {
  display: block;
}
a.cun_editor-backbtn {
  background-color: #ffffff;
  color: #222222;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #EBE0F9;
  box-shadow: -8px 4px 10.199999809265137px 0px #9F80C633;
  padding: 7px 15px;
  border-radius: 12px;
  position: relative;
}
a.cun_editor-backbtn span {
  padding: 0px 10px 0px 0px;
  position: relative;
  top: -1px;
}
/*========= edito center menu css ========*/

.cun_editor_tittle_name_lg {
  display: none;
}
.cun_editor_tool_box_header {
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.cun_img_editor_main_box_tool_div {
  position: fixed;
  min-width: 180px;
  z-index: 111;
}
.sch-textareaSection {
  margin: 20px 0px 20px;
}
/* .cun_editor_setting_tabs .sch-library-tab.cun_editorImg-wrap .sch-search-section {
  margin: 0px 0px 0px;
} */
.cun_img_editor_tool_box {
  background-color: #ffffff;
  max-width: 160px;
  padding: 25px 5px 25px;
  border-radius: 30px;
  position: relative;
  z-index: 11;
}
.cun-editor-settingInner {
  /* max-height: 290px; */
  position: relative;
  overflow: auto;
  /* padding: 0 0 90px; */
}
.cun_img_editor_div {
  position: relative;
}
/* .cun_img_editor_tool_item_box.cun_img_editor_active {
  padding-bottom: 0px;
} */
.cun_img_editor_tool_item_box {
  cursor: pointer;
  border: none;
  background: #ffffff;
  padding: 10px 10px;
  margin: 0px 0px 10px;
  border-radius: 5px;
}
.cun_editor_dropbox_option {
  position: relative;
}
.cun_editor_hover_dropdown_box {
  transform: translateY(50px);
  position: absolute;
  left: 0;
  background: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  gap: 10px;
  max-height: 300px;
  border-radius: 10px;
  padding: 5px;
  opacity: 1;
  transition: all 2s;
  box-shadow: inset 0 -2px 3px 0 rgba(0, 0, 0, .06);
  z-index: 999;
}
.cun_img_editor_div:hover>.cun_tooltipEle {
  opacity: 1;
  visibility: visible;
}
.cun_editor_hover_dropdown_box {
  transform: translateY(50px);
  position: absolute;
  left: 0;
  background: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  gap: 10px;
  max-height: 300px;
  border-radius: 10px;
  padding: 5px;
  opacity: 1;
  transition: all 2s;
  box-shadow: inset 0 -2px 3px 0 rgba(0, 0, 0, .06);
  z-index: 999;
}
.cun_editor_hover_dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .3s;
  position: relative;
  width: 35px;
  height: 35px;
  background: #FFE5DF;
  border-radius: 10px;
}
.cun_editor_hover_dropdown_box:after {
  content: "";
  position: absolute;
  top: -5px;
  left: 12px;
  width: 12px;
  height: 12px;
  background: var(--whiteColor);
  transform: rotate(45deg);
  z-index: -1;
}
.cun_editor_hover_dropdown svg {
  width: 16px !important;
  fill: #ff776b;
}
.cun_img_editor_active {
  background: #ebebeb;
}
.cun_tooltipEle {
  text-align: center;
  position: absolute;
  bottom: calc(100% + -2px);
  width: 100px;
  background-color: #8417af;
  border-radius: 5px;
  font-size: 10px;
  left: 50%;
  transform: translate(-50%, -20%);
  opacity: 0;
  color: #ffffff;
  line-height: 26px;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 999;
  top: auto;
  bottom: -40px;
}
.cun_image_creator_text_btn:hover span.cun_tooltipEle {
  visibility: visible;
  opacity: 1;
}
.cun_tooltipEle:before {
  content: '';
  width: 1px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #8417af;
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  margin: auto;
  transform: rotate(180deg);
}
.cun_img_editor_tool_item_box svg {
  width: 25px;
  fill: var(--sch-title-color);
}

.cun_img_editor_tool_item_box h6 {
  color: #72778F;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  padding: 0px 0 0 30px;
  margin: 0;
  text-transform: capitalize;
}

.cun_img_editor_tool_item_box h6 span{
  position: absolute;
  left: 0;
  top: 0;
}
.cun_img_editor_tool_item_box > h6  > span.sch-drawingArrow {
  position: absolute;
  right: 0px;
  left: auto;
}
.cun-drawingMenu {
  background-color: #FAF6FF;
  padding: 10px 23px;
  border-radius: 0px 0px 20px 20px;
}
.cun-drawingMenu ul li a{
  color: #72778F;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  padding: 0px 0 0 30px;
  margin: 0;
  text-transform: capitalize;
}
.cun-drawingMenu ul li a span{
  position: absolute;
  left: 0;
  top: 0;
}
.cun-drawingMenu > ul > li {
  padding: 0 0 7px 0;
}
.cun-drawingMenu > ul > li:nth-child(2){
  padding: 0;
}

/* header2 */
.cun_edit_toolbox_height {
  height: 70px;
  max-height:70px;
}
.cun_img_editor_main_box_tool {
  background-color: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 30px;
}
.cun_editor_tool_box_flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  transition: all .5s;
}
.image_editor_range {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.image_editor_range .range input[type="range"] {
  -webkit-appearance: none;
  margin-left: 0px;
  width: 100%;
  height: 7px;
  background: #E2DAFF;
  border-radius: 5px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.image_editor_range .range input::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: ew-resize;
  background: var(--sch-primary-color);
}
.image_editor_range .cun_color_picker_toggle {
  margin-left: 10px;
  padding: 0 20px;
  height: 40px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--sch-border-color1);
  background-color: #F6F4FF;
}
.cun_color_picker_toggle h6 {
  font-weight: 500;
  font-size: 14px;
  color: #29325f;
  margin: 0;
}
.cun_image_editor_tool_label {
  font-size: 15px;
  font-weight: 500;
  color: #67678f;
  margin-right: 10px;
}
.justify-content-between {
  justify-content: space-between!important;
}
.ewp_input_size {
  min-width: 90px;
  max-width: 150px;
}
.cun_image_creator_text_btn {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #ebebff;
  color: #55668f;
  font-size: 16px;
  font-weight: 500;
  margin-right: 5px;
  transition: all .5s;
  border: 1px solid var(--sch-border-color1);
  background-color: #F6F4FF;
  transition: all 0.5s;
}
.cun_image_creator_text_btn svg {
  width: 16px;
  fill: #7a87ab;
  transition: all 0.5s;
}
.cun_image_creator_text_btn:hover {
  background: #8417af;
  color: #55668f;
}
.cun_image_creator_text_btn:hover svg {
  fill: #ffffff;
}
.cunFlipSection {
  padding: 0 0 0 10px;
}
.cun_image_creator_text_btn:hover .rz_tooltipEle {
  opacity: 1;
  visibility: visible;
}
.cun_image_creator_text_btn:hover .rz_tooltipEle {
  opacity: 1;
  visibility: visible;
}








/* right button css */

.cun_image_editor_btn_box {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  height: 100%;
  margin: 20px 0px 0px 0px;
  flex-wrap: wrap;
}
.cun_hidebtn {
  display: none !important;
}
.space_nowrap {
  white-space: nowrap;
}
.cun_btn {
  position: relative;
  color: var(--whiteColor);
  padding: 0px 12px;
  line-height: 40px;
  border: 0px;
  border-radius: 0px;
  height: 45px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background: var(--gradientColor);
 
  transition: all .5s ease;
}
.cun_btn svg {
  width: 25px;
  fill: var(--whiteColor);
  transition: .5s all ease;
}
.cun_btn:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  height: 100%;
  width: 0%;
  transition: none;
  border-radius: 5px;
}
.cun_addAccBtn_blk {
  color: var(--whiteColor);
  padding: 0px 15px;
  line-height: 45px;
  border: 0px;
  height: 45px;
  position: relative;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all .6s;
  text-align: center;
  box-shadow: 0px 8px 33.12px 2.88px rgba(141, 97, 254, 0.12);
  background-image: linear-gradient(180deg, #484766 0%, #181E35 100%);
  background-image: -ms-linear-gradient(180deg, #484766 0%, #181E35 100%);
  background: linear-gradient(180deg, #484766 0%, #181E35 100%);
}
.cun_addAccBtn_blk svg {
  width: 25px;
  fill: var(--whiteColor);
}
.cun_addAccBtn_blk:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  height: 100%;
  width: 0%;
  transition: none;
  border-radius: 5px;
}
/* left section css start */
/* setting tab css */
.cun_editor_platform_bg {
  background-color: #ffffff;
  border-radius: 10px;
  max-width: 350px;
  margin-right: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  right: 20px;
  transition: all 0.5s;
  padding: 15px 0px 150px;
  z-index: 999;
  top: 110px;
}
.cun_sidebarBtnRight .cun_editor_tool_sidebar2 {
  position: absolute;
  right: auto;
  border-radius: 10px 10px 0px 0px;
  left: -64px;
  transform: rotate(-90deg);
  top: 60px;
  background: var(--gradientColor);
  color: #ffffff;
  padding: 8px 16px 8px 17px;
  display: none;
}
a.cun_sidebarBtnRight .cun_editor_tool_sidebar1 {
  position: absolute;
  right: auto;
  border-radius: 20px 20px 0px 0px;
  left: -33px;
  transform: rotate(-90deg);
  top: 30px;
  background: var(--gradientColor);
  padding: 10px 9px 1px 9px;
  display: none;
}
a.cun_sidebarBtnRight .cun_editor_tool_sidebar1 svg {
  transform: rotate(90deg);
  position: relative;
  top: -4px;
}
.cun_sidebarBtnRight svg {
  fill: #ffffff;
  width: 13px;
  height: 13px;
}
.cun-img-editor-left-res a {
  position: absolute;
  right: auto;
  border-radius: 0 0 20px 20px;
  right: -33px;
  transform: rotate(-90deg);
  top: 30px;
  background: var(--gradientColor);
  padding: 10px 9px 1px 9px;
}
.cun-img-editor-left-res a svg {
  fill: #ffffff;
  width: 13px;
  height: 13px;
  transform: rotate(270deg);
  position: relative;
  top: -6px;
}

.cun_editor_platform_box {
  display: grid;
  grid-gap: 20px;
  grid-gap: 20px;
  grid-gap: 20px;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 2fr));
  height: 100%;
  position: relative;
}
.cun_overflow_scroll {
  /* max-height: calc(100% - 75px); */
  overflow: auto;
  width: 100%;
  margin-top: 10px;
}
.sch-right-inner{
  overflow: unset;
}
.p-25 {
  padding: 0px 25px 25px;
}
.cun_img_editor_side_layers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  /* padding: 0px 10px; */
}
.cun_img_editor_side_layers h6 {
  font-size: 20px;
  margin: 0;
}
.cun_image_creator_blk_box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}
.cun_image_creator_blk_btn {
  position: relative;
  color: var(--whiteColor);
  padding: 0px 20px;
  border: 0px;
  height: 40px;
  font-size: 13px;
  width: -moz-fit-content;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  gap: 10px;
  cursor: pointer;
  transition: all .6s;
  text-align: center;
  box-shadow: 0px 8px 33.12px 2.88px rgba(141, 97, 254, 0.12);
  background:var(--gradientColor);
}
.cun_image_creator_blk_btn svg {
  width: 14px;
  fill: #ffffff;
}
.cun_image_editor_label {
  font-size: 14px;
  font-weight: 600;
  color: #67678f;
  margin-right: 10px;
  padding: 0;
}
.ewp_input {
  position: relative;
}
.cun_editor_setting_input {
  /* padding: 0 15px; */
  position: relative;
  height: 40px;
  background-color: #ebebff;
  display: flex;
  align-items: center;
  width: 100%;
  color: #29325f;
  outline: none;
  border-radius: 30px;
  border: 1px solid transparent;
  font-weight: 500;
  font-size: 12px;
  box-shadow: 0px 8px 32px 0px rgba(44, 90, 183, 0.04);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.cun_image_creator_linear_btn {
  position: relative;
  color: var(--whiteColor);
  padding: 0px 20px;
  border: 0px;
  height: 38px;
  font-size: 13px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  gap: 10px;
  cursor: pointer;
  transition: all .6s;
  text-align: center;
  background: var(--gradientColor);
  text-transform: capitalize;
}

.cun_editor_chose_size_box {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.cun_image_editor_heading_div {
  max-width: 320px;
  width: 100%;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 15px;
  cursor: pointer;
  border: 1px solid var(--sch-border-color1);
  background-color: #F6F4FF;
}
.cun_image_editor_heading_div p {
  color: #161930;
}

.cun_editor_square {
  max-width: 120px;
  min-height: 40px;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #EBEBFF;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all .5s;
}
.cun_editor_square:nth-child(1) svg {
  width: 15px;
}
.cun_editor_square svg {
  width: 25px;
  fill: #67678f;
  height: 20px;
}
.cun_editor_square span {
  font-size: 12px;
  font-weight: 500;
  color: #67678f;
}
.cun_editor_square_active {
  border-color: #8417af;
}
.cun_input_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cun_input_wrapper_div_custom_size .ewp_input_size {
  min-width: 60px;
  max-width: 145px;
}
.ewp_input input, .ewp_input select {
  padding: 0 15px;
  position: relative;
  height: 40px;
  border: 1px solid var(--sch-border-color1);
  background-color: #F6F4FF;
  display: block;
  width: 100%;
  color: #29325f;
  outline: none;
  border-radius:5px;
  font-weight: 500;
  font-size: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}
.cun-TabList {
  border: 1px solid #d5d5d5;
  border-radius: 20px 20px;
  overflow: hidden;
  height: 40px;
  display: flex;
  list-style: none;
  font-size: 18px;
  padding: 0;
}
.cunselectBgTab ul.cun-TabList {
  margin: 0px 0px 15px;
}
.tabs {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  color: var(--headdingColor);
}
.active-tabs {
  font-size: 12px;
  font-weight: 500;
  color: #ffffff !important;
  background: var(--gradientColor) !important;
}
.cun-settingBg .content.cunContentTab {
  padding: 0;
}
.tabs:not(:last-child) {
  border-right: 1px solid #d5d5d5;
}
.active-content {
  display: flex;
}
.content {
  display: none;
  padding: 10px;
}
.cun_input_wrapper_div {
  display: flex;
  align-items: center;
}
.ewp_input {
  position: relative;
}
.ewp_input_size select.subcribe_site {
  /* margin: 0px 0px 0px 10px; */
  appearance: auto;
  border: 1px solid var(--sch-border-color1);
  background-color: #F6F4FF;
}
.ewp_input_size {
  min-width: 90px;
  max-width: 150px;
}
/* ============== text font family =============== */
.cun_custom_form {
  margin-top: 20px;
  position: relative;
  width: 100%;
}
.cun_customInput {
  border: none;
  height: 50px;
  border-radius: 6px;
  box-shadow: none;
  font-size: 14px;
  padding: 0px 10px;
  border: 1px solid #ebebef;
  width: 100%;
  background-color: #ffffff;
  font-family: var(--fontFamily);
}
.cun_fontList_dv {
  max-height: 175px;
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border: 1px solid #ebebef;
  border-top: 0;
  border-radius: 0 0 10px 10px;
  margin-top: 0px;
  position: relative;
}
.cun_fontList_dv .cun_searchBox {
  padding: 6px 12px;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0 0 0 / 15%);
}
.cun_custom_form {
  margin-top: 20px;
  position: relative;
  width: 100%;
}
.cun_searchBox .cun_custom_form {
  margin-top: 0;
}
.cun_customInput {
  border: none;
  height: 50px;
  border-radius: 6px;
  box-shadow: none;
  font-size: 14px;
  padding: 0px 10px;
  border: 1px solid #ebebef;
  width: 100%;
  background-color: #ffffff;
}
.cun_custom_form.cun_customLoader input {
  margin: 0px 0px 10px;
  border: 1px solid var(--sch-border-color1);
  background-color: #F6F4FF;
}
.cun_inputIcon {
  margin-left: 10px;
}
.cun_custom_form .cun_inputIcon {
  position: absolute;
  right: 25px;
  transform: translate(-50%, 50%);
}
.cun_fontList_dv .cun_searchBox .cun_inputIcon {
  right: 10px;
}
.cun_image_editor_text_tool {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.cun_input_wrapper {
  display: flex;

  justify-content: space-between;
  align-items: center;
}
.editor_checkbox input[type=checkbox] {
  display: none;
}
.editor_checkbox input[type=checkbox] {
  display: none;
}
.editor_checkbox input[type="radio"], input[type="checkbox"] {
  display: none;
}
.editor_checkbox label span {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--sch-border-color1);
    background-color: #F6F4FF;
  color: #55668f;
  margin-right: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.editor_checkbox label span svg {
  fill: #55668f;
  width: 16px;
}












/*=============== color css ==========*/
.cun_color_picker_wrapper {
  max-width: 200px;
  min-width: 150px;
  position: relative;
}

.cun_color_picker_wrapper>.cun_color_picker_toggle {
  height: 45px;
  border: 1px solid transparent;
  border-radius: 30px;
  background-color: #ebebff;
  padding: 10px 15px 10px 10px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
}
.cun_color_picker_wrapper>.cun_color_picker_toggle input {
  width: 35px;
  background: transparent;
  border: none;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
}
.cun_color_picker_wrapper>.cun_color_picker_toggle span {
  font-size: 14px;
  font-weight: 500;
  color: #67678f;
}
/* color css end */


.cun_image_editor_def_color {
  display: flex;
  grid-gap: 5px;
  gap: 10px;
  flex-wrap: wrap;
}
.cun_image_editor_def_color_box {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.active-content {
  display: flex;
}
.cun_editor_setting_tabs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.cun_editor_setting_tabs_img {
  width: 84px;
  height: 84px;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all .5s;
}
.cun_editor_setting_tabs_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cun_input_wrapper_div {
  display: flex;
  align-items: center;
}
.image_editor_range {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cun_input_wrapper_div {
  display: flex;
  align-items: center;
}
.cun_color_picker_wrapper {
  max-width: 200px;
  min-width: 150px;
  position: relative;
}
.cun_color_picker_wrapper>.cun_color_picker_toggle {
  height: 45px;
  border-radius: 30px;
  padding: 10px 15px 10px 5px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--sch-border-color1);
    background-color: #F6F4FF;
}


.cun_editorImg-wrap ul.nav.nav-tabs {
  padding: 0px;
}
.sch-library-tab.cun_editorImg-wrap .sch-library-tab-inner {
  padding: 20px 0px 80px;
}
.content.cunContentTab {
  padding: 20px 0 0 0;
}
.cun_editorImg-wrap .nav-tabs .nav-link {
  padding: 0px 8px 15px;
  margin: 0 0 0px;
}
/* .sch-library-tab.cun_editorImg-wrap .sch-search-section {
  margin: 20px 0px 0px;
} */
/*========== setting sidebar css start end ==========*/
/*========== filter css start ============*/
.cun_editor_filter_box {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
}

.cun_editor_filter_box button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background: #eaeafd;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  color: #55668f;
  border: 1px solid var(--sch-border-color1);
  background-color: #F6F4FF;
}
.cun_editor_accordion {
  background: #ffffff;
  border: 1px solid #ffffff;
  width: 100%;
  height: 100%;
  transition: 0.4s;
  border-radius: 5px;
  border: 1px solid var(--sch-border-color1);
  background-color: #F6F4FF;
}
.cun_editor_filter_box label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  /* background: #eaeafd; */
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 500;
  color: #55668f;
}
.cun_editor_filter_box .cun_editor_accordion:hover {
  border: 1px solid #8417af !important;
}

















/* editor center css start */
.cun-canvas-mainwrapper {
  /* padding: 0 460px 100px 450px; */
  /* position: absolute;
  top: 0px;
  left: 0;
  right: 0; */
}
.cun_canvasHolder {
  /* border: 1px solid var(--primaryColor); */
  overflow: hidden;
  
}
.cun_img_editor_main_box {
  background-color: var(--whiteColor) !important;
  margin: 0 auto;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border: 2px solid #8417af;
}
.Landscape {
  max-width: 935px !important;
  height: auto !important;
  aspect-ratio: 16/9;
  transition: all.5s;
}
.landscaperation {
  aspect-ratio: 16/9;
  max-width: 100% !important;
  height: auto !important;
}
.canvas-container, .canvas-container canvas {
  width: 100% !important;
  aspect-ratio: 16/9;
}
.landscaperation .canvas-container, .landscaperation .canvas-container canvas {
  max-width: 100% !important;
  aspect-ratio: 16/9;
  transition: all.5s;
  height: auto !important;
}
.Landscape .canvas-container, .Landscape .canvas-container canvas {
  aspect-ratio: 16/9 !important;
}
.cun-img-editor-left-res {
  display: none;
}
.cun_editor_platform_box .sch-library-tab-inner {
  /* height: 100vh; */
  margin: 0 10px 0 0;
}
.cun-settingBg {
  height: 380px;
}

.sch-right-inner.sch-editorBox {
  margin: 0px;
}
.cun_editor_platform_box .nav-tabs .nav-link {
  padding: 7px 7px 15px;
  margin: 0;
  border: 1px solid #ffffff;
}
.cun_editor_platform_box .sch-post-heading-flex > h2 {
  margin: 0;
}
li.sch-active-aiBox {
  border-radius: 5px;
  border: 2px solid #0e0e0e;
}
.cun-canva-undo-redo-btnWrap{
  display: none;
}
.pxl-customModel{
  position: relative;
}
.pxl-customModel .modal-backdrop.pxl-editor-customModel .modal-content a.rz_closeIcon {
  position: absolute;
  right: -1px;
  top: -2px;
  z-index: 1;
}
.cun-canva-undo-redo-btnWrap > ul {
  /* background-color: #ffffff; */
  max-width: 230px;
  /* margin: 20px auto 0px; */
  margin: 0px auto 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  border-radius: 10px;
  gap: 10px;
}
.cun-canva-undo-redo-btnWrap > ul>li>a {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid var(--sch-border-color1);
  background-color: #F6F4FF;
  padding: 5px 20px;
}
.cun_editor_platform_bg .cun-headreSelect {
  padding: 0;
}
.row.py-3.pxl-editor-row-py3-none {
  display: none;
}
.pxl-customModel .modal-backdrop.pxl-editor-customModel {
  background-color: #0000009c;
}
.pxl-customModel .modal-backdrop.pxl-editor-customModel .modal-content {
  background: #ffffff;
  width: 600px;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 35%;
  border-radius: 8px;
}
.modal-backdrop.pxl-editor-customModel .modal-body .cun_edit_svg_modal .canvas-container canvas.pxlModel-canvas {
  width: auto !important;
  height: auto !important;
  aspect-ratio: unset;
}
/*********** COlor picker css *********************/

.pu_color_picker_wrapper{
  max-width: 200px;
  position: relative;
}
.pu_color_picker_wrapper > .pu_color_picker_toggle{
  height: 54px;
  border: 1px solid #e5eaf2;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 8px 20px 0px rgba(177, 193, 224, 0.08);
  padding: 10px 10px 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.pu_color_picker_wrapper > .pu_color_picker_toggle > .pu_color_name{
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  flex: 1;
  padding: 0 0 0 15px;
}
.pu_color_picker_wrapper > .pu_color_picker_toggle > .pu_color_preview{
  width: 36px;
  height: 36px;
  border-radius: 8px;
  border: 1px solid #e5eaf2;
}
.pu_color_picker_wrapper > .pu_color_picker_dropdown{
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  /* border: 1px solid #ebe7e7; */
  border-radius: 8px;
  /* opacity: 0;
  visibility: hidden; */
  transform: translateY(10px);
  transition: all 0.3s cubic-bezier(0.25,0.8,0.25,1);
  z-index: 1;
  padding: 8px;
  width: 292px;
}
.pu_color_picker_wrapper.open > .pu_color_picker_dropdown{
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.sketch-picker{
  box-shadow: none !important;
  width: 100% !important;
  padding: 0 !important;
}
.sketch-picker > div:first-child{
  border-radius: 6px;
  margin-bottom: 10px;
}
.sketch-picker .flexbox-fix{
  display: none !important;
}
.hue-horizontal{
  border-radius: 15px !important;
}

.hue-picker{
  margin-bottom: 15px;
}
.twitter-picker{
  box-shadow: none !important;
}
.twitter-picker > div:first-child{
  display: none;
}
.twitter-picker > div:nth-child(2){
  display: none;
}
.twitter-picker > div:nth-child(3){
  padding: 0 !important;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
.twitter-picker > div:nth-child(3) > span{
  flex: none;
}
.twitter-picker > div:nth-child(3) > span > div{
  width: 25px !important;
  height: 25px !important;
  border-radius: 25px !important;
  margin: 0px!important;
}
.twitter-picker > div:nth-child(3) > div:nth-last-child(2){
  display: flex;
  width: 100%;
}
.twitter-picker > div:nth-child(3) > div:nth-last-child(3){
  display: none !important;
}
.twitter-picker > div:nth-child(3) > div:nth-last-child(2) > input{
  width: 100% !important;
  margin-top: 4px;
  height: 38px !important;
  border-radius: 0 6px 6px 0 !important;
  border: 1px solid #e5eaf2 !important;
  box-shadow: none !important;
  padding-left: 15px !important;
  margin-left: 42px;
  text-transform: uppercase;
}
.twitter-picker > div:nth-child(3) > div:nth-last-child(2)::after{
  content: "#";
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 4px;
  left: 0;
  background-color: #eef1f5;
  border-radius: 6px 0 0 6px;
  font-size: 18px;
  font-weight: 300;
  border: 1px solid #e5eaf2;
  border-right: 0;
}
.twitter-picker > div:nth-child(3) > div:nth-last-child(1){
  display: none;
}
.pxl-customModel .modal-backdrop.pxl-editor-customModel .cun_edit_svg_modal {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  max-width: 290px;
}
.cun_editor_overlaynone {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}
.cun_editor_tool_disable_overlay {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  cursor: not-allowed;
}
.cun_editor_tool_disable_overlay:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* background-color: #0000003b; */
  border-radius: 5px;
  cursor: not-allowed;
}

.cun_img_editor_bg .sch-library-tab-inner {
  height: auto;
}
.cun_img_editor_main_box_tool.pxl-toolbox-responsive {
  display: none;
}
.cun_image_editor_heading_text.cun-img-editor-head-responsive {
  display: none;
}
/* =====================media css  =====================*/
@media(max-width:1840px){
  /* .Landscape {
    width: 730px !important;
    height: auto !important;
} */

}
@media(max-width:1800px){
  .cun_img_editor_main_box {
    transform: scale(0.8);
  }
}
@media(max-width:1600px){

  .cun_img_editor_tool_box {
    border-radius: 8px;
    margin: 20px 0 0 0;
    padding: 5px 5px 25px;
}
  .cun_editor_tool_sidebar2{
    display: block;
  }
  .cun_editor_platform_bg {
    /* position: fixed;
    right: -350px;
    transition: all 0.5s;
    box-shadow: -8px 4px 10.199999809265137px 0px #9F80C633;
    top: 80px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 15px 0px 110px; */
}
.cun_sidebarBtnRight .cun_editor_tool_sidebar2{
  /* display: block;
  left: -65px; */
}
.cun_editor_platform_bg.isOpenSidebarRight {
  right: 0px;
}
a.cun_sidebarBtnRight .cun_editor_tool_sidebar1{
  display: block;
}
.cun_edit_toolbox_height {
  /* height: auto; */
}
.cun_img_editor_main_box_tool {
  /* width: 100%;
  max-width: 700px;
  margin: 0px auto 20px;
  padding: 20px 20px; */
}
.cun-canvas-mainwrapper {
  top: 40px;
}
}
@media(max-width:1500px){
  .cun_img_editor_active {
    background-color: #FAF6FF;
}
  .cun_img_editor_bg {
    padding: 10px 10px;
}
  a.cun_editor-backbtn {
    display: block;
    width: fit-content;
    margin: 0 auto;
}
  .cun_image_editor_heading_text.cun-img-editor-head-desktop {
    display: none;
}
.cun_image_editor_heading_text.cun-img-editor-head-responsive {
  display: block;
  text-align: center;
  background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
  padding: 10px 0 0;
  border-radius: 5px;
}
.cun_image_editor_heading_text p {
  margin: 0px auto 0;
  color: #ffffff;
}
  .cun_image_editor_heading_text {
    display: block;
}

.cun_image_editor_btn_box {
  margin: 0;
}
}
@media(max-width:1200px){

  .cun_img_editor_tool_box {
    z-index: 11;
}
.cun_image_editor_heading_text {
  /* position: relative;
  top: -20px;
  left: -40px; */
}
.cun-img-editor-left-res a {
  top: 70px;
}
.cun_sidebarBtnRight .cun_editor_tool_sidebar2{
  top: 70px;
}
  .cun_img_editor_tool_box {
    /* background-color: #F6F4FF;
    border-radius: 0px;
    position: fixed;
    left: 0;
    width: 350px;
    height: 100vh;
    z-index: 1;
    top: 80px;
    max-width: 100%;
    left: -350px;
    transition: all 0.5s;
    border: 1px solid var(--sch-border-color1); */
}
.cun-editor-settingInner {
  /* padding: 10px 30px 210px; */
  position: relative;
  overflow-y: auto;
  height: 100%;
}
.cun-img-editor-left-res {
  display: block;
}
.cun_img_editor_tool_box.settingSidebarOpen {
  left: 0;
}
.Landscape {
  width: 822px !important;
}
.cun-drawingMenu {
  background-color: #ffffff;
}
.cun-canvas-mainwrapper {
  position: relative;
}
}
@media(max-width:991px){
  .cun_img_editor_tool_box {
    top: 69px;
}
.cun_editor_platform_bg{
  top: 69px;
}
  /* .cun_img_editor_tool_box {
    top: 70px;
}
  .cun_image_editor_btn_box{
    height: auto;
  }
  .cun_editor_platform_bg {
    top: 70px;
}
  .cun_img_editor_main_box {
    max-width: 500px;
}
  .Landscape {
    width: 742px !important;
}

.cun_image_editor_heading_text {
  top: -30px;
} */

.pxl-customModel .modal-backdrop.pxl-editor-customModel .modal-content {
  width: 430px;
}
}
@media(max-width:768px){

  /* .Landscape {
    max-width: 390px !important;
}
.cun_img_editor_main_box_tool {
  max-width: 450px;
} */
}
@media(max-width:575px){
  .cun_editor_platform_bg {
    top: 55px;
}

  /* .cun_image_editor_heading_text {
    top: -40px;
}
  .cun_img_editor_main_box_tool {
    max-width: 400px;
}
  .cun_img_editor_tool_box {
    top: 57px;
}
  .Landscape {
    width: 452px !important;
}
.cun_img_editor_main_box {
  max-width: 380px;
}
.cun_editor_platform_bg {
  top: 55px;
} */
}
@media(max-width:580px){

.cun_img_editor_tool_box {
  top: 50px;
}
.cun_img_editor_tool_box {
  width: 300px;
  left: -300px;
}
.cun-img-editor-left-res a {
  top: 40px;
}
}
@media(max-width:480px){
  .cun_img_editor_tool_box {
    width: 230px;
    left: -230px;
}
  .cun_sidebarBtnRight .cun_editor_tool_sidebar2 {
    display: block;
    left: -68px;
}


  /* .cun_image_editor_heading_text {
    top: -10px;
}
  .cun_img_editor_main_box_tool {
    max-width: 280px;
}
  .cun_img_editor_main_box {
    max-width: 280px;
  }
  .cun_img_editor_tool_box{
    width:210px;
    left: -210px;
  }
  .cun_editor_platform_bg{
    max-width: 280px;
    right: -280px;
  }
  .Landscape {
    width: 330px !important;
}
.Portrait {
  width: 300px !important;
} */
.cun_editor_platform_bg {
  max-width: 320px;
  right: -320px;
}
.cun-img-editor-left-res a {
  top: 25px;
}
.pxl-customModel .modal-backdrop.pxl-editor-customModel .modal-content {
  width: 340px;
}
.modal-backdrop.pxl-editor-customModel .modal-body .cun_edit_svg_modal .canvas-container canvas.pxlModel-canvas {
  height: 100% !important;
  width: 100% !important;
}
}
@media(max-width:380px){
  .pxl-customModel .modal-backdrop.pxl-editor-customModel .modal-content {

    width: 290px;
}
  .cun-img-editor-left-res a {
    top: 30px;
}
  .cun_editor_platform_bg {
    max-width: 270px;
    right: -270px;
    top: 50px;
}

  /* .cun_img_editor_main_box_tool {
    max-width: 250px;
}
  .Landscape {
    width: 270px !important;
  }
  .Portrait {
    width: 260px !important;
}
.cun_img_editor_main_box {
  max-width: 230px;
}
.cun_editor_platform_bg {
  top: 45px;
}
.cun_img_editor_tool_box {
  top: 50px;
} */
}


