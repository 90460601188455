/* 
[Master Stylesheet] 
Product: PixaLab 
----------------------------
Table of content
1) Global
-  Main Body
-  Link
-  Image Width
-  HTML List
-  Button
9) Responsive
*/
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* 1) Global */

:root {
	--sch-global-family: 'Outfit', sans-serif;
	--sch-title-family: 'Outfit', sans-serif;
	--sch-body-bg: #fcfbff;
	--sch-white: #ffffff;
	--sch-secondary: #f7f3f4;
	--sch-primary-color: #8417AF;
	--sch-global-color: #72788F;
	--sch-title-color: #161930;
	--sch-border-color: #E9EDF6;
	--sch-color1: #AD9FC1;
	--sch-color2: #F6F4FF;
	--sch-color3: #CDC3FC;
	--sch-color4: #EDE3F6;
	--sch-color5: #9487CB;
	--sch-border-color1: #E5DFFE;
	--sch-global-size: 14px;
	--sch-title-size: 16px;
	--sch-transition: all 0.3s;
	--sch-gradient-bg: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%), #FFF;
}

html,
body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

* {
	outline: 0 !important;
	box-sizing: border-box;
}


/*--------------------------- Main Body ---------------------------*/

body {
	font-family: var(--sch-global-family);
	font-weight: 400;
	font-size: var(--sch-global-size);
	line-height: 1.6;
	background-color: #F7F3FC;
	color: var(--sch-global-color);
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
}

::-webkit-scrollbar {
	width: 6px;
}

::-webkit-scrollbar-track {
	background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
	background-color: var(--sch-primary-color);
	border-radius: 60px;
}


/* heading  */

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--sch-title-color);
	margin: 0 !important;
	/* text-transform: capitalize; */
	font-family: var(--sch-title-family);
	line-height: 1.2;
	font-weight: 500;
}

label {
   text-transform: capitalize;
}

/* Link */

a {
	color: inherit;
}
/* .css-13cymwt-control:focus {
    border: 2px solid #8417af;
}
input[type=text]:focus {
	border-color:#8417af !important;
	border-radius: 5px;
  }
  .css-13cymwt-control:focus {
    border: 1px solid #8417af !important;
} */
.css-qr46ko{
	padding: 0px !important;
  }
.css-19bb58m > input:focus-visible {
    padding: 0px 40px!important;
  }
  .css-19bb58m > input:focus {
    padding: 0px 40px!important;
  }
  input:focus-visible {
	box-shadow: 0 0 0 1px #2196f3 !important;
	border-radius: 3px; 
  }
  input:focus {
	box-shadow: unset !important;
  }
  select:focus-visible {
	box-shadow: 0 0 0 1px #2196f3 !important;
	border-radius: 3px !important;;
	outline: none;
  }
  /* a:focus{
	color: #2196f3 !important;;
  } */
  a:focus-visible{
	box-shadow: 0 0 0 1px #2196f3 !important;
  }
  button:focus-visible{
	box-shadow: 0 0 0 1px #2196f3 !important;
  }
  textarea:focus{
	/* box-shadow: 0 0 0 1px #2196f3 !important; */
  }
  textarea:focus-visible{
	box-shadow: 0 0 0 1px #2196f3 !important;
  }
  button.sch-btn-iconLeft:focus {
    box-shadow: 0 0 0 1px #2196f3 !important;
}

.css-d7l1ni-option{
	cursor: pointer !important;
  }
  .css-tr4s17-option{
	cursor: pointer !important;
  }
/* a:hover,
a:focus,
button,
button:hover {
	color: var(--sch-primary-color);
} */

a,
a:hover,
a:focus,
button,
button:hover {
	text-decoration: none;
	-webkit-transition: var(--sch-transition);
	-moz-transition: var(--sch-transition);
	-ms-transition: var(--sch-transition);
	-o-transition: var(--sch-transition);
	transition: var(--sch-transition);
}


/* Image Width */

img {
	max-width: 100%;
}

.css-19bb58m > input:focus-visible {
	padding: 0px 40px!important;
  }
  .css-19bb58m > input:focus {
	padding: 0px 40px!important;
  }
/* HTML List */

ul,
ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

.sch-section-spacer {
	padding: 80px 0 50px;
}

.has-solid-bg {
	background: var(--sch-secondary);
}
.pxlText-capital {
	text-transform: capitalize !important;
  }
  .pxl-body-white{
	background-color: #ffffff;
  }
 
/* ===========default css start ==================*/
.sch-divide-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sch-divide-flex.sch-section-bottom-space {
    padding: 0 0 30px;
}

/*================== button =====================*/
/* ====common btn=== */
.pxlCommon-btn {
	cursor: pointer;
	position: relative;
	padding: 4px 20px 4px 20px;
	border-radius: 5px;
	color: var(--sch-white);
	height: 50px;
	font-size: 14px;
	font-weight: 500;
	box-shadow: none;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
	border: 0;
	flex-wrap: wrap;
	background: -o-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#994E8F', endColorstr='#F3527C', GradientType=1);
	background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
    min-width: 120px;
	max-width: fit-content;
	border: 1px solid #6f10b9;
	/* box-shadow: 0px 20px 50px 0px #6927F24D; */
  }
/*== button css ==*/
button.sch-btn-iconLeft {
    position: relative;
    background: var(--sch-primary-color);
    padding: 4px 20px 4px 5px;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    color: var(--sch-white);
    height:45px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    box-shadow: none;
    display: flex;
    align-items: center;
	justify-content: flex-start;
    position: relative;
    overflow: hidden;
    border: 0;
    flex-wrap: wrap;
    /* text-transform: capitalize; */
    background: -o-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#994E8F', endColorstr='#F3527C', GradientType=1);
    background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	border: 1px solid #6f10b9;
}
button.sch-btn-iconLeft:before {
    content: '';
    display: block;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
    background-size: 210% 100%;
    background-position: right bottom;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}
button.sch-btn-iconLeft > span {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 13px 0 0;
    /* background: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%); */
    background: linear-gradient(91deg, #C02A93 8.52%, #6F10B9 110.1%);
    border-radius: 3px;
}
button.sch-btn-iconLeft:hover:before {
    background-position: left bottom;
}
/*== button css ==*/
a.sch-loadmoreBtn {
	color: var(--sch-primary-color);
	font-size: 16px;
	font-weight: 500;
	transition: all 0.5s;
	border: 1px solid transparent;
}
.sch-btn {
	background: var(--sch-primary-color);
	padding: 12px 20px;
	width: fit-content;
	border-radius: 5px;
	color: var(--sch-white);
	height: 50px;
	/* min-width: 170px; */
	min-width: fit-content;
	font-size: 14px;
	font-weight: 500;
	justify-content: center;
	box-shadow: none;
	display: flex;
	align-items: center;
	justify-self: center;
	position: relative;
	overflow: hidden;
	border: 0;
	flex-wrap: wrap;
	/* text-transform: capitalize; */
	background: -moz-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	background: -webkit-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	background: -o-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#994E8F', endColorstr='#F3527C', GradientType=1);
	background: -ms-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	border: 1px solid transparent;
}

.sch-btn:before {
	content: '';
	display: block;
	background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
	background-size: 210% 100%;
	background-position: right bottom;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	border-radius: 0px;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
}

.sch-btn:after {
	content: '';
	display: block;
	background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
	background-size: 210% 100%;
	background-position: right bottom;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	border-radius: 0px;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
}

.sch-btn:hover:before {
	background-position: left bottom;
}

.sch-btn:hover:after {
	background-position: right bottom;
}



a.sch-btn-iconLeft{
	position: relative;
	background: var(--sch-primary-color);
	padding: 4px 20px 4px 5px;
	width: fit-content;
	border-radius: 5px;
	color: var(--sch-white);
	height: 50px;
	min-width: fit-content;
	font-size: 14px;
	font-weight: 500;
	box-shadow: none;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	overflow: hidden;
	border: 0;
	flex-wrap: wrap;
	/* text-transform: capitalize; */
	background: -moz-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	background: -webkit-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	background: -o-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#994E8F', endColorstr='#F3527C', GradientType=1);
	background: -ms-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	border: 1px solid transparent;
}

a.sch-btn-iconLeft:before{
	content: '';
	display: block;
	background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
	background-size: 210% 100%;
	background-position: right bottom;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	border-radius: 0px;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
}
a.sch-btn-iconLeft:after{
	content: '';
	display: block;
	background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
	background-size: 210% 100%;
	background-position: right bottom;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	border-radius: 0px;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
}
a.sch-btn-iconLeft > span {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 13px 0 0;
    /* background: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%); */
    background: linear-gradient(91deg, #C02A93 8.52%, #6F10B9 110.1%);
    border-radius: 3px;
}

a.sch-btn-iconLeft:hover:before {
	background-position: left bottom;
}

a.sch-btn-iconLeft:hover:after {
	background-position: right bottom;
}
/*=============== right icon button ===========*/
.sch-btn-iconRight{
    background: var(--sch-primary-color);
	padding: 4px 5px 4px 20px;
	width: fit-content;
	border-radius: 5px;
	color: var(--sch-white) !important;
	/* height: 50px; */
	min-width: fit-content;
	font-size: 14px;
	font-weight: 500;
	box-shadow: none;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	overflow: hidden;
	border: 0;
	flex-wrap: wrap;
	/* text-transform: capitalize; */
	background: -moz-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	background: -webkit-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	background: -o-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#994E8F', endColorstr='#F3527C', GradientType=1);
	background: -ms-linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	border: 1px solid transparent;
}
.sch-btn-iconRight > span > img {
    width: 15px;
    height: 15px;
}
.sch-btn-iconRight:before{
    content: '';
	display: block;
	background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
	background-size: 210% 100%;
	background-position: right bottom;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	border-radius: 0px;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
}
.sch-btn-iconRight:after{
    content: '';
	display: block;
	background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgb(253 253 253 / 10%) 50%);
	background-size: 210% 100%;
	background-position: right bottom;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	border-radius: 0px;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
}
.sch-btn-iconRight > span {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 13px;

	background: linear-gradient(91deg, #C02A93 8.52%, #6F10B9 110.1%);
    border-radius: 3px;
}

.sch-btn-iconRight:hover:before {
	background-position: left bottom;
}

.sch-btn-iconRight:hover:after {
	background-position: right bottom;
}

.sch-btn,
.sch-btn:hover,
.sch-btn:focus {
	-webkit-transition: var(--sch-transition);
	-moz-transition: var(--sch-transition);
	-ms-transition: var(--sch-transition);
	-o-transition: var(--sch-transition);
	transition: var(--sch-transition);
	color: var(--sch-white);
}

.sch-btn span {
	position: relative;
	z-index: 1;
}

/*--------------------------- Section Title ---------------------------*/
.sch-font-20-weight-600{
	font-size: 20px !important;
    font-weight: 600 !important;
}

.sch-schedular-heading-name > h2 {
	position: relative;
    color: #161930;
}
.sch-schedular-heading-name > h2:after{
	content: "";
	position: absolute;
	bottom: -14px;
	left: 0;
	height: 4px;
	background: #AE249B;
	right: 0;
}
.sch-see-more-link a{
	position: relative;
	font-size: 14px;
	font-weight: 500;
	color: #8417AF;
	/* text-transform: capitalize; */
}

.sch-see-more-link a span {
    padding: 0 0 0 10px;
}














.sch-title-wrap {
	margin-bottom: 30px;
	border-bottom: 4px double var(--sch-border-color);
	position: relative;
	display: flex;
	flex-wrap: wrap;
	grid-gap: 20px;
	justify-content: space-between;
	padding: 0 0 10px;
}

.sch-title-wrap h2 {
	font-size: 16px;
	font-weight: 700;
	text-align: left;
	margin: 0;
	position: relative;
}

.sch-title-wrap>h3 {
	margin: 0;
}

.sch-title-wrap>h3>a {
	color: var(--sch-primary-color);
	font-size: 14px;
	font-weight: 500;
	/* text-transform: capitalize; */
	display: block;
	padding: 0 20px 0 0;
	position: relative;
}

.sch-title-wrap>h3>a>svg {
	position: absolute;
	right: 0;
	top: 4px;
}

.sch-title-wrap h2:before {
	content: "";
	position: absolute;
	bottom: -14px;
	left: 0;
	height: 4px;
	background: #AE249B;
	right: 0;
}

.sch-title-link {
	color: var(--sch-primary-color);
	font-weight: 500;
}

.sch-title-link span {
	margin-left: 15px;
}

/*--------------------- section title border bottom none end--------------------*/
.sch-borderNoneTitle .sch-title-wrap {
	border-bottom: none;
}

.sch-borderNoneTitle .sch-title-wrap h2:before {
	background: none;
}

.sch-borderNoneTitleDouble .sch-title-wrap {
	border-bottom: none;
}

.sch-rightTitleNone .sch-title-wrap>h3 {
	display: none;
}
.remove.cun-no-design-wrapper .cun-noDesign-inner {
    padding: 80px 0px;
}
/*--------------------- section title border bottom none end--------------------*/
/*--------------------------- Header ---------------------------*/



.sch-container-fluid {
	max-width: 1800px;
	margin: auto;
	padding: 0px 20px 20px;
}


.sch-main-menu .menu>li .sch-menu-toggle {
	display: inline-block;
	vertical-align: middle;
	font-size: 20px;
	margin-bottom: 2px;
	pointer-events: none;
	color: var(--sch-title-color);
}

.sch-main-menu .menu>li:hover .sch-menu-toggle {
	color: var(--sch-primary-color);
	transform: rotate(45deg);
}

.sch-main-menu .menu>li:hover .sch-menu-toggle,
.sch-main-menu .menu>li .sch-menu-toggle {
	-webkit-transition: var(--sch-transition);
	-moz-transition: var(--sch-transition);
	-ms-transition: var(--sch-transition);
	-o-transition: var(--sch-transition);
	transition: var(--sch-transition);
}

@keyframes sticky {
	0% {
		transform: translateY(-80%);
	}

	100% {
		transform: translateY(0%);
	}
}
/* .sch-header-col {
	min-height: 80px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
} */

/* .sch-header-col-sm {
	width: 380px;
} */

.sch-header-col-lg {
	/* width: calc(100% - 800px); */
	position: relative;
}

/* .sch-logo {
	text-align: left;
	width: 100%;
} */

.sch-btns-wrap {
	width: 100%;
}
.cun-headreSelect.cun-headerSelectRes {
    margin: 0 0 30px;
	display: none;
}
.sch-header-col.sch-header-col-sm.sch-headerRight {
    width: fit-content;
}

.sch-header-col.sch-header-col-sm.sch-headerRight .sch-btns-wrap {
    width: 100%;
    display: flex;
}
.sch-header-col.sch-header-col-sm.sch-headerRight .sch-btns-wrap ul {
    grid-gap: 6px;
}
.sch-header-col.sch-header-col-sm.sch-headerRight .sch-btns-wrap {
    width: 100%;
    display: flex;
}

.sch-main-menu .menu>li {
	position: relative;
}

/* .sch-main-menu .menu>li>a {
	display: flex;
	align-items: center;
	padding: 21px 10px 20px;
	font-weight: 400;
	position: relative;
	font-size: 16px;
	color: var(--sch-global-color);
	
} */
.sch-main-menu .menu>li>a>span.sch_draft_count {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: #ffffff;
    background: linear-gradient(91deg, #6F10B9 8.52%, #C02A93 110.1%), #D9D9D9;
    font-size: 10px;
    font-weight: 600;
	margin: 0px 0px 0px 5px;
}
/* .sch-main-menu .menu>li.active a {
	color: var(--sch-primary-color);
} */

/* .sch-main-menu .menu>li>a:before {
	content: "";
	position: absolute;
	bottom:-7px;
	left: 0;
	right: 0;
	margin: auto;
	background: var(--sch-primary-color);
	width: 80%;
	height: 5px;
	border-radius: 10px 10px 0px 0px;
	opacity: 0;
} */

.sch-btns-wrap>ul {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	grid-gap: 15px;
	justify-content: flex-end;
}

.sub-menu>li a {
	font-weight: 700;
	/* text-transform: capitalize; */
	position: relative;
	color: var(--sch-global-color);
}


/* Toggle */

/* .sch-menu-btn {
	width: 45px;
	height: 45px;
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: center;
	border-radius: 5px;
	background: var(--sch-primary-color);
	align-items: center;
	display: none;
	box-shadow: 0 10px 37px -5px rgb(243 82 124 / 16%);
} */

/* .sch-menu-btn span>span {
	display: block;
	width: 25px;
	height: 3px;
	-webkit-transition: var(--sch-transition);
	-moz-transition: var(--sch-transition);
	-ms-transition: var(--sch-transition);
	-o-transition: var(--sch-transition);
	transition: var(--sch-transition);
	margin: 5px auto;
	background: var(--sch-white);
	border-radius: 100px;
} */

/* .sch-btns-wrap.sch-nav-open .sch-menu-btn {
	padding-bottom: 3px;
} */

/* .sch-btns-wrap.sch-nav-open .sch-menu-btn span>span:nth-child(2) {
	opacity: 0;
	visibility: hidden;
}

.sch-btns-wrap.sch-nav-open .sch-menu-btn span>span:nth-child(1) {
	transform: translate(0px, 6px) rotate(-48deg);
}

.sch-btns-wrap.sch-nav-open .sch-menu-btn span>span:nth-child(3) {
	transform: translate(0px, -10px) rotate(48deg);
} */

.sch-info-btn {
	width: 50px;
	height: 50px;
	background: var(--sch-primary-color);
	display: inline-flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	box-shadow: 0 10px 37px -5px rgb(243 82 124 / 16%);
}

.sch-info-btn img {
	height: 20px;
}

/* .sch-info-btn:hover,
.sch-menu-btn:hover {
	background: var(--sch-title-color);
} */
.pxl-tooltip-button-form.pxl-reload-tooltip.pxl-tooltip-btn-diable button.cun-socialIcon.cun-socail-delete.sch-btn-disbale .cun-show-tooltip {
    left: -5px;
}
/* User Drop Down*/
.user-info-wrapper {
	display: flex;
	position: relative;
	flex-wrap: wrap;
}

.user-info {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
	grid-gap: 15px;
}

.user-info:after {
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid #72778F;
	content: "";
}

.user-info img.user-img {
	height: 45px;
	width: 45px;
	object-fit: cover;
	border: none;
	border-radius: 50%;
}

/* .user-info-box {
	max-width: 200px;
	background: #393e4f;
	position: absolute;
	top: 60px;
	border-radius: 15px;
	min-width: 200px;
	right: 0;
	-webkit-transform: translateY(50px);
	-moz-trans-webkit-transform: translateY(50px);
	-ms-trans-webkit-transform: translateY(50px);
	-o-trans-webkit-transform: translateY(50px);
	transform: translateY(50px);
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	box-shadow: 0 0 20px rgb(0 0 0 / 8%);
} */
.user-info-box {
    max-width: 220px;
    background: #ffffff;
    position: absolute;
    top: 60px;
    border-radius: 15px;
    min-width: 220px;
    right: 0;
    -webkit-transform: translateY(50px);
    -moz-trans-webkit-transform: translateY(50px);
    -ms-trans-webkit-transform: translateY(50px);
    -o-trans-webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    /* box-shadow: 0 0 20px rgb(0 0 0 / 8%); */
    border: 1px solid #E9EDF6;
    box-shadow: 0px 0px 60px 0px #58257129;
}
.user-info-box:before {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 6px solid #393e4f;
	content: "";
	position: absolute;
	right: 35px;
	top: -6px;
}

.user-info-box >ul {
	padding: 18px 30px 14px;
	border-top: 1px solid #F3EBFA;
}

.user-info-box > ul > li {
	width: 100%;
	display: inline-block;
	margin-bottom: 10px;
	font-weight: 500;
	/* text-transform: capitalize; */
	text-align: left;
	color: #72778F;
}
.user-info-box > ul > li > a {
    font-size: 16px;
    font-weight: 400;
    color: #72778F;
}
.user-info-box > ul > li > a > span {
    padding-right: 20px;
}

.user-info-box > li > a:hover {
	padding-left: 5px;
}

.user-info-box.show {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateY(0px);
	-moz-trans-webkit-transform: translateY(0px);
	-ms-trans-webkit-transform: translateY(0px);
	-o-trans-webkit-transform: translateY(0px);
	transform: translateY(0px);
}

.user-info-box,
.user-info-box.show {
	-webkit-transition: var(--sch-transition);
	-moz-transition: var(--sch-transition);
	-ms-transition: var(--sch-transition);
	-o-transition: var(--sch-transition);
	transition: var(--sch-transition);
}

.drop-down-header h4 {
	margin: 0;
	font-size: 16px;
	color: var(--sch-global-color);
	font-weight: 400;
	max-width: 80px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.user-info-box li a svg {
	height: 18px;
	vertical-align: middle;
	margin-right: 10px;
}

.pxl-user-info-userName-box {
    padding: 10px 10px 19px 10px;
}
.pxl-user-info-userName-box > ul > li{
	position: relative;
}

.pxl-user-info-userName-box > ul > li > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EDF0FF;
    padding: 6px 9px;
    border-radius: 5px;
	position: relative;
    transition: all 0.5s;
}
.pxl-user-info-userName-box > ul > li>a:after {
    content: '';
    position: absolute;
    left: -10px;
    width: 219px;
    height: 1px;
    background-color: #F3EBFA;
    bottom: -20px;
}
.pxl-user-info-userName-box > ul > li > a > .pxl-userInfoBox {
    display: flex;
    align-items: center;
}
.pxl-user-info-userName-box > ul > li > a > .pxl-userInfoBox > p {
    font-size: 14px;
    font-weight: 400;
    padding: 0 0 0 10px;
	color: #181719;
	/* text-transform: capitalize; */
	max-width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.pxl-userInfoBox1 {
    /* margin: 25px 0 0 10px;
    max-height: 170px;
    position: relative;
    overflow: auto; */
}
.pxl-profile-brandText > h2 {
    font-size: 12px;
    font-weight: 500;
    /* text-transform: capitalize; */
    background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
    display: inline-block;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 25px;
    position: absolute;
    right: 15px;
    bottom: -32px;
    z-index: 1;
	margin: 0;
}

.pxl-userInfoBox1 > ul > li > a{
	display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 9px;
    border-radius: 5px;
    transition: all 0.5s;
}
.pxl-userInfoBox1 > ul > li > a > .pxl-userInfoBox {
    display: flex;
    align-items: center;
}
.pxl-userInfoBox1 > ul > li > a > .pxl-userInfoBox > p {
    font-size: 13px;
    font-weight: 400;
    padding: 0 0 0 10px;
	color: #181719;
	/* text-transform: capitalize; */
	max-width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.pxl-user-info-userName-box > ul > li > a > .pxl-userInfoBox img {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    object-fit: cover;
    object-position: top;
	border: 1px solid #C6CBE5;
}
.pxl-userInfoBox1 > ul > li > a > .pxl-userInfoBox > .pxl-userName-letter > h2 {
    font-size: 12px;
    width: 30px;
    height: 30px;
    background-color: #c6cbe5;
    justify-content: center;
    display: flex;
    align-items: center;
    /* text-transform: capitalize; */
    color: #181719;
    border-radius: 50px;
    border: 1px solid #c6cbe5;
}

.pxl-userInfoBox1 > ul > li > a .pxl-userInfoBox > img {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    object-fit: cover;
    object-position: top;
}

/*------ DashBoard Style  ------*/
.sch-container {
	max-width: 1180px;
	margin: auto;
}
.sch-section-row {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(295px, 1fr)); */
	grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
	
    gap: 15px;
}
.sch-row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
}
/* .sch-grid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
} */
.sch-post-count-wrap{
	display: grid;
	grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}
/* Counts  */
.sch-count-cards {
	padding: 30px 0;
}

.sch-count-widget {
	background: #e2ceff;
	padding: 30px 30px;
	border-radius: 10px;
	position: relative;
	display: flex;
	flex-wrap: wrap;
}

.sch-count-widget:hover {
	-webkit-transform: translateY(-6px);
	-moz-transform: translateY(-6px);
	-ms-transform: translateY(-6px);
	-o-transform: translateY(-6px);
	transform: translateY(-6px);
}

.sch-count-widget,
.sch-count-widget:hover {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.sch-count-icon span {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(180deg, #C9B3F3 0%, rgba(202, 180, 244, 0) 100%);
	height: 44px;
	width: 44px;
	border-radius: 10px;
}

.sch-count-icon {
	width: 100%;
	margin: 0 0 10px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	grid-gap: 10px;
}

.sch-count-widget p {
	color: #72778F;
	font-size: 14px;
}

.sch-count-icon h3 {
	margin: 0;
	font-size: 20px;
	font-weight: 700;
}

.sch-grid .sch-count-widget:nth-child(2) {
	background-color: #fff3d9;
}

.sch-grid .sch-count-widget:nth-child(2):before {
	background-color: #FFD478;
}

.sch-grid .sch-count-widget:nth-child(2) .sch-count-icon span {
	background: linear-gradient(180deg, #FFDE99 0%, rgba(248, 232, 209, 0.22) 100%);
}

.sch-grid .sch-count-widget:nth-child(3) {
	background-color: #c7efff;
}

.sch-grid .sch-count-widget:nth-child(3):before {
	background-color: #73DBFF;
}

.sch-grid .sch-count-widget:nth-child(3) .sch-count-icon span {
	background: linear-gradient(180deg, #97E5FF 0%, rgba(240, 254, 255, 0.35) 100%);
}

.sch-grid .sch-count-widget:nth-child(4) {
	background-color: #fccfcf;
}

.sch-grid .sch-count-widget:nth-child(4):before {
	background-color: #FF9D9D;
}

.sch-grid .sch-count-widget:nth-child(4) .sch-count-icon span {
	background: linear-gradient(180deg, #FFADB8 0%, rgba(255, 242, 240, 0.42) 100%);
}

/*========================= Banner css start =====================*/
.sch-banner-box.pxl-dashboardBanner-box {
	margin: 30px 0 0;
    padding: 0px 20px;
    position: relative;
    color: var(--sch-white);
    text-align: center;
    border-radius: 10px;
    /* background: linear-gradient(90.81deg, #6F10B9 8.52%, #C02A93 110.1%); */
    background-image: url(../../../../public/assets/images/scheduler/schdule-bg.jpg);
    min-height: 230px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    z-index: 22;
    background-position: center;
    background-repeat: no-repeat;
}
button.pxl-create-post-bnr-btn {
    width: 150px;
    height: 46px;
    background-color: #FFEACA;
    border: 1px solid #FFEACA;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    color: #222222;
    font-size: 14px;
    font-weight: 400;
	margin: 8px auto 0;
}
button.pxl-create-post-bnr-btn span {
    padding: 0 10px 0 0;
}
/* position animation */
img.sch-banner-pos1 {
	position: absolute;
	left: 25px;
	top: 50px;
	border-radius: 5px;
	width: 104px;
	animation: 5.4s floating linear infinite;
  }
  
  img.sch-banner-pos2 {
	position: absolute;
	left: 140px;
	bottom: 33px;
	border-radius: 5px;
	animation: 7.4s floating linear infinite;
  }
  
  img.sch-banner-pos3 {
	position: absolute;
	left: 180px;
	border-radius: 5px;
	width: 82px;
	bottom: 130px;
	animation: 9.4s floating linear infinite;
  }
  
  img.sch-banner-pos4 {
	position: absolute;
	left: 240px;
	border-radius: 5px;
	width: 80px;
	bottom: 65px;
	animation: 5.4s floating linear infinite;
  }
  
  img.sch-banner-pos5 {
	position: absolute;
	left: 385px;
	border-radius: 5px;
	bottom: 43px;
	width: 96px;
	animation: 6.4s floating linear infinite;
  }
  
  img.sch-banner-pos6 {
	position: absolute;
	right: 354px;
	top: 41px;
	border-radius: 5px;
	width: 120px;
	animation: 5.4s floating linear infinite;
  }
  
  img.sch-banner-pos7 {
	position: absolute;
	right: 260px;
	border-radius: 5px;
	bottom: 42px;
	width: 118px;
	animation: 7.4s floating linear infinite;
  }
  
  img.sch-banner-pos8 {
	position: absolute;
	right: 110px;
	bottom: 40px;
	border-radius: 5px;
	width: 89px;
	animation: 9.4s floating linear infinite;
  }
  
  img.sch-banner-pos9 {
	position: absolute;
	right: 35px;
	top: 30px;
	border-radius: 5px;
	width: 57px;
	animation: 5.4s floating linear infinite;
  }
  
  @keyframes floating {
	from {
	  -webkit-transform: rotate(0deg) translate(-5px) rotate(0deg);
	  -moz-transform: rotate(0deg) translate(-5px) rotate(0deg);
	  -ms-transform: rotate(0deg) translate(-5px) rotate(0deg);
	  -o-transform: rotate(0deg) translate(-5px) rotate(0deg);
	  transform: rotate(0deg) translate(-5px) rotate(0deg);
	  transition: 1s ease-in-out;
	}
  
	to {
	  -webkit-transform: rotate(360deg) translate(-5px) rotate(-360deg);
	  -moz-transform: rotate(360deg) translate(-5px) rotate(-360deg);
	  -ms-transform: rotate(360deg) translate(-5px) rotate(-360deg);
	  -o-transform: rotate(360deg) translate(-5px) rotate(-360deg);
	  transform: rotate(360deg) translate(-5px) rotate(-360deg);
	  transition: 1s ease-in-out;
	}
  }

  /* position animation */
.sch-banner-box.pxl-dashboardBanner-box .sch-banner-content {
	width: 100%;
}

.sch-banner-box.pxl-dashboardBanner-box .sch-banner-content > h2 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: var(--sch-white);
    margin: 0 0 5px;
    /* text-transform: capitalize; */
}
.sch-banner-box.pxl-dashboardBanner-box .sch-banner-content > p {
	font-size: 22px;
	font-weight: 400;
	padding: 3px 0 0 0;
}
/* banner btn create design post */
.sch-banner-box.pxl-dashboardBanner-box .pxl-banner-create-design-post-btn >ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
	gap: 10px;
}
.sch-banner-box.pxl-dashboardBanner-box .sch-banner-content .sch-search-box {
    max-width: 600px;
    margin: 20px auto 0;
}
.sch-banner-box.pxl-dashboardBanner-box .sch-banner-content .sch-search-box .sch-search-row {
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffff;
    min-height: 50px;
    border-radius: 6px;
    position: relative;
    width: 100%;
}
.sch-banner-box.pxl-dashboardBanner-box .sch-search-box .sch-search-row input {
    width: 100%;
    padding: 0px 40px 0 20px;
}.sch-workspace-main-wrapper.pxl-workspace-repear-boxSingle .sch-workspace-box-fix a {
    padding: 140px 0px;
}
/* search select css */
.sch-banner-select-search-box {
    display: flex;
    align-items: center;
	width: 100%;
}
.sch-banner-select-search-box .cun-headreSelect {
    padding: 0px 0 0 3px;
    /* min-width: fit-content; */
	min-width: 130px;
	width: 250px;
}
.sch-banner-select-search-box .cun-headreSelect .css-b62m3t-container .css-13cymwt-control {
    border: none;
    background-color: #F2E2FF;
}
.sch-banner-select-search-box .cun-headreSelect .css-1fdsijx-ValueContainer .css-1jqq78o-placeholder {
    color: #8417AF !important;
	font-weight: 600 !important;
}
.sch-banner-select-search-box .cun-headreSelect .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer .css-1xc3v61-indicatorContainer svg.css-tj5bde-Svg {
    fill: #8417AF !important;
}

.sch-banner-select-search-box .cun-headreSelect .css-b62m3t-container .css-t3ipsp-control {
    border: none !important;
    background: #F2E2FF;
	height: 45px;
}
.sch-banner-select-search-box .cun-headreSelect .css-b62m3t-container .css-13cymwt-control .css-1fdsijx-ValueContainer .css-1dimb5e-singleValue{
	color: #8417AF !important;
	/* font-weight: 600 !important; */
}
.sch-banner-select-search-box .cun-headreSelect .css-b62m3t-container .css-t3ipsp-control .css-1dimb5e-singleValue{
	color: #8417AF !important;
	/* font-weight: 600 !important; */
}
.sch-banner-select-search-box .cun-headreSelect .css-b62m3t-container .css-t3ipsp-control .css-1hb7zxy-IndicatorsContainer .css-1xc3v61-indicatorContainer svg.css-tj5bde-Svg{
	fill: #8417AF !important;
}
.sch-banner-select-search-box .cun-headreSelect .css-b62m3t-container .css-13cymwt-control {
    height: 45px;
}
/* search select css end */
.sch-banner-box.pxl-dashboardBanner-box .sch-banner-content .sch-search-box .sch-search-row button.sch-search-btn {
    background: transparent;
    border: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    padding: 0;
    width: 50px;
    cursor: pointer;
}
.pxl-banner-create-design-post-btn > ul > li > .pxl-banner-btn button {
    grid-gap: 10px;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #222;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    min-width: 160px;
    padding: 11px 20px;
}
.pxl-banner-create-design-post-btn > ul > li > .pxl-banner-btn.pxl-create-design-btn button {
    background-color: #caffcf;
}
.pxl-banner-create-design-post-btn > ul > li > .pxl-banner-btn.pxl-create-post-btn button {
    background: #ffeaca;
}
/* banner btn create design post end */



.sch-btn-list.sch-btn-list-imageDashboard.pxl-bannerBtn-disable .cun-socialIcon .cun-show-tooltip {
    transform: translate(0, 0px);
    top: -60px;
}



/*================== Search css start ================*/
.sch-search-box {
	margin: 21px 0 0;
}
/* .sch-search-row {
    display: flex;
    flex-wrap: wrap;
    background: var(--sch-white);
    min-height: 45px;
    border-radius: 6px;
    position: relative;
    width: 100%;
} */
/* .sch-search-row.sch-search-bg-color{
    padding: 0;
    background-color: var(--sch-color2);
    border: 1px solid var(--sch-border-color1);
} */

.sch-search-row input {
	width: calc(100% - 160px);
	border: 0;
	background: transparent;
	padding: 1px 20px;
	font-size: 16px;
}

.sch-search-row.sch-search-bg-color input {
	width: 100%;
	padding: 0px 38px 0 15px;
}

.sch-search-row select {
	background-color: #F2E2FF;
	border: 0;
	padding: 8px 10px;
	width: 160px;
	border-radius: 6px;
	color: #8517AE;
	background-image: url(../../../../public/assets/images/scheduler/select-icon.svg);
	background-repeat: no-repeat;
	background-position: center right;
	background-size: 40px 7px;
	-webkit-appearance: none;
	-moz-appearance: none;
	cursor: pointer;
}

.sch-search-section h2 {
	color: #161930;
	font-size: 16px;
	font-weight: 500;
}

.sch-search-rowinput::placeholder {
	color: var(--sch-global-color);
}

.sch-search-btn {
	background: transparent;
	border: 0;
	position: absolute;
	right: 0;
	bottom: 0;
	top: 0;
	height: 100%;
	padding: 0;
	width: 50px;
	cursor: pointer;
}

/* List btn  */
.sch-btn-list {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: 30px 0 0;
}.sch-btn-list.sch-btn-list-imageDashboard.pxl-bannerBtn-disable {
    margin: 20px 0 0;
}

/* Template  Box  */
.sch-col-5 {
	width: 20%;
	padding: 0 15px;
}

.sch-template-box {
	position: relative;
	margin: 0 0 44px;
	border-radius: 10px;
	border: 1px solid #E9EDF6;
    padding: 5px;
	height: fit-content;
	/* display: flex;
	align-items: center;
	justify-content: space-between; */
}
.sch-template-box.schSchedular{
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #ffffff;
}
.sch-template-box.schSchedular .sch-draft-content{
	width: calc(100% - 42px);
	padding: 10px 10px 10px 10px;
}
.sch-template-box.schSchedular.sch-draft-box-imgNone .sch-draft-content {
    width: 100%;
}
.sch-template-box.schSchedular .sch-draft-img-box {
    width: 50px;
    height: 50px;
    min-width: 50px;
	
}
.sch-template-box.schSchedular .sch-draft-img-box img {
    width: 50px;
    height: 50px;
    max-height: 50px;
    min-height: 50px;
    border-radius: 5px;
}
.sch-template-img {
	position: relative;
}
.sch-template-img > img {
    width: 100%;
    border-radius: 10px;
    max-height: 280px;
    min-height: 280px;
	object-fit: cover;
    object-position: center top;
}

.sch-template-box > img {
	width: 100%;
}
button.sch-edit-temp-btn {
    /* text-transform: capitalize; */
}
.sch-design-name > h2 {
    color: #4F536F;
    font-size: 16px;
    font-weight: 500;
}
.sch-date-hours{

}
.sch-date-hours > span {
    color: #72778F;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    top: 3px;
    padding: 0 0 0 10px;
}
.sch-template-img:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 100%;
	background: rgb(0 0 0 / 60%);
	border-radius: 10px;
}
.sch-edit-temp-btn svg{
	fill: #8417AF;
}
.sch-edit-temp-btn {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	color: #8417AF;
    font-size: 14px;
    font-weight: 500;
	display: flex;
    align-items: center;
    justify-content: center;
	margin: auto;
	width: -moz-fit-content;
	width: fit-content;
	height: -moz-fit-content;
	height: fit-content;
	background: var(--sch-white);
	padding: 5px 10px;
	border-radius: 5px;
	transform: translateY(100px);
	opacity: 0;
	visibility: hidden;
}

.sch-edit-temp-btn span {
    padding: 0 0 0 5px;
}

.sch-template-box:hover .sch-edit-temp-btn {
	visibility: visible;
	opacity: 1;
	transform: translateY(0px);
}

.sch-template-box:hover .sch-template-img:before {
	bottom: 0;
}

.sch-template-box,
.sch-template-box:hover,
.sch-template-box:hover .sch-edit-temp-btn,
.sch-template-box .sch-template-img:before,
.sch-template-box:hover .sch-template-img:before {
	-webkit-transition: var(--sch-transition);
	-moz-transition: var(--sch-transition);
	-ms-transition: var(--sch-transition);
	-o-transition: var(--sch-transition);
	transition: var(--sch-transition);
}

.sch-btn-list ul {
	display: inline-flex;
	flex-wrap: wrap;
	grid-gap: 10px 20px;
}

.sch-btn-list ul a {
	background: #CAFFCF;
	display: inline-flex;
	flex-wrap: wrap;
	padding: 11px 20px;
	align-items: center;
	border-radius: 10px;
	min-width: 160px;
	justify-content: center;
	grid-gap: 10px;
	color: #222222 !important;
}

.sch-btn-list ul a span {
	height: 24px;
}
/* recent design box css */
.sch-recent-designnBox .sch-template-img {
    margin: 0px 0px 18px;
}
.sch-project-name {
    padding: 14px 0px 0px 0px;
}
.sch-project-name > h3 {
    color: #8417AF;
    font-size: 13px;
    font-weight: 600;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 50px;
    background-image: linear-gradient(to right, #f1e7f8, #f3e7f7, #f4e8f6, #f6e8f6, #f7e9f5);
}

/*--------------------------- Work space css start  ---------------------------*/
.sch-templates-wrapper.sch-templates-topspace {
	padding: 40px 0 40px 0;
}

.sch-templates-wrapper.sch-templates-wrapper-bottomSpace {
	padding: 0 0 40px 0;
}

.pxl-mydesign-createProjectBox .sch-workspace-box-fix {
	/* width:332px;
    padding: 94px 0px; */
	text-align: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	border-radius: 6px;
	background-image: url(../../../../public/assets/images/scheduler/workspace-bg.jpg);
	display: flex;
    align-items: center;
    justify-content: center;

}

.pxl-mydesign-createProjectBox .sch-workspace-box-fix h2 {
	display: block;
	width: 100%;
	color: #161930;
	font-size: 16px;
	font-weight: 500;
	/* text-transform: capitalize; */
	padding: 20px 0 0 0;
}

/* .pxl-mydesign-createProjectBox .sch-workspace-box-fix a {
	display: block;
	width: 100%;
	color: #161930;
	font-size: 16px;
	font-weight: 500;
	text-transform: capitalize; 
	padding: 20px 0 0 0;
	width: 100%;
	padding: 94px 0px;
	min-height: 305px;
	max-height: 295px;
} */
.pxl-mydesign-createProjectBox > .sch-workspace-box-fix > a {
    display: block;
    width: 100%;
    color: #161930;
    font-size: 16px;
    font-weight: 500;
    /* text-transform: capitalize; */
    padding: 20px 0 0 0;
    /* width: 332px; */
    width: 100%;
    padding: 94px 0px;
    /* min-height: 100%; */
    max-height: 100%;
}
.pxl-mydesign-createProjectBox .sch-workspace-row {
    display: grid;
    justify-content: flex-start;
    gap: 22px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.pxl-mydesign-createProjectBox .sch-workspace-repear-box {
	/* width: 332px; */
	border: 1px solid var(--sch-border-color);
	padding: 10px 10px 15px;
	border-radius: 6px;
	/* min-height: 305px;
	max-height: 305px; */
	background-color: #ffffff;
	position: relative;
}
.pxl-mydesign-createProjectBox .sch-workspace-repear-box .sch-custom-check-box.cun-select {
    opacity: 0;
    /* visibility: hidden; */
}
.pxl-mydesign-createProjectBox .sch-workspace-repear-box:hover .sch-custom-check-box.cun-select{
	opacity: 1;
	visibility: visible;
}
.pxl-mydesign-createProjectBox .sch-workspace-repear-box .sch-custom-check-box.cun-select {
    left: 5px;
    top: 5px;
}
.pxl-mydesign-createProjectBox .sch-workspace-repear-box .sch-imagebox {
    position: relative;
}

.pxl-mydesign-createProjectBox .sch-workspace-repear-box .sch-imagebox .pxl-processing-text > h2{
	align-items: center;
    background: #00000047;
    border-radius: 5px;
    bottom: 0;
    cursor: inherit;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 1;
	color: var(--whiteColor);
    font-size: 16px;
    /* text-transform: capitalize; */
}
/* .pxl-mydesign-createProjectBox .sch-workspace-inenr-img-flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	position: relative;
} */
.pxl-mydesign-createProjectBox .sch-workspace-inenr-img-flex {
    display: grid;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
	padding: 0 0 10px;
}
.pxl-mydesign-createProjectBox .sch-workspace-inenr-img-flex .sch-workspace-inner-box {
    margin: 0;
}
.pxl-mydesign-createProjectBox .sch-workspace-inenr-img-flex  .full_link{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.pxl-mydesign-createProjectBox .sch-workspace-inner-box {
	margin: 0px 0px 10px;
}
.pxl-mydesign-createProjectBox .sch-mydesign-row-wrap .pxl-images-innerBoxes {
    border: 1px solid #e9edf6;
    border: 1px solid var(--sch-border-color);
    border-radius: 6px;
    max-height: 305px;
    min-height: 305px;
    padding: 10px;
    width: 100%;
}
.pxl-mydesign-createProjectBox .sch-workspace-inner-box a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 5px 5px;
    background: #f5e9f6;
    border-radius: 5px;
}
.pxl-mydesign-createProjectBox .sch-section-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sch-uncategorized-mainwrapper-box .sch-workspace-repear-box.sch-workspace-designbox .sch-draft-content .sch-workspace-info h2{
	color: #161930;
    display: block;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    overflow: hidden;
    padding: 5px 0 10px;
    text-overflow: ellipsis;
    /* text-transform: capitalize; */
    white-space: normal;
	max-width: 250px;
}
.sch-uncategorized-mainwrapper-box.pxl-mydesign-createProjectBox .sch-draft-content .sch-workspace-info h2{
	color: #161930;
    display: block;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    overflow: hidden;
    padding: 5px 0 10px;
    text-overflow: ellipsis;
    /* text-transform: capitalize; */
    white-space: nowrap;
	max-width: 250px;
}
.sch-workspace-info.pxlsch-workspace-headingText > h4 {
    font-size: 16px;
    color: #161930;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 240px;
    white-space: nowrap;
}
.sch-workspace-repear-single {
    background-color: #fff;
    border: 1px solid #e9edf6;
    border-radius: 6px;
    padding: 10px 10px 15px;
	position: relative;
}
.sch-workspace-row.sch-workspace-row-single .sch-workspace-box-fix a {
    padding: 110px 0px;
}
.sch-workspace-repear-single .sch-draft-content {
    padding: 15px 0 0 0;
}
.pxl-mydesign-createProjectBox .sch-section-flex .sch-workspace-info > h2 > a{
    color: #161930;
    display: block;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    overflow: hidden;
    padding: 5px 0 10px;
    text-overflow: ellipsis;
    /* text-transform: capitalize; */
    white-space: nowrap;
	max-width: 250px;
}
.pxl-mydesign-createProjectBox .sch-workspace-info>h3 {
    align-items: center;
    color: #72778f;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 0 0 0 25px;
    position: relative;
    /* text-transform: capitalize; */
}
.pxl-mydesign-createProjectBox .sch-workspace-repear-box .sch-section-flex .sch-draft-content {
    padding: 0px;
}
.pxl-mydesign-createProjectBox .sch-section-flex .sch-workspace-info > h3 > span{
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    top: 2px;
    white-space: nowrap;
	max-width: 230px;
}
.pxl-mydesign-createProjectBox .sch-section-flex .sch-workspace-info > h3 > img{
    left: 0;
    position: absolute;
    top: 0;
}
.sch-banner-box.pxl-dashboardBanner-box .sch-banner-content .sch-search-box.sch-template-searchBox .sch-search-row .sch-banner-select-search-box .sch-banner-select-search-input {
    width: 100%;
    padding: 0 5px 0 0;
}
/* .pxl-mydesign-createProjectBox .sch-workspace-inner-box a img {
	width: 150px;
	min-height: 94px;
	object-fit: cover;
	object-position: top;
	border-radius: 6px;
} */
.pxl-mydesign-createProjectBox .sch-workspace-inner-box a img {
    width: 100%;
    min-height: 100px;
    object-fit: cover;
    object-position: top;
    border-radius: 6px;
	min-height: 130px;
	max-height: 130px;
}
.pxl-useTemplate-btn-wrap button.sch-edit-temp-btn{
	background: linear-gradient(90deg, #6f10b9 8.52%, #c02a93 110%);
    border: none;
    border-radius: 5px;
    bottom: 0;
    color: #fff;
    font-size: 16px;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    left: 0;
    margin: auto;
    opacity: 0;
    padding: 5px 10px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(100px);
    visibility: hidden;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
}
.sch-workspace-inner-box.sch-workspace-file-box {
	background-image: url(../../../../public/assets/images/scheduler/workspace-bg.jpg);
	/* width: 150px; */
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px 0px;
	position: relative;
}

.sch-workspace-inner-box.sch-workspace-file-box a img {
    width: 100%;
    /* height: 54px; */
    object-fit: contain;
    padding: 38px 0px 0;
    max-width: 70px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

p.sch-workspace-files {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    color: var(--sch-white);
    background-color: #7748B5;
    font-weight: 600;
    font-size: 12px;
    width: 100%;
    max-width: 70px;
    padding: 5px 8px;
    border-radius: 30px;
    margin: 0 auto;
    /* text-transform: capitalize;  */
    top: 50px;
    left: 0;
    right: 0;
    /* bottom: 0; */
    display: flex;
    align-items: center;
    justify-content: center;
}

main.sch-main-wrapper {
	height: 40px;
}

.sch-workspace-info>h2 {
	/* margin: 0;
	padding: 0; */
}

.sch-workspace-info>h2>a {
	/* color: #161930;
	font-size: 15px;
	font-weight: 500;
	 text-transform: capitalize; 
	margin: 0;
	padding: 15px 0 10px;
	display: block; */
}

.sch-workspace-info>h3 {
	/* color: #72778F;
	font-size: 14px;
	font-weight: 400;
text-transform: capitalize;
	margin: 0;
	display: flex;
	align-items: center;
	position: relative;
	padding: 0 0 0 25px; */
}

.sch-workspace-info>h3>img {
	position: absolute;
	left: 0;
	top: 0px;
}

/*--------------------------- Work space css end  ---------------------------*/
/*------------------------ Work space project css start  --------------------*/
.sch-workspace-repear-box.sch-workspace-designbox {
	padding: 0;
	border: none;
}

.sch-workspace-box-fix.sch-workspace-box-newdesign {
	min-height: 290px;
	max-height: 290px;
}

/*--------------------------- Work space project css end  --------------------*/
/*----------------------------- popup css start  -----------------------------*/
.modal-header {
	border-bottom: 1px solid var(--sch-border-color1);
}

.modal-title {
	margin-bottom: 0;
	line-height: var(--bs-modal-title-line-height);
	/* text-transform: capitalize; */
	color: var(--textColor);
	font-size: 18px;
	font-weight: 600;
}

.modal-body {
	padding: 30px 30px 30px;
}

.modal-body .form-group label {
    margin-bottom: 15px;
    font-size: 16px;
    /* text-transform: capitalize; */
    color: var(--textColor);
    font-weight: 500;
}
.form-control {
	height: 50px;
	border-radius: 5px;
}

/* .form-control:focus {
	border-color: #e5dffe;
} */

.modal-lg,
.modal-xl {
	--bs-modal-width: 600px;
}

/*--------------------------- popup css end  ---------------------------*/
/*---------------------- create new post css start  -------------------*/
/* toggle css */

/* .sch-socialAccount-toggle {
    display: flex;
    align-items: center;
	opacity: 0;
	visibility: hidden;
	position: fixed;
    left: 20px;
    top: 100px;
	z-index: -1;
} */

/* toggle css end */
.sch-crete-post-inner {
	position: relative;
	transition: all 0.5s;
}
.openLibrarySidebar .sch-post-center {
    padding:0 380px 100px 330px;
}
.sch-ceate-left-wrapper {
	padding: 0 0 0px 0px;
	width: 100%;
	position: relative;
}

.sch-post-left-count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 30px;
}

.sch-post-left-count>h2 {
	padding: 0 0 27px;
	margin: 0;
	font-size: 20px;
	font-weight: 600;
	color: #161930;
}
.sch-postAccount-heading>h2 {
	margin: 0;
	font-size: 20px;
	font-weight: 600;
	color: #161930;
}
.sch-post-left-count .sch-tooltipShow {
    top: 0px;
    left: 40px;
}
.sch-post-left-count .sch-tooltipShow:before {
   transform: rotate(90deg);
    bottom: 8px;
    width: 7px;
    height: 7px;
    left: -11px;
    border-top: 9px solid #000;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
}
/* res toggle */
.sch-socialAccount {
    position: relative;
}
a.sch-accountSocial-arrow {
    background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
    box-shadow: 0px 0px 20px 0px #555F861A;
    width: 40px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    border-radius: 0px 30px 30px 0px;
    top: 5px;
	opacity: 0;
	visibility: hidden;
}

/* res toggle */
.sch-post-left {
	width: 320px;
	position: relative;
	z-index: 1;
	transition: all 0.5s;
}
.sch-account-accordian {
    position: relative;
    overflow: auto;
    /* max-height: 570px; */
	max-height: 750px;
    padding-bottom: 20px;
}
.sch-post-left .sch-account-accordian .accordion-item {
	margin: 0 0 10px;
}

.accodianBodyBottomSpace.accordion-body {
	padding-bottom: 10px;
}

.sch-account-accordian .accordion-flush .accordion-item {
	border-top-right-radius: 12px;
	border-top-left-radius: 12px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	border-bottom: 0;
}

.sch-account-accordian .accordion-flush .accordion-item:nth-child(1) {
	background: #E8F0FF;
}

.sch-account-accordian .accordion-flush .accordion-item:nth-child(2) {
	background: #ffdff57d;
}

.sch-account-accordian .accordion-flush .accordion-item:nth-child(3) {
	background: #E8F0FF;
}

.sch-account-accordian .accordion-flush .accordion-item:nth-child(4) {
	background: #ffdcdc69;
}
.sch-account-accordian .accordion-flush .accordion-item:nth-child(5){
	/* background: #ECF3FF; */
	background: #ffdcdc69;
}
.sch-account-accordian .accordion-flush .accordion-item .sch-accordion-itemInner.accordion-item {
    background: #ffffff;
    margin: 10px 10px 10px;
    padding: 0;
}

.sch-account-accordian .accordion-button::after {
	background-image: url(../../../../public/assets/images/newpost/down-svg1.svg);
	margin: 4px 5px 0 0;
	width: 12px;
	height: 7px;
	object-fit: contain;
	background-size: contain;
	background-position: top;
}

h2.pxl-accodian-color1>.accordion-button::after {
	background-image: url(../../../../public/assets/images/newpost/linkedin-arrow.svg);
}

h2.pxl-accodian-color2>.accordion-button::after {
	background-image: url(../../../../public/assets/images/newpost/instagram-arrow.svg);
}

h2.pxl-accodian-color3>.accordion-button::after {
	background-image: url(../../../../public/assets/images/newpost/linkedin-arrow.svg);
}

h2.pxl-accodian-color4>.accordion-button::after {
	background-image: url(../../../../public/assets/images/newpost/pinterest-arrow.svg);
}
h2.pxl-accodian-color5>.accordion-button::after{
	background-image: url(../../../../public/assets/images/newpost/pinterest-arrow.svg);
}
.sch-account-accordian>.accordion-item>h2.accordion-header>.accordion-button:not(.collapsed):before {
	background-color: none;
}

.sch-account-accordian .accordion-item h2.accordion-header .accordion-button:not(.collapsed):before {
	display: none;
}

.sch-account-accordian .accordion-button:not(.collapsed) {
	background: none;
}

h2.pxl-accodian-color1.accordion-header .accordion-button {
	background-color: #C7D8F8;
}

h2.pxl-accodian-color2.accordion-header .accordion-button {
	background-color: #FFDFF5;
}

h2.pxl-accodian-color3.accordion-header .accordion-button {
	background-color: #C7D8F8;
}

h2.pxl-accodian-color4.accordion-header .accordion-button {
	background-color: #FFDCDC;
}
h2.pxl-accodian-color5.accordion-header .accordion-button{
	/* background-color: #cfe0ff; */
	background-color: #f3b6b6;
}
.sch-account-accordian button.accordion-button {
	border-radius: 10px !important;
}

.pxl-accordian-header.pxl-accordian-header1>h2 {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
}

.sch-fb-color {
	color: #1E4BA0;
}

.sch-insta-color {
	color: #C33697;
}

.sch-linkedin-color {
	color: #0F4B9B;
}

.sch-pinterest-color {
	color: #C80000;
}
.sch-youtube-color{
	color: #C80000;
}
/* accordian profile css */
.sch-accodian-user {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.sch-account-accordian .accordion-flush .accordion-item .sch-accordion-itemInner.accordion-item .sch-accodian-body1 .sch-accodian-user {
    margin: 0 0 10px;
}

.sch-user-img {
	width: 42px;
	height: 42px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sch-user-img>img {
	width: 42px;
	height: 42px;
	background-size: cover;
	background-position: top;
	border-radius: 50px;
}

.sch-user-img.sch-user-img-add {
	background-color: #E4EEFF;
}

.sch-user-img.sch-user-img-add img {
	width: 18px;
}

.sch-user-name {
	padding: 0 0 0 10px;
}

.sch-user-name>h2 {
	color: #161930;
	font-weight: 600;
	display: -webkit-box;
	width: 150px;
	max-width: 100%;
	height: 16px;
	margin: 0 auto;
	font-size: 14px;
	line-height: 1;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.sch-user-name>p {
	color: #72778F;
	font-size: 12px;
	font-weight: 500;
}

.sch-accodian-body1 {
    /* padding: 5px 0 5px 30px; */
	border-radius: 10px;
    position: relative;
    max-height: 210px;
    overflow-y: auto;
    overflow-x: hidden;
	background: #ffffff;
    /* margin: 10px 10px 10px;
    padding: 0px 15px 6px; */
}

.sch-accodian-body1 .sch-accodian-user .sch-custom-check-box {
    width: 100%;
    display: block;
}
.sch-add-account .sch-accodian-user {
    background: #ffffff;
    margin: 0px 10px;
    border-radius: 10px;
    padding: 0px;
    width: auto;
    display: block;
}
.sch-account-accordian .accordion-flush .accordion-item .sch-accordion-itemInner.accordion-item .sch-accodian-body1 {
    padding: 10px 0 0;
}
.sch-accodian-body1 .sch-accodian-user label.cun-checkboxLabel {
    width: 100%;
    cursor: pointer;
}
.sch-accodian-body1 .sch-accodian-user .sch-custom-check-box .form-group label:before{

}
.sch-add-account .sch-accodian-user a {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 10px;
}
.sch-user-info {
	display: flex;
	align-items: center;
}
/*======= create post center css start ======*/
.sch-post-center {
	width: 100%;
	/* padding: 0 450px 100px 330px; */
	padding: 0 0px 100px 330px;
	position: absolute;
	top: 0px;
	/* z-index: 1; */
}

.sch-post-center h2.sch-tab-heading {
	color: var(--sch-title-color);
	font-weight: 600;
	font-size: 20px;
	text-align: center;
	padding: 0 0 27px;
	margin: 0;
}

.sch-post-center>ul {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: fit-content;
	max-width: fit-content;
	margin: 0 auto;
}

.sch-post-center>ul>li {
	padding: 0px 15px 15px;
}
.pxl-apps-heading {
    padding: 30px 0 0 0;
}
/* new post box area */
.sch-post-content textarea {
	border: none;
	width: 100%;
	/* max-width: 710px; */
	padding-right:70px;
	height:255px;
	resize: none;
	overflow-y: visible;
	/* color: var(--sch-color1); */
	color: #a08fba;
	font-size: 16px;
	font-weight: 400;
	background: transparent;
	padding: 0px 0px 10px;
}

.sch-post-content textarea::placeholder {
	color: var(--sch-color1);
	font-size: 16px;
	font-weight: 400;
}

.sch-post-bottom {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 20px;
	padding: 0px 25px;
}

.sch-post-upload-images>ul>li {
	display: inline-block;
	position: relative;
	padding: 0px 12px 20px 0px;
}

.sch-post-upload-images>ul>li>a {
	position: absolute;
	top: -10px;
	right: 5px;
}
.sch-post-title-input {
	position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 30px;
}
.sch-post-title-input label {
    color: var(--sch-title-color);
    font-size: 16px;
    padding: 0 20px 0 0;
    font-weight: 500;
}
.pxl-postTitle-input {
    border: 1px solid var(--sch-border-color1);
    padding: 7px 7px;
    border-radius: 6px;
    background-color: #ffffff;
    filter: drop-shadow(0px 0px 60px rgba(88, 37, 113, 0.06));
}
.sch-post-title-input .pxl-postTitle-input input {
    /* border: 1px solid var(--sch-border-color1); */
	border: none;
    /* background-color: #F6F4FF; */
	background-color: #F4ECFC;
    height: 40px;
    padding: 0px 15px;
    border-radius: 5px;
    display: block;
}
.sch-post-area-box {
	max-width: 860px;
	width: 100%;
	height: 390px;
	border: 1px solid var(--sch-border-color);
	filter: drop-shadow(0px 0px 60px rgba(88, 37, 113, 0.06));
	border-radius: 15px;
	margin: 0 auto 0;
	padding: 10px 10px;
	background-color: var(--sch-white);
	position: relative;

	z-index: 11;
	
}
.sch-post-area-box-inner {
    background-color: #F4ECFC;
    border-radius: 10px;
    height: 100%;
    padding: 20px 25px;
    position: relative;
    width: 100%;
}


.sch-post-content-box {
    position: relative;
    height: 265px;
	/* padding-right: 60px;
    overflow-y: auto; */
}
.sch-color-picker-svg>a {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--sch-white);
    border-radius: 50px;
    position: absolute;
    right: 0;
    top: 0;
}
.sch-color-picker-dropedown {
    position: absolute;
    top: 10px;
    right: 70px;
    background-color: #393e4f;
    padding: 13px 30px 13px;
    border-radius: 10px;
    color: #ffffff;
    transition: all 0.5s;
    opacity: 0;
}
.sch-color-picker-dropedown:before {
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #393e4f;
    content: "";
    position: absolute;
    right: -13px;
    top: 15px;
    transform: rotate(87deg);
	transition: all 0.5s;
}
.sch-color-picker-svg{
	transition: all 0.5s;
}
.sch-color-picker-svg:hover .sch-color-picker-dropedown {
    opacity: 1;
   
}
.sch-color-picker-dropedown ul li a:hover {
    color: #ffffff;
}
.sch-color-picker-dropedown ul li {
    padding: 0 0 5px;
	font-size: 14px;
	transition: all 0.5s;
}
.sch-color-picker-dropedown ul li a span {
    padding: 0 0 0 10px;
}
.sch-post-schedule-area {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 10px;
}

.sch-footer-post-ul2 {
	display: flex;
	gap: 10px;
	position: relative;
}
.sch-footer-post-ul2>li>a {
	width: 50px;
	height: 50px;
	background-color: var(--sch-white);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	/* margin: 0px 15px 5px 0px; */
	/* margin: 0px 0px 5px 0px; */
	border-radius: 12px;
	color: #4F536F;
	font-size: 14px;
	font-weight: 400;
}
.sch-footer-post-ul2>li:nth-child(2)>a {
    width: 130px;

}
.sch-footer-post-ul2>li:nth-child(2)>a span {
    padding-right: 10px;
	width: 30px;
}
.sch-footer-post-ul2>li:nth-child(3)>a {
    width: 130px;
}
.sch-footer-post-ul2>li:nth-child(3)>a span {
    padding-right: 10px;
}
.sch-schedule-hashtag {
	display: flex;
	align-items: center;
	/* width: 341px; */
	justify-content: space-between;
}

.sch-post-button {
	/* background: var(--sch-white); */
	width: fit-content;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border-radius: 8px;
	padding: 20px 30px;
	color: #4F536F;
	font-size: 15px;
	font-weight: 400;
}

.pxl-sub-categoryActive .pxl-accordion-icons-right {
	/* top: 4px; */
		top: 14px;
}

.sch-post-button.sch-hashtags {
	padding: 20px 15px;
}

.sch-post-button.sch-hashtags a {
	/* text-transform: capitalize; */
	color: #6B597A;
	font-size: 14px;
	font-weight: 600;
}

.sch-post-button.sch-post-button-gradient {
	color: var(--sch-white);
	padding: 0;
}

.sch-post-button>a>img {
	margin: 0 8px 0 0px;
	position: relative;
	top: -2px;
	z-index: 1;
}

.sch-post-button.sch-post-button-gradient:hover a {
	color: var(--sch-body-bg);
}
.sch-postUploadImg {
    padding: 10px 60px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-height: 100px;
    overflow-y: auto;
    position: relative;
    margin: 10px 0px 0px 0px;
    justify-content: center;
}
.sch-postImgInner {
    position: relative;
    width: 63px;
    height: 63px;
    margin: 0 20px 15px 0;
	background: #F3EEF8;
    border-radius: 5px;
    border: 1px solid var(--borderColor);
}
.sch-postImgInner img{
	width: 63px;
    height: 63px;
	object-fit: contain;
	border-radius: 5px;
	padding: 5px 10px 7px;
}
.sch-postImgInner video{
	width: 63px;
    height: 63px;
}
.sch-post-bottom-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: -moz-fit-content;
    max-width: 100%;
    margin: 0px auto 0;
    gap: 10px;
    flex-wrap: wrap;
}
.sch-postImgInner a.schCross {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
    padding: 0px 6px;
    border-radius: 20px;
    position: absolute;
    top: -10px;
    right: -10px;
}

.sch-relative {
	position: relative;
}

.sch-loadingText {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	font-weight: 100;
	background-color: #cccccc69;
	border-radius: 10px;
	z-index: 99;
	color: #000;
}

/* common btn */
a.sch-common-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--sch-white);
	position: relative;
	font-size: 14px;
	background: var(--sch-gradient-bg);
	transition: all 0.5s;
	width: fit-content;
	padding: 10px 25px;
	border-radius: 8px;
	box-shadow: 0px 16.667px 41.667px 0px rgba(105, 39, 242, 0.30);
}

a.sch-common-btn span {
	padding: 0px 8px 0px 0px;
}

.sch-common-btn-white {
	background: var(--sch-body-bg);
	filter: drop-shadow(0px 0px 60px rgba(88, 37, 113, 0.16));
	border: 1px solid var(--sch-border-color);
	color: var(--sch-primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	font-size: 14px;
	background: var(--sch-body-bg);
	transition: all 0.5s;
	width: fit-content;
	padding: 10px 25px;
	border-radius: 5px;
	height: 50px;
}

.sch-common-btn-white span {
	padding: 0px 8px 0px 0px;
	position: relative;
	top: -2px;
}

/*=========== right section css start ============*/
/* res arrow */
.sch-post-arrow-responsive{
	/* opacity: 0;
	visibility: hidden; */
}
.sch-post-arrow-responsive a {
    background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
    box-shadow: 0px 0px 20px 0px #555F861A;
    width: 40px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -40px;
    border-radius: 30px 0px 0px 30px;
    top: 10px;
}
.sch-post-arrow-responsive a svg {
    transform: rotate(180deg);
}
.sch-post-right {
	background-color: #F3EEF8;
	height: 100vh;
	max-width: 450px;
	width: 100%;
	position: fixed;
	right: -450px;
	top: 10px;
	margin: 70px 0px;
	/* z-index: 1; */
	z-index: 111;
	border: 1px solid transparent;
	transition: all 0.5s;
}
.openLibrarySidebar .sch-post-right {
    right: 0;
}
.sch-right-inner {
	background: var(--sch-white);
	margin: 20px 20px;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	/* height: 87vh; */
	height: 100vh;
}

.sch-library-tab>.nav.nav-tabs {
	border-bottom: 1px solid #F2E1F9;
	padding: 14px 20px 0;
}

.sch-library-tab .nav.nav-tabs .nav-item .nav-link {
	position: relative;
}

.sch-library-tab .nav-tabs .nav-link:before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	background: var(--primaryColor);
	width: 50%;
	height: 5px;
	border-radius: 10px 10px 0px 0px;
	opacity: 0;
}

.sch-library-tab .nav-tabs .nav-link.active:before {
	opacity: 1;
}

.sch-library-tab-inner {
	padding: 20px 30px 80px;
	position: relative;
	overflow: auto;
	height: 80vh;
}

.nav-tabs .nav-link {
	color: var(--sch-title-color);
	font-size: 14px;
	font-weight: 600;
	/* text-transform: capitalize; */
	padding: 0px 14px 14px;
	border: 0px solid;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	background-color: unset;
}

.nav-link:focus-visible {
	box-shadow: unset;
}

.pxl_rightbarSwitch svg {
	display: inline-block;
	transform: rotate(180deg);
}

.pxl_rightbarSwitch {
	position: absolute;
	left: -45px;
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px 0px 0px 50px;
	background-color: #fff;
	z-index: 999;
	box-shadow: 0px 0px 10px #ccc;
	transition: all 0.5s;
	top: 15px;
	display: none;
}

/*============== crete new post tabs design =================*/


/*=== post inner tab css ===*/

/* .sch-post-image-item {    
    width: calc(33.3% - 7px);
} */
/* .sch-post-image-item img {
    border-radius: 5px;
    background: #F6F8FA;
    padding: 0;
    display: block;
    min-height: 110px;
    max-height: 110px;
    width: 100%;
    object-fit: contain;
    object-position: center;
} */

.sch-post-image-item audio {
	width: 100%;
}

.loadmorebtn a {
	margin: 0 auto;
}

/*================ ai images tab css start ================*/
.sch-textareaSection {
	margin: 0px 0px 20px;
	position: relative;
}
/* .sch-post-content {
    height: 100%;
} */
/* .sch-post-content .sch-postTextarea {
    height: 100%;
} */
.sch-textareaSection textarea {
	background-color: #F6F4FF;
	width: 100%;
	padding: 20px;
	border-radius: 6px;
	height: 200px;
	resize: none;
	border: 1px solid var(--sch-border-color1);
	position: relative;
	overflow-y: auto;
	color: var(--sch-title-color);
	font-size: 16px;
	font-weight: 400;
}

.sch-textareaSection textarea::placeholder {
	color: var(--sch-color5);
}

.sch-img-promt-sample {
	position: absolute;
	bottom: 10px;
	right: 5px;
}

.sch-img-promt-sample>a {
	font-size: 13px;
	font-weight: 500;
	color: var(--sch-primary-color);
	width: 134px;
	height: 40px;
	border: 1px solid #E9EDF6;
	background-color: var(--sch-body-bg);
	filter: drop-shadow(0px 0px 60px rgba(88, 37, 113, 0.16));
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
}

.sch-img-promt-sample>a>span {
	padding: 0px 10px 0px 0px;
}

.sch-aspect-ration>h2 {
	padding: 0 0 15px;
	margin: 0;
}

.sch-aspect-ration ul {
	display: flex;
	gap: 10px;
}
li.sch-active-aiBox {
    border-radius: 5px;
    border: 2px solid #0e0e0e;
}
.sch-ai-image-button {
	margin: 30px 0 70px;
}

.sch-textfont16 {
	color: #161930;
	font-size: 16px;
	font-weight: 500;
}

/*================ create post app tab css ===============*/
.sch-apps-wrapper h2{
	padding-bottom: 20px;
}
ul.sch-apps-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

ul.sch-apps-flex>li {
    text-align: center;
    padding: 0 10px 10px 0px;
}

ul.sch-apps-flex>li>a>p {
	color: #161930;
	font-size: 12px;
	font-weight: 500;
	padding: 10px 0px 0px 0px;
}

/* .sch-my-library-wrpper {
    padding: 0 0 80px;
} */
/* ===========my library css start ==================*/
.sch-file-upload-library .pxl-custom-file-upload .pxl-custom-file-select {
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #F6F4FF;
	margin: 0 0 24px 0;
}

.sch-file-upload-library .pxl-custom-file-upload .pxl-custom-file-select .pxl-custom-file-select-button {
	background-color: transparent;
	padding: 0px;
	height: auto;
	line-height: normal;
}

.sch-file-upload-library .pxl-custom-file-upload .pxl-custom-file-select .pxl-custom-file-select-name b {
	color: #971CA6;
	font-weight: 700;
}

.sch-post-image-item p {
	text-align: center;
	padding: 5px 0 0;
	display: block;
	max-width: 80px;
	height: 28px;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0 auto;
}

.sch-generate-content-btn {
	padding: 18px 0 0 0;
}

/*=========== custom checkbox css start ===============*/
/* .sch-account-accordian .sch-custom-check-box {
	position: absolute;
	right: 13px;
  } */
  .sch-custom-check-box .form-group {
	  display: block;
  }
  
  .sch-custom-check-box .form-group input {
	  padding: 0;
	  height: initial;
	  width: initial;
	  margin-bottom: 0;
	  display: none;
	  cursor: pointer;
  }
  
  .sch-custom-check-box .form-group label:before {
	  content: '';
	  -webkit-appearance: none;
	  background-color: var(--sch-color2);
	  border: 2px solid var(--sch-color3);
	  padding: 10px;
	  display: inline-block;
	  vertical-align: middle;
	  cursor: pointer;
	  margin-right: 5px;
	  border-radius: 5px;
	  position: absolute;
	  top: 0px;
	  right: 0px;
  }
  .sch-custom-check-box input[type=checkbox]:checked+label:before {
	  background: var(--sch-gradient-bg);
	  border-color: #c02a93;
  }
  .sch-custom-check-box .form-group input:checked+label:after {
	  content: '';
	  display: block;
	  position: absolute;
	  top: 5px;
	  left: auto;
	  width: 5px;
	  height: 11px;
	  border: 2px solid var(--sch-white);
	  border-width: 0 2px 2px 0;
	  transform: rotate(45deg);
	  right: 14px;
  }
  
  .sch-relativeClass{
	position: relative;
  }
/*========= select option css start ==========*/
.sch-banner-select-sec {
    margin: 0px;
    position: relative;
    top: 7px;
    left: 10px;
    width: 160px;
    text-align: left;
}
.sch-banner-select-sec .css-13cymwt-control {
    background: #F2E2FF !important;
    border-color: #F2E2FF !important;
}
.sch-banner-select-sec .css-t3ipsp-control{
	background: #F2E2FF !important;
    border-color: #F2E2FF !important;
}
/* .css-1dimb5e-singleValue {
    color: #8417AF!important;
    font-weight: 600;
} */

/* .css-qbdosj-Input{
	color: #8417AF !important;
  } */
.sch-banner-select-sec .css-1jqq78o-placeholder{
	color: var(--sch-primary-color) !important;
	font-weight: 600 !important;
}
.css-10wo9uf-option {
    color: #000000 !important;
}
.css-tr4s17-option{
	background-color: var(--sch-primary-color) !important;
	text-overflow: ellipsis;
    overflow: hidden;
}
.css-10wo9uf-option:active {
    background-color: #dddddd !important;
}
.css-d7l1ni-option {
    background-color: #cccccc3d !important;
    color: #000000 !important;
}
.css-1n6sfyn-MenuList {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}
.css-t3ipsp-control {
    box-shadow: unset !important;
    border-color: hsl(0, 0%, 80%) !important;
}
.css-1u9des2-indicatorSeparator{
	background-color: transparent !important;
}

.css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    text-align: left;
}

.cun-headreSelect .css-13cymwt-control {
	height: 50px;
	border: 1px solid var(--sch-border-color1) !important;
  }
  
  li.cun-resSelect .css-t3ipsp-control {
	border: 1px solid var(--sch-border-color1) !important;
	height: 50px;
  }
  
  li.cun-resSelect .css-b62m3t-container .css-13cymwt-control {
	height: 50px;
  }




























/*--------------------------- create new post css end  ---------------------------*/
/* popup close css */
.btn-close {
    background-image: url(../../../../public/assets/images/popup-cross1.svg);
    opacity: 1;
    background-size: cover;
    position: absolute;
    right: 0px;
    top: 0px;
	z-index: 1;
}
.btn-close:hover{
	opacity: 1;
}
/* .sch-post-arrow-responsive {
    display: none;
} */

.sch-select-schedule-date > h2 {
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
    border-radius: 12px;
    padding: 16px 18px;
    color: #ffffff;
}
.sch-select-schedule-date > h2 >img {
    padding: 0 10px 0 0;
}

ul.sch-footer-post-ul2 li:nth-child(2).sch-aiText .cun-show-tooltip {
    transform: translate(-4px, 0px);
    top: -60px;
    min-width: max-content;
}
ul.sch-footer-post-ul2 li:nth-child(2).sch-aiText .cun-show-tooltip > ul > li > a > svg {
    margin: 0px 7px 0px 0px;
}
ul.sch-footer-post-ul2 li:nth-child(3).sch-aiText .cun-show-tooltip {
	top: -40px;
}
.sch-post-bottom-btns .cun-socialIcon .cun-show-tooltip {
    transform: translate(0px, 0px);
}
.sch-draft-box .sch-draft-img-box .sch-custom-check-box {
    position: absolute;
    top: 0;
    left: 0px;
}
/* images schdular css add */
.sch-count-widget {
    background: #e2ceff;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    position: relative;
}
.sch-count-widget:before {
    background: #bf9ffd;
    border-radius: 0 10px 10px 0;
    bottom: 0;
    content: "";
    height: 80%;
    left: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    width: 8px;
}
.remove .cun-noDesign-inner {
    padding: 80px 30px;
}

.cun-noDesign-inner>h2 {
    font-size: 25px;
    font-weight: 700;
    margin: 0;
    padding: 20px 0;
    /* text-transform: capitalize; */
}
.cun-noDesign-inner .scun-noImg-btn a.sch-btn-iconLeft {
    margin: 0 auto;
}
.cun-noDesign-inner>img {
    max-width: 250px;
    width: 100%;
}
.cun-noDesign-inner {
    padding: 200px 0;
    text-align: center;
	/* background-color: #c5bdcc57; */
	border-radius: 5px;

}
.sch-grid {
    grid-gap: 30px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
.sch-grid.pxl-dashboard-count-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}
.sch-grid.pxl-dashboard-count-box .sch-count-widget {
    width: 320px;
}
.pxl-images-templateMain-wrapper-box .sch-tempalte-innerwrap {
    display: flex;
    padding: 40px 0 50px;
}
.pxl-images-templateMain-wrapper-box .sch-template-left-category {
    margin: 0 30px 0 0;
    width: 200px;
}
.pxl-images-templateMain-wrapper-box .sch-templateHeading>h2 {
    color: #161930;
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 20px;
}
.pxl-images-templateMain-wrapper-box .sch-template-category>ul>.sch-categoryInnerDiv>li>.main>a {
    align-items: center;
    background: #0000;
    border-radius: 3px;
    color: #4f536f;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    justify-content: space-between;
    margin: 0 0 10px;
    padding: 5px 7px;
    position: relative;
    /* text-transform: capitalize; */
    transition: all .5s;
}
.pxl-images-templateMain-wrapper-box .sch-template-category>ul>div>li>.main.cun-opensub-category>a {
    background: #dfdcec;
}
.pxl-images-templateMain-wrapper-box .sch-template-category>ul>.sch-categoryInnerDiv>li>.main>a>p {
    display: block;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.pxl-images-templateMain-wrapper-box .sch-template-category>ul>.sch-categoryInnerDiv>li>.main>a>span {
    position: absolute;
    right: 10px;
    top: 5px;
}
.pxl-images-templateMain-wrapper-box ul.sch-templateSubcategory-ul {
    display: none;
    padding: 0 0 0 30px;
    transition: all .5s;
}
.pxl-images-templateMain-wrapper-box ul.sch-templateSubcategory-ul>li {
    position: relative;
}
.pxl-images-templateMain-wrapper-box ul.sch-templateSubcategory-ul>li>a>p {
    color: #4f536f;
    display: block;
    font-size: 14px;
    font-weight: 500;
    max-width: 130px;
    overflow: hidden;
    padding: 0 0 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.pxl-images-templateMain-wrapper-box ul.sch-templateSubcategory-ul>li:after {
    background: #4f536f;
    content: "";
    display: none;
    height: 2px;
    left: -20px;
    position: absolute;
    top: 10px;
    transition: all .5s;
    width: 13px;
}
.pxl-images-templateMain-wrapper-box ul.sch-templateSubcategory-ul li.schSubmenuActive.active:after {
    display: block;
}
/* right css */
.pxl-images-templateMain-wrapper-box .pxl-images-templateMain-wrapper-box .sch-template-categoryImg {
    width: 100%;
}
.pxl-images-templateMain-wrapper-box .sch-templaterow1 {
    grid-gap: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill,minmax(170px,1fr));
}
.pxl-images-templateMain-wrapper-box .sch-section-mansory, .sch-section-row.schsection-rowTemplate {
    padding: 20px 0 0;
}
.sch-template-categoryImg {
    width: 100%;
}
.cun-no-design-wrapper {
	background-image: url(../../../../public/assets/images/image-editor/editor-bg.png);
    background-position: 50%;
    background-size: cover;
}
.pxl-nodesign-wrap1{
	background-image: url(../../../../public/assets/images/image-editor/editor-bg.png);
    background-position: 50%;
    background-size: cover;
}

.pxl-use-template-disable .cun-show-tooltip {
    transform: unset;
    top: -60px;
}
/* ==== project incategorize css start =====*/
.sch-uncategorized-mainwrapper-box .cun-uncategorizedWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin: 0 0 30px;
    flex-wrap: wrap;
}
.sch-uncategorized-mainwrapper-box .cun-uncategorizedWrapper .cun-headerSelect-wrap{
	display: flex;
    align-items: center;
    position: relative;
	gap: 10px;
	flex-wrap: wrap;
}
.sch-uncategorized-mainwrapper-box .cun-uncategorizedWrapper .cun-headerSelect-wrap .cun-headreSelect {
    padding: 0;
}
.sch-uncategorized-mainwrapper-box .sch-workspace-box-fix a {
    padding: 110px 0px;
    min-height: 100%;
    max-height: 100%;
}
.pxl-uncategorize-heading > h2 {
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
	font-size: 22px;
    font-weight: 500;
}
.pxl-uncategorize-heading > h2 > a{
	font-size: 22px;
    font-weight: 500;
}
.pxl-uncategorize-heading>h2>a>span {
    padding: 0 10px 0 0;
    position: relative;
    top: -3px;
}
.sch-uncategorized-mainwrapper-box .sch-workspace-repear-box .sch-imagebox > img {
    border-radius: 5px;
	width: 100%;
}
.sch-uncategorized-mainwrapper-box .sch-section-flex .sch-workspace-info > h2 {
	color: #161930;
    display: block;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    overflow: hidden;
    padding: 5px 0 10px;
    text-overflow: ellipsis;
    /* text-transform: capitalize; */
    white-space: nowrap;
    max-width: 250px;
}
.sch-uncategorized-mainwrapper-box .sch-section-flex .sch-workspace-info > h3 {
	align-items: center;
    color: #72778f;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 0 0 0 25px;
    position: relative;
    /* text-transform: capitalize; */
}
.sch-uncategorized-mainwrapper-box .sch-section-flex .sch-workspace-info > h3 > span{
	overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    top: 2px;
    white-space: nowrap;
    max-width: 230px;
}
/* ==== image dashboard heading css =====*/
.pxl-image-dashboard-heading-flexWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
	margin: 0 0 30px;
}
.pxl-image-dashboard-heading-flexWrapper .pxl-images-page-heading-name > h2 {
    font-size: 20px;
    font-weight: 600;
	position: relative;
    color: #161930;
}
.pxl-image-dashboard-heading-flexWrapper .pxl-images-page-heading-name > h2:after {
    content: "";
    position: absolute;
    bottom: -14px;
    left: 0;
    height: 4px;
    background: #AE249B;
    right: 0;
}
.pxl-image-dashboard-heading-flexWrapper .pxl-see-more-wrap > a{
	position: relative;
    font-size: 14px;
    font-weight: 500;
    color: #8417AF;
    /* text-transform: capitalize; */
}
.pxl-image-dashboard-heading-flexWrapper .pxl-see-more-wrap > a > span {
    padding: 0 0 0 10px;
}
.pxl-mydesign-createProjectBox.sch-templates-topspace {
    margin: 20px 0 0;
}
.cun-socialIcon.pxl-previewPost-disable .cun-show-tooltip {
    top: -40px;
}
.pxl-uncategorize-heading {
    position: relative;
}
.sch-imagebox {
    position: relative;
}
.pxl-processing-section > h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    background-color: #0000005c;
    width: 100%;
    height: 100%;
    font-size: 18px;
    color: #ffffff;
    text-transform: capitalize;
    border-radius: 5px;
}
.pxl-banner-btn.pxl-create-design-btn button.cun-socialIcon.sch-btn-disbale .cun-show-tooltip {
    left: 20px;
	top: -60px;
}
.pxl-banner-btn.pxl-create-post-btn button.cun-socialIcon.sch-btn-disbale .cun-show-tooltip {
    left: 20px;
	top: -60px;
}
.pxl-reload-tooltip.pxl-tooltip-btn-diable button.cun-socail-delete.cun-socialIcon.sch-btn-disbale .cun-show-tooltip {
    top: -120px;
}
.sch-workspace-repear-single .sch-imagebox img {
    width: 100%;
    max-height: 220px;
    min-height: 220px;
    border-radius: 5px;
    object-fit: cover;
    object-position: top;
}
/* schedular popup download */
.cun-download-format {
    display: flex;
    align-items: center;
    justify-content: space-between;
	width: 100%;
	gap:10px;
}
.cun-download-format-left {
    display: flex;
    align-items: center;
    border: 1px solid #F3E7FF;
    background-color: #FDFBFF;
	width: 100%;
	height: 50px;
	border-radius: 5px;
}
.cun-download-format .cun-download-format-left h2 {
    font-size: 16px;
    font-weight: 500;
    /* text-transform: capitalize; */
    background-color: #EEE4F9;
    padding: 15px 30px;
    max-width: 190px;
    width: 120px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.cun-download-select {
    width: 100%;
}
.cun-download-select .css-b62m3t-container .css-13cymwt-control {
    border: none;
    width: 100%;
}
.cun-download-select .css-b62m3t-container .css-t3ipsp-control {
    border: none;
}
.cun-download-btn-box a .pxl-downloadIcon {
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.cun-download-btn-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 5px;
    border-radius: 5px;
    margin: 10px 0px 10px;
    /* background: #fefaff; */
    background: linear-gradient(90deg, #6f10b9 8.52%, #C02a93 110%);
    border: 1px solid #F3E7FF;
    height: 50px;
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    max-width: 304px;
    margin: 15px auto 10px;
}
.cun-downloadMneu {
    display: none;
	margin: 0 0 10px;
    transition: all 0.5s;
}
.cun-downloadMneu.cun-download-dropedown-open {
    display: block;
  
}
.cun-downloadMneu > ul {
    background-color: #ffffff;
    padding: 15px 20px;
    margin: 0 0 10px;
    border-radius: 5px;
    border: 1px solid #EEE0FF;
    box-shadow: 0px 0px 40px 0px #B79BD24D;
    max-width: 240px;
    margin: 0 auto 10px;
}
.cun-downloadMneu > ul > li {
    padding: 0 0 5px;
    font-size: 14px;
    font-weight: 400;
    color: #4F536F;
}
.cun-downloadMneu > ul > li:last-child{
	padding: 0;
}
.cun-downloadMneu > ul > li > span {
    padding: 0 6px 0 0px;
}
.cun-downloadMneu > ul > li.active {
    color: #8417af;
}

.sch-workspace-repear-single:hover .sch-custom-check-box.cun-select.sch_hide_checkbox {
    opacity: 1;
    visibility: visible;
}
.sch-workspace-repear-single .sch-custom-check-box.cun-select.sch_hide_checkbox{
	top: 5px;
	left: 5px;
}
.sup_proj_setting_form.pxl-ticketStatusBox .cun-noDesign-inner {
    padding: 40px 0;
	margin: 0px 0px 20px;
}
.sup_proj_setting_form.pxl-ticketStatusBox .cun-noDesign-inner>img {
    max-width: 200px;
}
.sup_proj_setting_form.pxl-ticketStatusBox .cun-noDesign-inner>h2 {
    font-size: 18px;
}
.cun-socialIcon.pxl-previewPost-disable .cun-show-tooltip {
    top: -80px;
}
.css-166bipr-Input {
    max-width: 150px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}

.selected-values {
	/* Ensure the selected values stay on one line */
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%; /* Adjust as needed */
  }
  .pxlBuilder-mainBox {
    padding: 30px 0 0;
}
.pxl-draftSingle-mainWrapper-box {
    padding: 30px 0 0;
}
.pxlSocial-accountMain-wrapper-box {
    padding: 30px 0 140px 0;
}
.sch-template-box.pxl-schedular-recentDraft-index.schSchedular {
    padding:10px 10px 10px 10px;
}
.sch-template-box.pxl-schedular-recentDraft-index.schSchedular .sch-draft-img-box {
    margin: 0px 10px 0px 0px;
}
.sch-section-row.pxlrecent-schedualer-indexbox .sch-draft-content {
    padding: 0px;
}
.sch-template-box.pxl-schedular-recentDraft-index.schSchedular .sch-draft-content {
    padding: 0px;
}
.sch-section-row.pxlrecent-schedualer-indexbox .sch-template-box.schSchedular {
    padding: 10px 10px 10px 10px;
}
.sch-section-row.pxlrecent-schedualer-indexbox .sch-template-box.schSchedular .sch-draft-img-box {
    margin-right: 10px;
}
.pxl-button-disable {
    cursor: unset !important;
}
.pxl-postNow-disable {
    cursor: not-allowed !important;
    opacity: 0.5;
}
.pxl-schduler-newPost-wrapper {
    padding: 30px 0 0 0;
}
.sch-workspace-repear-box.active_post .sch-custom-check-box.cun-select.sch_hide_checkbox {
    opacity: 1;
    visibility: visible;
}

.pxlDraft-postMainBOx .sch-container-fluid .sch-draft-post-wrpper {
    padding: 30px 0px 80px;
}
.pxlReload-information-svg {
    display: flex;
	gap: 10px;
}
.pxl-informationSec.sch-tooltipWrap .sch-tooltipShow {
    width: 130px;
    right: auto;
    left: 150px;
    top: -3px;
}
.pxl-informationSec.sch-tooltipWrap .sch-tooltipShow:before {
    left: auto;
    right: -13px;
    transform: rotate(-90deg);
}
.pxl-informationSec.sch-tooltipWrap .sch-tooltipShow p {
    white-space: unset;
}
.pxl-informationSec.sch-tooltipWrap .sch-tooltipShow:before {
    top: 12px;
}
.sch-myDesignFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px;
}
.sch-myDesignFlex  .cun-headreSelect {
    padding: 0;
}
.sch-myDesignFlex .cun-headerSelect-wrap{
	gap: 10px;
}
.cun-download-btn-box a .pxl-downloadIcon {
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cun-download-btn-box a .pxl-downloadIcon span {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8c30d3;
    border-radius: 5px;
}
.cun-download-btn-box a .pxl-downloadIcon span img {
    width: 16px;
    height: 16px;
}
/* select2 option ellipse */
.pxl-selectBrand .css-10wo9uf-option {
	max-width: 530px !important;
	text-overflow: ellipsis!important;
	overflow: hidden!important;
	white-space: nowrap!important;
  }
  .pxl-selectBrand .css-d7l1ni-option{
	max-width: 530px !important;
	text-overflow: ellipsis!important;
	overflow: hidden!important;
	white-space: nowrap!important;
  }
  .pxl-schedular-dash-recent-draft .sch-template-box {
    margin: 0;
}
.pxl-supportProject-wrap .cun-headreSelect.pxl-supportModel span a {
    color:#6f10b9;
    font-weight: 600;
}
.sch-uncategorized-mainwrapper-box.pxl-mydesign-createProjectBox .sch-draft-content .sch-workspace-info.pxlsch-workspace-headingText >h2 {
    color: #8417af;
}
.pxl-schedule-nodata-found {
    margin: 30px 80px;
}
.pxlInstagram-account-connection-popup .sch-socialAccount-wrapper .sch-search-section .sch-search-row input {
	width: 100%;
    background: #fbfaff;
    border: 1px solid #ece7fd;
}
.pxlInstagram-account-connection-popup .sch-social-account-wrapper .sch-social-account-flex {
    background: #fbfaff;
    border: 1px solid #ece7fd;
}
.pxlInstagram-account-connection-popup .sch-social-account-wrapper .sch-social-account-flex .sch-socail-pageName h2 {
    font-size: 14px;
    color: #747a91;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 160px;
    white-space: nowrap;
}
.sch-continueBtn.pxl-account-btn-left {
    justify-content: left;
}
.pxl-schdule-post-socialIcons-sec {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0px 10px 0px 0px;
}
.pxl-schedule-nodata-found.pxl-schedule-nodata-found01 {
    margin: 30px 0;
}
.pxl-selectsocial-account-data-content > h6 {
    font-size: 18px;
}
.pxl-selectsocial-account-data-post-wrap {
    display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 15px;
	padding: 20px 0px 20px;
}
.pxl-selectSocial-accountImg-wrap {
    display: flex;
    gap: 5px;
}
.pxl-selectSocial-accountImg-wrap img {
    width: 25px;
}
.sch-draft-social-icons-wrap > ul {
    display: flex;
    align-items: center;
    gap: 5px;
}
.sch-draft-social-icons-wrap > ul > li > p {
    color: #72778F;
    font-size: 14px;
    font-weight: 400;
}
.sch-draft-social-icons-wrap > ul > li > img {
    width: 20px;
    height: 20px;
}
.pxl-dashboard-trendingTemplates .sch-section-row {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}
.pxl-dashboard-trendingTemplates .sch-template-box {
    padding: 10px;
}
.pxl-dashboard-featuredTemplate .sch-section-row{
	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}
.pxl-dashboard-featuredTemplate .sch-template-box {
    padding: 10px;
}
.sch-draft-social-icons-wrap {
    padding: 10px 0 0;
}
.pxl-draftSingle-mainWrapper-box .sch-draft-social-icons-wrap {
    display: flex;
    gap: 10px;
}
/* .sch-post-title-input{
	position: relative;
} */
.pxl-postcenterDisable .sch-post-title-input:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    background: #00000003;
    max-width: 300px;
    margin: 0 auto;
}
.pxl-postcenterDisable .sch-post-area-box:after{
	content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
}
.pxl-postcenterDisable .sch-post-area-box:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: not-allowed;
}
.sch-accodian-body1.pxl-accordian-disable-socialIcons:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    background: transparent;
    cursor: not-allowed;
    top: 0;
    bottom: 0;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    
}
.pxl-page-not-found-wrap.cun-no-design-wrapper {
    background-image: none;
}
.pxl-page-not-found-wrap .cun-noDesign-inner > img {
    max-width: 600px;
}
.pxl-page-not-found-wrap .cun-noDesign-inner>h2{
	font-size: 30px;
}
.scun-noImg-btn a.pxlCommon-btn {
    margin: 0 auto;
}
.pxl-page-not-found-wrap > .cun-noDesign-inner > h2{
	font-size: 28px;
	padding: 15px 0 5px;
	font-weight: 500;
}
.pxl-page-not-found-wrap > .cun-noDesign-inner > h2 > span {
    font-size: 40px;
	padding: 0 15px 0 0;
	font-weight: 800;
}
.pxl-page-not-found-wrap > .cun-noDesign-inner > p {
    font-size: 18px;
    padding: 5px 0 15px;
    font-weight: 500;
    color: #757474;
    max-width: 395px;
    margin: 0 auto;
}
/*--------------------------- Responsive ---------------------------*/
@media(max-width:1800px) {
	.openLibrarySidebar .sch-post-center {
		padding: 0 450px 100px 340px;
	}
}
@media(max-width:1600px) {
	.pxl-mydesign-createProjectBox .sch-workspace-inenr-img-flex {
		grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
	}
	.sch-template-box{
		margin: 0;
	}
	.sch-divide-flex.sch-section-bottom-space {
		padding: 30px 0 30px;
	}
	.sch-count-cards {
		padding: 30px 30px;
	}
	.sch-header-col-sm{
		/* width: fit-content !important; */
	}
	.pxl-mydesign-createProjectBox .sch-workspace-box-fix a{
		grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
	}
}
@media(max-width:1500px){
	/* .sch-header-wrapper {
		position: fixed;
		width: 100%;
	} */
	 
	.openLibrarySidebar .sch-post-center {
		padding: 0 460px 100px 360px;
	}
}
@media(max-width:1400px){
	.sch-count-widget {
		width: 230px;
	}
	.sch-grid {
		display: flex;
		grid-gap: 15px;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
	}
	.sch-count-widget:before{
		display: none;
	}
}
@media(max-width:1385px){

}
@media(max-width:1345px) {
	.openLibrarySidebar .sch-post-center {
		padding: 0 410px 100px 360px;
	}
	.pxl-mydesign-createProjectBox .sch-workspace-inenr-img-flex {
		grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
	}
	.sch-post-right{
		max-width: 390px;
		right: -390px;
	}
	/* .sch-post-right {
		max-width: 400px;
	} */

	/* .sch-post-center {
		padding: 0 400px 100px 330px;
	} */

	.nav-tabs .nav-link {
		font-size: 13px;
		padding: 0px 10px 14px;
	}
}



@media(max-width:1399.98px) {
	/* .sch-main-menu .menu {
		grid-gap: 6px;
	} */

}
@media (min-width: 1200px) {
	.sch-crete-post-inner.openLibrarySidebar .sch-post-content textarea {
		height:240px;
	}
	.sch-main-menu .menu>li:hover>a {
		color: var(--sch-primary-color);
	}

	.sch-main-menu .menu>li.active a:before {
		opacity: 1;
	}

	.sub-menu {
		position: absolute;
		width: max-content;
		min-width: 200px;
		top: 90%;
		left: 0;
		z-index: 9;
		transform: translate(0px, 20px);
		-webkit-transition: var(--sch-transition);
		-moz-transition: var(--sch-transition);
		-ms-transition: var(--sch-transition);
		-o-transition: var(--sch-transition);
		transition: var(--sch-transition);
		opacity: 0;
		visibility: hidden;
		border: none;
		border-radius: 0px 0px 10px 10px;
		padding: 9px 0 7px;
		background: var(--sch-white);
		border-top: 3px solid var(--sch-primary-color);
		box-shadow: 0 0 30px rgb(0 0 0 / 4%);
	}

	.sub-menu li {
		display: block;
		text-align: left;
		padding: 0;
	}

	.sub-menu li a {
		display: block;
		padding: 5px 20px;
		overflow: hidden;
		position: relative;
	}

	.sub-menu li a:hover {
		color: var(--sch-primary-color);
	}

	.sch-main-menu>ul>li:hover .sub-menu {
		transform: translate(0px, 0px);
		visibility: visible;
		opacity: 1;
	}

}


@media(max-width:1200px) {
	.pxl-informationSec.sch-tooltipWrap .sch-tooltipShow {
		left: 95px;
		top: 6px;
	}
	/* a.sch-menu-btn {
		opacity: 1;
		visibility: visible;
	} */
	.openLibrarySidebar .sch-post-center {
		padding: 0 390px 100px 0;
	}
	.sch-post-arrow-responsive {
		display: block;
	}

	.sch-post-area-box {
		/* z-index: 0; */
	}
	.sch-socialAccount-toggle {
		display: flex;
		align-items: center;
		opacity: 1;
		visibility: visible;
		position: absolute;
		right: -40px;
		top: 10px;
		z-index: -1;
	}
	
	.sch-postUploadImg {
		max-height: 88px;
	}
	a.sch-accountSocial-arrow {
		opacity: 1;
		visibility: visible;
	  }
	  .sch-post-left {
		width: 300px;
		position: fixed;
		z-index: 1;
		transition: all 0.5s;
		box-shadow: 0px 0px 20px 0px #555F861A;
		background: #ffffff;
		transition: all 0.5s;
		height: 100vh;
		padding: 10px;
		left: -300px;
		top: 75px;
		border-right: 1px solid #F2E1F9;
		border-top: 1px solid #F2E1F9;
		z-index: 99;
	}
	 
	.sch-post-left.classAdd {
	  left: 0px;
	  top: 80px;
	}
	.sch-post-right.openLibrarySidebar {
		right: 0px;
		z-index: 111;
	}
	.sch-right-inner {
		margin: 0px 0px;
		border-radius: 0px;
	}

	.sch-color-picker-svg>a {
		width: 40px;
		height: 40px;
		right: 10px;
		top: 10px;
	}

	.sch-post-center {
		padding: 0 0px 100px 0px;
	}

	.pxl_rightbarSwitch {
		display: flex;
	}

	.sch-post-right.openRightBar {
		left: auto;
		right: 0;
		border: 1px solid #e5e5e5;
	}
}


@media(max-width:991px){
	.modal.show .modal-dialog {
		padding: 0 20px 70px;
	}
	.pxl-mydesign-createProjectBox .sch-section-flex .sch-workspace-info > h2 > a {
		max-width: 220px;
	}
	.pxl-mydesign-createProjectBox .sch-workspace-inenr-img-flex {
		grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
	}
	.sch-divide-flex.sch-divide-flex-res {
		display: flex;
	}
	.sch-divide-flex.sch-section-bottom-space.pxl-dashboardFlex {
		display: flex;
	}
	.sch-divide-flex {
		display: block;
	}
	a.sch-accountSocial-arrow {
		top: 0px;
	}
	.sch-post-left.classAdd {
		top: 70px;
	}
	.sch-post-right {
		top: 0px;
	}
	.sch-header-col-sm {
		width: 200px;
	}
	.sch-main-menu .menu>li.cun-resSelect>a {
		width: 100%;
		display: block;
		padding: 10px 5px 0 10px;
	}
	/* .sch-header-wrapper {
		padding: 10px 0;
	} */
	.sch-header-col{
		min-height: fit-content;
	}
	a.sch-btn-iconLeft{
		height: auto;
	}
	.sch-menu-btn {
		width: auto;
		height: auto;
		padding: 10px 10px;
	}
	.sch-col-5 {
		width: 50%;
		padding: 0 15px;
	}
	
	.sch-user-img>img {
		width: 32px;
		height: 32px;
	}
	.sch-user-name>h2 {
		width: 130px;
	}
	.sch-post-center {
		padding: 0px 0px 100px;
	}
	.openLibrarySidebar .sch-post-center {
		padding: 0 0px 100px 0;
	}
	.sch-menu-btn span>span {
		width: 20px;
		height: 3px;
		margin: 3px auto;
	}
	.pxl-images-templateMain-wrapper-box .sch-tempalte-innerwrap {
		/* display: block; */
	}
	.pxl-images-templateMain-wrapper-box .sch-template-left-category {
		/* width: 100%; */
	}
	.pxl-mydesign-createProjectBox .sch-myDesignFlex {
		align-items: unset;
	}
}
@media(max-width:960px){
	.pxl-mydesign-createProjectBox .sch-workspace-inenr-img-flex {
		grid-template-columns:repeat(auto-fill, minmax(110px, 1fr));
	}
}
@media(max-width:923px){
	.pxl-mydesign-createProjectBox .sch-workspace-inenr-img-flex {
		grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
	}
}

@media(max-width:767px){
	.pxl-uncategorize-heading > h2 {
		max-width: 150px;
	}
	.pxl-mydesign-createProjectBox .sch-myDesignFlex.pxl-myProject4Box {
		display: flex;
	}
	.pxl-mydesign-createProjectBox .sch-myDesignFlex.pxl-myProject4Box .cun-headerSelect-wrap {
		margin: 0;
	}
	.pxl-page-not-found-wrap .cun-noDesign-inner > img {
		max-width: 450px;
	}
	.pxl-schedular-dash-recent-draft.pxl-recentDraft-indexBox .sch-divide-flex.sch-divide-flex-res.sch-section-bottom-space {
		padding: 0px 0 30px;
	}
	.sch-grid.pxl-dashboard-count-box {
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		display: grid;
		grid-gap: 20px;
	}
	.sch-grid.pxl-dashboard-count-box .sch-count-widget {
		width: 100%;
	}
	.pxl-ticketBoxColor1:after{
		display: none;
	}
	.pxl-ticketBoxColor2:after{
		display: none;
	}
	.pxl-ticketBoxColor3:after{
		display: none;
	}
	.pxl-ticketBoxColor4:after{
		display: none;
	}
	.pxl-ticketBoxColor5:after{
		display: none;
	}
	.pxl-images-templateMain-wrapper-box ul.sch-templateSubcategory-ul>li>a>p {
		max-width: 90px;
	}
	.pxl-mydesign-createProjectBox .sch-workspace-inner-box a img {
		min-height: 100px;
		max-height: 100px;
	}
	.pxl-mydesign-createProjectBox .sch-workspace-inenr-img-flex {
		grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
	}
	p.sch-workspace-files {
		top: 37px;
	}
	.sch-workspace-inner-box.sch-workspace-file-box a img {
		padding: 23px 0px 0;
	}
	.pxl-mydesign-createProjectBox .sch-myDesignFlex {
		display: block;
	}
	.pxl-mydesign-createProjectBox .sch-myDesignFlex .cun-headerSelect-wrap {
		margin: 15px 0 0;
	}
	.pxl-mydesign-createProjectBox .sch-section-flex .sch-workspace-info > h2 > a {
		max-width: 210px;
	}
	/* .sch-workspace-main-wrapper.pxl-workspace-repear-boxSingle .sch-workspace-row {
		display: block;
	} */
	.sch-workspace-main-wrapper.pxl-workspace-repear-boxSingle .sch-workspace-row .sch-workspace-repear-box.false {
		margin: 30px 0px 0px;
	}

	.pxl-mydesign-createProjectBox .sch-workspace-box-fix {
		padding: 10px 50px;
		/* margin: 0 0 15px; */
	}
	.pxl-mydesign-createProjectBox .sch-workspace-repear-box {
		/* margin: 0px 0px 15px; */
	}
	.sch-uncategorized-mainwrapper-box.pxl-mydesign-createProjectBox .sch-workspace-row .sch-workspace-repear-box {
		margin: 0;
	}
	.pxl-mydesign-createProjectBox .sch-workspace-row {
		grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
	}
	.sch-schedular-heading-name > h2:after{
		display: none;
	}
	.sch-post-bottom-btns {
		/* margin: 0px auto 70px; */
	}
	
	.sch-post-schedule-area {
		/* display: block; */
	}
	.sch-select-schedule-date {
		/* text-align: center;
		margin: 0 auto;
		max-width: max-content; */
	}

	
	
	
	.sch-select-schedule-date > h2 {
		width: max-content;
	}
	.sch-headBtn button.sch-btn-iconLeft {
		height: 41px;
		padding: 0px 10px;
	}
	.sch-headBtn button.sch-btn-iconLeft span {
		display: none;
	}
	.openLibrarySidebar .sch-post-center {
		padding: unset;
	}
	.sch-post-center {
		/* padding: unset; */
	}
	/* .sch-header-wrapper {
		padding: 15px 0px;
	} */
	.sch-btns-wrap.sch-nav-open .sch-menu-btn span>span:nth-child(1) {
		transform: translate(0px, 4px) rotate(-48deg);
	}
	.sch-menu-btn {
		padding: 12px 12px;
	}
	.user-info {
		grid-gap: 7px;
	}
	.user-info img.user-img {
		height: 40px;
		width: 40px;
	}
	.sch-menu-btn span>span {
        height: 2px;
    }
	/* .sch-post-center {
		padding: 10px 0px 100px 0px;
	} */
	.cun-uncategorizedWrapper.pxl-uncategorized-singleWrap .cun-headerSelect-wrap {
		margin: 0;
	}
	.cun-uncategorizedWrapper.pxl-uncategorized-singleWrap .cun-AllChecknoxSelect {
		margin: 0;
	}
	.sch-grid.pxl-dashboard-count-box .sch-count-widget {
		/* width: 200px; */
	}
	.sch-footer-post-ul2>li:nth-child(2)>a {
		width: 110px;
	}
	.sch-footer-post-ul2>li:nth-child(3)>a {
		width: 110px;
	}
}
@media(max-width:580px){
	.cun-noDesign-inner>h2 br {
		display: none;
	}
	.pxl-schedular-dash-recent-draft.pxl-recentDraft-indexBox .sch-divide-flex.sch-divide-flex-res.sch-section-bottom-space {
		padding: 30px 0 30px;
	}
	.sch-grid.pxl-dashboard-count-box {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}
	ul.sch-footer-post-ul2 li:nth-child(2).sch-aiText .cun-show-tooltip {
		left: -20px;
	}
	.sch-footer-post-ul2 {
		gap: 10px;
	}

	.cun-show-tooltip {
		display: none;
	}
	.sch-post-left.classAdd {
		top: 57px;
	}
	.pxl-mydesign-createProjectBox .sch-section-flex .sch-workspace-info > h2 > a {
		max-width:190px;
	}
	.sch-workspace-main-wrapper.pxl-workspace-repear-boxSingle .sch-workspace-row .sch-workspace-box-fix {
		padding: 0px;
	}
	.sch-workspace-main-wrapper.pxl-workspace-repear-boxSingle .sch-workspace-row .sch-workspace-box-fix a{
		padding: 80px 0;
	}
	.cun-uncategorizedWrapper.pxl-uncategorized-singleWrap .cun-headerSelect-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.cun-uncategorizedWrapper.pxl-uncategorized-singleWrap {
		padding: 25px 0 0;
	}
	.cun-uncategorizedWrapper.pxl-uncategorized-singleWrap {
		/* display: flex;
		align-items: center;
		justify-content: center; */
	}
	.pxl-mydesign-createProjectBox .sch-workspace-inner-box a img {
		/* min-height: 100px; */
	}
	.sch-workspace-inner-box.sch-workspace-file-box a img {
		padding: 25px 0px 0;
	}
	.sch-post-left {
		top: 56px;
	}
	.sch-schedular-heading-name h2 {
		font-size: 16px !important;
		font-weight: 600 !important;
	  }
	  p.sch-workspace-files {
		top: 35px;
	}
	.sch-count-icon{
		display: block;   
		text-align: center;
	}
	.sch-count-widget p {
		font-weight: 500;
	}
	.sch-count-widget {
		justify-content: center;
	}
	.sch-banner-box.pxl-dashboardBanner-box .sch-banner-content > h2 {
		font-size: 22px;
		font-weight: 500;
		text-align: center;
		color: var(--sch-white);
		margin: 0 0 5px;
		/* text-transform: capitalize; */
	}
	
	.sch-banner-box.pxl-dashboardBanner-box .sch-banner-content > p {
		font-size: 16px;
        line-height: normal;
        padding: 10px 0 0 0;
	}

	.header_nine_dot_wrapper .menu_dot {
		margin-left: 0;
	}
	.sch-header-col-sm {
		width: 120px;
	}
	/* .pxl_headerWrapper {
		margin-bottom: 0;
	} */
	.sch-count-cards {
		padding: 20px 20px;
	}
	.sch-grid {
		margin: 0px;
	}
	.sch-post-title-input label {
		padding: 0;
	}
	.sch-post-title-input{
		display: block;
		text-align: center;
	}
	.sch-post-title-input .pxl-postTitle-input input {
		width: 100%;
	}
	.pxl-postTitle-input {
		max-width: 300px;
		margin: 10px auto 0;
	}
	.sch-post-center h2.sch-tab-heading {
		padding: 0 0 15px;
	}
	.sch-count-widget {
		width: 100%;
		margin: 0px;
	}
	.sch-menu-btn {
		padding: 8px 8px;
	}
	.sch-header-col{
		min-height: auto;
	}
	.sch-schedule-hashtag {
		justify-content: center;
		margin: 10px 0 0;
	}
	
	ul.sch-apps-flex>li>a>p {
		padding: 5px 0px 0px 0px;
	}
	li.sch-aiText.cun-socialIcon .cun-show-tooltip {
		display: block !important;
	}
	.sch-post-bottom {
		padding: 0px 15px;
	}
	.sch-select-schedule-date > h2 >img {
		display: none;
	}
	.pxl-pixasupport-tickets-status-wrap {
		grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	}
}
@media(max-width:575.98px) {

	/* .user-info-wrapper {
		position: relative;
		top: 0;
		right: 0;
	} */

	.sch-header-wrapper .sch-btn {
		padding: 8px 10px;
		font-size: 14px;
	}
}

@media(max-width:575px) {
	.pxl-uncategorize-heading > h2 {
		max-width: 100px;
		padding: 0 0 15px 0;
	}
	.pxl-draftSingle-mainWrapper-box .sch-draft-social-icons-wrap {
		display: block;
	}
	.pxl-images-templateMain-wrapper-box .sch-template-left-category {
		width: 350px;
	}
	.sch-banner-box.pxl-dashboardBanner-box {
		background-image: linear-gradient(90.81deg, #6F10B9 8.52%, #C02A93 110.1%);
		padding: 20px 20px 25px;
		min-height: auto;
	}
	
	.sch-banner-box.pxl-dashboardBanner-box .pxl-banner-create-design-post-btn >ul{
		display: block;
	}
	.sch-banner-box.pxl-dashboardBanner-box .pxl-banner-create-design-post-btn >ul >li {
		margin: 10px 0 0;
	}
	.sch-footer-post-ul2>li>a {
		height: auto;
		border-radius: 5px;
		padding: 10px 10px;
		max-width: max-content;
		font-size: 14px;
	}

	.sch-footer-post-ul2>li>a > svg {
		width: 23px;
		height: 23px;
	}
	.sch-select-schedule-date > h2 {
		width: max-content;
		padding: 13px 10px;
		font-size: 14px;
		border-radius: 5px;
	}
	.pxl-user-info-userName-box > ul > li > a > .pxl-userInfoBox img {
		width: 40px;
		height: 40px;
	}
	.pxl-userInfoBox1 > ul > li > a .pxl-userInfoBox img {
		width: 30px;
		height: 30px;
	}
	.pxl-userInfoBox1 {
		height: 40px;
		margin: 20px 0 0 10px;

	}
	.pxl-user-info-userName-box > ul > li>a:after {
		bottom: -10px;
	}
	.pxl-user-info-userName-box {
		padding: 10px 10px 10px 10px;
	}
	.user-info-box >ul {
		padding: 10px 20px 5px;
	}
	.user-info-box > ul > li > a {
		font-size: 14px;
	}
	.user-info-box{
		right: -10px;
		top: 50px;
	}
	.user-info {
		grid-gap: 2px;
	}
	.sch-header-col {
		min-height: auto;
	}
	
	.user-info img.user-img {
		height: 35px;
        width: 35px;
	}
	.sch-menu-btn {
		width: 35px;
		height: 35px;
		padding: 4px 7px 4px;
	}
	.header_nine_dot_wrapper .menu_dot img {
		width: 35px;
		height: 35px;
	}
	.sch-btns-wrap .sch-headBtn button.sch-btn-iconLeft {
		height: 35px;
	}
	.sch-headBtn button.sch-btn-iconLeft {
		height: 36px;
		padding: 0px 10px;
	}
	.cun-noDesign-inner {
		padding: 50px 0;
	}
	.cun-noDesign-inner>img {
		max-width: 150px;
	}
	.cun-noDesign-inner>h2 {
		font-size: 20px;
	}
}
@media(max-width:656px){
	.pxl-mydesign-createProjectBox .sch-workspace-inenr-img-flex {
		grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
	}
}
@media(max-width:541px){
	.pxl-mydesign-createProjectBox .sch-workspace-box-fix {
		padding: 50px 50px;
	}
	.pxl-mydesign-createProjectBox .sch-workspace-row {
		grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
	}
}
@media(max-width:480px) {
	.sch-post-schedule-area {
		/* display: block; */
		padding: 0 0 5px;
	}
	.pxl-apps-heading {
		padding: 0;
	}
	.pxl-mydesign-createProjectBox .sch-myDesignFlex.pxl-myProject4Box {
		display: block;
	}
	.pxl-page-not-found-wrap > .cun-noDesign-inner > p {
		font-size: 16px;
		padding: 5px 0 15px;
		max-width: 325px;
	}
	.pxl-page-not-found-wrap > .cun-noDesign-inner > h2 > span {
		font-size: 30px;
	}
	.pxl-page-not-found-wrap > .cun-noDesign-inner > h2 {
		font-size: 20px;
	}
	.pxl-page-not-found-wrap .cun-noDesign-inner > img {
		max-width: 350px;
	}
	.pxl-schedular-dash-recent-draft.pxl-recentDraft-indexBox .sch-divide-flex.sch-divide-flex-res.sch-section-bottom-space {
		padding: 0px 0 30px;
	}
	.pxl-dashboard-trendingTemplates {
		padding: 0px 0 30px 0;
	}
	.pxl-pixasupport-ticket-statusBox {
		padding: 20px 20px;
	}
	.pxl-pixasupport-ticket-statusBox h2 {
		font-size: 25px;
	}
	.pxl-pixasupport-tickets-status-wrap {
		grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
		gap: 10px;
	}
	.pxl-images-templateMain-wrapper-box ul.sch-templateSubcategory-ul>li>a>p {
		max-width: 100px;
	}
	.sch-banner-box.pxl-dashboardBanner-box .sch-banner-content > h2 {
		font-size: 20px;
	}
	.remove .cun-noDesign-inner {
		padding: 20px 30px;
	}
	.cun-noDesign-inner>h2 {
		font-size: 18px;
		font-weight: 500;
	}
	ul.sch-footer-post-ul2 li:nth-child(2).sch-aiText .cun-show-tooltip {
		left: -40px;
	}
	li.sch-aiText.cun-socialIcon .cun-show-tooltip {
		width: 150px;
		top: -60px !important;
		left: -20px;
	}
	
	.sch-count-icon {
		display: flex;
		justify-content: center;
	}
	.sch-grid.pxl-dashboard-count-box .sch-count-widget {
		width: 100%;
		margin: 0 0 20px 0;
	}
	.sch-grid.pxl-dashboard-count-box {
		display: block;
	}
	.pxl-informationSec.sch-tooltipWrap .sch-tooltipShow {
		width: 98px;
		font-size: 10px;
	}
	.pxl-uncategorize-heading > h2 > a {
		font-size: 18px;
	}
	.pxl-images-templateMain-wrapper-box .sch-template-category>ul>.sch-categoryInnerDiv>li>.main>a>p {
		max-width: 200px;
	}
	.sch-banner-box.pxl-dashboardBanner-box .sch-search-box .sch-search-row input {
		padding: 0px 16px 0 10px;
	}
	.pxl_headerWrapper .sch-main-menu {
		max-width: 250px;
	}
	/* .sch-footer-post-ul2>li>a {
		padding: 6px 6px;
	} */
	/* .sch-footer-post-ul2>li>a > svg {
		width: 18px;
		height: 18px;
	} */
	/* .sch-footer-post-ul2>li>a{
		font-size: 0px;
	} */
	.sch-footer-post-ul2 {
		gap: 5px;
	}
	.sch-footer-post-ul2>li:nth-child(2)>a span {
		padding-right: 0;
	}
	.sch-footer-post-ul2>li:nth-child(2)>a span {
		display: none;
	}
	.sch-footer-post-ul2>li:nth-child(3)>a span {
		display: none;
	}
	.sch-post-bottom {
		padding: 0px 10px;
	}
	.sch-select-schedule-date > h2 {
		padding: 13px 10px;
		font-size: 12px;
	}

	.sch-btns-wrap .sch-headBtn button.sch-btn-iconLeft {
		height: 28px;
	}

	.sch-headBtn button.sch-btn-iconLeft {
		height: 31px;
		font-size: 12px;
	}
	.sch-menu-btn {
		width: 30px;
		height: 31px;
	}
	.sch-btns-wrap>ul>li:nth-child(1)>a.sch-btn-iconLeft {
		padding: 5px 5px;
		font-size: 12px;
	}
	.sch-post-left-count .sch-tooltipShow {
		left: -140px;
	}
	.sch-post-left-count .sch-tooltipShow:before {
	  transform: rotate(-90deg);
	  left: auto;
	  right: -13px;
	}
	.sch-btns-wrap>ul {
		grid-gap: 5px;
	}
	/* .sch-btns-wrap {
		position: absolute;
	} */
	.sch-post-left {
		width: 270px;
		left: -270px;
	}
	.sch-socialAccount-toggle {
		right: 0;
	}
	a.sch-accountSocial-arrow {
		width: 30px;
		height: 30px;
		right: -30px;
	}
	a.sch-accountSocial-arrow svg{
		width: 10px;
		height: 10px;
	}
	.sch-user-name>h2 {
		width: 95px;
	}
	.sch-library-tab .nav.nav-tabs .nav-item .nav-link.active {
		background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
		color: #ffffff;
	}
	.sch-header-row .sch-header-col:last-child {
		width: auto;
	}

	
	.sch-btns-wrap a.sch-btn {
		padding: initial;
		font-size: 17px;
		height: auto;
		width: 33px;
		height: 33px;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 23px;
	}
	.drop-down-header h4 {
		display: none;
	}
	.sch-menu-btn span>span {
		width: 15px;
	}
	.sch-post-arrow-responsive a svg {
		fill: #ffffff;
		width: 10px;
		height: 10px;
	}
	.sch-post-arrow-responsive a {
		width: 30px;
		height: 30px;
		left: -30px;
	}
	.sch-post-right {
		max-width: 260px;
		right: -260px;
	}
	.sch-post-bottom-btns {
		justify-content: center;
		flex-wrap: wrap;
	}


	/* .sch-post-arrow-responsive a {
		left: -150px;
	} */
	.sch-library-tab .nav-tabs .nav-link.active:before {
		opacity: 0;
	}
	.sch-library-tab .nav.nav-tabs .nav-item .nav-link {
		background: #ffffff;
		margin: 0px 3px 5px;
		border-radius: 3px;
		padding: 5px 10px;
	}
	.sch-btn {
		padding: 9px 14px 12px;
		height: 40px;
		font-size: 12px;
	}
	
	.sch-post-left-count>h2 {
		font-size: 18px;
	}
	.header_nine_dot_wrapper .menu_dot img {
		width: 28px;
		height: 28px;
	}
	.user-info img.user-img {
		height: 28px;
		width: 28px;
	}
	.sch-menu-btn {
		width: 28px;
		height: 28px;
	}
	.sch-banner-select-search-box .cun-headreSelect {
		min-width: 100px;
	}
	.sch-post-bottom {
		bottom: 5px;
	}
	.sch-myDesignFlex .cun-headerSelect-wrap {
		flex-wrap: wrap;
		gap: 5px;
	}
}
@media(max-width:485px){
	.pxl-mydesign-createProjectBox .sch-workspace-row {
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	}
	.pxl-mydesign-createProjectBox .sch-workspace-inenr-img-flex {
		grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
	}
}
@media(max-width:480px){
	.sch-post-bottom-btns button.pxlCommon-btn {
		min-width: 80px;
		padding: 10px 15px;
		height: auto;
	}
}
@media(max-width:380px) {
	.sch-myDesignFlex.pxl-myProject4Box .cun-headreSelect {
		min-width: 150px;
	}
	.pxl-page-not-found-wrap > .cun-noDesign-inner > h2 > span {
		font-size: 25px;
	}
	.pxl-page-not-found-wrap .cun-noDesign-inner > img {
		max-width: 230px;
	}
	.sch-footer-post-ul2 {
		gap: 5px;
	}
	.sch-footer-post-ul2>li>a {
		padding: 9px 10px;
		font-size: 11px;
	}
	.sch-select-schedule-date > h2 {
		padding: 9px 9px;
		font-size: 12px;
	}
	.sch-footer-post-ul2>li>a > svg {
		width: 18px;
		height: 18px;
	}
	.sch-post-bottom {
		padding: 0px 7px;
	}
	.sch-btns-wrap>ul>li:nth-child(1)>a.sch-btn-iconLeft {
		font-size: 10px;
	}
	.sch-header-wrapper .sch-container-fluid {
		padding: 0px 10px;
	}
	.sch-post-left {
		top: 48px;
	}
	.sch-post-left.classAdd {
		top: 48px;
	}
	
}



/* TickTok  Modal Style*/
.pxl_ticktok_modal  .modal-body {
    padding: 40px;
}
.pxl_btn_wrap_tiktok {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
	margin: 30px 0 0;
    justify-content: center;
}
.pxl_btn_wrap_tiktok .sch-common-btn-white {
    color: #2E253A;
    font-weight: 500;
    padding: 12px 20px;
    min-width: 120px;
}
/* CheckBox Style  */
.pxl_custom_checkbox label {
    width: fit-content;
    display: inline-block;
    margin: 0;
}
.pxl_custom_checkbox {
    display: block;
	position: relative;
}
.pxl_custom_checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none !important;
    cursor: pointer;
  }  
  .pxl_custom_checkbox label {
    position: relative;
    cursor: pointer;
  }
  .pxl_custom_checkbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: #FAFCFF;
    border: 2px solid #D6DFEB;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
    height: 20px;
    width: 20px;
    vertical-align: text-bottom;
}
.pxl_custom_checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 7px;
    width: 6px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.pxl_custom_checkbox input:checked + label:before {
	background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);
	border-color: #c02a93;
}
  /**/
  .pxl_ticktok_modal .modal-header {
    border: 0;
    padding: 0;
}
.pxl_ticktok_modal .modal-dialog {
    max-width: 360px;
}
.modal-centered-title {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    padding: 0 0 20px;
}
.pxl_custom_checkbox_wrap {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    align-items: center;
}
/* Switch  */
.pxl_input_feilds.plx_has_switch label.plx_custom_switch {
    display: inline-block;
    height: 18px;
    position: relative;
    width: 32px;
    margin: 0;
}
    .plx_custom_switch input {
	display:none;
  }
    .plx_slider {
	background-color: #ccc;
	bottom: 0;
	cursor: pointer;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: .4s;
  }
  .plx_slider:before {
    background-color: #fff;
    bottom: 2px;
    content: "";
    height: 14px;
    left: 2px;
    position: absolute;
    transition: .4s;
    width: 14px;
}
.plx_custom_switch input:checked + .plx_slider::before {
    transform: translateX(14px);
}
   .plx_custom_switch input:checked + .plx_slider {
	background-color:  #91ED48;
	;
  }
    .plx_custom_switch input:checked + .slider:before {
	transform: translateX(26px);
  }
    .plx_slider {
	border-radius: 34px;
  }
    .plx_slider:before {
	border-radius: 50%;
  }
  .pxl_input_feilds.plx_has_switch {
    display: grid;
    grid-template-columns: 35px 1fr;
    align-items: center;
    grid-gap: 5px;
}
.pxl_input_feilds.plx_has_switch label {
	margin: 0;
	width: fit-content;
}
.plx_slider.disable {
	cursor: not-allowed;

}

/* Icons Style  */
.sch-accodian-body1 .sch-accodian-user .sch-custom-check-box {
    padding-right: 60px;
}
.sch-accodian-body1 .sch-accodian-user .sch-custom-check-box .form-group label:before {
    right: 25px;
}  	
.sch-accodian-body1 .sch-accodian-user .sch-custom-check-box .form-group label:after {
    right: 40px;
}
.ticktok-settings {
    position: absolute;
    right: 0;
    top: 0px;
    cursor: pointer;
}